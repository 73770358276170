import React from 'react';
import { Tab } from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import PropTypes from 'prop-types';

const UrdTabs = ({ list, onClick }) => {
    // ===== Vertical URD Tabs ======
    return (
        <div className="verticalTab">
            {/* Generate the vertical tabs based on API response */}
            {
                list.map((item, i) => (
                    <Tab tabFor={i} onClick={(e) => onClick(e, item.name, item.unit)}
                    ><b>{item.taglabel}</b></Tab>
                ))
            }
        </div>
    )
}

UrdTabs.propTypes = {
    onClick: PropTypes.func.isRequired,
}

export default UrdTabs;