import {
  INIT, SUCCESS, LOADING, ERROR,
} from '../../utils/constant';
import {
  GET_USER, GET_USER_SUCCESS, GET_USER_ERROR, LOGOUT_USER,
  GET_URL_DATA, GET_URL_DATA_SUCCESS, GET_URL_DATA_ERROR, GET_USER_DATA_INIT, GET_USER_DATA_SUCCESS, GET_USER_DATA_FAILED
} from './constant';

const initialState = {
  phase: INIT,
  user: null,
  error: null,
  urlData:null,
  status: '',
  sessionFlag:null
};

/**
 * User Reducer
 * @param {Object} state
 * @param {Object} action
 */
export function userStore(state = initialState, action) {
   
  switch (action.type) {
    case GET_USER:
    case GET_URL_DATA:
      return {
        ...state,
        phase: LOADING,
        status: GET_USER_DATA_INIT
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        user: action.data,
        sessionFlag:action.sessionFlag,
        error: null, 
        status: GET_USER_DATA_SUCCESS
      };
    case GET_USER_ERROR:
    case GET_URL_DATA_ERROR:
      return { 
        ...state,
        phase: ERROR,
        error: action.error,
        status: GET_USER_DATA_FAILED
      };

    case GET_URL_DATA_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        urlData: action.payload,
        error: null,
      };
    
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}

/**
 * Get User Info for Login
 * @param {String} email
 */
export const loginUser = payload => ({
  type: GET_USER,
  payload,
});


/**
 * Get Url Info for Login
 * @param {String} email
 */
export const getUrlData = payload => ({
  type: GET_URL_DATA,
  payload
});
