import BootstrapTable from "react-bootstrap-table-next";
import React, { Component, Fragment } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import Popup from "../shared/Popup";
import { getURLDetails } from "../../utils/methods";
import ProgressBar from "react-bootstrap/ProgressBar";
import { prepareErrorPopupMessage, getMaxLineNo } from "../../utils/helper";
import toastr from "toastr";
import PriceRollup from "./price-rollup";

class EnteredModelData extends Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this); // To open and close the accordion

    this.state = {
      show: false,
      isEnabled: true,
      isIconVisible: true,
      isErrorPopup: false,
      cellValue: "",
      submittedLines: false,
      processingLines: false,
      cancelDisabled: false,
      refreshDisabled: false,
      showPriceRollup: false,
      assembleToData: [],
      priceRollData: [],
      countMap: {},
      priceRollDropdownData: [],
      isEnabledSubmitOk: false,
    };
  }
  completedLines = {};

  componentDidUpdate(prevProps) {
    const { MyGetSubmitedData, concurrentFlagStatus, assembleToData, priceRollData } = this.props;
    if (prevProps.concurrentFlagStatus !== concurrentFlagStatus) {
      if (concurrentFlagStatus === "Y") {
        toastr.success("Submitted Lines Successfully", "Success");
        MyGetSubmitedData("Y");
        this.setState({ refreshDisabled: true });
        this.clearRefresh();
      }
    }

    if (prevProps.priceRollData !== priceRollData) {
      let priceRollData = Object.assign([], this.props.priceRollData);
      let countMap = priceRollData.reduce((map, row) => {
        if (!map[row.LINE_NUMBER]) map[row.LINE_NUMBER] = 1;
        else map[row.LINE_NUMBER] += 1;
        return map;
      }, {})
      this.setState({
        priceRollData,
        countMap
      });
    }
    if (prevProps.assembleToData !== assembleToData) {
      let assembleToData = Object.assign([], this.props.assembleToData);
      this.setState({
        assembleToData
      });
      let priceRollDropdownData = Object.assign([], this.props.priceRollDropdownData);
      this.setState({
        priceRollDropdownData
      });
    }
  }

  componentWillReceiveProps({ enteredModelData }) {
    if (
      enteredModelData.length !== this.props.enteredModelData.length &&
      enteredModelData.length > 0
    ) {
      let lineStatus = enteredModelData.find(x => {
        return (x.LINE_STATUS = this.lineStatusInfo(x.LINE_STATUS) !== null);
      });
      if (Boolean(lineStatus)) this.refreshDataTable();
      this.setState({
        submittedLines: Boolean(lineStatus),
        isEnabled: !Boolean(lineStatus),
        isIconVisible: !Boolean(lineStatus),
        cancelDisabled: Boolean(lineStatus),
        refreshDisabled: Boolean(lineStatus)
      });
    }
  }


  // ===== Function called when the accordion is opened and closed =====
  handleClose = async e => {
    this.setState({ show: false });
  };

  // Functioned called when My enterted model data needs to be refreshed =====
  refreshDataTable = () => {
    const { getEnteredModelData } = this.props;
    setTimeout(() => {
      getEnteredModelData();
    }, 1000);
    this.refreshDataTable.intervalId = setInterval(() => {
      getEnteredModelData();
    }, 20000);
  };

  clearRefresh = () => {
    if (this.refreshDataTable.intervalId) {
      clearInterval(this.refreshDataTable.intervalId);
      this.refreshDataTable.intervalId = null;
    }
  };

  // ===== Function called to submit all the lines =====
  submitData = async () => {
    const { initSubmitData, visibleSections } = this.props;
    this.completedLines = {};
    this.setState({
      isEnabled: false
    })
    await initSubmitData("N");
    this.refreshDataTable();
    this.setState({
      isIconVisible: false,
      showDeleteConfirmation: false,
      deleteRow: -1,
      submittedLines: true,
      processingLines: true
    });
    visibleSections();
  };

  // ===== Function called when the accordion is opened and closed =====
  handleShow() {
    this.setState({ show: true, isEnabledSubmitOk: true });
  }

  // ===== Function called to cancel the request submitted =====
  cancelData = async () => {
    const { getCancelData } = this.props;
    await getCancelData();
  };

  // Functioned called when My enterted model data needs to be refreshed =====
  refreshData = async () => {
    await this.props.getEnteredModelData();
  };

  // ===== Function called when the edit button is clicked =====
  editData = (e, rowIndex, modelName, row, quantityCheck) => {
    const { getAdditionalInfoData, divCode, baseRow } = this.props;
    this.props.getEditData({ lineNumber: rowIndex, model: modelName, division: divCode, quantityCheck: quantityCheck });
    getAdditionalInfoData({
      modelName: modelName,
      warehouse: baseRow.warehouse,
      divCode,
      id: baseRow.id
    });
    this.props.clearSaveErrors();
  };

  ErrorPopup = cell => {
    this.setState({
      isErrorPopup: true,
      cellValue: cell
    });
  };

  // === Function to disable the cancel request ===
  disabledCancel = () => {
    this.setState({ cancelDisabled: true });
  };

  // ===== Function to get the status info =====
  statusInfo = (status, statusMessage) => {
    let statusIcon = status;
    let statusMsg = statusMessage;
    if (statusIcon == null) {
      return "";
    } else if (statusIcon.startsWith("INCOMPLETE")) {
      return (
        <i
          className="fa fa-exclamation-circle"
          aria-hidden="true"
          title="Click here to check the Incomplete Message"
          onClick={() => this.ErrorPopup(prepareErrorPopupMessage(statusMsg))}
        />
      );
    } else if (statusIcon.startsWith("UNCONFIG") || statusIcon === "False") {
      return (
        <i
          className="fa fa-times-circle-o"
          title="Click here to see the Error Message"
          onClick={() => this.ErrorPopup(prepareErrorPopupMessage(statusMsg))}
        />
      );
    } else {
      return (
        <i className="fa fa-check-circle-o" title="Validation Successfully" />
      );
    }
  };

  // ===== Function to get the line status ======
  lineStatusInfo = (status, statusMessage, rowIndex) => {
    const { concurrentFlagStatus } = this.props;
    let statusIcon = status;
    let statusMsg = statusMessage;
    switch (statusIcon) {
      case "LINE_ENTERED":
        return (
          <Fragment>
            <ProgressBar
              animated
              now={25}
              label={`25%`}
              variant="success"
              data-toggle="tooltip"
              title="Line Entered"
            />
          </Fragment>
        );
      case "READY_FOR_CONFIGURATION":
        {
          this.completedLines[rowIndex] = true;
        }
        return (
          <Fragment>
            <ProgressBar
              animated
              now={50}
              label={`50%`}
              variant="success"
              data-toggle="tooltip"
              title="Configuring Model"
            />
          </Fragment>
        );

      case "CONFIGURATION_EXCEPTION":
        {
          this.completedLines[rowIndex] = true;
        }
        return (
          <Fragment>
            <ProgressBar
              className="crsr-ptr"
              now={concurrentFlagStatus === "Y" ? 100 : 95}
              label={concurrentFlagStatus === "Y" ? `100%` : `95%`}
              variant="warning"
              data-toggle="tooltip"
              title="Invalid Configuration"
              onClick={() =>
                this.ErrorPopup(prepareErrorPopupMessage(statusMsg))
              }
            />
          </Fragment>
        );
      case "CONFIGURATION_INCOMPLETE":
        {
          this.completedLines[rowIndex] = true;
        }
        return (
          <Fragment>
            <ProgressBar
              animated
              now={75}
              label={`75%`}
              variant="danger"
              data-toggle="tooltip"
              title="Incomplete Configuration"
            />
          </Fragment>
        );

      case "CONFIGURATION_COMPLETED":
        {
          this.completedLines[rowIndex] = true;
        }
        return (
          <Fragment>
            <ProgressBar
              animated
              now={75}
              label={`75%`}
              variant="success"
              data-toggle="tooltip"
              title="Complete Configuration"
            />
          </Fragment>
        );
      case "PRICING":
        {
          this.completedLines[rowIndex] = true;
        }
        return (
          <Fragment>
            <ProgressBar
              animated
              now={90}
              label={`90%`}
              variant="success"
              data-toggle="tooltip"
              title="Pricing Line"
            />
          </Fragment>
        );
      case "ADDED_TO_ORDER":
        {
          this.completedLines[rowIndex] = true;
        }
        return (
          <Fragment>
            <ProgressBar
              now={concurrentFlagStatus === "Y" ? 100 : 95}
              label={concurrentFlagStatus === "Y" ? `100%` : `95%`}
              variant="success"
              data-toggle="tooltip"
              title="Upload Complete"
            />
          </Fragment>
        );
      case "ADDED_TO_ORDER_WARN":
        {
          this.completedLines[rowIndex] = true;
        }
        return (
          <Fragment>
            <ProgressBar
              className="crsr-ptr"
              now={concurrentFlagStatus === "Y" ? 100 : 95}
              label={concurrentFlagStatus === "Y" ? `100%` : `95%`}
              variant="warning"
              data-toggle="tooltip"
              title="Upload Complete with Errors"
              onClick={() =>
                this.ErrorPopup(prepareErrorPopupMessage(statusMsg))
              }
            />
          </Fragment>
        );
      case "PROCESSING_LINES":
        return (
          <Fragment>
            <ProgressBar
              animated
              now={10}
              label={`10%`}
              variant="success"
              data-toggle="tooltip"
              title="Processing Line"
            />
          </Fragment>
        );
      case "ERROR_UPLOADING":
        {
          this.completedLines[rowIndex] = true;
        }
        return (
          <Fragment>
            <ProgressBar
              className="crsr-ptr"
              now={concurrentFlagStatus === "Y" ? 100 : 95}
              label={concurrentFlagStatus === "Y" ? `100%` : `95%`}
              variant="danger"
              data-toggle="tooltip"
              title="Failed to Enter Line"
              onClick={() =>
                this.ErrorPopup(prepareErrorPopupMessage(statusMsg))
              }
            />
          </Fragment>
        );
      case "REQUEST_CANCELLED":
        {
          this.completedLines[rowIndex] = true;
        }
        return (
          <Fragment>
            <ProgressBar
              className="crsr-ptr"
              now={concurrentFlagStatus === "Y" ? 100 : 95}
              label={concurrentFlagStatus === "Y" ? `100%` : `95%`}
              variant="danger"
              data-toggle="tooltip"
              title="Request Cancelled "
              onClick={() =>
                this.ErrorPopup(prepareErrorPopupMessage(statusMsg))
              }
            />
          </Fragment>
        );
      case "PRICING_INCOMPLETE":
        {
          this.completedLines[rowIndex] = true;
        }
        return (
          <Fragment>
            <ProgressBar
              className="crsr-ptr"
              now={concurrentFlagStatus === "Y" ? 100 : 95}
              label={concurrentFlagStatus === "Y" ? `100%` : `95%`}
              variant="danger"
              data-toggle="tooltip"
              title="Pricing Failed"
              onClick={() =>
                this.ErrorPopup(prepareErrorPopupMessage(statusMsg))
              }
            />
          </Fragment>
        );
      default:
        return null;
    }
  };

  processingLineStarted = () => {
    return (
      <Fragment>
        <ProgressBar
          className="crsr-ptr"
          now={15}
          label={`0%`}
          variant="success"
          data-toggle="tooltip"
          title="Processing Lines"
        />
      </Fragment>
    );
  };

  configStatusInfo = status => {
    return <span>{status}</span>;
  };

  // ===== Function called to delete the line =====
  deleteData = rowIndex => {
    this.props.getDeleteData(rowIndex);
  };

  render() {
    const { enteredModelData, onNewRow, concurrentFlagStatus } = this.props; // passing props
    const tableData = enteredModelData.map(x => {
      return {
        ...x,
        uniqueKey: concurrentFlagStatus + x["LINE_NUMBER"]
      };
    });
    const {
      isEnabled, isIconVisible, isErrorPopup, showDeleteConfirmation, submittedLines, refreshDisabled,
      showPriceRollup, assembleToData, priceRollData, priceRollDropdownData, isEnabledSubmitOk } = this.state;
    const formatWithIcon = (cell, row, rowIndex, formatExtraData) => {
      let lineNumber = row.LINE_NUMBER;
      let modelName = row.MODEL_NAME;
      let quantityCheck = row.ITEM_QUANTITY
      if (isIconVisible) {
        return [
          <button
            onClick={e => {
              this.editData(e, lineNumber, modelName, row, quantityCheck);
              this.props.onEdit();
            }}
            className="btn btn-primary minWidth-auto border-0 edit-btn transparent"
          >
            <i className="fa fa-pencil" />
          </button>,
          <button
            onClick={() => {
              this.setState({
                showDeleteConfirmation: true,
                deleteRow: `${lineNumber}`
              });
            }}
            className="btn btn-primary minWidth-auto border-0 delete-btn"
          >
            <i className="fa fa-trash-o" aria-hidden="true" />
          </button>
        ];
      }
    };

    // ===== Function called to get the status of the icon =====
    const formatStatusIcon = (cell, row) => {
      return this.configStatusInfo(row.CONFIG_STATUS);
    };

    // ===== Function called to get the inline status =====
    const formatInlineStatus = (cell, row) => {
      return this.statusInfo(
        row.INLINE_MDL_RESULT_MSG,
        row.INLINE_MDL_ERROR_MSG
      );
    };

    // ===== Function calls on formatting the line status =====
    const formatLineStatusIcon = (cell, row, rowIndex) => {
      if (row.LINE_STATUS == null) {
        this.setState({ processingLines: true });
        return this.processingLineStarted();
      } else if (row.LINE_STATUS !== null) {
        this.setState({ processingLines: false });
        return this.lineStatusInfo(
          row.LINE_STATUS,
          row.ERROR_MESSAGE,
          rowIndex
        );
      }
    };

    const columns = [
      // {
      //   dataField: "SerialNum",
      //   text: "Sl.No",
      //   formatter: indexVal
      // },
      {
        dataField: "LINE_NUMBER",
        text: "Line Number"
      },
      {
        dataField: "MODEL_NAME",
        text: "Model Name"
      },
      {
        dataField: "ITEM_TYPE",
        text: "Item Type"
      },
      {
        dataField: "WAREHOUSE",
        text: "WareHouse"
      },
      {
        dataField: "ITEM_QUANTITY",
        text: "Quantity"
      },
      {
        dataField: "SIZING_REFERENCE_ID",
        text: "Sizing Id"
      },
      {
        dataField: "INLINE_MDL_RESULT_MSG",
        text: "Inline Model Status",
        formatter: formatInlineStatus
      },
      {
        dataField: "CONFIG_STATUS",
        text: "Configuration Status",
        formatter: formatStatusIcon
      },
      // {
      //   dataField: "ERROR_MESSAGE",
      //   text: "Configuration Message"
      // },
      {
        dataField: "LINE_STATUS",
        text: "Line Status",
        formatter: formatLineStatusIcon
      },
      {
        dataField: "Action",
        text: "Action",
        formatter: formatWithIcon
      }
    ];

    return (
      <div
        className={
          "mt-2 enteredData-tbl" +
          " " +
          (isIconVisible ? " " : "submitted-datatable")
        }
      >
        {showDeleteConfirmation && (
          // ====== Delete Confirmation pop up messgae =======
          <Popup
            title={"Delete Confirmation"}
            message={'Do you want to delete line #' + this.state.deleteRow + '?'}
            onCancel={() => {
              this.setState({
                showDeleteConfirmation: false,
                deleteRow: -1
              });
            }}
            onOK={() => {
              this.setState(
                {
                  showDeleteConfirmation: false,
                  deleteRow: -1
                },
                this.deleteData.bind(this, this.state.deleteRow)
              );
            }}
          />
        )}

        {isErrorPopup && (
          // ====== Error pop up box message =======
          <Popup
            title="Error Message"
            message={this.state.cellValue}
            onOK={() => {
              this.setState({
                isErrorPopup: false,
                cellValue: ""
              });
            }}
            onCancel={() => {
              this.setState({
                isErrorPopup: false,
                cellValue: ""
              });
            }}
          />
        )}

        {/* React Bootstrap table  */}
        <BootstrapTable
          key={tableData.length + concurrentFlagStatus}
          bordered={false}
          striped
          hover
          keyField="uniqueKey"
          data={tableData}
          columns={columns}
          className="search-table"
          noDataIndication="No Data Available"
        />

        {showPriceRollup && (
          <PriceRollup
            data={priceRollData}
            assembleToData={assembleToData}
            priceRollDropdownData={priceRollDropdownData}
            onSave={this.savePriceRollup}
            onClose={() => this.togglePriceRollup(false)}
            onPriceDataChange={(e, lineNo) => this.onDataChange(e, lineNo)}
            onPriceRollDataSave={this.priceDataSave}
            countMap={this.state.countMap}
          />
        )}

        {enteredModelData.length === 0 ? (
          ""
        ) : (
            <div className="text-right">
              {submittedLines !== true ? (
                <React.Fragment>
                  <button
                    className="btn btn-primary addNewBtn mr-3"
                    onClick={() => this.togglePriceRollup(true)}
                  >
                    Price Rollup
                </button>
                  <button
                    className="btn btn-primary addNewBtn mr-3"
                    onClick={() => {
                      onNewRow(getMaxLineNo(enteredModelData) + 1);
                      this.props.updateRequiredMultiLevelModel({requiredMultiLevelModel: false});
                      this.props.prepareNewRow();
                    }}
                  >
                    Add New Row
                </button>
                </React.Fragment>
              ) : (
                  ""
                )}

              {submittedLines && (
                <div className="d-inline-block">
                  <span
                    className={
                      "mr-3 refresh-icon" +
                      " " +
                      (refreshDisabled ? "disabled" : "")
                    }
                  >
                    <i
                      className="fa fa-refresh"
                      aria-hidden="true"
                      onClick={this.refreshData}
                    />
                  </span>
                  <button
                    type="button"
                    className={
                      "btn btn-primary mr-3 align-top cancel-btn" +
                      " " +
                      (Object.values(this.completedLines).filter(x => x)
                        .length === enteredModelData.length
                        ? "disabled"
                        : "")
                    }
                    onClick={this.cancelData}
                  >
                    Cancel
                </button>
                </div>
              )}

              <Button
                variant="primary"
                onClick={this.handleShow}
                className={
                  "btn btn-primary float-right mb-3 submit-btn" +
                  " " +
                  (submittedLines === true ? "disabled" : "")
                }
                disabled={!isEnabled}
              >
                Submit
            </Button>
            </div>
          )}

        {/* ======= Confirmation pop up starts here ======*/}
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Submit Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <> <i class="fa fa-info-circle" aria-hidden="true"></i> <p className="submitNote"> <b> NOTE: Please close the current Oracle forms before submitting. </b></p> </> 
            <p className= "SubmitConfirmation"> Do you want to upload the Model Data to{" "} {getURLDetails().I_APP_CODE === "ONT" ? "Order" : "Quote"}? </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              disabled={!isEnabledSubmitOk}
              variant="primary"
              onClick={() => {
                this.setState({ isEnabledSubmitOk: false }, () => {
                  this.handleClose();
                  this.submitData();
                });
              }}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        {/* ======= Confirmation pop up ends here ======*/}
      </div>
    );
  }

  savePriceRollup = () => {
    this.props.getassembleData(this.state.priceRollData);
    toastr.success("Price Values are Updated Successfully", "Success");
    this.setState({
      showPriceRollup: false
    });
  };

  togglePriceRollup = value => {
    if (value) {
      this.props.getpriceRollUpData();
      this.props.getassembleData();
    }
    this.setState({
      showPriceRollup: value
    });
  };

  onDataChange = (e, lineNo) => {
    const name = e.target.name;
    const value = e.target.value;
    let priceRollData = this.state.priceRollData;
    priceRollData = priceRollData.map((row) => {
      if ((name === "ASSEMBLE_TO" || name === "PRICE_ROLLUP") && row.LINE_NUMBER === lineNo) {
        row[name] = value;
      }
      return row;
    });
    this.setState({ priceRollData });
  };

  // ===== To declare that a prop is a specific JS primitive. =====
  static propTypes = {
    MyGetSubmitedData: PropTypes.func.isRequired,
    enteredModelData: PropTypes.array.isRequired,
    submittedData: PropTypes.array.isRequired,
    updateRequiredMultiLevelModel: PropTypes.func.isRequired,
  };
}


export default EnteredModelData;
