import { InputPayload, RMTInputPayload } from "./urd-template";
import { getURLDetails, getUserDetails } from "./methods";
//changes added for rfc AGL-ORA000231
//Passed noteModelData array as an argument
export const preparePayloadForExcelUpload = (buName, modelData, urdData, urdSheetName, noteModelData = []) => {
    let payload;
    let hasData = false;
    hasData = modelData && modelData
        .filter(r => Object.keys(r).filter(k => Boolean(r[k])).length > 0).length > 0;

    if (hasData) {
        modelData = modelData.map(dataObject => convertModelValuesToUpperCase(dataObject, buName));
        urdData = urdData.map(dataObject => convertModelValuesToUpperCase(dataObject));
        const parsedModelData = prepareModelDataForExcelUpload(buName, modelData);
        let parsedUrdData = {};
        let parsedRMTUrdData = {};

        if (buName === 'RMT' || urdSheetName === 'RMT Unconfigured Items-URD') {
            parsedUrdData = [InputPayload];
            let lineWiseBaseModels = prepareCombinedRMTModelsWithOrder(modelData, parsedModelData);
            parsedRMTUrdData = prepareRMTUrdDataForExcelUpload(urdData, lineWiseBaseModels);
        }

        else {
            let lineWiseBaseModels = parsedModelData
                .map(r => `${r.LINE_NUMBER}|${r.MODEL_NAME_1}`)
                .reduce((acc, n) => acc.includes(n) ? acc : acc.concat(n), []);
            if (urdData && urdData.length > 0) {
                parsedUrdData = prepareUrdDataForExcelUpload(urdData, lineWiseBaseModels);
            } else {
                parsedUrdData = InputPayload;
            }

            // if(modelData[0].BASE_MODEL==="RMT"){
            // parsedUrdData = [InputPayload];
            // let lineWiseBaseModels = prepareCombinedRMTModelsWithOrder(modelData, parsedModelData);
            // parsedRMTUrdData = prepareRMTUrdDataForExcelUpload(urdData, lineWiseBaseModels);
            // }else{
            //     parsedRMTUrdData = [RMTInputPayload];
            // }
            parsedRMTUrdData = [RMTInputPayload];
        }
        const parsedHoldData = prepareHoldDataForExcelUpload();
        let parsedLineDetailsData = [];
        //Added "NOTES" data from excel-sheet while uploading, if notes data exists.
        if (noteModelData && noteModelData.length) {
            parsedLineDetailsData = prepareLineDetailsDataForExcelUpload(noteModelData);

        }
        let flat = parsedModelData.length ? parsedModelData[0].MODEL_STRING_1 === parsedModelData[0].MODEL_NAME_1 : null;


        payload = {
            modelData: parsedModelData,
            urdData: parsedUrdData,
            RMTurdData: parsedRMTUrdData,
            lineNumber: null,
            flat: flat,
            holdData: parsedHoldData,
            lineDetailsData: parsedLineDetailsData
        };
    }
    return payload;
}

//Passed parameters for buName and respective modelData for excel upload
const prepareModelDataForExcelUpload = (buName, modelData) => {
    let parsedModelData = [];
    switch (buName) {
        case 'RMT':
        case 'ISV':
        case 'ROXAR':
            parsedModelData = prepareDoubleModelData(modelData);
            break;
        case 'MMI':
        case 'DMC':
        case 'RAS':
        case 'RA':
            parsedModelData = prepareTripleModelData(modelData);
            break;
        case 'VA':
        case 'MHM':
        case 'Unconfigured Items':
            parsedModelData = prepareSingleModelData(modelData);
            break;
    }

    return parsedModelData;
}

const prepareDoubleModelData = (modelData) => {
    let parsedDoubleModelData = [];
    let lineNos = modelData.map(r => r.LINE_NUMBER)
        .reduce((acc, n) => acc.includes(n) ? acc : acc.concat(n), []);

    parsedDoubleModelData = lineNos.map(lineNo => {
        let rows = modelData.filter(item => item.LINE_NUMBER === lineNo);
        const payload = Object.assign({}, templateModelDataPayload);

        let _i = 1;
        rows.map((rowItem, i) => {
            const MANUAL_MODIFIER = rowItem.MANUAL_MODIFIER_SUB_MODEL_1 || '';
            const PRICE_ADJUSTMENT = rowItem.PRICE_ADJUSTMENT_1 || '';
            const ADJUSTMENT_METHOD = (MANUAL_MODIFIER.split('|')[1] || '').trim();

            if (i === 0) {
                if (!rowItem.MODEL_NAME_1) {
                    payload[`MODEL_NAME_${_i}`] = rowItem.MODEL_NAME_2 !== '' ? rowItem.MODEL_NAME_2 : '';
                    payload[`MODEL_STRING_${_i}`] = rowItem.MODEL_STRING_2 !== '' ? rowItem.MODEL_STRING_2 : '';
                    _i++;

                    payload[`MANUAL_MODIFIER_BASE_MODEL`] = MANUAL_MODIFIER;
                    payload[`PRICE_ADJUSTMENT`] = PRICE_ADJUSTMENT;
                    payload[`ADJUSTMENT_METHOD`] = ADJUSTMENT_METHOD;
                } else {
                    payload[`MODEL_NAME_${_i}`] = rowItem.MODEL_NAME_1;
                    payload[`MODEL_STRING_${_i}`] = rowItem.MODEL_STRING_1;
                    _i++;
                    payload[`MODEL_NAME_${_i}`] = rowItem.MODEL_NAME_2;
                    payload[`MODEL_STRING_${_i}`] = rowItem.MODEL_STRING_2;
                    _i++;

                    payload[`MANUAL_MODIFIER_SUB_MODEL_${i + 1}`] = MANUAL_MODIFIER;
                    payload[`PRICE_ADJUSTMENT_${i + 1}`] = PRICE_ADJUSTMENT;
                    payload[`ADJUSTMENT_METHOD_${i + 1}`] = ADJUSTMENT_METHOD;
                }

                Object.keys(rowItem)
                    .filter(fKey => !fKey.startsWith('MODEL_NAME_')
                        && !fKey.startsWith('MODEL_STRING_')
                        && !fKey.startsWith('MANUAL_MODIFIER')
                        && !fKey.startsWith('PRICE_ADJUSTMENT')
                        && !fKey.startsWith('ADJUSTMENT_METHOD'))
                    .map(key => {
                        if (payload.hasOwnProperty(key) && rowItem[key]) {
                            payload[key] = rowItem[key];
                        }
                        return key
                    })

            } else {
                payload[`MODEL_NAME_${_i}`] = rowItem.MODEL_NAME_2 || '';
                payload[`MODEL_STRING_${_i}`] = rowItem.MODEL_STRING_2 || '';
                _i++;

                payload[`MANUAL_MODIFIER_SUB_MODEL_${i + 1}`] = MANUAL_MODIFIER;
                payload[`PRICE_ADJUSTMENT_${i + 1}`] = PRICE_ADJUSTMENT;
                payload[`ADJUSTMENT_METHOD_${i + 1}`] = ADJUSTMENT_METHOD;
            }

            return rowItem;
        })
        return payload;
    })

    return parsedDoubleModelData;
}

// Preparing Normal URD payload
const prepareUrdDataForExcelUpload = (urdData, lineWiseBaseModels) => {
    let parsedUrdData = urdData.map(rowItem => {
        let urdObject = Object.assign({}, InputPayload);
        Object.keys(rowItem)
            .map(key => {
                if (urdObject.hasOwnProperty(key) && rowItem[key]) {
                    urdObject[key] = rowItem[key];
                }
                return key;
            });

        urdObject = updateHeaderDetails(urdObject);

        return urdObject;
    });

    lineWiseBaseModels.map(line => {
        let LINE_NUMBER = line.split('|')[0];
        let BASE_MODEL = line.split('|')[1];

        let urdDataCount = parsedUrdData.filter(r => r.LINE_NUMBER === LINE_NUMBER).length;
        let baseModelCount = parsedUrdData.filter(r => r.LINE_NUMBER === LINE_NUMBER && r.BASE_MODEL === BASE_MODEL).length;

        if (baseModelCount === 0 && urdDataCount > 0) {
            let rmtUrdObject = Object.assign({}, RMTInputPayload);

            rmtUrdObject.LINE_NUMBER = LINE_NUMBER;
            rmtUrdObject.BASE_MODEL = BASE_MODEL;

            rmtUrdObject = updateHeaderDetails(rmtUrdObject);

            parsedUrdData.push(rmtUrdObject);
        }
    })

    return parsedUrdData;
}

// Preparing RMT URD payload
const prepareRMTUrdDataForExcelUpload = (urdData, lineWiseBaseModels) => {
    let uniqueLines = urdData.map(r => `${r.LINE_NUMBER}|${r.SUBMODEL_NUMBER || 1}|${r.BASE_MODEL}|${r.UNIT_NUMBER}`)
        .reduce((acc, n) => acc.includes(n) ? acc : acc.concat(n), []);

    let parsedRMTUrdData = uniqueLines.map(uniqueLine => {
        let lineNo = uniqueLine.split('|')[0];
        let submodelNo = uniqueLine.split('|')[1];
        let baseModel = uniqueLine.split('|')[2];
        let unitNo = uniqueLine.split('|')[3];
        let rmtUrdObject = Object.assign({}, RMTInputPayload);

        let rows = urdData.filter(item => item.LINE_NUMBER === lineNo
            && item.BASE_MODEL === baseModel
            && item.UNIT_NUMBER === unitNo
            && (item.SUBMODEL_NUMBER === submodelNo
                || (!item.hasOwnProperty('SUBMODEL_NUMBER') && submodelNo === "1")));
        rmtUrdObject.SUBMODEL_NUMBER = parseInt(submodelNo);
        rmtUrdObject.BASE_MODEL = baseModel;
        rmtUrdObject.UNIT_NUMBER = unitNo;

        let _wire = 1;
        const _wireEnd = 10;
        let _short = 11;
        const _shortEnd = 15;
        let _name = 16;
        const _nameEnd = 20;
        let _long = 21;
        const _longEnd = 25;
        let _perm = 26;
        const _permEnd = 30;
        let _tagOpt = 1;
        let _attach = 1;
        let _measureType = 1;
        let _lrv = 1;
        let _urv = 1;
        let _uom = 1;
        let _desg = 1;
        let _assoc = 1;
        let _tbd = 1;
        let _sqr = 1;
        let _np = 1;
        let indexedFields = ['TAG_OPTN', 'ATTACH', 'MEASUREMENT_TYPE', 'LRV', 'URV', 'UOM', 'DESG', 'ASSOC', 'TBD', 'SQR', 'NP'];
        rmtUrdObject.LINE_NUMBER = lineNo;

        rows.map((rowItem, i) => {

            // Capturing labelled TAG_OPTN
            if (rmtUrdObject.hasOwnProperty(`TAG_OPTN${_tagOpt}`)) {
                rmtUrdObject[`TAG_OPTN${_tagOpt}`] = rowItem.TAG_OPTN || null;
                _tagOpt++;
            }

            // Capturing labelled ATTACH
            if (rmtUrdObject.hasOwnProperty(`ATTACH${_attach}`)) {
                rmtUrdObject[`ATTACH${_attach}`] = rowItem.ATTACH || null;
                _attach++;
            }

            // Capturing labelled MEASUREMENT_TYPE
            if (rmtUrdObject.hasOwnProperty(`MEASUREMENT_TYPE${_measureType}`)) {
                rmtUrdObject[`MEASUREMENT_TYPE${_measureType}`] = rowItem.MEASUREMENT_TYPE || null;
                _measureType++;
            }

            // Capturing labelled LRV
            if (rmtUrdObject.hasOwnProperty(`LRV${_lrv}`)) {
                rmtUrdObject[`LRV${_lrv}`] = rowItem.LRV || null;
                _lrv++;
            }

            // Capturing labelled URV
            if (rmtUrdObject.hasOwnProperty(`URV${_urv}`)) {
                rmtUrdObject[`URV${_urv}`] = rowItem.URV || null;
                _urv++;
            }

            // Capturing labelled UOM
            if (rmtUrdObject.hasOwnProperty(`UOM${_uom}`)) {
                rmtUrdObject[`UOM${_uom}`] = rowItem.UOM || null;
                _uom++;
            }

            // Capturing labelled DESG
            if (rmtUrdObject.hasOwnProperty(`DESG${_desg}`)) {
                rmtUrdObject[`DESG${_desg}`] = rowItem.DESG || null;
                _desg++;
            }

            // Capturing labelled ASSOC
            if (rmtUrdObject.hasOwnProperty(`ASSOC${_assoc}`)) {
                const assocValue = rowItem.ASSOC || null;
                let assocLen = assocValue ? assocValue.toString().length : 0;
                if (assocLen >= 4 && assocLen % 2 === 0) {
                    // means 2 Assoc values are in the single value and need to split it
                    rmtUrdObject[`ASSOC${_assoc}`] = assocValue.substr(0, assocLen / 2);
                    _assoc++;
                    rmtUrdObject[`ASSOC${_assoc}`] = assocValue.substr(assocLen / 2, assocLen);
                    _assoc++;
                } else {
                    rmtUrdObject[`ASSOC${_assoc}`] = assocValue;
                    _assoc++;
                }

            }


            // Capturing labelled TBD
            if (rmtUrdObject.hasOwnProperty(`TBD${_tbd}`)) {
                rmtUrdObject[`TBD${_tbd}`] = rowItem.TBD || null;
                _tbd++;
            }

            // Capturing labelled SQR
            if (rmtUrdObject.hasOwnProperty(`SQR${_sqr}`)) {
                rmtUrdObject[`SQR${_sqr}`] = rowItem.SQR || null;
                _sqr++;
            }


            // Capturing labelled NP
            if (rmtUrdObject.hasOwnProperty(`NP${_np}`)) {
                rmtUrdObject[`NP${_np}`] = rowItem.NP || null;
                _np++;
            }

            let index = i + 1;
            let tagTypeKey = `TAG_TYPE${index}`;
            let tagType = (rowItem.TAG_TYPE && rowItem.TAG_TYPE.toUpperCase()) || null;
            rmtUrdObject[tagTypeKey] = tagType;

            switch (tagType) {
                case 'WIRE':
                    generateNumberArrayBetweenRange(_wire, _wireEnd)
                        .map((prefix, pIndex) => {
                            let line = `LINE_${pIndex + 1}`;
                            let urdLine = `LINE_${prefix}`;
                            if (rowItem[line]) {
                                rmtUrdObject[urdLine] = rowItem[line];
                                _wire++;
                            }
                            return prefix;
                        });
                    break;
                case 'SHORT':
                    generateNumberArrayBetweenRange(_short, _shortEnd)
                        .map((prefix, pIndex) => {
                            let line = `LINE_${pIndex + 1}`;
                            let urdLine = `LINE_${prefix}`;
                            if (rowItem[line]) {
                                rmtUrdObject[urdLine] = rowItem[line];
                                _short++;
                            }
                            return prefix;
                        })
                    break;
                case 'NAME':
                    generateNumberArrayBetweenRange(_name, _nameEnd)
                        .map((prefix, pIndex) => {
                            let line = `LINE_${pIndex + 1}`;
                            let urdLine = `LINE_${prefix}`;
                            if (rowItem[line]) {
                                rmtUrdObject[urdLine] = rowItem[line];
                                _name++;
                            }
                            return prefix;
                        });
                    break;
                case 'LONG':
                    generateNumberArrayBetweenRange(_long, _longEnd)
                        .map((prefix, pIndex) => {
                            let line = `LINE_${pIndex + 1}`;
                            let urdLine = `LINE_${prefix}`;
                            if (rowItem[line]) {
                                rmtUrdObject[urdLine] = rowItem[line];
                                _long++;
                            }
                            return prefix;
                        });
                    break;
                case 'PERM':
                    generateNumberArrayBetweenRange(_perm, _permEnd)
                        .map((prefix, pIndex) => {
                            let line = `LINE_${pIndex + 1}`;
                            let urdLine = `LINE_${prefix}`;
                            if (rowItem[line]) {
                                rmtUrdObject[urdLine] = rowItem[line];
                                _perm++;
                            }
                            return prefix;
                        });
                    break;
            }

            // Capturing Manufacturing Notes
            if (rmtUrdObject.hasOwnProperty(`MFG_NOTES`) && rowItem.MFG_NOTES) {
                rmtUrdObject[`MFG_NOTES`] = rowItem.MFG_NOTES;
            }

            // Capture repeated non-line & non-indexed fields
            if (i === 0) {
                Object.keys(rowItem)
                    .filter(fKey => !fKey.startsWith('LINE_')
                        && !fKey.startsWith('SUBMODEL_NUMBER')
                        && !fKey.startsWith('BASE_MODEL')
                        && !fKey.startsWith('UNIT_NUMBER')
                        && !fKey.startsWith('MFG_NOTES')
                        && !indexedFields.includes(fKey))
                    .map(key => {
                        if (rmtUrdObject.hasOwnProperty(key) && rowItem[key]) {
                            rmtUrdObject[key] = rowItem[key];
                        }
                        return key;
                    });
            }

            return rowItem;
        });

        rmtUrdObject = updateHeaderDetails(rmtUrdObject);

        return rmtUrdObject;
    })

    let orderedRMTUrdData = [];
    lineWiseBaseModels.map(line => {
        let LINE_NUMBER = line.LINE_NUMBER;
        let BASE_MODEL = line.BASE_MODEL;
        let ITEM_QUANTITY = line.ITEM_QUANTITY;
        let SUBMODEL_NUMBER = line.SUBMODEL_NUMBER;

        let quantityItems = new Array(parseInt(ITEM_QUANTITY)).fill(0);
        quantityItems.map((_0, i) => {
            let UNIT_NUMBER = (i + 1).toString();

            let existingUrdData = parsedRMTUrdData
                .filter(r => r.LINE_NUMBER === LINE_NUMBER
                    && r.BASE_MODEL === BASE_MODEL
                    && r.UNIT_NUMBER === UNIT_NUMBER
                    && r.SUBMODEL_NUMBER === SUBMODEL_NUMBER);

            if (existingUrdData.length === 0) {
                let rmtUrdObject = Object.assign({}, RMTInputPayload);

                rmtUrdObject.LINE_NUMBER = LINE_NUMBER;
                rmtUrdObject.BASE_MODEL = BASE_MODEL;
                rmtUrdObject.UNIT_NUMBER = UNIT_NUMBER;
                rmtUrdObject.SUBMODEL_NUMBER = parseInt(SUBMODEL_NUMBER);

                rmtUrdObject = updateHeaderDetails(rmtUrdObject);

                orderedRMTUrdData.push(rmtUrdObject);
            } else {
                orderedRMTUrdData.push(existingUrdData[0]);
            }
            return _0;
        });
        return line;
    })

    return orderedRMTUrdData;
}

const updateHeaderDetails = dataObject => {
    dataObject.HOST_HEADER_ID = getURLDetails().I_HEADER_ID;
    dataObject.SEQUENCE_NUMBER = getUserDetails().SEQUENCE_NUMBER;
    dataObject.APPLICATION_CODE = getURLDetails().I_APP_CODE;
    return dataObject;
}

const prepareTripleModelData = (modelData) => {
    let parsedTripleModelData = [];

    let lineNos = modelData.map(r => r.LINE_NUMBER)
        .reduce((acc, n) => acc.includes(n) ? acc : acc.concat(n), []);

    parsedTripleModelData = lineNos.map(lineNo => {
        let rows = modelData.filter(item => item.LINE_NUMBER === lineNo);
        const payload = Object.assign({}, templateModelDataPayload);

        let _i = 1;
        rows.map((rowItem) => {
            if (rowItem.MODEL_NAME_1) {
                payload[`MODEL_NAME_${_i}`] = rowItem.MODEL_NAME_1;
                payload[`MODEL_STRING_${_i}`] = rowItem.MODEL_STRING_1 || '';
                _i++;
            }
            if (rowItem.MODEL_NAME_2) {
                payload[`MODEL_NAME_${_i}`] = rowItem.MODEL_NAME_2;
                payload[`MODEL_STRING_${_i}`] = rowItem.MODEL_STRING_2 || '';
                _i++;
            }
            if (rowItem.MODEL_NAME_3) {
                payload[`MODEL_NAME_${_i}`] = rowItem.MODEL_NAME_3;
                payload[`MODEL_STRING_${_i}`] = rowItem.MODEL_STRING_3 || '';
                _i++;
            }

            if (rowItem.MANUAL_MODIFIER_BASE_MODEL) {
                let MANUAL_MODIFIER_BASE_MODEL = rowItem.MANUAL_MODIFIER_BASE_MODEL || '';
                payload['MANUAL_MODIFIER_BASE_MODEL'] = MANUAL_MODIFIER_BASE_MODEL;
                payload['PRICE_ADJUSTMENT'] = rowItem.PRICE_ADJUSTMENT || '';
                payload['ADJUSTMENT_METHOD'] = (MANUAL_MODIFIER_BASE_MODEL.split('|')[1] || '').trim();
            }

            if (rowItem.MANUAL_MODIFIER_SUB_MODEL_1) {
                const MANUAL_MODIFIER = rowItem.MANUAL_MODIFIER_SUB_MODEL_1 || '';
                const PRICE_ADJUSTMENT = rowItem.PRICE_ADJUSTMENT_1 || '';
                const ADJUSTMENT_METHOD = (MANUAL_MODIFIER.split('|')[1] || '').trim();
                if (!rowItem.MODEL_NAME_1) {
                    payload['MANUAL_MODIFIER_BASE_MODEL'] = MANUAL_MODIFIER;
                    payload['PRICE_ADJUSTMENT'] = PRICE_ADJUSTMENT;
                    payload['ADJUSTMENT_METHOD'] = ADJUSTMENT_METHOD;
                } else {
                    payload['MANUAL_MODIFIER_SUB_MODEL_1'] = MANUAL_MODIFIER;
                    payload['PRICE_ADJUSTMENT_1'] = PRICE_ADJUSTMENT;
                    payload['ADJUSTMENT_METHOD_1'] = ADJUSTMENT_METHOD;
                }
            }

            if (rowItem.MANUAL_MODIFIER_SUB_MODEL_2) {
                const MANUAL_MODIFIER = rowItem.MANUAL_MODIFIER_SUB_MODEL_2 || '';
                const PRICE_ADJUSTMENT = rowItem.PRICE_ADJUSTMENT_2 || '';
                const ADJUSTMENT_METHOD = (MANUAL_MODIFIER.split('|')[1] || '').trim();
                if (!rowItem.MODEL_NAME_1) {
                    payload['MANUAL_MODIFIER_BASE_MODEL'] = MANUAL_MODIFIER;
                    payload['PRICE_ADJUSTMENT'] = PRICE_ADJUSTMENT;
                    payload['ADJUSTMENT_METHOD'] = ADJUSTMENT_METHOD;
                } else {
                    payload['MANUAL_MODIFIER_SUB_MODEL_2'] = MANUAL_MODIFIER;
                    payload['PRICE_ADJUSTMENT_2'] = PRICE_ADJUSTMENT;
                    payload['ADJUSTMENT_METHOD_2'] = ADJUSTMENT_METHOD;
                }
            }

            Object.keys(rowItem)
                .filter(fKey => !fKey.startsWith('MODEL_NAME_')
                    && !fKey.startsWith('MODEL_STRING_')
                    && !fKey.startsWith('MANUAL_MODIFIER')
                    && !fKey.startsWith('PRICE_ADJUSTMENT')
                    && !fKey.startsWith('ADJUSTMENT_METHOD'))
                .map(key => {
                    if (payload.hasOwnProperty(key) && rowItem[key]) {
                        payload[key] = rowItem[key];
                    }
                    return key
                })
        });

        return payload;
    });

    return parsedTripleModelData;
}

//mapped NOTES data with model items
// const prepareNotesModelData = (modelData) => {
//     let parsedNotesModelData = [];
//     let lineNos = modelData.map(r => r.LINE_NUMBER)
//         .reduce((acc, n) => acc.includes(n) ? acc : acc.concat(n), []);
//     parsedNotesModelData = lineNos.map((lineNo) => {
//         let rows = modelData.filter(item => item.LINE_NUMBER === lineNo);
//         const payload = {};//Object.assign({}, notesDataPayload=[]);
//         let _i = 1;
//         rows.map((rowItem) => {            
//             if (rowItem.BASE_MODEL_1) {
//                 payload[`MODEL_NAME_${_i}`] = rowItem.BASE_MODEL_1;
//                 payload[`LINE_NUMBER`] = rowItem.LINE_NUMBER;
//                 _i++;
//             }           
//         });
//         return payload;
//     })
//     return parsedNotesModelData;
// }

const prepareSingleModelData = (modelData) => {
    let parsedSingleModelData = [];
    let lineNos = modelData.map(r => r.LINE_NUMBER)
        .reduce((acc, n) => acc.includes(n) ? acc : acc.concat(n), []);

    parsedSingleModelData = lineNos.map(lineNo => {
        let rows = modelData.filter(item => item.LINE_NUMBER === lineNo);
        const payload = Object.assign({}, templateModelDataPayload);

        let _i = 1;
        rows.map((rowItem) => {
            if (rowItem.MODEL_NAME_1 || rowItem.ITEM_NUMBER) {
                // modelstring.push({LINE_NUMBER:rowItem.LINE_NUMBER, MODEL_STRING_1 :rowItem.MODEL_STRING_1});
                payload[`MODEL_NAME_${_i}`] = rowItem.MODEL_NAME_1 || rowItem.ITEM_NUMBER || '';
                payload[`MODEL_STRING_${_i}`] = rowItem.MODEL_STRING_1 || rowItem.ITEM_NUMBER || '';
                _i++;
            }

            let MANUAL_MODIFIER_BASE_MODEL = rowItem.MANUAL_MODIFIER_BASE_MODEL || '';
            payload['MANUAL_MODIFIER_BASE_MODEL'] = MANUAL_MODIFIER_BASE_MODEL;
            payload['PRICE_ADJUSTMENT'] = rowItem.PRICE_ADJUSTMENT || '';
            payload['ADJUSTMENT_METHOD'] = (MANUAL_MODIFIER_BASE_MODEL.split('|')[1] || '').trim();

            Object.keys(rowItem)
                .filter(fKey => !fKey.startsWith('MODEL_NAME_')
                    && !fKey.startsWith('MODEL_STRING_')
                    && !fKey.startsWith('MANUAL_MODIFIER')
                    && !fKey.startsWith('PRICE_ADJUSTMENT')
                    && !fKey.startsWith('ADJUSTMENT_METHOD'))
                .map(key => {
                    if (payload.hasOwnProperty(key) && rowItem[key]) {
                        payload[key] = rowItem[key];
                    }
                    return key
                })
        });

        return payload;
    });

    return parsedSingleModelData;
}

export const prepareHoldDataForExcelUpload = () => {
    let holdDetails = {};
    holdDetails["HOLD_NAME"] = "";
    holdDetails["HOLD_ID"] = "";
    holdDetails["HOLD_DATE"] = "";
    holdDetails["HOLD_COMMENT"] = "";
    return [holdDetails]
}

//Reading the NOTES data and passing the payload in form of arrays
export const prepareLineDetailsDataForExcelUpload = (modelData) => {
    if(modelData === undefined || modelData === null) {
        let lineDetails = {};
        lineDetails["NOTES_SEQ"] = "";
        lineDetails["CATEGORY_NAME"] = "";
        lineDetails["CATEGORY_DESC"] = "";
        lineDetails["TITLE"] = "";
        lineDetails["DATA_TYPE"] = "";
        lineDetails["COMMENTS"] = "";
        return [lineDetails];
    } else {
        let lineDetails = [];
        if (modelData.length > 0) {
            modelData.map((eachData) => {
                lineDetails.push({
                    NOTES_SEQ: eachData["LINE_NUMBER"] + "." + eachData["TAG_NUMBER"], //LineNumber is combined with the NotesSequence while uploading the excel-sheet
                    CATEGORY_NAME: eachData["LINE_5"],
                    CATEGORY_DESC: eachData["LINE_5_1"],
                    TITLE: eachData["LINE_5_2"],
                    DATA_TYPE: eachData["TAG_TYPE"],
                    COMMENTS: eachData["LINE_5_3"],
                })
            })
            return lineDetails;
        }
        return lineDetails;
    }
}

export const convertKeysToUpperCase = data => {
    const isArray = Array.isArray(data);
    const isObject = typeof (data) === 'object';

    if (isArray) {
        return data.map(item => {
            if (typeof (item) === 'object') {
                return convertObjectKeysToUpperCase(item);
            } else {
                return item;
            }
        })
    } else if (isObject) {
        return convertObjectKeysToUpperCase(data);
    }
}

const convertObjectKeysToUpperCase = dataObject => {
    let newObject = {};
    Object.keys(dataObject).map(key => {
        const KEY = key.toUpperCase().trim();
        newObject[KEY] = dataObject[key].toString().trim();
        return key;
    });
    return newObject;
}

const convertModelValuesToUpperCase = (dataObject, buName) => {
    let newObject = {};
    Object.keys(dataObject).map(key => {
        let value = dataObject[key];
        if (buName === "RAS") {
            if (key.startsWith('MODEL_NAME_')
                || key.startsWith('MODEL_STRING_')
                || key.startsWith('ITEM_NUMBER')
                || key.startsWith('BASE_MODEL')) {
                value = value;
            }
        }
        else {
            if (key.startsWith('MODEL_NAME_')
                || key.startsWith('MODEL_STRING_')
                || key.startsWith('ITEM_NUMBER')
                || key.startsWith('BASE_MODEL')) {
                value = value.toUpperCase();
            }
        }

        newObject[key] = value;
        return key;
    });
    return newObject;
}

export const generateNumberArrayBetweenRange = (start, end, limit = -1) => {
    let numbers = [];
    for (let i = start; i <= end; i++) {
        numbers.push(i);
    }
    numbers = limit > 0 ? numbers.slice(0, limit) : numbers;
    return numbers;
}

const prepareCombinedRMTModelsWithOrder = (modelData, parsedModelData) => {
    let lineWiseBaseModels = [];

    let modelDataWithProcessStatus = modelData.map(md => ({ ...md, processed: false }));

    parsedModelData.map(pr => {
        const LINE_NUMBER = pr.LINE_NUMBER;
        const ITEM_QUANTITY = pr.ITEM_QUANTITY;

        new Array(11).fill(0).map((_0, i) => {
            let modelPosition = i + 1;
            let modelName = pr[`MODEL_NAME_${modelPosition}`];
            let modelString = pr[`MODEL_STRING_${modelPosition}`];

            if (modelName) {
                const modelRow = modelDataWithProcessStatus
                    .find(r => r.LINE_NUMBER === LINE_NUMBER
                        && r.MODEL_NAME_2 === modelName
                        && r.MODEL_STRING_2 === modelString
                        && r.processed === false);

                let SUBMODEL_NUMBER = modelRow ? modelRow.SUBMODEL_NUMBER || "1" : "1";

                modelDataWithProcessStatus = modelDataWithProcessStatus.map(mdu => {
                    if (mdu.LINE_NUMBER === LINE_NUMBER
                        && mdu.MODEL_NAME_2 === modelName
                        && mdu.MODEL_STRING_2 === modelString
                        && mdu.SUBMODEL_NUMBER === SUBMODEL_NUMBER
                        && mdu.processed === false) {
                        mdu.processed = true;
                    }
                    return mdu;
                });

                SUBMODEL_NUMBER = parseInt(SUBMODEL_NUMBER);

                let modeObject = {
                    LINE_NUMBER,
                    BASE_MODEL: modelName,
                    ITEM_QUANTITY,
                    SUBMODEL_NUMBER
                }
                lineWiseBaseModels.push(modeObject);
            }
            return _0;
        });
        return pr;
    });
    return lineWiseBaseModels;
}

export const templateModelDataPayload = {
    "HOST_HEADER_ID": "",
    "SEQUENCE_NUMBER": "",
    "LINE_NUMBER": "",
    "ORG_ID": "",
    "APPLICATION_CODE": "",
    "USER_ID": "",
    "MODEL_NAME_1": "",
    "MODEL_STRING_1": "",
    "MODEL_NAME_2": "",
    "MODEL_STRING_2": "",
    "MODEL_NAME_3": "",
    "MODEL_STRING_3": "",
    "MODEL_NAME_4": "",
    "MODEL_STRING_4": "",
    "MODEL_NAME_5": "",
    "MODEL_NAME_6": "",
    "MODEL_STRING_6": "",
    "MODEL_NAME_7": "",
    "MODEL_STRING_7": "",
    "MODEL_NAME_8": "",
    "MODEL_STRING_8": "",
    "MODEL_NAME_9": "",
    "MODEL_STRING_9": "",
    "MODEL_NAME_10": "",
    "MODEL_STRING_10": "",
    "MODEL_NAME_11": "",
    "MODEL_STRING_11": "",
    "ITEM_QUANTITY": "",
    "WAREHOUSE": "",
    "MANUAL_MODIFIER_BASE_MODEL": "",
    "PRICE_ADJUSTMENT": "",
    "ADJUSTMENT_METHOD": "",
    "MANUAL_MODIFIER_SUB_MODEL_1": "",
    "PRICE_ADJUSTMENT_1": "",
    "ADJUSTMENT_METHOD_1": "",
    "MANUAL_MODIFIER_SUB_MODEL_2": "",
    "PRICE_ADJUSTMENT_2": "",
    "ADJUSTMENT_METHOD_2": "",
    "MANUAL_MODIFIER_SUB_MODEL_3": "",
    "PRICE_ADJUSTMENT_3": "",
    "ADJUSTMENT_METHOD_3": "",
    "MANUAL_MODIFIER_SUB_MODEL_4": "",
    "PRICE_ADJUSTMENT_4": "",
    "ADJUSTMENT_METHOD_4": "",
    "MANUAL_MODIFIER_SUB_MODEL_5": "",
    "PRICE_ADJUSTMENT_5": "",
    "ADJUSTMENT_METHOD_5": "",
    "MANUAL_MODIFIER_SUB_MODEL_6": "",
    "PRICE_ADJUSTMENT_6": "",
    "ADJUSTMENT_METHOD_6": "",
    "MANUAL_MODIFIER_SUB_MODEL_7": "",
    "PRICE_ADJUSTMENT_7": "",
    "ADJUSTMENT_METHOD_7": "",
    "MANUAL_MODIFIER_SUB_MODEL_8": "",
    "PRICE_ADJUSTMENT_8": "",
    "ADJUSTMENT_METHOD_8": "",
    "MANUAL_MODIFIER_SUB_MODEL_9": "",
    "PRICE_ADJUSTMENT_9": "",
    "ADJUSTMENT_METHOD_9": "",
    "MANUAL_MODIFIER_SUB_MODEL_10": "",
    "PRICE_ADJUSTMENT_10": "",
    "ADJUSTMENT_METHOD_10": "",
    "END_USER_LOCATION": "",
    "KOB3": "",
    "PICK_PACK_LEAD_TIME": "",
    "CUST_PO_LINE": "",
    "CUST_PO_LINE_2": "", //sudhakar new field for RT_002477 RT_002336
    "CUST_PO_LINE_3": "", //sudhakar new field for RT_002477 RT_002336
    "CUST_PO_LINE_4": "", //sudhakar new field for RT_002477 RT_002336
    "CUST_PO_LINE_5": "", //sudhakar new field for RT_002477 RT_002336    
    "QS_CAUSE_CODE": "",
    "SOURCE_TYPE": "",
    "CUSTOMER_REF": "",
    "CUSTOMER_REF_2": "", //sudhakar new field for RT_002477 RT_002336
    "CUSTOMER_REF_3": "", //sudhakar new field for RT_002477 RT_002336
    "CUSTOMER_REF_4": "", //sudhakar new field for RT_002477 RT_002336
    "CUSTOMER_REF_5": "", //sudhakar new field for RT_002477 RT_002336
    "USER_ITEM_DESCRIPTION": "", //added new field for RT_002629    
    "USER_ITEM_DESCRIPTION_2": "", //added new field for RT_002629
    "USER_ITEM_DESCRIPTION_3": "", //added new field for RT_002629
    "USER_ITEM_DESCRIPTION_4": "", //added new field for RT_002629
    "USER_ITEM_DESCRIPTION_5": "", //added new field for RT_002629
    "PROJECT_NUMBER": "",
    "TASK_NUMBER": "",
    "ASSEMBLE_TO": "",
    "CUSTOMSVALUE": "",
    "INV_ITEM_DFF": "",
    "SHIP_INSTRUCTIONS": "",
    "PACK_INSTRUCTIONS": "",
    "LINE_TYPE": "",
    "LINE_TYPE_ID": "",
    "CONSOL_LOCATION_ID": "",
    "CONSOL_CUST_ID": "",
    "SHIP_SET": "",
    "FULFILLMENT_SET_NAME": "",
    "PRICE_ROLL_UP": "",
    "SIZING_REFERENCE_ID": ""
};