import axios from "axios";
import { API_URL, API_SUB_KEY } from "../../utils/constant";
import { getUserDetails, getURLDetails, getCurrentAndPastDates } from "../../utils/methods";
import moment from "moment";
import toastr from "toastr";

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": API_SUB_KEY,
    "Pragma": "no-cache"
  },
  timeout: 240000
});

export const sizingAPI = divCode =>
  axiosInstance
    .get("/usersizing", {
      params: {
        SIZING_ID: "",
        FIRST_NAME: "",
        LAST_NAME: "",
        DIV_CODE: getUserDetails().DIV || divCode || "",
        EMAIL_ID: getUserDetails().EMAIL_ADDRESS
      }
    })
    .then(response => response.data);

export const copySizingAPI = ({ data, divCode, lineNumber }) => {
  let sizingID = data.map(id => ({ SIZING_ID: id }));
  return axiosInstance
    .post("/addlinebysizeddata", {
      RESTHeader: {
        Responsibility: getUserDetails().RESP_KEY,
        RespApplication: getUserDetails().RESP_APPL_NAME,
        SecurityGroup: "STANDARD",
        NLSLanguage: "AMERICAN",
        Language: "EN",
        Org_Id: getURLDetails().I_ORG_ID
      },
      InputParameters: {
        I_HEDEAR_ID: getURLDetails().I_HEADER_ID,
        I_SEQUENCE_NUMBER: getUserDetails().SEQUENCE_NUMBER,
        I_LINE_NUMBER: lineNumber,
        I_APPLICATION_CODE: getURLDetails().I_APP_CODE,
        I_DIV_CODE: getUserDetails().DIV || divCode,
        I_SIZING_REF_ID: {
          I_SIZING_REF_ID_ITEM: sizingID
        }
      }
    })
    .then(response => response.data);
};

export const OrderAPI = () =>
  axiosInstance
    .get("/displayorders", {
      params: {
        I_ORACLE_ORDER_NUMBER: "",
        I_ORDERED_FROM: getURLDetails().I_ORG_ID,
        I_CUST_ACCOUNT_ID: getUserDetails().SOLD_TO_ORG_ID,
        I_GS_ORDER_NUM: "",
        I_SERIAL_NUM: "",
        I_FROM_DATE: moment(
          getCurrentAndPastDates().FormattedDate,
          "YYYY-MM-DD"
        ).format("YYYY-MM-DD"), // past 60 days
        I_TO_DATE: moment(
          getCurrentAndPastDates().currentDate,
          "YYYY-MM-DD"
        ).format("YYYY-MM-DD"), // current date
        I_ORDER_STATUS: "",
        I_CUST_PO_NUM: "",
        I_ORDERED_BY: ""
      }
    })
    .then(response => response.data);

export const OrderDetailsAPI = orderId =>
  axiosInstance
    .get("/orderlines", {
      params: {
        I_ORG_ID: getURLDetails().I_ORG_ID,
        I_ORDER_HEADER_ID: orderId,
        I_ORACLE_ORDER_LINE_NUMBER: "",
        I_CUST_ACCOUNT_ID: getUserDetails().SOLD_TO_ORG_ID,
        I_SERIAL_NUMBER: "",
        I_TAG_NUMBER: ""
        // I_ORG_ID: "5701",
        // I_ORDER_HEADER_ID: "45599430",
        // I_CUST_ACCOUNT_ID: "930270",
      }
    })
    .then(response => response.data);

export const MyEnteredModelAPI = () =>
  axiosInstance
    .get("/displaymdlstatus", {
      params: {
        I_HOST_HEADER_ID: getURLDetails().I_HEADER_ID,
        I_SEQUENCE_NUMBER: getUserDetails().SEQUENCE_NUMBER,
      }
    })
  .then(response => response.data);


export const submitDataAPI = bool =>
  axiosInstance
    .post("/submitrequest", {
      RESTHeader: {
        Responsibility: getUserDetails().RESP_KEY,
        RespApplication: getUserDetails().RESP_APPL_NAME,
        SecurityGroup: "STANDARD",
        NLSLanguage: "AMERICAN",
        Language: "EN",
        Org_Id: getURLDetails().I_ORG_ID
      },
      InputParameters: {
        I_HOST_HEADER_ID: getURLDetails().I_HEADER_ID,
        I_SEQUENCE_NUMBER: getUserDetails().SEQUENCE_NUMBER,
        I_USER_ID: getURLDetails().I_USER_ID,
        I_APPL_CODE: getURLDetails().I_APP_CODE,
        I_RESP_ID: getURLDetails().I_RESP_ID,
        I_REPORT_STATUS_FLAG: bool
      }
    })
    .then(response => {
      return response.data;
    });

export const CancelDataAPI = () =>
  axiosInstance
    .post("/cancelrequest", {
      RESTHeader: {
        Responsibility: getUserDetails().RESP_KEY,
        RespApplication: getUserDetails().RESP_APPL_NAME,
        SecurityGroup: "STANDARD",
        NLSLanguage: "AMERICAN",
        Language: "EN",
        Org_Id: getURLDetails().I_ORG_ID
      },
      InputParameters: {
        I_HEADER_ID: getURLDetails().I_HEADER_ID,
        I_SEQUENCE_NUMBER: getUserDetails().SEQUENCE_NUMBER
      }
    })
    .then(response => {
      toastr.success("All Eligible lines are cancelled successfully", "Success");
      return response.data;
    });

export const copyLinesAPI = ({ selectedLines, id }) => {
  let copyLines = selectedLines.map(num => ({
    SEQUENCE_NUMBER: getUserDetails().SEQUENCE_NUMBER,
    LINE_NUMBER: num
  }));

  return axiosInstance
    .post("/copyexistingordlines", {
      RESTHeader: {
        Responsibility: getUserDetails().RESP_KEY,
        RespApplication: getUserDetails().RESP_APPL_NAME,
        SecurityGroup: "STANDARD",
        NLSLanguage: "AMERICAN",
        Language: "EN",
        Org_Id: getURLDetails().I_ORG_ID
      },
      InputParameters: {
        I_HOST_HEADER_ID: getURLDetails().I_HEADER_ID,
        I_HEADER_ID: id,
        I_APPLICATION_CODE: getURLDetails().I_APP_CODE,
        I_USER_ID: getURLDetails().I_USER_ID,
        I_ORG_ID: getURLDetails().I_ORG_ID,
        I_RESP_ID: getURLDetails().I_RESP_ID,
        I_COPY_LINES: {
          I_COPY_LINES_ITEM: copyLines
        }
      }
    })
    .then(response => {
      toastr.success("Lines Added Successfully", "Success");
      return response.data;
    });
};

export const QuoteDataAPI = () =>
  axiosInstance
    .get("/displayquotes", {
      params: {
        I_CUST_ACCOUNT_ID: getUserDetails().CUST_ACCOUNT_ID,
        I_GS_QUOTE_NUM: "",
        I_ORACLE_QUOTE_NUMBER: "",
        I_ORDERED_FROM: getURLDetails().I_ORG_ID,
        I_FROM_DATE: moment(getCurrentAndPastDates().FormattedDate, "YYYY-MM-DD").format("YYYY-MM-DD"), // past 60 days
        I_TO_DATE: moment(getCurrentAndPastDates().currentDate, "YYYY-MM-DD").format("YYYY-MM-DD"), // current date
        I_CREATED_BY: getUserDetails().EMAIL_ADDRESS,
        I_QUOTE_STATUS: "DRAFT"
      }
    })
    .then(response => response.data);


export const QuoteLinesAPI = ({ id, revNum, quoteNum }) =>
  axiosInstance
    .get("/quotelines", {
      params: {
        I_ORG_ID: getURLDetails().I_ORG_ID,
        I_QUOTE_NUMBER: quoteNum,
        I_QUOTE_REVISION_NUMBER: revNum,
        I_CUST_ACCOUNT_ID: getUserDetails().CUST_ACCOUNT_ID
      }
    })
    .then(response => response.data);
