import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Moment from 'react-moment';
import React, { Component } from "react";
import GroupedTable from "../shared/grouped-table";

class OrderTable extends Component {
    state = { // initial state
        selectedLines: {}
    }
    
    render() {
        const { orderTableData, orderDetails } = this.props; // passing props
        const { selectedLines } = this.state; // passing state
        const { SearchBar } = Search;
        // Function to get the from and to deatils in pagination
        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                Showing {from} to {to} of {size} Results
            </span>
        );
        // BootstrapTable options
        const options = {
            paginationSize: 2,
            pageStartIndex: 1,
            firstPageText: "First",
            prePageText: "Previous",
            nextPageText: "Next",
            lastPageText: "Last",
            nextPageTitle: "First page",
            prePageTitle: "Pre page",
            firstPageTitle: "Next page",
            lastPageTitle: "Last page",
            showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [
                {
                    text: "5",
                    value: 5
                },
                {
                    text: "10",
                    value: 10
                },
                {
                    text: "All",
                    value: orderTableData.length
                }
            ]
        };
        // Table Headings 
        const columns = [
            {
                dataField: "ORACLE_ORDER_NUM",
                text: "Order Number"
            },
            {
                dataField: "ORDER_HEADER_ID",
                text: "Order Id"
            },
            {
                dataField: "ORDERED_DATE",
                text: "Ordered Date",
                formatter: this.formatDate
            },
            {
                dataField: "ORDERED_BY",
                text: "Ordered By"
            },
            {
                dataField: "CUST_PO_NUMBER",
                text: "Customer PO Number"
            },
            {
                dataField: "ORDER_STATUS",
                text: "Order Status"
            }
        ];
        const detailscolumns = [
            {
                dataField: "PO_LINE_NUMBER",
                displayName: "Order Line Number",
                group: true
            },
            {
                dataField: "PRODUCT_NUMBER",
                displayName: "Model Name",
                group: false
            },
            {
                dataField: "BASE_MODELNAME",
                displayName: "Base Model Name",
                group: false
            },
            {
                dataField: "MODEL_STRING",
                displayName: "Model String",
                group: false
            },
            {
                dataField: "LINE_TYPE",
                displayName: "Line Type",
                group: false
            },
            {
                dataField: "QUANTITY",
                displayName: "Quantity",
                group: false
            },
        ];
        const expandRow = {
            // on expand rows
            onExpand: (row, isExpand, rowIndex, e) => {
                const { getOrderDetails } = this.props;
                let id = row.ORDER_HEADER_ID;
                if (isExpand && !orderDetails[id]) {
                    getOrderDetails({ id });
                }
            },
            renderer: row => {
                let orderHeaderId = row.ORDER_HEADER_ID;
                let childData = orderDetails[orderHeaderId];
                let selectedIds = Object.assign([], selectedLines[orderHeaderId])
                return (
                    <div>
                        {
                            childData && (
                                <GroupedTable hasRowCheck={true} onSelect={(id, checked) => this.onSelectedOrderLine(id, checked, orderHeaderId, childData)}
                                    data={childData} columns={detailscolumns} selectedIds={selectedIds} />
                            )
                        }
                        {
                            selectedIds.length > 0 && (
                                <button className="btn-primary m-auto d-block"
                                    onClick={() => this.copyOrderLines(selectedIds, orderHeaderId)} title="Copy Orderlines">
                                    <i className="fa fa-files-o pr-2" aria-hidden="true"></i>Copy Orderlines
                                </button>
                            )
                        }
                    </div>
                )
            },
            showExpandColumn: true,
            nonExpandable: [0]
        };

        return (
            <ToolkitProvider
                keyField="ORACLE_ORDER_NUM"
                data={orderTableData}
                columns={columns}
                search >
                {props => (
                    <div>
                        <div className="searchbar-row w-50">
                            <label className="label font-weight-bold float-left">My Orders</label>
                            <SearchBar {...props.searchProps} className="searchbar w-100 border-right-0" placeholder="Search Order" />
                            <button className="btn btn-default w-auto search-btn"><i className="fa fa-search"></i></button>
                        </div>
                        <BootstrapTable
                            bordered={false}
                            striped
                            hover
                            {...props.baseProps}
                            // selectRow={selectRow}
                            pagination={paginationFactory(options)
                            }
                            expandRow={expandRow}
                            noDataIndication="No Orders Available"
                        />
                    </div>
                )}
            </ToolkitProvider>
        );
    }

    // Function to get the formatted date
    formatDate = (cell) => {
        return (
            <Moment format='YYYY/MM/DD' date={cell} />
        );
    };

    // On save function to cal copy lines data
    saveOrder = async () => {
        const { CopyLinesData } = this.props;
        await CopyLinesData();
    }

    // gets the selected order line details 
    onSelectedOrderLine = (id, checked, orderHeaderId, childData) => {
        let selectedLines = Object.assign([], this.state.selectedLines[orderHeaderId]);
        let refId = id;
        let validParent =  childData.filter(c => c.HEADER_ID === orderHeaderId).length > 0
        if(validParent){
            if (checked) {
                selectedLines.push(refId);
            } else {
                selectedLines = selectedLines.filter(id => id !== refId)
            }
            let newSelectedLines = {...this.state.selectedLines};
            newSelectedLines[orderHeaderId] = selectedLines;
            this.setState({ selectedLines : newSelectedLines });
        }
    }

    // Function to get the copy order lines
    copyOrderLines = async (selectedLines, id) => {
        const { CopyLinesData, visibiltyCopySizing } = this.props;
        await CopyLinesData({ selectedLines, id });
        setTimeout(() => {
            this.setState({ selectedLines: [] });
            visibiltyCopySizing();
        }, 3000)
    }
}



export default OrderTable;
