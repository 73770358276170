import React from 'react';

const Status = ({ id, name, onClick, status, wrapperClass }) => {
    let statusIcon = '';
    let hoverMsg = '';
    switch (status) { // Checks the status 
        case 'progress': // When status is in progress
            statusIcon = 'fa-spinner';
            hoverMsg = 'Loading';
            break;
        case 'success': // When status is success
            statusIcon = 'fa-check-circle-o';
            hoverMsg = 'Saved Succesfully'
            break;
        case 'failed': // When status is failed
            statusIcon = 'fa-times-circle-o';
            hoverMsg = 'Click here to see the Error Message'
            break;
        default:
            break;
    }
    return (
        // Dynamic button which takes Id, name, classname, onClick, and title dynamic
        <button id={id} name={name} className={wrapperClass} onClick={onClick} title={hoverMsg}>
            {
                status === 'progress' ?
                    <div className="status-loader">
                        <div className="loader-block">
                            <div className="loader"></div>
                        </div>
                    </div>
                    : <i className={`fa ${statusIcon}`} aria-hidden="true"></i>
            }
        </button>
    );
}

export default Status;