import axios from "axios";
import { API_URL, API_SUB_KEY } from "../../utils/constant";
import { getURLDetails } from "../../utils/methods";

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": API_SUB_KEY,
    "Pragma": "no-cache"
  },
  timeout: 240000
});

export const authorizedUser = () =>
  axiosInstance
    .get("/userdata", {
      params: {
        I_USER_ID: getURLDetails().I_USER_ID,
        I_HEADER_ID: getURLDetails().I_HEADER_ID,
        I_SESSION_ID: getURLDetails().I_SESSION_ID,
        I_ORG_ID: getURLDetails().I_ORG_ID,
        I_RESP_ID: getURLDetails().I_RESP_ID,
        I_APP_CODE: getURLDetails().I_APP_CODE
      }
    })
    .then(response => response.data);
