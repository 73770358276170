import React from 'react';
import './index.css';

const GroupedTable = ({onSelect, hasRowCheck, data, columns, selectedIds}) => {
    /*************************************************************
    Don't remove this section!!!!
    Here the expected data format for reference
    const columns = [
        {
            dataField: 'id',
            displayName: 'Id',
            group: true
        },
        {
            dataField: 'product',
            displayName: 'Product',
            group: true
        },
        {
            dataField: 'date',
            displayName: 'Date',
            group: false
        },
        {
            dataField: 'amount',
            displayName: 'Amount',
            group: false
        }
    ];
    const data = [
        {
            id: 1,
            product: 'Hadoop in Action',
            date: '01-01-2019',
            amount: 100,
            rowSpan: 3
        },
        {
            id: 1,
            product: 'Hadoop in Action',
            date: '02-01-2019',
            amount: 100
        },
        {
            id: 1,
            product: 'Hadoop in Action',
            date: '04-01-2019',
            amount: 101
        },
        {
            id: 2,
            product: 'React in Action',
            date: '01-01-2019',
            amount: 67,
            rowSpan: 1
        },
        {
            id: 3,
            product: 'Angular in Action',
            date: '01-01-2019',
            amount: 56,
            rowSpan: 3
        },
        {
            id: 3,
            product: 'Angular in Action',
            date: '03-01-2019',
            amount: 56
        },
        {
            id: 3,
            product: 'Angular in Action',
            date: '07-01-2019',
            amount: 56
        }
    ];
    *************************************************************/ 
    return (
        <div className="grouped-table">
            <table className="table table-splited table-bordered">
                <TableHeader columns={columns} hasRowCheck={hasRowCheck} />
                <TableBody data={data} columns={columns} hasRowCheck={hasRowCheck} 
                    onSelect={onSelect} selectedIds={selectedIds}/>
            </table>
        </div>
        
    );
}

const TableHeader = ({columns, hasRowCheck}) => {
    const theadItems = prepareTheadItems(columns, hasRowCheck)
    return(
        <thead className="thead-light">
            <tr>
                {theadItems}
            </tr>
        </thead>
    );
};

const prepareTheadItems = (columns, hasRowCheck) => {
    let theadItems = [];
    if(hasRowCheck) {
        theadItems.push(<th key={'select'}>{'Select'}</th>);
    }
    columns.map((c, i) => {
        theadItems.push(<th key={i}>{c.displayName}</th>)
    });
    return theadItems;
}

const EmptyData = ({columns}) => {
    return(
        <tr>
            <td colSpan={columns.length +1 }>No Data Available for this Order</td>
        </tr>
    )
}
const TableBody = ({ data, columns, hasRowCheck, onSelect, selectedIds }) => {
    return (
        <tbody>
            {
                data.length > 0 ?
                data.map((d, i) => (
                    <TableRow key={i} row={d} columns={columns} 
                        hasRowCheck={hasRowCheck} onSelect={onSelect} selectedIds={selectedIds} />
                )):<EmptyData columns={columns}/>
            }
        </tbody>
    );
}

const TableRow = ({ row, columns, hasRowCheck, onSelect, selectedIds }) => {
    const tdItems = prepareTdItems(row, columns, hasRowCheck, onSelect, selectedIds);
    return (
        <tr>
            {tdItems}
        </tr>
    );
}

const prepareTdItems = (row, columns, hasRowCheck, onSelect, selectedIds) => {
    let tdItems = [];
    const tdClass = "grp-table-td";
    if(hasRowCheck) {
        if(row.rowSpan) {
            tdItems.push(<td className={tdClass} rowSpan={row.rowSpan}>
                <input type="checkbox" 
                    checked={selectedIds.includes(row.id)}
                    onClick={(e) => onSelect(row.id, e.target.checked)}/>
            </td>);
        }
    }
    
    columns.map((c, i) => {
        if(c.group) {
            if(row.rowSpan) {
                tdItems.push(<td className={tdClass} key={i} rowSpan={row.rowSpan}>{row[c.dataField]}</td>);
            }
        } else {
            tdItems.push(<td className={tdClass} key={i}>{row[c.dataField]}</td>);
        }
    })
    return tdItems;
}

export default GroupedTable;