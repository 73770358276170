import { takeLatest, call, put } from "redux-saga/effects";
import { GET_URL_DATA, GET_URL_DATA_SUCCESS, GET_URL_DATA_ERROR, GET_USER, GET_USER_SUCCESS, GET_USER_ERROR } from "./constant";
import { GET_ERRORMSG } from "../error-store/constant";
import { authorizedUser } from "./api";
import { prepareErrorObject } from "../error-store/helper";

/**
 * action Type of GET_URL
 * @param {Object} action
 */
function* urlSaga(action) {
  try {
    const data = action.payload;
    yield sessionStorage.setItem(
      "urldata",
      JSON.stringify({
        phase: "SUCCESS",
        urlData: JSON.stringify(data),
        error: null
      })
    );
    yield put({ type: GET_URL_DATA_SUCCESS, data });
    
  } catch (error) {
    let data = prepareErrorObject({
      code: error.code,
      message: error.message,
      api: GET_URL_DATA_ERROR
    });
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: GET_URL_DATA_ERROR, error });
  }
}

/**
 * action Type of GET_USER
 * @param {Object} action
 */
function* userSaga(action) {
  try {
    const data = yield call(authorizedUser, action.payload);
    yield sessionStorage.setItem(
      "persist:root",
      JSON.stringify({
        phase: "SUCCESS",
        user: JSON.stringify(data),
        error: null
      })
    );
    //yield put({ type: GET_MY_ENTERED_MODELDATA });
    yield put({ type: GET_USER_SUCCESS, data });
    yield put({ type: GET_ERRORMSG, data });  
  } catch (error) {
    let data = prepareErrorObject(error, GET_USER_ERROR );
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: GET_USER_ERROR, error });
  }
}

/**
 * Get URL Saga
 */
export function* getURLSaga() {
  yield takeLatest(GET_URL_DATA, urlSaga);
}

/**
 * Get User Saga
 */
export function* getUserSaga() {
  yield takeLatest(GET_USER, userSaga);
}
