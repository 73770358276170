import { GET_ERRORMSG, CLEAR_ERRORMSG } from "./constant";
const initialState = [];

export function errorDataStore(state = initialState, action) {
    switch (action.type) {
        case GET_ERRORMSG:
            return [
                ...state, {
                    errorCode: action.data.OutputParameters.O_ERROR_CODE,
                    errorMsg: action.data.OutputParameters.O_ERROR_MSG,
                    errorOn: action.data.OutputParameters.API,
                }
            ];
        case CLEAR_ERRORMSG:
            return state.filter(errorItem => errorItem.errorOn !== action.errorOn);
        default:
            return state;
    }
}

// Action creator -- clearErrorStatus
export const clearErrorStatus = (errorOn) => ({
    type: CLEAR_ERRORMSG,
    errorOn
})
