
import React from "react";
import logo from "../../images/emerson-logo.png";

function Header() {
    return (
        <>
            <header>
                <nav className="navbar navbar-expand-sm bg-dark navbar-dark fixed-top">
                    <a className="navbar-brand">
                        <img src={logo} alt="logo" className="app-logo" />
                    </a>
                    <div className="app-title">
                        Rapid Quote Order Management
            </div>
                </nav>
            </header>
        </>
    )
}

export default Header;