import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { userStore } from "./user"
import { orderStore } from "./order";
import { modalDataStore } from "./model-data";
import { errorDataStore } from "./error-store";
import { PERSIST_KEY } from "../utils/constant";
const persistConfig = {
  key: PERSIST_KEY,
  storage
};

const rootReducer = combineReducers({
  userStore: persistReducer(persistConfig, userStore),
  orderStore: orderStore,
  modalDataStore:modalDataStore,
  errorDataStore: errorDataStore
});

export default rootReducer;