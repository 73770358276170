import { API_ERROR_OBJECT, API_TIMEOUT_ERROR_MESSAGE } from './constant';
import { getUserDetails } from "../../utils/methods";

export const prepareErrorObject = (error, api) => {
    let { code, message } = {
        code: error.hasOwnProperty("Code") ? error.Code : 500, // checks if the code has its property and take the data accordingly 
        message: error.hasOwnProperty("Message") ? error.Message : error.message,
    }

    let errObj = Object.assign({}, JSON.parse(JSON.stringify(API_ERROR_OBJECT)));
    errObj['OutputParameters']['O_ERROR_CODE'] = 'Y';
    let customMessage = "";

    if (code === "ISG_USER_RESP_MISMATCH") { // when the error code is ISG_USER_RESP_MISMATCH
        let errMsg = `RQOM is not enabled for this responsibility.Please log a ticket with the Emerson Service Desk for ${getUserDetails().RESP_NAME} to be assigned to EMR_ISG_RQOM_USER.`
        customMessage = code === 'ECONNABORTED' ? API_TIMEOUT_ERROR_MESSAGE : errMsg.replace(message, errMsg)
    }

    if (error.message === "Error while getting Division Code - No data found: ORA-01403: no data found") { // when the error code is No data found: ORA-01403: no data found
        let errMsgCustom = "Entered Model details is incorrect, Please enter proper data"
        customMessage = errMsgCustom
        // customMessage = code === 'ECONNABORTED' ? API_TIMEOUT_ERROR_MESSAGE : errMsgCustom.replace(message, errMsgCustom)
    }

    if(code === 'ECONNABORTED'){
        customMessage = code === 'ECONNABORTED' ? API_TIMEOUT_ERROR_MESSAGE : message
    }

    if(message.includes(500)){ // when the error code is 500
        customMessage = code === 'ECONNABORTED' ? API_TIMEOUT_ERROR_MESSAGE : message
    }

    if(message.includes(404)){ // when the error code is 404
        customMessage = code === 'ECONNABORTED' ? API_TIMEOUT_ERROR_MESSAGE : message
    }

    if(message.includes("PPLT")){
        customMessage = message
    }

    errObj['OutputParameters']['O_ERROR_MSG'] = customMessage || '';
    errObj['OutputParameters']['API'] = api || '';
    return errObj;
}
