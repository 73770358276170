import { connect } from 'react-redux';
import PasteOptions from './PasteOptions';

const mapStateToProps = state => state;

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(PasteOptions);

