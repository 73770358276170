import React, { Component } from "react";
import Aux from "../../shared/AuxContainer";
import TextInput from "../../shared/TextInput";
import SelectInput from "../../shared/SelectInput";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

class ApplyHoldsRows extends Component {
  render() {
    const { onRowAdd, id, onRowRemove, seedData, data } = this.props; // passing props

    return (
      <tbody>
        <Aux>
          <tr>
            <td className="position-relative">
              <SelectInput
                name="holdName"
                defaultOption="--Select Hold Name--"
                value={data.holdName}
                options={seedData[0].holdDetailsData}
                onChange={e => this.onChangeData(e, id)}
                title={data.holdName}
              />
            </td>
            <td className="position-relative">
              <TextInput
                name="holdType"
                value={data.holdType}
                onChange={e => this.onChangeData(e, id)}
                type="text"
                placeholder="Hold Type"
                title={data.holdType}
              />
            </td>
            <td className="position-relative">
              <TextInput
                name="description"
                value={data.description}
                error=""
                type="text"
                placeholder="Description"
                onChange={e => this.onChangeData(e, id)}
                title={data.description}
              />
            </td>
            <td className="position-relative">
              <DatePicker
                value={data.holdUntillDate}
                onChange={date => {
                  this.onChangeData(
                    {
                      target: {
                        name: "holdUntillDate",
                        value: moment(date.toDateString()).format('DD-MMM-YYYY')
                      }
                    },
                    id
                  );
                }}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                name="holdUntillDate"
              />
            </td>
            <td className="position-relative">
              <TextInput
                name="holdComments"
                value={data.holdComments}
                error=""
                type="text"
                placeholder="Hold Comments"
                title={data.holdComments}
                onChange={e => this.onChangeData(e, id)}
              />
            </td>
            <td className="text-center">
              {id === 0 && (
                <button
                  onClick={onRowAdd}
                  className="btn btn-primary minWidth-auto border-0 p-0 add-btn"
                  title="Add Hold Row"
                >
                  <i className="fa fa-plus-circle" />
                </button>
              )}
              {id !== 0 && (
                <button
                  onClick={() => onRowRemove(id)}
                  className="btn btn-primary minWidth-auto border-0 p-0 delete-btn"
                  title="Delete Hold Row"
                >
                  <i className="fa fa-trash-o" aria-hidden="true" />
                </button>
              )}
            </td>
          </tr>
        </Aux>
      </tbody>
    );
  }

  clearHoldData = () => {
    const initialRowApplyHold = Object.assign({}, this.props.holdData);
    let ApplyHoldRows = [initialRowApplyHold];
    this.props.updateRow({ ApplyHoldRows });
  };

  // ===== Function triggered when onChange event is called ===== 
  onChangeData = (e, id) => {
    const value = e.target.value;
    const name = e.target.name;
    this.updateRowsContent(id, name, value);
  };

  // ====== Function called to update the content of rows =======
  updateRowsContent = (id, name, value) => {
    let initialRowApplyHold = Object.assign({}, JSON.parse(JSON.stringify(this.props.data)));
    initialRowApplyHold[name] = value;
    if (name === "holdName") {
      this.props.updateHoldName({ id, initialRowApplyHold });
    } else {
      this.props.updateHoldRow({ id, initialRowApplyHold });
    }
  };
}

// ===== To declare that a prop is a specific JS primitive. ===== 
ApplyHoldsRows.propTypes = {
  onRowAdd: PropTypes.func.isRequired,
  onRowRemove: PropTypes.func.isRequired
};

export default ApplyHoldsRows;
