import React, { Component } from 'react';
import LineNotesHeader from '../line-notes-header';
import LineNotesRow from '../line-notes-row';
import uuid from "uuid";

class LineNotes extends Component {

    // ===== Life cycle method to generate the base row  =====
    componentDidMount() {
        this.generateFormWithBaseRow();
    }
    
    // ===== Function to create the base row =====
    generateFormWithBaseRow = () => {
        const initialRowLines = Object.assign({}, this.props.data.initialRowLineNotes);
        let LineNotesRows = [initialRowLines];
        this.props.updateRow({ LineNotesRows });
    };

    render() {
        const { LineNotesRows } = this.props.data // passing the props to render
        return (
            <table className="w-100 modelData-tbl">
                <LineNotesHeader />
                {
                    LineNotesRows.map((row, i) =>
                        <LineNotesRow key={row.id} onRowAdd={this.onRowLineNotesAdd} id={row.id} onRowRemove={this.onRowItemRemove} />
                    )
                }
            </table>
        )
    }

    // ===== Add line notes rows =====
    onRowLineNotesAdd = () => {
        const { data } = this.props
        const initialRow = Object.assign({}, data.initialRowLineNotes);
        let id = uuid.v4();
        let item = {
            ...initialRow,
            id
        };
        this.props.addLineNotes([...data.LineNotesRows, item]);
    };

    // ===== Remove line notes =====
    onRowItemRemove = id => {
        let LineNotesRows = Object.assign([], this.props.data.LineNotesRows);
        LineNotesRows = LineNotesRows.filter(row => row.id !== id);
        this.props.deleteRow({
            LineNotesRows
        });
        this.props.updateRow({ LineNotesRows })
    };
}


export default LineNotes;