import { connect } from 'react-redux';
import SizingTable from './SizingTable';
import { getCopySizing, getSizing, getMyEnteredModel } from "../../store/order/index";
import { prepareEnteredModelData, getDivisionCode } from "../../utils/helper";


// ===== Map state received from reducer to props  =====
const mapStateToProps = (state) => {
    return {
        referenceId: prepareRefId(state.orderStore), // Will check and remove later
        sizingTable: prepareSizingData(state.orderStore),// To prepare the sizing data
        copySizings: prepareCopySizingData(state.orderStore), // To prepare the copy sizing data
        enteredModelData: prepareEnteredModelData(state.orderStore), // to prepare the entered model data 
        divCode: getDivisionCode(state.modalDataStore), // get Div code
        status: state.orderStore.status,// get the status
    }
};


// Will check and remove later
const prepareRefId = data => {
    let result = [];
    if (Object.keys(data).length > 0) {
        try {
            // result = data.modelString.OutputParameters.O_MODEL_STRING.O_MODEL_STRING_ITEM
            //     .map(i => i.MODEL_STRING)
        }
        catch {
        }
    }
    return result;
}

// Function to prepare the sizing data 
const prepareSizingData = data => {
    let result = [];
    if (Object.keys(data).length > 0) {
        try {
            let ResponseType = data.sizing.OutputParameters.O_MYSIZING_LIST.O_MYSIZING_LIST_ITEM;
            if (Array.isArray(ResponseType) === false) { // To check if its an array
                result = [data.sizing.OutputParameters.O_MYSIZING_LIST.O_MYSIZING_LIST_ITEM]
            } else {
                result = data.sizing.OutputParameters.O_MYSIZING_LIST.O_MYSIZING_LIST_ITEM
            }
        }
        catch {
        }
    }
    return result;
}

// Function to prepare the copy sizing data 
const prepareCopySizingData = data => {
    let result = "";
    if (Object.keys(data).length > 0) {
        try {
            result = data.copySizingsData.OutputParameters.O_SIZING_REF_LIST.O_SIZING_REF_LIST_ITEM.SIZING_ID;
        }
        catch {
        }
    }
    return result;
}

// ===== Mapping dispatch functions to the props  =====
const mapDispatchToProps = {
    getSizings: payload => getSizing(payload), // dispatching the get sizing function
    CopySelectedSizings: payload => getCopySizing(payload), // dispatching the get copy sizing function
    EnteredModelData: payload => getMyEnteredModel(payload),// dispatching the get my entered model data
};

export default connect(mapStateToProps, mapDispatchToProps)(SizingTable); // The connect() function connects a React component SizingTable to a Redux store(OrderStore).

