// Action Creators 

const GET_ERRORMSG = 'GET_ERRORMSG';
const CLEAR_ERRORMSG = 'CLEAR_ERRORMSG';
const API_TIMEOUT_ERROR_MESSAGE = 'Response Timeout';
const API_ERROR_OBJECT = {
    OutputParameters: {
        O_ERROR_CODE: 'Y',
        O_ERROR_MSG: '',
        API: ''
    }
}

// Exporting the Action
export {
    GET_ERRORMSG,
    CLEAR_ERRORMSG,
    API_TIMEOUT_ERROR_MESSAGE,
    API_ERROR_OBJECT
};