import React, { Component } from "react";
import uuid from "uuid";
import PropTypes from "prop-types";
import toastr from "toastr";
import ModelHeader from "../model-header";
import ModelContent from "../model-content";
import SelectInput from "../../shared/SelectInput";
import Popup from "../../shared/Popup";
import { getURLDetails, getUserDetails } from "../../../utils/methods";
import { Modal, Button } from "react-bootstrap";
import XLSX from "xlsx";
import moment from "moment";
import {
  prepareModalNameParams,
  parseModelDataForInlineValidation,
  download,
} from "../../../utils/helper";
import PasteOptions from "../paste-options/";
import { InputPayload, RMTInputPayload } from "../../../utils/urd-template";
import { prepareErrorPopupMessage } from "../../../utils/helper";
import {
  preparePayloadForExcelUpload,
  templateModelDataPayload,
  convertKeysToUpperCase,
} from "../../../utils/excel-helper";
import {
  STATUS_IMPORT_EXCEL_SUCCESS,
  STATUS_IMPORT_EXCEL_FAILED,
  STATUS_INSERT_SUCCESS,
  STATUS_IMPORT_PASTELIST_SUCCESS,
  STATUS_IMPORT_PASTELIST_FAILED,
  STATUS_URD_SUCCESS,
  STATUS_URD_FAILED,
  STATUS_GET_MODELNAME_SUCCESS,
} from "../../../store/model-data/constant";
import DynamicSelectInput from "../../shared/DynamicSelectInput";
// ====== changes are made for the RFC AGL-ORA000231 ======
// ====== generate an array of column objects ======
const make_cols = (refstr) => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};

class ModelManagement extends Component {
  state = {
    // set initial state
    openPasteOptions: false,
    hasValid: false,
    hasSubmitted: false,
    disableBaseModel: false,
    currentId: null,
    errors: {},
    showSaveError: false,
    showImportDialog: false,
    openURD: false,
    capturedModelNames: [],
    excelUploadTotalBUs: 0,
    excelUploadProcessedBUs: 0,
    excelUploadSkippedBUs: 0,
    excelUploadPendingData: [],
    excelUploadingBU: "",
    disableExcelButtons: false,
    urdFetchData: [],
    isFetchingMultiLevelModel: false,
    showMultiLevelModelList: false,
  };

  //  ==== life cycle method to get the model strings on load and generate the empty row ====
  componentDidMount() {
    let sessionFlagData = getUserDetails().SESSION_FLAG;
    // if (sessionFlagData === "Y") {
    setTimeout(() => {
      this.props.getModelString(this.props.urlDetails);
    }, 2000);
    // }
    this.generateFormWithBaseRow();
  }

  componentDidUpdate(prevProps) {
    const {
      currentModelName,
      MyGetModifierData,
      enteredModelData,
      onSaveError,
      status,
      data,
      quantityChanged,
      basemodeldata,
    } = this.props;
    const { currentId } = this.state;
    if (prevProps.data.rows !== data.rows) {
      this.validateInputs(data.rows);
      if (data.edit) {
        let updateModelString = [];
        let updateModelNames = [];
        updateModelString = data.rows
          .map((i) => i.modelString.toUpperCase())
          .filter((x) => x !== "");
        updateModelNames = data.rows
          .filter((r) => Boolean(r.modelString))
          .map((i) => i.modelName.toUpperCase())
          .filter((x) => x !== "");

        if (data.selectedBaseModelName !== "flat") {
          this.getModelNames(updateModelString, 0, updateModelNames, data.rows);
        }
        this.findWarehouses(data.rows[0].modelName, 0);
        MyGetModifierData();
        this.props.updateRow({ edit: false, rows: data.rows });

        if (prevProps.quantityChanged !== data.rows[0].quantity) {
          this.props.OnQuantityChange({ value: data.rows[0].quantity, id: 0 });
        }
      }

      let urdFetchData = [...this.state.urdFetchData];
      data.rows.map((item) => {
        if (urdFetchData.filter((fItem) => fItem.id === item.id).length === 0) {
          let rowFetchData = {};
          rowFetchData.id = item.id;
          rowFetchData.modelName = item.modelName;
          rowFetchData.fetch = false;
          urdFetchData.push(rowFetchData);
        }
      });

      this.setState({ urdFetchData }, () => {
        const pendingFetchCount = this.state.urdFetchData.filter(
          (i) => i.fetch === false
        ).length;
        if (pendingFetchCount > 0) {
          // call process pending detch function
          this.processPendingUrdFetch();
        }
      });
    }

    // checks if the current model name is equal to previous model name
    if (prevProps.currentModelName !== currentModelName) {
      let rows = Object.assign([], this.props.data.rows);
      rows = rows.map((item) => {
        if (item.id === currentId) {
          item.modelName = currentModelName;
        }
        return item;
      });
      this.props.addRow(rows);
    }

    // checks if current and previous entered model data length are equal
    if (
      (enteredModelData &&
        enteredModelData.length !== prevProps.enteredModelData.length) ||
      onSaveError.status !== prevProps.onSaveError.status
    ) {
      if (onSaveError.status === "success" && !this.props.onSaveError.isRMT) {
        this.setState(
          {
            hasSubmitted: false,
          },
          () => {
            this.props.clearBaseModels("ALL");
            this.props.clearHoldRow();
            this.props.clearSaveErrors();
            this.generateFormWithBaseRow();
          }
        );
      }
    }

    // checks if current and previous location status ar equal
    if (prevProps.locationStatus !== this.props.locationStatus) {
      this.validateInputs(this.props.data.rows);
    }

    // checks if the  current and the previous status is equal
    if (prevProps.status !== status) {
      switch (status) {
        case STATUS_IMPORT_EXCEL_SUCCESS:
          const { excelUploadingBU } = this.state;
          const hasCompleted = this.validateExcelUploadCompletion();
          if (!hasCompleted) {
            // Continue for pending BU sheets
            this.processExcelUploadPendingBUs(excelUploadingBU);
          }
          break;
        case STATUS_IMPORT_EXCEL_FAILED:
          this.toggleShowImportDialog();
          this.setState({ disableExcelButtons: false });
          toastr.error("Error in Importing Excel Data", "Error");
          break;
        case STATUS_INSERT_SUCCESS:
          toastr.success("Inserted Data Successfully", "Success");
          this.processInlineValidation();
          break;
        case STATUS_IMPORT_PASTELIST_SUCCESS:
          this.togglePasteData();
          toastr.success("Paste List Imported Successfully", "Success");
          break;
        case STATUS_IMPORT_PASTELIST_FAILED:
          toastr.error("Error in Importing Paste List", "Error");
          break;
        case STATUS_URD_SUCCESS:
          const { urdFetchId } = this.props;
          let urdFetchData = [...this.state.urdFetchData];
          urdFetchData = urdFetchData.map((fItem) => {
            if (fItem.id === urdFetchId) {
              fItem.fetch = true;
            }
            return fItem;
          });
          this.setState({ urdFetchData }, () => {
            this.processPendingUrdFetch();
          });

          this.setState({});
          break;
        case STATUS_GET_MODELNAME_SUCCESS:
          this.setState({ isFetchingMultiLevelModel: false });
          break;
      }
    }

    if (
      JSON.stringify(prevProps.basemodeldata) !== JSON.stringify(basemodeldata)
    ) {
      const { selectedBaseModelName } = this.props.data;
      const validBaseModel =
        basemodeldata.filter((bm) => bm.label === selectedBaseModelName)
          .length > 0;
      if (!validBaseModel && selectedBaseModelName) {
        this.onDynamicBaseModelSelect("");
      }
    }
  }

  addOptionBUs = ["MMI", "ISV", "RMT", "ROX", "DMC", "RAS"];

  render() {
    const {
      rows,
      warehouses,
      modelStrings,
      userlocations,
      selectedBaseModelName,
      baseModelNew,
    } = this.props.data;
    const {
      errors,
      hasValid,
      disableBaseModel,
      showSaveError,
      showImportDialog,
      disableExcelButtons,
      isFetchingMultiLevelModel,
      showMultiLevelModelList,
    } = this.state; // passing the state data
    const {
      sequenceNo,
      floatingBtnsVisible,
      userdata,
      basemodeldata,
      onSaveError,
      divCode,
      exit,
      requiredMultiLevelModel,
    } = this.props; // passing the props data
    const hasAction = this.addOptionBUs.includes(userdata) || userdata === null;
    const maxModelCount = baseModelNew.maxModelCount || 9999;
    return (
      <div className="modelData-section">
        {/* <div className="row" ref={baseModelNode => this.baseModelNode = baseModelNode}>
          <DynamicSelectInput 
            label="Multi Level Model"
            name="Multi Level Model"
            defaultOption="--Select Multi-Level Model--"
            value={selectedBaseModelName}
            allowFilter={false}
            showList={showMultiLevelModelList}
            wrapperClass="col-md-6"
            showLoader={isFetchingMultiLevelModel}
            onClick={this.loadMultiLevelModels} 
            onSelect={this.onDynamicBaseModelSelect}
            options={basemodeldata}/>
        </div> */}

        <div className="row">
          <div className="base-input multi-level-check">
            <input
              type="checkbox"
              checked={requiredMultiLevelModel}
              onClick={this.toggleMultiLevelModel}
            />
          </div>

          <SelectInput
            wrapperClass="col-md-6 base-input"
            name="baseModel"
            label="Multi Level Model"
            defaultOption="--Select Multi-Level Model--"
            value={selectedBaseModelName}
            options={basemodeldata}
            onChange={this.onBaseModelSelect}
            disabled={disableBaseModel}
          />
        </div>

        <table className="w-100 modelData-tbl">
          {/* ==== Model data header ==== */}
          <ModelHeader
            location={divCode === "MHM" ? true : false}
            hasAction={hasAction}
          />

          {/* ==== Model Content ==== */}
          <ModelContent
            seqStart={sequenceNo}
            data={rows}
            errors={errors}
            onChange={this.onRowFormChange}
            onAdd={this.onRowItemAdd}
            onRemove={this.onRowItemRemove}
            warehouses={warehouses}
            userlocations={userlocations}
            modelStrings={modelStrings}
            onModelStringSelect={this.onModelStringSelect}
            onFindModelNames={this.getModelNames}
            findWarehouses={this.findWarehouses}
            location={divCode === "MHM" ? true : false}
            hasAction={hasAction}
            maxModelCount={maxModelCount}
            toggleStatusPopup={this.toggleStatusPopup}
            baseModel={selectedBaseModelName}
            exit={exit}
          />
        </table>

        {/* ==== Floating buttons for clear, save, paste, upload ==== */}
        {floatingBtnsVisible && (
          <div className="floating-btns">
            <button
              onClick={(e) => this.clearData(e)}
              className="btn btn-default clear-btn d-block w-auto mb-2"
              disabled={!hasValid}
            >
              <i className="fa fa-eraser" />
              Clear
            </button>
            <button
              onClick={(e) => this.saveModelData(e)}
              className="btn btn-default save-btn d-block w-auto mb-2"
              disabled={!hasValid}
            >
              <i className="fa fa-save" />
              Save
            </button>
            <button
              onClick={() => this.toggleShowImportDialog()}
              className="btn btn-default save-btn d-block w-auto mb-2"
            >
              <i className="fa fa-arrow-up" />
              Import
            </button>
            <button
              onClick={(e) => this.togglePasteData(e)}
              className="btn btn-default paste-btn d-block w-auto"
            >
              <i className="fa fa-paste"></i>Paste
            </button>

            {/* <button
              onClick={() => this.toggleShowImportDialog()}
              className="btn btn-default save-btn d-block w-auto mb-2"
              disabled={getUserDetails().SESSION_FLAG === "N"}

            >
              <i className="fa fa-arrow-up" />Import
            </button>
            <button onClick={(e) => this.togglePasteData(e)}
              className="btn btn-default paste-btn d-block w-auto"
              disabled={getUserDetails().SESSION_FLAG === "N"}
            >
              <i className="fa fa-paste"></i>Paste
            </button> */}
          </div>
        )}
        {onSaveError &&
          Object.keys(onSaveError).length > 0 &&
          showSaveError && (
            <Popup
              title={onSaveError.title}
              message={prepareErrorPopupMessage(onSaveError.message)}
              onOK={() => this.toggleStatusPopup(false)}
              onCancel={() => this.toggleStatusPopup(false)}
            />
          )}
        <PasteOptions
          lineNo={(this.props.enteredModelData.length || 0) + 1}
          open={this.state.openPasteOptions}
          onClose={this.togglePasteData}
          onSave={(data) => this.savePasteModelData(data)}
        />
        {/* ==== Pop up for Downloading the template ===== */}
        <Modal
          show={showImportDialog}
          backdrop={true}
          onHide={() => this.toggleShowImportDialog()}
        >
          <Modal.Header closeButton>
            <Modal.Title>{"Import Model Data"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {" "}
              Download the template <i className="fa fa-arrow-right" /> edit{" "}
              <i className="fa fa-arrow-right" /> upload to import model data.
            </p>
            {this.state.excelPreview && (
              <div
                dangerouslySetInnerHTML={{ __html: this.state.excelPreview }}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={disableExcelButtons}
              variant="primary"
              onClick={() => download("/template/RQOM_Excel.xlsx")}
            >
              <i className="fa fa-download" /> Download
            </Button>
            <Button
              disabled={disableExcelButtons}
              variant="primary"
              onClick={() => {
                this.uploadElm.value = null;
                this.uploadElm.click();
              }}
            >
              <input
                style={{ position: "fixed", visibility: "hidden" }}
                ref={(input) => (this.uploadElm = input)}
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e) => {
                  if (e.target.files[0]) {
                    this.setState({ disableExcelButtons: true });
                    this.importExcelDataFromFile(e.target.files[0]);
                  }
                }}
              />
              <i className="fa fa-upload" />
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  // ===== Function called when import button is clicked =====
  importExcelDataFromFile = (file) => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    let urdSheetName = this.state.urdSheetName;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      let divCode = getUserDetails().DIV;
      const allSheets = wb.SheetNames.filter(
        (name) =>
          name !== "Instructions" &&
          name !== "Revision" &&
          name !== "Warehouses" &&
          name !== "SEL_DATA" &&
          name !== "Masters"
      );
      //Added buSheet names including NOTES
      let buSheets = allSheets.filter(
        (name) =>
          name === divCode ||
          name === "Unconfigured Items" ||
          name === "NOTES" ||
          divCode === null
      );
      buSheets = buSheets.filter((name) => !name.includes("-URD"));
      buSheets = buSheets.filter((name) => !name.includes("CATEGORY_SHORT_NAME"));
      // If DIV code is MHM, need to read RA sheet too
      if (divCode === "MHM") {
        buSheets.push("RA");
      }

      this.setState({
        excelUploadTotalBUs: buSheets.length,
        excelUploadProcessedBUs: 0,
        excelUploadSkippedBUs: 0,
      });

      let excelUploadPendingData = Object.assign(
        [],
        this.state.excelUploadPendingData
      );
      // If "NOTES" tab is placed before corresponding BU then it should show pop-up to place "NOTES" tab in the end
      if (
        buSheets.findIndex((item) => item === "NOTES") !==
        buSheets.length - 1
      ) {
        alert(
          "Notes sheet placed at the wrong position in excel-sheet! Please keep it at the end."
        );
        return;
      }
      buSheets.map((buName) => {
        /* Get model-data worksheet */
        const buWsName = wb.SheetNames.find((name) => name === buName);
        const modelSheet = wb.Sheets[buWsName];

        /* Convert array of arrays */
        const modelRows = XLSX.utils.sheet_to_row_object_array(modelSheet, {
          blankrows: false,
        });

        /* Geting Model Data */
        const modelSheetData = {
          data: modelRows,
          cols: make_cols(modelSheet["!ref"]),
        };
        const urdExistingBUs = [
          "Unconfigured Items",
          "RMT",
          "MMI",
          "DMC",
          "RAS",
          "VA",
          "ISV",
        ];

        /* Get urd-data worksheet */
        let urdSheet = "";
        let urdSheetName = "";
        if (urdExistingBUs.includes(buWsName)) {
          if (buWsName === "Unconfigured Items") {
            urdSheet = wb.Sheets[`RMT ${buWsName}-URD`];
            urdSheetName = `RMT ${buWsName}-URD`;
          } else {
            urdSheet = wb.Sheets[`${buWsName}-URD`];
            urdSheetName = `${buWsName}-URD`;
          }
        }

        /* Convert array of arrays */
        let urdSheetData = [];
        if (urdSheet) {
          const urdRows = XLSX.utils.sheet_to_row_object_array(urdSheet, {
            blankrows: false,
          });
          /* Getting URD Data */
          urdSheetData = { data: urdRows, cols: make_cols(urdSheet["!ref"]) };
        }

        let modelData = [];
        let urdData = [];
        //Reading the notes data from first row itself
        try {
          modelData = convertKeysToUpperCase(
            modelSheetData.data.slice(buName === "NOTES" ? 4 : 5)
          );
          modelData = modelData.filter((row) => Boolean(row.LINE_NUMBER));
        } catch {}

        try {
          urdData = convertKeysToUpperCase(urdSheetData.data.slice(5));
        } catch {}

        //If buName is "NOTES", sync divCode with "Notes" and push notes data in "parm" for that respective BU.
        let parm = { buName, modelData, urdData, urdSheetName };
        if(divCode === null && buName === "NOTES" && modelData.length > 0){
          divCode = modelData[0].BASE_MODEL;
        }
        if (buName === "NOTES") {
          const divCodeIndex = excelUploadPendingData.findIndex(
            (item) => (item.buName === divCode)
          );
          excelUploadPendingData[divCode === null ? 1 : divCodeIndex]["noteModelData"] = modelData;

          // modelData=modelData.map((item)=>{
          //   let index=0;
          //   const dataFiltered= noteData.modelData.filter((noteDataItem)=>noteDataItem.LINE_NUMBER===item.LINE_NUMBER);
          //   urdData= noteData.urdData
          //   dataFiltered.map((itc) => {
          //     item[`MODEL_STRING_${index+1}`] = itc.MODEL_STRING_2 || "";
          //     item[`MODEL_STRING_${index+2}`] = itc.MODEL_STRING_3 || "";
          //     item[`ITEM_QUANTITY_${index+1}`]=itc.ITEM_QUANTITY;
          //     item[`WAREHOUSE_${index+1}`]=itc.WAREHOUSE;
          //     ++index;
          //   });

          //   return item;
          // })
        } else {
          excelUploadPendingData.push(parm);
        }        
      });

      // Update pending BU data and start processing with first BU
      this.setState(
        {
          excelUploadPendingData,
        },
        () => {
          // Starts processing first BU
          this.processExcelUploadPendingBUs();
        }
      );
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  // ===== Function called to execute the excel sheet BU wise and pass noteData as an array argument=====
  processBuWiseExcelData = (
    buName,
    modelData,
    urdData,
    urdSheetName,
    noteData = []
  ) => {
    const { importExcelData, emailData } = this.props;
    // add noteData as a parameter in payload data
    const payload = preparePayloadForExcelUpload(
      buName,
      modelData,
      urdData,
      urdSheetName,
      noteData
    );
    if (payload) {
      this.setState(
        {
          excelUploadProcessedBUs: this.state.excelUploadProcessedBUs + 1,
        },
        () => {
          importExcelData({ ...payload, emailData });
        }
      );
      //If noteData exists, increment excelUploadSkippedBUs counter by 1
      if (noteData.length) {
        this.setState(
          {
            excelUploadSkippedBUs: this.state.excelUploadSkippedBUs + 1,
          },
          () => {}
        );
      }
    } else {
      // Since no payload, skipping the BU

      this.setState(
        {
          excelUploadSkippedBUs: this.state.excelUploadSkippedBUs + 1,
        },
        () => {
          const { excelUploadTotalBUs, excelUploadSkippedBUs } = this.state;
          if (excelUploadTotalBUs === excelUploadSkippedBUs) {
            toastr.error(
              "Nothing to import. Please provide data in Excel.",
              "Error"
            );
            this.toggleShowImportDialog();
            this.setState({ disableExcelButtons: false });
          } else {
            // Checking for BU completion
            const hasCompleted = this.validateExcelUploadCompletion();

            if (!hasCompleted) {
              const { excelUploadingBU } = this.state;
              // Continue by passing current BU name
              this.processExcelUploadPendingBUs(excelUploadingBU);
            }
          }
        }
      );
    }
  };

  processExcelUploadPendingBUs = (excelUploadingBU) => {
    let excelUploadPendingData = Object.assign(
      [],
      this.state.excelUploadPendingData
    );

    // Removing the current BU, if exists
    excelUploadPendingData = excelUploadPendingData.filter(
      (buSet) => buSet.buName !== excelUploadingBU || !excelUploadingBU
    );

    // Process the next BU, if exists
    if (excelUploadPendingData.length > 0) {
      this.setState({ excelUploadPendingData }, () => {
        //Add noteModelData in excelUploadPendingData
        const { buName, modelData, urdData, urdSheetName, noteModelData } =
          excelUploadPendingData[0];
        this.setState(
          {
            excelUploadingBU: buName,
          },
          () => {
            //Add noteModelData in processBuWiseExcelData or return empty array
            this.processBuWiseExcelData(
              buName,
              modelData,
              urdData,
              urdSheetName,
              noteModelData || []
            );
          }
        );
      });
    }
  };

  // ====== Function to validate the excel upload is completed or not ======
  validateExcelUploadCompletion = () => {
    const {
      excelUploadTotalBUs,
      excelUploadProcessedBUs,
      excelUploadSkippedBUs,
    } = this.state;
    let hasCompleted = false;
    if (
      excelUploadTotalBUs ===
      excelUploadProcessedBUs + excelUploadSkippedBUs
    ) {
      this.setState(
        {
          excelUploadPendingData: [],
          excelUploadingBU: "",
        },
        () => {
          this.toggleShowImportDialog();
          this.setState({ disableExcelButtons: false });
          toastr.success("Excel Data Imported Successfully", "Success");
        }
      );
      hasCompleted = true;
    }
    return hasCompleted;
  };

  toggleShowImportDialog = () => {
    this.setState({
      showImportDialog: !this.state.showImportDialog,
    });
  };

  // ===== Function to generate new rwo on load =====
  generateFormWithBaseRow = () => {
    const initialRow = Object.assign({}, this.props.data.initialRow);
    let rows = [initialRow];
    this.props.updateRow({ rows, selectedBaseModelName: "" });
  };

  // ===== Onchange event triggered =====
  onRowFormChange = (event, id) => {
    const name = event.target.name;
    const value = event.target.value;
    this.updateRowsContent(id, name, value);
    this.validateInputs(this.props.data.rows);
    this.setState({
      currentId: id,
    });
  };

  // ===== Function to update the row contents =====
  updateRowsContent = (id, name, value) => {
    let rows = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.props.data.rows))
    );
    if (name === "quantity" || name === "warehouse") {
      rows.map((row) => {
        row[name] = value;
        return row;
      });
    } else {
      rows.map((row) => {
        if (row.id === id) {
          row[name] = value;
        }
        return row;
      });
    }
    if (name === "modelString") {
      this.setState({ disableBaseModel: false });
    }
    this.props.updateRow({ rows });
    if (name === "quantity") {
      this.props.OnQuantityChange({ value: value === "" ? "1" : value, id });
    }
  };

  // ===== Function called on add new row =====
  onRowItemAdd = () => {
    const baseRow = Object.assign({}, this.props.data.rows[0]);
    const initialRow = Object.assign({}, this.props.data.initialRow);
    //commented and added  to generate sequence by number by sudhakar RT_002477 RT_002336 (+)
    //let id = uuid.v4();
    let id = this.props.data.rows.length;
    //(-)
    let item = {
      ...initialRow,
      id,
      quantity: baseRow.quantity,
      warehouse: baseRow.warehouse,
    };
    this.props.addRow([...this.props.data.rows, item]);
  };

  // ===== Function called when a rwo is deleted  =====
  onRowItemRemove = (id) => {
    const { initialRow } = this.props.data;
    let selectedBaseModelName = this.props.data.selectedBaseModelName;
    let rows = Object.assign([], this.props.data.rows);
    if (
      selectedBaseModelName === "" ||
      (selectedBaseModelName !== "" && rows.length > 2)
    ) {
      rows = rows.filter((row) => row.id !== id);
    } else {
      this.props.clearBaseModels("ALL");
      rows = [initialRow];
      selectedBaseModelName = "";
    }
    this.props.deleteRow({
      rows,
      selectedBaseModelName,
    });

    let updateModelString = rows
      .map((i, index) => {
        let modelString = i.modelString.toUpperCase();
        return modelString;
      })
      .filter((x) => x !== "");
    this.getModelNames(updateModelString, id, rows);
  };

  // ===== Function called when the model string is selected =====
  onModelStringSelect = (e, id) => {
    let name = "modelString";
    let value = e.target.textContent;
    this.updateRowsContent(id, name, value); // update the store with model string is selected
    this.setState({
      disableBaseModel: false,
    });

    let quantity = this.props.data.rows.filter((row) => row.id === id)[0]
      .quantity;
    if (!quantity) {
      name = "quantity";
      value = 1;
      this.updateRowsContent(id, name, value); // update the store with model string is selected
    }
  };

  togglePasteData = (e) => {
    this.setState({ openPasteOptions: !this.state.openPasteOptions });
  };

  // ===== Function called when the data is pasted in pasteList =====

  savePasteModelData = (payload) => {
    const { importPasteList, emailData } = this.props;
    if (payload) {
      importPasteList({ ...payload, emailData });
    }
  };

  // ===== Function called when save is clicked =====
  saveModelData = async (e) => {
    const {
      GetInsert,
      divCode,
      sequenceNo,
      data,
      emailData,
      updateRequiredMultiLevelModel,
    } = this.props;
    const { rows } = this.props.data;
    let divcode = this.props.divCode;
    const { hasValid } = this.state;
    let parsedData = await this.parseModelDataForSave(rows);

    this.setState({
      hasSubmitted: true,
    });
    if (hasValid) {
      let lineNumber = `${parseInt(sequenceNo)}`;
      const parsedUrdData = rows.map((x) => {
        let urd = { ...InputPayload }; // Static payload for insert URD
        urd["HOST_HEADER_ID"] = getURLDetails().I_HEADER_ID;
        urd["SEQUENCE_NUMBER"] = getUserDetails().SEQUENCE_NUMBER;
        urd["LINE_NUMBER"] = lineNumber;
        urd["APPLICATION_CODE"] = getURLDetails().I_APP_CODE;
        urd["BASE_MODEL"] = x.modelName.toUpperCase();
        urd["QUANTITY_REF"] = x.quantity;
        if (divcode !== "RMT" && divcode !== "RNI") {
          urd =
            x.urdData[0] &&
            Object.entries(x.urdData[0]).reduce((o, [key, value]) => {
              o[key] = value;
              return o;
            }, urd);
        }
        return urd;
      });

      // RMT URD Payload
      const RMTparsedUrdData = rows
        .map((row, index) => {
          return row.urdData.map((x, i) => {
            let RMTurd = { ...RMTInputPayload }; // Static payload for insert RMT URD
            RMTurd["HOST_HEADER_ID"] = getURLDetails().I_HEADER_ID;
            RMTurd["SEQUENCE_NUMBER"] = getUserDetails().SEQUENCE_NUMBER;
            RMTurd["LINE_NUMBER"] = lineNumber;
            RMTurd["APPLICATION_CODE"] = getURLDetails().I_APP_CODE;
            RMTurd["BASE_MODEL"] = row.modelName.toUpperCase();
            RMTurd["UNIT_NUMBER"] = i + 1;
            RMTurd["SUBMODEL_NUMBER"] =
              data.selectedBaseModelName !== ""
                ? row.id !== 0
                  ? index
                  : null
                : index + 1;
            if (divCode === "RMT" || divCode === "RNI") {
              RMTurd = Object.entries(x).reduce((o, [key, value]) => {
                if (o[key] !== undefined) o[key] = value;
                return o;
              }, RMTurd);
            }
            return RMTurd;
          });
        })
        .flat();

      const parsedHoldData = this.props.data.ApplyHoldRows.map((x) => {
        let holdDetails = {}; // payload for hold details insert
        holdDetails["HOLD_NAME"] = x.holdName.split("|")[0];
        holdDetails["HOLD_ID"] = x.holdId;
        holdDetails["HOLD_DATE"] =
          x.holdUntillDate !== ""
            ? moment(x.holdUntillDate, "DD-MMM-YYYY").format("YYYY-MM-DD")
            : "";
        holdDetails["HOLD_COMMENT"] = x.holdComments;
        return holdDetails;
      });
      const parsedLineDetailsData = this.props.data.LineNotesRows.map((x) => {
        let lineDetails = {};
        // payload for line notes details for UI screen
        lineDetails["NOTES_SEQ"] = lineNumber + "." + x.lineNoSeq; //Reading the lineNumber and notesSequence in combined format
        lineDetails["CATEGORY_NAME"] = x.lineCategory.split("|")[0];
        lineDetails["CATEGORY_DESC"] = x.lineNotesDescription;
        lineDetails["TITLE"] = x.lineNotesTitle;
        lineDetails["DATA_TYPE"] = x.lineNotesDataType;
        lineDetails["COMMENTS"] = x.lineNotesComments;
        return lineDetails;
      });

      let modelData = Object.assign({}, templateModelDataPayload);
      parsedData.map((part) => {
        Object.keys(part).map((key) => {
          modelData[key] = part[key];
          return key;
        });
        return part;
      });

      await GetInsert({
        // insert data api call
        modelData: [modelData],
        urdData: parsedUrdData,
        RMTurdData: RMTparsedUrdData,
        lineNumber,
        flat: rows[0].modelString === rows[0].modelName,
        holdData: parsedHoldData,
        lineDetailsData: parsedLineDetailsData,
        emailData,
      });
    } else {
      console.error("hasValid", hasValid);
    }
  };

  processInlineValidation = () => {
    const { GetInlineValidation, data, divCode, sequenceNo } = this.props;
    const { rows } = data;
    let parsedDataForInline = parseModelDataForInlineValidation({
      rows,
      divcode: divCode,
    });
    let lineNumber = `${parseInt(sequenceNo)}`;

    GetInlineValidation({
      // inline validation call
      modelData: parsedDataForInline,
      divCode: divCode,
      lineNumber,
      validate: rows[0].modelString !== rows[0].modelName,
      RowId: rows.id,
    });
  };

  // ===== Function to clear the data =====
  clearData = (e) => {
    this.props.clearBaseModels("ALL");
    this.props.clearSaveErrors();
    this.props.clearURDData();
    this.generateFormWithBaseRow();
  };

  // ====== Function to validate the inputs =======
  validateInputs = (data) => {
    data.map((row) => {
      const id = row.id;
      let errors = Object.assign(
        {},
        JSON.parse(JSON.stringify(this.state.errors))
      );
      let exsitingError = errors[id] || {};
      const { error, hasError } = this.prepareErrorObject(exsitingError, row);
      this.setState({
        hasValid: !hasError,
        errors: {
          ...errors,
          [id]: { ...error },
        },
      });
    });
  };

  // ===== validation when model name  and model string is empty =====
  prepareErrorObject = (error, row) => {
    const { selectedBaseModelName } = this.props.data;
    const { locationStatus, basemodeldata } = this.props;
    const { hasSubmitted } = this.state;
    if (row.modelString === "") {
      error["modelString"] = "Enter the Model String";
    } else {
      delete error["modelString"];
    }

    if (!row.modelStringEnable) {
      if (!basemodeldata.find((x) => x.value === selectedBaseModelName)) {
        error["modelName"] = "Select the valid multi-level model";
      } else {
        delete error["modelName"];
      }
    } else {
      if (row.modelName === "") {
        error["modelName"] = "Enter the Model Name";
      } else {
        delete error["modelName"];
      }
    }

    if (row.quantity <= 0) {
      error["quantity"] = "Enter Valid Quantity";
    } else {
      delete error["quantity"];
    }

    if (row.userlocation === "") {
      if (locationStatus === "1") {
        error["userlocation"] = "Select the Location";
      }
    } else {
      delete error["userlocation"];
    }

    return {
      error: hasSubmitted ? error : {},
      hasError: Object.keys(error).length > 0,
    };
  };

  // ===== Prepare model data object on save =====
  parseModelDataForSave = (rows) => {
    const { divCode } = this.props;
    let newRows1 = rows.map((item, i) => {
      let newItem = {};
      if (i === 0) {
        if (divCode === "RAS") {
          newItem[`MODEL_NAME_${i + 1}`] = item.modelName;
          newItem[`MODEL_STRING_${i + 1}`] = item.modelString;
          //commented and added by sudhakar RT_002477 RT_002336 (+)
          newItem["CUST_PO_LINE"] = item.additionalData.customerpolineNum;
          newItem["CUSTOMER_REF"] = item.additionalData.customerRef;
          //comment ended by sudhakar RT_002477 RT_002336 (-)
          newItem["USER_ITEM_DESCRIPTION"] =
            item.additionalData.userItemDescription; //added new field for RT_002629
        } else {
          newItem[`MODEL_NAME_${i + 1}`] = item.modelName.toUpperCase();
          newItem[`MODEL_STRING_${i + 1}`] = item.modelString.toUpperCase();
          //commented and added by sudhakar RT_002477 RT_002336 (+)
          newItem["CUST_PO_LINE"] = item.additionalData.customerpolineNum;
          newItem["CUSTOMER_REF"] = item.additionalData.customerRef;
          //comment ended by sudhakar RT_002477 RT_002336 (-)
          newItem["USER_ITEM_DESCRIPTION"] =
            item.additionalData.userItemDescription; //added new field for RT_002629
        }
      } else {
        if (divCode === "RAS") {
          newItem[`MODEL_NAME_${i + 1}`] = item.modelName;
          newItem[`MODEL_STRING_${i + 1}`] = item.modelString;
          //commented and added by sudhakar RT_002477 RT_002336 (+)
          newItem[`CUST_PO_LINE_${i + 1}`] =
            item.additionalData.customerpolineNum;
          newItem[`CUSTOMER_REF_${i + 1}`] = item.additionalData.customerRef;
          //comment ended by sudhakar RT_002477 RT_002336 (-)
          newItem[`USER_ITEM_DESCRIPTION_${i + 1}`] =
            item.additionalData.userItemDescription; //added new field for RT_002629
        } else {
          newItem[`MODEL_NAME_${i + 1}`] = item.modelName.toUpperCase();
          newItem[`MODEL_STRING_${i + 1}`] = item.modelString.toUpperCase();
          //commented and added by sudhakar RT_002477 RT_002336 (+)
          newItem[`CUST_PO_LINE_${i + 1}`] =
            item.additionalData.customerpolineNum;
          newItem[`CUSTOMER_REF_${i + 1}`] = item.additionalData.customerRef;
          //comment ended by sudhakar RT_002477 RT_002336 (-)
          newItem[`USER_ITEM_DESCRIPTION_${i + 1}`] =
            item.additionalData.userItemDescription; //added new field for RT_002629
        }
      }
      return newItem;
    });

    let newRows2 = [];
    if (rows.length > 0) {
      newRows1.push({
        ITEM_QUANTITY: rows[0].quantity,
        WAREHOUSE: rows[0].warehouse,
      });

      newRows2 = rows.map((item, i) => {
        let newItem = {};
        if (i === 0) {
          newItem["MANUAL_MODIFIER_BASE_MODEL"] = item.modifier;
          newItem["PRICE_ADJUSTMENT"] = item.priceValue;
          newItem["ADJUSTMENT_METHOD"] = item.operator;
        } else {
          newItem[`MANUAL_MODIFIER_SUB_MODEL_${i}`] = item.modifier;
          newItem[`PRICE_ADJUSTMENT_${i}`] = item.priceValue;
          newItem[`ADJUSTMENT_METHOD_${i}`] = item.operator;
        }
        return newItem;
      });

      if (this.props.divCode === "MHM") {
        newRows2.push({
          END_USER_LOCATION: rows[0].userlocation,
        });
      }
      let getLineId = {
        lineType: rows[0].additionalData
          ? rows[0].additionalData.lineType.split("|")[0]
          : "",
        lineId: rows[0].additionalData
          ? rows[0].additionalData.lineType.split("|")[1]
          : "",
      };

      let locationId = {
        consoleLocationId: rows[0].additionalData
          ? rows[0].additionalData.consoleAddress !== ""
            ? rows[0].additionalData.consoleAddress.split("|")[1]
            : ""
          : "",
        consoleCustomId: rows[0].additionalData
          ? rows[0].additionalData.consoleAddress !== ""
            ? rows[0].additionalData.consoleAddress.split("|")[2]
            : ""
          : "",
      };

      newRows2.push({
        KOB3: rows[0].additionalData ? rows[0].additionalData.kobDetails : "",
        PICK_PACK_LEAD_TIME: rows[0].additionalData
          ? rows[0].additionalData.pickPackTime
          : "",
        CUST_PO_LINE: rows[0].additionalData
          ? rows[0].additionalData.customerpolineNum
          : "",
        QS_CAUSE_CODE: rows[0].additionalData
          ? rows[0].additionalData.qscausecode
          : "",
        SOURCE_TYPE: rows[0].additionalData
          ? rows[0].additionalData.sourceType
          : "",
        CUSTOMER_REF: rows[0].additionalData
          ? rows[0].additionalData.customerRef
          : "",
        PROJECT_NUMBER: rows[0].additionalData
          ? rows[0].additionalData.projectNum.split("|")[0]
          : "",
        TASK_NUMBER: rows[0].additionalData
          ? rows[0].additionalData.taskNum
          : "",
        ASSEMBLE_TO: rows[0].additionalData
          ? rows[0].additionalData.assembleTo
          : "",
        CUSTOMSVALUE: rows[0].additionalData
          ? rows[0].additionalData.customsvalue
          : "",
        INV_ITEM_DFF: rows[0].additionalData
          ? rows[0].additionalData.itemType
          : "",
        SHIP_INSTRUCTIONS: rows[0].additionalData
          ? rows[0].additionalData.shippingInstructions
          : "",
        PACK_INSTRUCTIONS: rows[0].additionalData
          ? rows[0].additionalData.packingInstructions
          : "",
        LINE_TYPE: getLineId.lineType || "",
        LINE_TYPE_ID: getLineId.lineId || "",
        CONSOL_LOCATION_ID: locationId.consoleLocationId,
        CONSOL_CUST_ID: locationId.consoleCustomId,
        SHIP_SET: rows[0].additionalData ? rows[0].additionalData.shipSet : "",
        FULFILLMENT_SET_NAME: rows[0].additionalData
          ? rows[0].additionalData.fullfillmentSet
          : "",
        PRICE_ROLL_UP: rows[0].additionalData
          ? rows[0].additionalData.priceRollUp
          : "",
        SIZING_REFERENCE_ID: rows[0].sizingId,
        //added new field for RT_002629
        USER_ITEM_DESCRIPTION: rows[0].additionalData
          ? rows[0].additionalData.userItemDescription
          : "",
      });
    }
    let newRows = [...newRows1, ...newRows2];
    return newRows;
  };

  // ===== Prepare urd object for save =====
  prepareUrdDataForSave = (urdInsertData) => {
    let urdRows = Object.keys(urdInsertData).map((id) => {
      return urdInsertData[id];
    });
    return urdRows;
  };

  // ===== Function trigred when the base model is selected =====
  onBaseModelSelect = (e) => {
    const { selectedBaseModelName } = this.props.data;
    const { basemodeldata, divCode } = this.props;
    const value = e.target.value;
    const baseModelNew = basemodeldata.find((i) => i.value === value);
    let existingRows = Object.assign([], this.props.data.rows);

    if (value === "") {
      let updatedRows = existingRows.slice(1);
      updatedRows.map((r, i) => {
        if (i === 0) {
          r.id = 0;
        }
        return;
      });

      const rows = Object.assign([], updatedRows);
      this.props.updateRow({
        rows,
        selectedBaseModelName: value,
      });
      return;
    }

    if (selectedBaseModelName !== "") {
      existingRows = existingRows.filter((r, i) => i !== 0);
    }
    const existingBaseRow = Object.assign({}, existingRows[0]);
    const existingSubRows = existingRows;
    let hasSubRow = existingSubRows && existingSubRows.length > 0;
    let subRows = existingSubRows.map((r) => {
      //commented and added by sudhakar RT_002477 RT_002336 (+)
      //r.id = uuid.v4();
      r.id = existingSubRows.length;
      //(-)
      r.modelString = hasSubRow ? r.modelString : existingBaseRow.modelString;
      r.modelName = hasSubRow ? r.modelName : existingBaseRow.modelName;
      r.quantity = hasSubRow ? r.quantity : existingBaseRow.quantity;
      r.warehouse = hasSubRow ? r.warehouse : existingBaseRow.warehouse;
      r.modifier = hasSubRow ? r.modifier : existingBaseRow.modifier;
      r.operator = hasSubRow ? r.operator : existingBaseRow.operator;
      r.modelStringEnable = true;
      return r;
    });

    let baseRow = Object.assign({}, this.props.data.initialRow);
    baseRow.modelName = value;
    baseRow.modelStringEnable = false;
    baseRow.quantity = existingBaseRow.quantity || 1;
    baseRow.warehouse = existingBaseRow.warehouse;
    baseRow.modelNames = basemodeldata.map((x) => ({
      NAME: x.label,
      MODEL_STRING: "",
      DIVISION_ID: divCode,
    }));
    let rows = [baseRow];
    rows = [...rows, ...subRows];
    this.props.updateRow({
      rows,
      selectedBaseModelName: value,
      baseModelNew,
    });
  };

  onDynamicBaseModelSelect = (value) => {
    const { selectedBaseModelName } = this.props.data;
    const { basemodeldata, divCode } = this.props;
    //const value = e.target.textContent;
    const baseModelNew = basemodeldata.find((i) => i.value === value);
    let existingRows = Object.assign([], this.props.data.rows);

    this.setState({ showMultiLevelModelList: false });

    if (existingRows.length === 1 && value === "") {
      return;
    }

    if (value === "" && existingRows.length > 1) {
      let updatedRows = existingRows.slice(1);
      updatedRows.map((r, i) => {
        if (i === 0) {
          r.id = 0;
        }
        return;
      });

      const rows = Object.assign([], updatedRows);
      this.props.updateRow({
        rows,
        selectedBaseModelName: value,
      });
      return;
    }

    if (selectedBaseModelName !== "") {
      existingRows = existingRows.filter((r, i) => i !== 0);
    }
    const existingBaseRow = Object.assign({}, existingRows[0]);
    const existingSubRows = existingRows;
    let hasSubRow = existingSubRows && existingSubRows.length > 0;
    let subRows = existingSubRows.map((r) => {
      //commented and added by sudhakar RT_002477 RT_002336(+)
      //r.id = uuid.v4();
      r.id = existingSubRows.length;
      //(-)
      r.modelString = hasSubRow ? r.modelString : existingBaseRow.modelString;
      r.modelName = hasSubRow ? r.modelName : existingBaseRow.modelName;
      r.quantity = hasSubRow ? r.quantity : existingBaseRow.quantity;
      r.warehouse = hasSubRow ? r.warehouse : existingBaseRow.warehouse;
      r.modifier = hasSubRow ? r.modifier : existingBaseRow.modifier;
      r.operator = hasSubRow ? r.operator : existingBaseRow.operator;
      r.modelStringEnable = true;
      return r;
    });

    let baseRow = Object.assign({}, this.props.data.initialRow);
    baseRow.modelName = value;
    baseRow.modelStringEnable = false;
    baseRow.quantity = existingBaseRow.quantity || 1;
    baseRow.warehouse = existingBaseRow.warehouse;
    baseRow.modelNames = basemodeldata.map((x) => ({
      NAME: x.label,
      MODEL_STRING: "",
      DIVISION_ID: divCode,
    }));
    let rows = [baseRow];
    rows = [...rows, ...subRows];
    this.props.updateRow({
      rows,
      selectedBaseModelName: value,
      baseModelNew,
    });
  };

  toggleStatusPopup = (value) => {
    this.setState({ showSaveError: value });
  };

  // ===== function called to find model name and warehouse =====
  getModelNames = (modelString, id, modelName, rowdata) => {
    const {
      findModelNames,
      MyGetModifierData,
      data,
      updateRequiredMultiLevelModel,
    } = this.props;
    let requiredMultiLevelModel = data.edit
      ? Boolean(data.selectedBaseModelName)
      : this.props.requiredMultiLevelModel;
    updateRequiredMultiLevelModel({ requiredMultiLevelModel });
    let rows = Object.assign([], rowdata ? rowdata : this.props.data.rows);
    let finalModelString = Array.isArray(modelString)
      ? modelString.map((str) => str.toUpperCase())
      : modelString.toUpperCase();
    let I_MODEL_DETAILS = prepareModalNameParams(
      finalModelString,
      id,
      modelName,
      rows
    );
    const I_PARENT_FLAG = requiredMultiLevelModel ? "Y" : "N";
    findModelNames({ I_MODEL_DETAILS, id, I_PARENT_FLAG });
    MyGetModifierData();
  };

  findWarehouses = (modelNameStr, id) => {
    let modelNameString = modelNameStr.toUpperCase();
    let sessionFlagData = getUserDetails().SESSION_FLAG;
    // if (sessionFlagData === 'Y') {
    this.props.MyWareHouseData(modelNameString);
    this.props.URDData({ modelNameString, id });
    if (this.props.location) {
      this.props.UserLocationData(modelNameString);
    }
    // }
  };

  processPendingUrdFetch = () => {
    const urdFetchData = [...this.state.urdFetchData];
    const pendingUrdFetch = urdFetchData.filter((i) => i.fetch === false);
    if (pendingUrdFetch.length > 0) {
      let rowItem = pendingUrdFetch[0];
      this.props.URDData({
        modelNameString: rowItem.modelName,
        id: rowItem.id,
      });
    }
  };

  toggleMultiLevelModel = () => {
    let requiredMultiLevelModel = !this.props.requiredMultiLevelModel;
    this.setState(
      {
        disableBaseModel: this.props.requiredMultiLevelModel,
      },
      () => {
        const { findModelNames, data, updateRequiredMultiLevelModel } =
          this.props;
        updateRequiredMultiLevelModel({ requiredMultiLevelModel });
        let rows = Object.assign([], data.rows);
        if (requiredMultiLevelModel) {
          let I_PARENT_FLAG = "Y";
          let modelStrings = data.rows
            .map((i) => {
              let modelString = i.modelString.toUpperCase();
              return modelString;
            })
            .filter((x) => x !== "");
          let id = 0;
          let modelName = data.rows
            .map((i) => {
              return i.modelName.toUpperCase();
            })
            .filter((x) => x !== "");
          if (modelStrings.length > 0) {
            let I_MODEL_DETAILS = prepareModalNameParams(
              modelStrings,
              id,
              modelName,
              rows
            );
            findModelNames({ I_MODEL_DETAILS, id, I_PARENT_FLAG });
          }
        }
      }
    );
  };

  loadMultiLevelModels = () => {
    const { requiredMultiLevelModel, updateRequiredMultiLevelModel } =
      this.props;
    if (!requiredMultiLevelModel) {
      updateRequiredMultiLevelModel({ requiredMultiLevelModel: true });
      const { findModelNames, data } = this.props;
      let rows = Object.assign([], data.rows);
      let I_PARENT_FLAG = "Y";
      let modelStrings = data.rows
        .map((i) => {
          let modelString = i.modelString.toUpperCase();
          return modelString;
        })
        .filter((x) => x !== "");
      let id = 0;
      let modelName = "";
      if (modelStrings.length > 0) {
        this.setState(
          {
            isFetchingMultiLevelModel: true,
            showMultiLevelModelList: true,
          },
          () => {
            let I_MODEL_DETAILS = prepareModalNameParams(
              modelStrings,
              id,
              modelName,
              rows
            );
            findModelNames({ I_MODEL_DETAILS, id, I_PARENT_FLAG });
          }
        );
      }
    }
    this.setState({
      showMultiLevelModelList: !this.state.showMultiLevelModelList,
    });
  };

  // ===== To declare that a prop is a specific JS primitive. =====
  static propTypes = {
    GetInsert: PropTypes.func.isRequired,
    GetInlineValidation: PropTypes.func.isRequired,
    URDData: PropTypes.func.isRequired,
    OnQuantityChange: PropTypes.func.isRequired,
    locationStatus: PropTypes.string.isRequired,
    updateRequiredMultiLevelModel: PropTypes.func.isRequired,
  };
}

export default ModelManagement;
