import { connect } from 'react-redux';
import AdditionalInfo from './AdditionalInfo';
import { updateInfoRow, getTaskNumber, handlepickchange } from "../../../store/model-data";
import { getDivisionCode, prepareTaskNumber, } from '../../../utils/helper';

// ===== Map state received from reducer to props  =====
const mapStateToProps = (state, ownProps) => {
    return {
        data: state.modalDataStore.modalData.rows[ownProps.row.id].additionalData, // additional data
        seedData: state.modalDataStore.modalData.rows[ownProps.row.id].additionalDataDetails, //master data for additional data
        modelName: ownProps.row.modelName || "", // get modelname
        divCode: getDivisionCode(state.modalDataStore) || "", // divcode
        taskNumberData: prepareTaskNumber(state.modalDataStore), // prepare task number 
    }
};

// ===== Mapping dispatch functions to the props  =====
const mapDispatchToProps = {
    updateRow: payload => updateInfoRow(payload), // dipatching Update info
    getTaskNumberData: paylod => getTaskNumber(paylod), // dipatching task number
    onHandlepickChange: payload => handlepickchange(payload) // dipatching pick pack change
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInfo); // The connect() function connects a React component AdditionalInfo to a Redux store(modalDataStore).

