import { getAdditionalTagNos } from "./helper";

export const displayData = {
    // CMF100M MODEL NAME
    "SENSOR URD": [
        {
            "parent": "",
            "group-label": "Sensor Calibration",
            "group-type": "frame",
            "display-features": {
                "VERTYP": 1,
            },
            "required-features": [
                "VERTYP"
            ]
        },
        {
            "parent": "VERTYP",
            "group-label": "",
            "group-type": "",
            "value": "Standard",
            "display-features": {
                "VERUNTS": 1
            },
            "required-features": []
        },
        {
            "parent": "VERTYP",
            "group-label": "",
            "group-type": "",
            "value": "Custom",
            "display-features": {
                "VERRT": 1,
                "VERUNTS": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": " ",
            "group-type": "topLine",
            "value": "Barrels(Oil)/sec",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "Barrels(Oil)/min",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "Barrels(Oil)/hr",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "Barrels(Oil)/day",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "Beerbarrels/sec",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "Beerbarrels/min",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "Beerbarrels/hr",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "Beerbarrels/day",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "ft3/sec",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "ft3/min",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "ft3/hr",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "ft3/day",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "ft3/day",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "Gallons(US)/sec",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "Gallons(US)/min",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "Gallons(US)/hr",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "Gallons(US)/day",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "IMPGAL/day",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "IMPGAL/hr",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "IMPGAL/min",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "IMPGAL/sec",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "litres/hr",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "litres/min",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "litres/sec",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "m3/day",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "m3/hr",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "m3/sec",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "Million Gal(US)/day",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "group-label": "",
            "group-type": "topLine",
            "value": "Million liters/day",
            "display-features": {
                "DENS": 1,
                "DENSU": 2
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "value": "Special Mass Flow Units",
            "group-label": "Special Units Definition",
            "group-type": "frame",
            "display-features": {
                "SPFUT": 1,
                "TOTTXT": 2,
                "SPBU": 3,
                "SPBTU": 4,
                "Conversion": 5,
            },
            "required-features": []
        },
        {
            "parent": "VERUNTS",
            "value": "Special Volume Flow Units",
            "group-label": "Special Units Definition",
            "group-type": "frame",
            "display-features": {
                "SPFUT": 1,
                "TOTTXT": 2,
                "SPBU": 3,
                "SPBTU": 4,
                "Conversion": 5,
                "DENS": 6,
                "DENSU": 7

            },
            "required-features": []
        },
        {
            "parent": "VERTYP",
            "value": "Custom",
            "group-label": "Custom Calibration",
            "group-type": "frame",
            "display-features": {
                "Flow Point or Percentage": 1,
            },
            "required-features": []
        },
        {
            "parent": "Flow Point or Percentage",
            "value": "Input Points",
            "group-label": "Custom Calibration",
            "group-type": "frame",
            "display-features": {
                "FP1": 1,
                "FP2": 2,
                "FP3": 3,
                "FP4": 4,
            },
            "required-features": []
        },
        {
            "parent": "Flow Point or Percentage",
            "value": "Input Percentage",
            "group-label": "Custom Calibration",
            "group-type": "frame",
            "display-features": {
                "PNTS01UI": 1,
                "PNTS02UI": 2,
                "PNTS03UI": 3,
                "PNTS04UI": 4,
            },
            "required-features": []
        }
    ],

    "TIIS Temperature Sensors": [
        {
            "parent": "",
            "group-label": "Ambient Temperature",
            "group-type": "frame",
            "display-features": {
                "MINIMUM_AMBIENT_TEMPERATURE": 1,
                "MAXIMUM_AMBIENT_TEMPERATURE": 2,
                "AMBIENT_TEMPERATURE_UNITS": 3
            },
            "required-features": [
                "MINIMUM_AMBIENT_TEMPERATURE", "MAXIMUM_AMBIENT_TEMPERATURE", "AMBIENT_TEMPERATURE_UNITS"
            ]
        },
        {
            "parent": "",
            "group-label": "Process Fluid Temperature",
            "group-type": "frame",
            "display-features": {
                "MINIMUM_PROCESS_FLUID_TEMPERATURE": 1,
                "MAXIMUM_PROCESS_FLUID_TEMPERATURE": 2,
                "PROCESS_FLUID_TEMPERATURE_UNITS": 3
            },
            "required-features": [
                "MINIMUM_PROCESS_FLUID_TEMPERATURE", "MAXIMUM_PROCESS_FLUID_TEMPERATURE", "PROCESS_FLUID_TEMPERATURE_UNITS"
            ]
        }

    ],

    "TIIS Temperature Transmitters": [
        {
            "parent": "",
            "group-label": "Ambient Temperature",
            "group-type": "frame",
            "display-features": {
                "MINIMUM_AMBIENT_TEMPERATURE": 1,
                "MAXIMUM_AMBIENT_TEMPERATURE": 2,
                "AMBIENT_TEMPERATURE_UNITS": 3
            },
            "required-features": [
                "MINIMUM_AMBIENT_TEMPERATURE", "MAXIMUM_AMBIENT_TEMPERATURE", "AMBIENT_TEMPERATURE_UNITS"
            ]
        }
    ],

    // TAG DATA FOR CMF100M SENSOR
    "TAG DATA": [
        {
            "parent": "",
            "group-label": "",
            "group-type": "",
            "display-features": {},
            "required-features": [],
            "dynamic-display": {
                "active": true,
                "prefix": "TAG",
                "count": "quantity"
            }
        }
    ],

    "TAG": [
        {
            "parent": "",
            "group-label": "",
            "group-type": "",
            "display-features": {},
            "required-features": [],
            "dynamic-display": {
                "active": true,
                "prefix": "TAG",
                "count": "quantity"
            }
        }
    ],
    // TRANSMITTER M-8732E
    "RAS TAG DATA": [
        {
            "parent": "",
            "group-label": "",
            "group-type": "",
            "display-features": {},
            "required-features": [],
            "dynamic-display": {
                "active": true,
                "prefix": "TAG",
                "count": "quantity"
            }
        }
    ],

    // Tag data for ISV Model
    "Tag_Model": [
        {
            "parent": "",
            "group-label": "",
            "group-type": "",
            "display-features": {},
            "required-features": [],
            "dynamic-display": {
                "active": true,
                "prefix": "TAG",
                "count": "quantity"
            }
        }
    ],

    "MFG_Model": [
        {
            "parent": "",
            "group-label": "Manufacturing Note",
            "group-type": "Frame",
            "display-features": {
                "MANUFACTURING_NOTE1": 1
            },
            "required-features": [
            ]
        }
    ],

    // TRANSMITTER 5700 and 2400s
    "URD_Model": [
        {
            "parent": "",
            "group-label": "Application",
            "group-type": "frame",
            "display-features": {
                "APPL": 1
            },
            "required-features": [
            ]
        },
        {
            "parent": "",
            "group-label": "Channel A",
            "group-type": "frame",
            "display-features": {
                "Channel_A_LRV": 1,
                "Channel_A_URV": 2
            },
            "required-features": [
            ]
        },
        {
            "parent": "",
            "group-label": "Channel B",
            "group-type": "frame",
            "display-features": {
                "Channel_B_LRV": 1,
                "Channel_B_URV": 2
            },
            "required-features": [
            ]
        },
        {
            "parent": "",
            "group-label": "Channel C",
            "group-type": "frame",
            "display-features": {
                "Channel_C_LRV": 1,
                "Channel_C_URV": 2
            },
            "required-features": [
            ]
        },
        {
            "parent": "",
            "group-label": "Channel D",
            "group-type": "frame",
            "display-features": {
                "Channel_D_LRV": 1,
                "Channel_D_URV": 2
            },
            "required-features": [

            ]
        }
    ],
    // TAGS FOR VERTEX, MAGNETIC, TRANSMITER
    "TAGS": [
        {
            "parent": "",
            "group-label": "Magflow & Vortex Tag Data",
            "group-type": "",
            "display-features": {
            },
            "required-features": [],
        }
    ],

    "MFG_NOTES": [
        {
            "parent": "",
            "group-label": "Manufacturing Note",
            "group-type": "Frame",
            "display-features": {
                "MANUFACTURING_NOTE": 1
            },
            "required-features": [
            ]
        }
    ],

    // TRANSMITTER 1700, 2700
    "URD Model": [
        {
            "parent": "",
            "group-label": "Application",
            "group-type": "",
            "display-features": {
                "APPL": 1
            },
            "required-features": [
            ]
        },
        {
            "parent": "",
            "group-label": "Units",
            "group-type": "",
            "display-features": {
                "DENSU": 1,
                "MSSFU": 2,
                "TEMPU": 3

            },
            "required-features": [
                "DENSU", "MSSFU", "TEMPU"
            ]
        },
        {
            "parent": "",
            "group-label": "Channel A",
            "group-type": "",
            "display-features": {
                "CHATYPE": 1,
                "Channel_A_Variable": 2,
                "CHANNEL_A_SCALING": 3,
                "Channel_A_LRV": 4,
                "Channel_A_URV": 5,
                "Channel_A_AO_Fault_Action": 6
            },
            "required-features": [
                "CHATYPE", "Channel_A_Variable", "CHANNEL_A_SCALING", "Channel_A_AO_Fault_Action"
            ]
        },
        {
            "parent": "",
            "group-label": "Channel D",
            "group-type": "",
            "display-features": {
                "CHDTYPE": 1
            },
            "required-features": [
                "CHDTYPE"
            ]
        },
        {
            "parent": "",
            "group-label": "Other Configuration Parameters",
            "group-type": "",
            "display-features": {
                "FDAMP": 1,
                "FLDIR": 2,
                "SLUGH": 3,
                "SLUGL": 4
            },
            "required-features": [
                "FLDIR"
            ]
        },
        {
            "parent": "APPL",
            "group-label": "Units",
            "group-type": "",
            "value": "Gas",
            "display-features": {
                "GSVU": 1
            },
            "required-features": [
                "GSVU"
            ]
        },
        {
            "parent": "APPL",
            "group-label": "Units",
            "group-type": "frame",
            "value": "Gas",
            "display-features": {
                "DENSU": 1,
                // need to add
                "MSSFU": 2,
                "TEMPU": 3
            },
            "required-features": [
                ""
            ]
        },
        {
            "parent": "APPL",
            "group-label": "Channel C",
            "group-type": "",
            "value": "Gas",
            "display-features": {
                "CHCTYPE": 1,
                "Channel_C_Variable": 2,
                "Channel_C_Scaling": 3,
                "Channel_C_Setting": 4,
                "Channel_C_Rate": 5,
                "Channel_C_Freq_Fault_Action": 6
            },
            "required-features": [
                "CHCTYPE", "Channel_C_Variable", "Channel_C_Scaling", "Channel_C_Freq_Fault_Action"
            ]
        },
        {
            "parent": "APPL",
            "value": "Gas",
            "group-label": "Gas Standard Volume Setup",
            "group-type": "",
            "display-features": {
                "GSVDENS_INPUT": 1,
                "DENSU": 2 //Need to show only values with kg/m3
            },
            "required-features": []
        },
        {
            "parent": "GSVU",
            "value": "Special Corrected Volume Flow Units",
            "group-label": "Gas Standard Volume Units",
            "group-type": "",
            "display-features": {
                "SPGFUT": 1,
                "SPGTT": 2,
                "SPGCF_INPUT": 3,
                "SPGBU": 4,
                "SPGBTU": 5
            },
            "required-features": [
                "SPGBU", "SPGBTU"
            ]
        },
        {
            "parent": "MSSFU",
            "value": "Special Mass Flow Units",
            "group-label": "Special Mass Units",
            "group-type": "",
            "display-features": {
                "SPGFUT": 1,
                "SPGTT": 2,
                "SPGCF_INPUT": 3,
                "SPGBU": 4,
                "SPGBTU": 5
            },
            "required-features": [
                "SPGBU", "SPGBTU"
            ]
        }
    ],
    // PAINT_DMC 
    "PAINT_DMC": [
        {
            "parent": "",
            "group-label": "1st Coat",
            "group-type": "frame",
            "display-features": {
                "1 Coat Paint Type": 1,
                "1_coat_Color": 2,
                "1_coat_Paint Thickness": 3
            },
            "required-features": [
            ]
        },
        {
            "parent": "",
            "group-label": "2nd Coat",
            "group-type": "frame",
            "display-features": {
                "2 Coat Paint Type": 1,
                "2_coat_color": 2,
                "2_coat_Paint_Thickness": 3
            },
            "required-features": [
            ]
        },
        {
            "parent": "",
            "group-label": "3rd Coat",
            "group-type": "frame",
            "display-features": {
                "3_coat_Paint_Type": 1,
                "3_coat_color": 2,
                "3_coat_Paint_Thickness": 3
            },
            "required-features": [
            ]
        }
    ],

    "TAG DETAILS": [
        {
            "parent": "",
            "group-label": "",
            "group-type": "",
            "display-features": {},
            "required-features": [],
            "dynamic-display": {
                "active": true,
                "prefix": "TAG",
                "count": "quantity"
            }
        }
    ],
    // ATC , G MASTER ASLY 
    "G MASTER ASLY": [
        {
            "parent": "",
            "value": "",
            "group-label": "",
            "group-type": "",
            "display-features": {
                "tag_nbr": 1,
                "valve_mounting_kit": 2,
                "va_mounting_actuator_on_valve": 3,
                "control_required": 4
            },
            "required-features": [
                "va_mounting_actuator_on_valve"
            ]
        },
        {
            "parent": "valve_mounting_kit",
            "value": "Yes",
            "group-label": "",
            "group-type": "",
            "display-features": {
                "valve_bore_size": 1,
                "spool_bracket": 2,
                "valve_mounting_material": 3,
            },
            "required-features": [
            ]
        },
        {
            "parent": "va_mounting_actuator_on_valve",
            "value": "Yes",
            "group-label": "",
            "group-type": "",
            "display-features": {
                "supply_valve": 1,
            },
            "required-features": [
            ]
        }
    ],
    "HD MASTER ASLY": [
        {
            "parent": "",
            "value": "",
            "group-label": "",
            "group-type": "",
            "display-features": {
                "tag_nbr": 1,
                "valve_mounting_kit": 2,
                "va_mounting_actuator_on_valve": 3,
                "control_required": 4
            },
            "required-features": [
                "va_mounting_actuator_on_valve"
            ]
        },
        {
            "parent": "valve_mounting_kit",
            "value": "Yes",
            "group-label": "",
            "group-type": "",
            "display-features": {
                "valve_bore_size": 2,
                "spool_bracket": 3,
                "valve_mounting_material": 4,
            },
            "required-features": [
            ]
        },
        {
            "parent": "va_mounting_actuator_on_valve",
            "value": "Yes",
            "group-label": "",
            "group-type": "",
            "display-features": {
                "supply_valve": 1,

            },
            "required-features": [
            ]
        }
    ],
    // ATC , G MASTER ASLY 
    "PAINT": [
        {
            "parent": "",
            "group-label": "",
            "group-type": "",
            "display-features": {
                "VA000-420-91": 1,
            },
            "required-features": [
            ]
        },
        {
            "parent": "VA000-420-91",
            "group-label": "",
            "group-type": "",
            "value": "VA000-422-44",
            "display-features": {
                "outhouse_type": 1
            },
            "required-features": []
        },
        {
            "parent": "VA000-420-91",
            "group-label": "",
            "group-type": "",
            "value": "ETO PAINT ITEM",
            "display-features": {
                "paint_color": 1,
                "paint_spec": 2
            },
            "required-features": []
        },
        {
            "parent": "VA000-420-91",
            "group-label": "",
            "group-type": "",
            "value": "VA000-423-06",
            "display-features": {
                "wuqing_special_paint": 1,
                "wuqing_paint_color": 2
            },
            "required-features": []
        },

    ],
    // ATC , G MASTER ASLY 
    "TEST CERT AND DOC": [
        {
            "parent": "",
            "group-label": "",
            "group-type": "",
            "display-features": {
                "Standard Documentation Levels": 1,
                "TestPressureNotes": 2,
                "VA000-425-45": 3

            },
            "required-features": [
            ]
        }
    ],
    "CDS_MAGMETER": [
        { // check with siva , this display feature is not there in json
            "parent": "",
            "group-label": "Units of Measure",
            "group-type": "frame",
            "display-features": {
                "FLOW_UNITS": 1,
                "VARIABLE_DAMPING": 2,
                "LINE_SIZE_IN": 3
            },
            "required-features": [
            ]
        },
        {
            "parent": "",
            "group-label": "Process Information",
            "group-type": "",
            "value": "",
            "display-features": {
                "FLUID_STATE": 1,
                "MINIMUM_FLOW": 2,
                "MAXIMUM_FLOW": 3,
                "FLOW_UNITS_FROM_PA": 4,
                "OPERATING_DENSITY": 5,
                "OPERATING_DENSITY_UNITS": 6,
                "OPERATING_PRESSURE": 7,
                "OPERATING_PRESSURE_UNITS": 8,
                "OPERATING_TEMPERATURE": 9,
                "OPERATING_TEMPERATURE_UNITS": 10,
                "SPECIFIC_GRAVITY": 11
            },
            "required-features": []
        }
    ],

    "CDS_VORTEX": [
        {
            "parent": "",
            "group-label": "Configuration Options",
            "group-type": "",
            "display-features": {
                "DEFAULT_OPTIONS": 1,
            },
            "required-features": [
                "DEFAULT_OPTIONS"
            ]
        },
        {
            "parent": "",
            "group-label": "Standard Units",
            "group-type": "",
            "display-features": {
                "MASS_FLOW_UNITS": 1,
                "VOLUMETRIC_FLOW_UNITS": 2,
                "FLOW_VELOCITY_UNITS": 3,
                "PROCESS_TEMPERATURE_UNITS": 4,
                "ELECTRONICS_TEMPERATURE_UNITS": 5
            },
            "required-features": [
            ]
        },
        {
            "parent": "",
            "group-label": "Process Variable Configuration",
            "group-type": "",
            "display-features": {
                "PRIMARY_VARIABLE": 1,
                "SECONDARY_VARIABLE": 2,
                "TERTIARY_VARIABLE": 3,
                "QUATERNARY_VARIABLE": 4
            },
            "required-features": [
            ]
        },
        {
            "parent": "PRIMARY_VARIABLE",
            "value": "PROCESS_MASS_FLOW_1",
            "group-label": "Process Variable Configuration",
            "group-type": "",
            "display-features": {
                "MASS_FLOW_LRV": 1,
                "MASS_FLOW_URV": 2,
            },
            "required-features": [
                "MASS_FLOW_LRV", "MASS_FLOW_URV"
            ]
        },
        {
            "parent": "PRIMARY_VARIABLE",
            "value": "PROCESS_VOLUMETRIC_FLOW_1",
            "group-label": "Process Variable Configuration",
            "group-type": "",
            "display-features": {
                "VOLUMETRIC_FLOW_LRV": 1,
                "VOLUMETRIC_FLOW_URV": 2,
            },
            "required-features": [
                "VOLUMETRIC_FLOW_LRV", "VOLUMETRIC_FLOW_URV"
            ]
        },
        {
            "parent": "PRIMARY_VARIABLE",
            "value": "PROCESS_FLOW_VELOCITY_1",
            "group-label": "Process Variable Configuration",
            "group-type": "",
            "display-features": {
                "FLOW_VELOCITY_LRV": 1,
                "FLOW_VELOCITY_URV": 2,
            },
            "required-features": [
                "FLOW_VELOCITY_LRV", "FLOW_VELOCITY_URV"
            ]
        },
        {
            "parent": "SECONDARY_VARIABLE",
            "value": "PROCESS_MASS_FLOW_2",
            "group-label": "Process Variable Configuration",
            "group-type": "",
            "display-features": {
                "MASS_FLOW_LRV": 1,
                "MASS_FLOW_URV": 2,
            },
            "required-features": [
                "MASS_FLOW_LRV", "MASS_FLOW_URV"
            ]
        },
        {
            "parent": "SECONDARY_VARIABLE",
            "value": "PROCESS_VOLUMETRIC_FLOW_2",
            "group-label": "Process Variable Configuration",
            "group-type": "",
            "display-features": {
                "VOLUMETRIC_FLOW_LRV": 1,
                "VOLUMETRIC_FLOW_URV": 2,
            },
            "required-features": [
                "VOLUMETRIC_FLOW_LRV", "VOLUMETRIC_FLOW_URV"
            ]
        },
        {
            "parent": "SECONDARY_VARIABLE",
            "value": "PROCESS_FLOW_VELOCITY_2",
            "group-label": "Process Variable Configuration",
            "group-type": "",
            "display-features": {
                "FLOW_VELOCITY_LRV": 1,
                "FLOW_VELOCITY_URV": 2,
            },
            "required-features": [
                "FLOW_VELOCITY_LRV", "FLOW_VELOCITY_URV"
            ]
        },
        {
            "parent": "TERTIARY_VARIABLE",
            "value": "PROCESS_MASS_FLOW_3",
            "group-label": "Process Variable Configuration",
            "group-type": "",
            "display-features": {
                "MASS_FLOW_LRV": 1,
                "MASS_FLOW_URV": 2,
            },
            "required-features": [
                "MASS_FLOW_LRV", "MASS_FLOW_URV"
            ]
        },
        {
            "parent": "TERTIARY_VARIABLE",
            "value": "PROCESS_VOLUMETRIC_FLOW_3",
            "group-label": "Process Variable Configuration",
            "group-type": "",
            "display-features": {
                "VOLUMETRIC_FLOW_LRV": 1,
                "VOLUMETRIC_FLOW_URV": 2,
            },
            "required-features": [
                "VOLUMETRIC_FLOW_LRV", "VOLUMETRIC_FLOW_URV"
            ]
        },
        {
            "parent": "TERTIARY_VARIABLE",
            "value": "PROCESS_FLOW_VELOCITY_3",
            "group-label": "Process Variable Configuration",
            "group-type": "",
            "display-features": {
                "FLOW_VELOCITY_LRV": 1,
                "FLOW_VELOCITY_URV": 2,
            },
            "required-features": [
                "FLOW_VELOCITY_LRV", "FLOW_VELOCITY_URV"
            ]
        },
        ,
        {
            "parent": "QUATERNARY_VARIABLE",
            "value": "PROCESS_MASS_FLOW_4",
            "group-label": "Process Variable Configuration",
            "group-type": "",
            "display-features": {
                "MASS_FLOW_LRV": 1,
                "MASS_FLOW_URV": 2,
            },
            "required-features": [
                "MASS_FLOW_LRV", "MASS_FLOW_URV"
            ]
        },
        {
            "parent": "QUATERNARY_VARIABLE",
            "value": "PROCESS_VOLUMETRIC_FLOW_4",
            "group-label": "Process Variable Configuration",
            "group-type": "",
            "display-features": {
                "VOLUMETRIC_FLOW_LRV": 1,
                "VOLUMETRIC_FLOW_URV": 2,
            },
            "required-features": [
                "VOLUMETRIC_FLOW_LRV", "VOLUMETRIC_FLOW_URV"
            ]
        },
        {
            "parent": "QUATERNARY_VARIABLE",
            "value": "PROCESS_FLOW_VELOCITY_4",
            "group-label": "Process Variable Configuration",
            "group-type": "",
            "display-features": {
                "FLOW_VELOCITY_LRV": 1,
                "FLOW_VELOCITY_URV": 2,
            },
            "required-features": [
                "FLOW_VELOCITY_LRV", "FLOW_VELOCITY_URV"
            ]
        },
        {
            "parent": "",
            "group-label": "Mating Pipe I.D.",
            "group-type": "",
            "display-features": {
                "PROCESS_LINE_SIZE": 1,
                "MATING_PIPE_ID_UNITS": 2,
                "SCHEDULE": 3
            },
            "required-features": [
            ]
        },
        {
            "parent": "",
            "group-label": "Variable Damping",
            "group-type": "",
            "display-features": {
                "FLOW_DAMPING": 1,
                "TEMPERATURE_DAMPING": 2
            },
            "required-features": [
            ]
        },
        {
            "parent": "",
            "group-label": "Process Information",
            "group-type": "",
            "display-features": {
                "FLUID": 1,
                "CUSTOM_FLUID": 2,
                "FLUID_STATE": 3,
                "OPERATING_DENSITY": 4,
                "OPERATING_DENSITY_UNITS": 5,
                "BASE_DENSITY": 6,
                "BASE_DENSITY_UNITS": 7,
                "DENSITY_RATIO": 8,
                "OPERATING_PRESSURE": 9,
                "OPERATING_PRESSURE_UNITS": 10,
                "BASE_PRESSURE": 11,
                "BASE_PRESSURE_UNITS": 12,
                "OPERATING_TEMPERATURE": 13,
                "OPERATING_TEMPERATURE_UNITS": 14,
                "BASE_TEMPERATURE": 15,
                "BASE_TEMPERATURE_UNITS": 16,
                "MINIMUM_FLOW": 17,
                "MAXIMUM_FLOW": 18,
                "OPERATING_COMPRESSIBILITY": 19,
                "BASE_COMPRESSIBILITY": 20,
                "OPERATING_VISCOSITY": 21,
                "OPERATING_VISCOSITY_UNITS": 22,
                "MOLECULAR_WEIGHT": 23,
                "SPECIFIC_GRAVITY": 24,
                "VAPOR_PRESSURE": 25,
                "VAPOR_PRESSURE_UNITS": 26,
                "FLOW_UNITS_FROM_PA": 27
            },
            "required-features": [

            ]
        }
    ],

    "CWM-GFC-1": [
        {
            "parent": "",
            "group-label": "Calibration",
            "group-type": "frame",
            "display-features": {
                "LRV": 1,
                "URV": 2,
                "UOM": 3
            },
            "required-features": [
            ]
        }
    ],

    "USM341520": [
        {
            "parent": "",
            "group-label": "Tag Details",
            "group-type": "",
            "display-features": {
                "Tag Required": 1
            },
            "required-features": [
            ]
        },
        {
            "parent": "Tag Required",
            "group-label": "",
            "group-type": "",
            "value": "Stainless Steel Tag",
            "display-features": {
                "Type of SS Tag": 1
            },
            "required-features": [
            ]
        }
    ],
    "USM341736": [
        {
            "parent": "",
            "group-label": "Tag Details",
            "group-type": "",
            "display-features": {
                "Tag Required": 1
            },
            "required-features": [
            ]
        },
        {
            "parent": "Tag Required",
            "group-label": "",
            "group-type": "",
            "value": "Stainless Steel Tag",
            "display-features": {
                "Type of SS Tag": 1
            },
            "required-features": [
            ]
        }
    ],
    "DPOFU03": [
        {
            "parent": "",
            "group-label": "Tag Details",
            "group-type": "",
            "display-features": {
                "Tag Required": 1
            },
            "required-features": [
            ]
        },
        {
            "parent": "Tag Required",
            "group-label": "",
            "group-type": "",
            "value": "Stainless Steel Tag",
            "display-features": {
                "Type of SS Tag": 1
            },
            "required-features": [
            ]
        }
    ],

    "DPSX02": [
        {
            "parent": "",
            "group-label": "Tag Details",
            "group-type": "",
            "display-features": {
                "Tag Required": 1
            },
            "required-features": [
            ]
        },
        {
            "parent": "Tag Required",
            "group-label": "",
            "group-type": "",
            "value": "Stainless Steel Tag",
            "display-features": {
                "Type of SS Tag": 1
            },
            "required-features": [
            ]
        }
    ],

    "DPOFU10": [
        {
            "parent": "",
            "group-label": "Tag Details",
            "group-type": "",
            "display-features": {
                "Tag Required": 1
            },
            "required-features": [
            ]
        },
        {
            "parent": "Tag Required",
            "group-label": "",
            "group-type": "",
            "value": "Stainless Steel Tag",
            "display-features": {
                "Type of SS Tag": 1
            },
            "required-features": [
            ]
        }
    ],
    "DPPLATE520": [
        // {
        //     "parent": "",
        //     "group-label": "Bore Details",
        //     "group-type": "",
        //     "display-features": {
        //         "Bore Type": 1,
        //     },
        //     "required-features": [
        //         "Bore Type"
        //     ]
        // },
        {
            "parent": "",
            "group-label": "Tag Details",
            "group-type": "",
            "display-features": {
                "Tag Required": 1
            },
            "required-features": [
            ]
        },
        {
            "parent": "Tag Required",
            "group-label": "",
            "group-type": "",
            "value": "Stainless Steel Tag",
            "display-features": {
                "Type of SS Tag": 1
            },
            "required-features": [
            ]
        }
    ],
    "DPPLATE560": [
        // {
        //     "parent": "",
        //     "group-label": "Bore Details",
        //     "group-type": "",
        //     "display-features": {
        //         "Bore Type": 1,
        //     },
        //     "required-features": [
        //         "Bore Type"
        //     ]
        // },
        {
            "parent": "",
            "group-label": "Tag Details",
            "group-type": "",
            "display-features": {
                "Tag Required": 1
            },
            "required-features": [
            ]
        },
        {
            "parent": "Tag Required",
            "group-label": "",
            "group-type": "",
            "value": "Stainless Steel Tag",
            "display-features": {
                "Type of SS Tag": 1
            },
            "required-features": [
            ]
        }

    ],

    "DPPLATE BORE DETAILS": [
        {
            "parent": "",
            "group-label": "",
            "group-type": "",
            "display-features": {},
            "required-features": [],
            "dynamic-display-dpPlate": {
                "active": true,
                "prefix": "TAG",
                "count": "quantity"
            }
        }
    ],

    "RMT URD": [
        {
            "parent": "",
            "group-label": "Calibration",
            "group-type": "frame",
            "display-features": {},
            "display-features-rmtUrd": {
                "CAL-ITEMS1": 1,
                "MSR_TYPE1": 2,
                "LRV1": 3,
                "URV1": 4,
                "UOM1": 5,
                "TYPE1": 6,
                "SQT1": 7,
                "NP1": 8,
                "ASSOC1": 9,
                "TBD1": 10,
                "CAL-ITEMS2": 11,
                "MSR_TYPE2": 12,
                "LRV2": 13,
                "URV2": 14,
                "UOM2": 15,
                "TYPE2": 16,
                "SQT2": 17,
                "NP2": 18,
                "ASSOC2": 19,
                "TBD2": 20,
                "CAL-ITEMS3": 21,
                "MSR_TYPE3": 22,
                "LRV3": 23,
                "URV3": 24,
                "UOM3": 25,
                "TYPE3": 26,
                "SQT3": 27,
                "NP3": 28,
                "ASSOC3": 29,
                "TBD3": 30,
                "CAL-ITEMS4": 31,
                "MSR_TYPE4": 32,
                "LRV4": 33,
                "URV4": 34,
                "UOM4": 35,
                "TYPE4": 36,
                "SQT4": 37,
                "NP4": 38,
                "ASSOC4": 39,
                "TBD4": 40,
                "MSR_TYPE11": 41,
                "LRV11": 42,
                "URV11": 43,
                "UOM11": 44,
                "NP11": 45,
                "MSR_TYPE22": 46,
                "LRV22": 47,
                "URV22": 48,
                "UOM22": 49,
                "NP22": 50

            },
            "required-features": [
            ]
        },
        {
            "parent": "",
            "group-label": "Wireless",
            "group-type": "frame",
            "display-features": {
                "NTWRK": 1,
                "JKEY": 2,
            },
            "required-features": [

            ]
        },
        {
            "parent": "",
            "group-label": "Manufacturing Notes",
            "group-type": "frame",
            "display-features": {
                "MFG_NOTES": 1,
            },
            "required-features": [

            ]
        },
        ...((size) => new Array(size).fill(0).map((x, i) => ({
            "parent": "",
            "group-label": `Tag Number ${i >= 1 ? ` ${i + 1}` : '1'}`,
            "group-type": "frame",
            "display-features": {
                ...(i === 0 ? { "ADDITION_BUTTON": 4 } : {}),
                [`TAG-ITEMS${i >= 1 ? ` ${i + 1}` : ''}`]: 1,
                [`ATTACH_TAG${i >= 1 ? ` ${i + 1}` : ''}`]: 2,
                [`OPTION_TAG${i >= 1 ? ` ${i + 1}` : ''}`]: 3,

            },
            "required-features": [
                "TAG-ITEMS"
            ]
        })))(6),
        ...getAdditionalTagNos(6, ['WIRE', 'SHORT', 'NAME', 'LONG', 'PERM'])
    ]
};

export const fieldMapping = {
    "Tag1": "TAG1",
    "Tag2": "TAG2",
    "Tag3": "TAG3",
    "Tag4": "TAG4",
    "Tag5": "TAG5",
    "Tag6": "TAG6",
    "Tag7": "TAG7",
    "Tag8": "TAG8",
    "Tag9": "TAG9",
    "Tag10": "TAG10",
    "Tag11": "TAG11",
    "Tag12": "TAG12",
    "Tag13": "TAG13",
    "Tag14": "TAG14",
    "Tag15": "TAG15",
    "Tag16": "TAG16",
    "Tag17": "TAG17",
    "Tag18": "TAG18",
    "Tag19": "TAG19",
    "Tag20": "TAG20",
    "Tag21": "TAG21",
    "Tag22": "TAG22",
    "Tag23": "TAG23",
    "Tag24": "TAG24",
    "Tag25": "TAG25",
    "Tag26": "TAG26",
    "Tag27": "TAG27",
    "Tag28": "TAG28",
    "Tag29": "TAG29",
    "Tag30": "TAG30",
    "Tag31": "TAG31",
    "Tag32": "TAG32",
    "Tag33": "TAG33",
    "Tag34": "TAG34",
    "Tag35": "TAG35",
    "Tag36": "TAG36",
    "Tag37": "TAG37",
    "Tag38": "TAG38",
    "Tag39": "TAG39",
    "Tag40": "TAG40",
    "Tag41": "TAG41",
    "Tag42": "TAG42",
    "Tag43": "TAG43",
    "Tag44": "TAG44",
    "Tag45": "TAG45",
    "Tag46": "TAG46",
    "Tag47": "TAG47",
    "Tag48": "TAG48",
    "Tag49": "TAG49",
    "Tag50": "TAG50",
    "Tag1-DP": "DPTAG1",
    "Tag2-DP": "DPTAG2",
    "Tag3-DP": "DPTAG3",
    "Tag4-DP": "DPTAG4",
    "Tag5-DP": "DPTAG5",
    "Tag6-DP": "DPTAG6",
    "Tag7-DP": "DPTAG7",
    "Tag8-DP": "DPTAG8",
    "Tag9-DP": "DPTAG9",
    "Tag10-DP": "DPTAG10",
    "Tag11-DP": "DPTAG11",
    "Tag12-DP": "DPTAG12",
    "Tag13-DP": "DPTAG13",
    "Tag14-DP": "DPTAG14",
    "Tag15-DP": "DPTAG15",
    "Tag16-DP": "DPTAG16",
    "Tag17-DP": "DPTAG17",
    "Tag18-DP": "DPTAG18",
    "Tag19-DP": "DPTAG19",
    "Tag20-DP": "DPTAG20",
    "Tag21-DP": "DPTAG21",
    "Tag22-DP": "DPTAG22",
    "Tag23-DP": "DPTAG23",
    "Tag24-DP": "DPTAG24",
    "Tag25-DP": "DPTAG25",
    "Tag26-DP": "DPTAG26",
    "Tag27-DP": "DPTAG27",
    "Tag28-DP": "DPTAG28",
    "Tag29-DP": "DPTAG29",
    "Tag30-DP": "DPTAG30",
    "Tag31-DP": "DPTAG31",
    "Tag32-DP": "DPTAG32",
    "Tag33-DP": "DPTAG33",
    "Tag34-DP": "DPTAG34",
    "Tag35-DP": "DPTAG35",
    "Tag36-DP": "DPTAG36",
    "Tag37-DP": "DPTAG37",
    "Tag38-DP": "DPTAG38",
    "Tag39-DP": "DPTAG39",
    "Tag40-DP": "DPTAG40",
    "Tag41-DP": "DPTAG41",
    "Tag42-DP": "DPTAG42",
    "Tag43-DP": "DPTAG43",
    "Tag44-DP": "DPTAG44",
    "Tag45-DP": "DPTAG45",
    "Tag46-DP": "DPTAG46",
    "Tag47-DP": "DPTAG47",
    "Tag48-DP": "DPTAG48",
    "Tag49-DP": "DPTAG49",
    "Tag50-DP": "DPTAG50",


    "Flow Point or Percentage": "CALIBRATION_INPUT_TYPE",
    "VERTYP": "CALIBRATION_TYPE",
    "VERRT": "DESIRED_FLOW_RATE",
    "VERUNTS": "CALIBRATION_UNITS",
    "SPFUT": "SPL_UNITS",
    "SPBU": "SPL_BASE_FLOW_UNITS",
    "SPBTU": "SPL_BASE_TIME_UNITS",
    "TOTTXT": "TOTALIZER_TEXT",
    "Conversion": "CONVERSION",
    "FP1": "FP1",
    "FP2": "FP2",
    "FP3": "FP3",
    "FP4": "FP4",
    "PNTS01UI": "PNTS1",
    "PNTS02UI": "PNTS2",
    "PNTS03UI": "PNTS3",
    "PNTS04UI": "PNTS4",
    "APPL": "APPLICATION_TYPE",
    "1 Coat Paint Type": "PAINT_TYPE_1_COAT",
    "1_coat_Color": "PAINT_COLOR_1_COAT",
    "1_coat_Paint Thickness": "PAINT_THICKNESS_1_COAT",
    "2 Coat Paint Type": "PAINT_TYPE_2_COAT",
    "2_coat_color": "PAINT_COLOR_2_COAT",
    "2_coat_Paint_Thickness": "PAINT_THICKNESS_2_COAT",
    "3_coat_Paint_Type": "PAINT_TYPE_3_COAT",
    "3_coat_color": "PAINT_COLOR_3_COAT",
    "3_coat_Paint_Thickness": "PAINT_THICKNESS_3_COAT",
    "valve_mounting_material": "VALVE_MOUNTING_KIT_MATERIAL",
    "control_required": "CONTROL_REQUIRED",
    "spool_bracket": "SPOOL_TYPE_BRACKET",
    "valve_mounting_kit": "VALVE_MOUNTING_KIT",
    "va_mounting_actuator_on_valve": "VALVE_Y_N",
    "supply_valve": "VALVE_SUPPLY",
    "valve_bore_size": "VALVE_SIZE",
    "TestPressureNotes": "TEST_PRESSURE_NOTES",
    "outhouse_type": "SPL_PAINT_OPTION",
    "paint_color": "PAINT_COLOR",
    "paint_spec": "PAINT_SPECIFICATION",
    "FLOW_UNITS": "FLOW_UNITS",
    "VARIABLE_DAMPING": "VAR_DAMPNG",
    "LINE_SIZE_IN": "LINE_SIZE_UNIT",
    "FLUID_STATE": "FLUID_STATE",
    "MINIMUM_FLOW": "MIN_FLOW_RATE",
    "MAXIMUM_FLOW": "MAX_FLOW_RATE",
    "OPERATING_DENSITY": "OPRTING_DNSTY",
    "OPERATING_PRESSURE": "OPRTING_PRESS",
    "OPERATING_TEMPERATURE": "OPRTING_TEMP",
    "SPECIFIC_GRAVITY": "SPECIFIC_GRAVITY",
    "FLOW_UNITS_FROM_PA": "FLOW_UNITS_PA",
    "OPERATING_DENSITY_UNITS": "DENSITY_UNITS",
    "OPERATING_PRESSURE_UNITS": "OPERATING_PRESS_UNIT",
    "OPERATING_TEMPERATURE_UNITS": "OPERATING_TEMP_UNIT",
    "DENS": "DENSITY_VALUE",
    "DENSU": "DENSITY_UNITS",
    "tag_nbr": "TAG_NBR",
    "DEFAULT_OPTIONS": "DEF_OPT",
    "MASS_FLOW_UNITS": "MASSUNITS",
    "VOLUMETRIC_FLOW_UNITS": "VOLUNITS",
    "FLOW_VELOCITY_UNITS": "FLOWVALUNITS",
    "PROCESS_TEMPERATURE_UNITS": "PRCSS_TEMP_UNIT",
    "PRIMARY_VARIABLE": "PRIMARY_VAR",
    "SECONDARY_VARIABLE": "SECON_VAR",
    "TERTIARY_VARIABLE": "TERITARY_VAR",
    "QUATERNARY_VARIABLE": "QUATER_VAR",
    "ELECTRONICS_TEMPERATURE_UNITS": "ELE_TEMP_UNIT",
    "MASS_FLOW_LRV": "MASS_LRV",
    "MASS_FLOW_URV": "MASS_URV",
    "VOLUMETRIC_FLOW_LRV": "VOL_LRV",
    "VOLUMETRIC_FLOW_URV": "VOL_URV",
    "FLOW_VELOCITY_LRV": "FLOW_LRV",
    "FLOW_VELOCITY_URV": "FLOW_URV",
    "PROCESS_LINE_SIZE": "NP_LINE_SIZE",
    "MATING_PIPE_ID_UNITS": "PIPE_UNIT",
    "SCHEDULE": "SCHD_SELCT",
    "FLOW_DAMPING": "FLW_DAMPNG",
    "TEMPERATURE_DAMPING": "TEMP_DAMPNG",
    "BASE_COMPRESSIBILITY": "BASE_COMPRESSIBILITY",
    "VAPOR_PRESSURE_UNITS": "VAPOR_PRESSURE_UNITS",
    "FLUID": "FLUID",
    "BASE_DENSITY_UNITS": "BSE_DEN_UNT",
    "BASE_PRESSURE_UNITS": "BSE_PRSS_UNT",
    "BASE_TEMPERATURE_UNITS": "BSE_TMP_UNT",
    "OPERATING_VISCOSITY_UNITS": "OPR_VIS_UNT",
    "CUSTOM_FLUID": "CUSTOM_FLUID",
    "DENSITY_RATIO": "DEN_RTO",
    "BASE_DENSITY": "BSE_DEN",
    "BASE_PRESSURE": "BSE_PRSS",
    "BASE_TEMPERATURE": "BSE_TEMP",
    "OPERATING_COMPRESSIBILITY": "OPR_COMR",
    "MOLECULAR_WEIGHT": "MOL_WEGHT",
    "OPERATING_VISCOSITY": "OPR_VIS",
    "VAPOR_PRESSURE": "VAP_PRSS",
    "Channel_A_LRV": "CHANNEL_A_LRV",
    "Channel_A_URV": "CHANNEL_A_URV",
    "Channel_B_LRV": "CHANNEL_B_LRV",
    "Channel_B_URV": "CHANNEL_B_URV",
    "Channel_C_LRV": "CHANNEL_C_LRV",
    "Channel_C_URV": "CHANNEL_C_URV",
    "Channel_D_LRV": "CHANNEL_D_LRV",
    "Channel_D_URV": "CHANNEL_D_URV",
    "Tag Required": "TAG_REQUIRED",
    "Type of SS Tag": "SS_TAG_TYPE",
    "1ST_TUBE_NAME_TAG_01_LINE_1": "TUBE_NAME_TAG_01_LINE_1",
    "1ST_XMTR_NAME_TAG_01_LINE_1": "XMTR_NAME_TAG_01_LINE_1",
    "1ST_TUBE_NAME_TAG_02_LINE_1": "TUBE_NAME_TAG_02_LINE_1",
    "1ST_XMTR_NAME_TAG_02_LINE_1": "XMTR_NAME_TAG_02_LINE_1",
    "1ST_TUBE_NAME_TAG_03_LINE_1": "TUBE_NAME_TAG_03_LINE_1",
    "1ST_XMTR_NAME_TAG_03_LINE_1": "XMTR_NAME_TAG_03_LINE_1",
    "1ST_TUBE_NAME_TAG_04_LINE_1": "TUBE_NAME_TAG_04_LINE_1",
    "1ST_XMTR_NAME_TAG_04_LINE_1": "XMTR_NAME_TAG_04_LINE_1",
    "1ST_TUBE_NAME_TAG_05_LINE_1": "TUBE_NAME_TAG_05_LINE_1",
    "1ST_XMTR_NAME_TAG_05_LINE_1": "XMTR_NAME_TAG_05_LINE_1",
    "Standard Documentation Levels": "DOCUMENTATION_LEVEL",
    "VA000-425-45": "WITNESS_TEST",
    "VA000-420-91": "PAINT_NAME",
    "Bore Type": "Bore Type",
    "MINIMUM_AMBIENT_TEMPERATURE": "MIN_AMB_TEMP",
    "MAXIMUM_AMBIENT_TEMPERATURE": "MAX_AMB_TEMP",
    "AMBIENT_TEMPERATURE_UNITS": "AMB_UNITS",
    "PROCESS_FLUID_TEMPERATURE_UNITS": "PRSS_FLD_UNITS",
    "MINIMUM_PROCESS_FLUID_TEMPERATURE": "MIN_PRSS_TEMP",
    "MAXIMUM_PROCESS_FLUID_TEMPERATURE": "MAX_PRSS_TEMP",
    "CAL-ITEMS": "CAL",
    // ====== SHORT=======
    "Tag Line 1 SHORT Tag Number 1": "LINE_11",
    "Tag Line 2 SHORT Tag Number 1": "LINE_12",
    "Tag Line 3 SHORT Tag Number 1": "LINE_13",

    "Tag Line 1 SHORT Tag Number  2": "LINE_11",
    "Tag Line 2 SHORT Tag Number  2": "LINE_12",
    "Tag Line 3 SHORT Tag Number  2": "LINE_13",

    "Tag Line 1 SHORT Tag Number  3": "LINE_11",
    "Tag Line 2 SHORT Tag Number  3": "LINE_12",
    "Tag Line 3 SHORT Tag Number  3": "LINE_13",

    "Tag Line 1 SHORT Tag Number  4": "LINE_11",
    "Tag Line 2 SHORT Tag Number  4": "LINE_12",
    "Tag Line 3 SHORT Tag Number  4": "LINE_13",

    "Tag Line 1 SHORT Tag Number  5": "LINE_11",
    "Tag Line 2 SHORT Tag Number  5": "LINE_12",
    "Tag Line 3 SHORT Tag Number  5": "LINE_13",

    "Tag Line 1 SHORT Tag Number  6": "LINE_11",
    "Tag Line 2 SHORT Tag Number  6": "LINE_12",
    "Tag Line 3 SHORT Tag Number  6": "LINE_13",
    // ======= NAME======
    "Tag Line 1 NAME Tag Number 1": "LINE_16",
    "Tag Line 2 NAME Tag Number 1": "LINE_17",
    "Tag Line 3 NAME Tag Number 1": "LINE_18",
    "Tag Line 4 NAME Tag Number 1": "LINE_19",
    "Tag Line 5 NAME Tag Number 1": "LINE_20",

    "Tag Line 1 NAME Tag Number  2": "LINE_16",
    "Tag Line 2 NAME Tag Number  2": "LINE_17",
    "Tag Line 3 NAME Tag Number  2": "LINE_18",
    "Tag Line 4 NAME Tag Number  2": "LINE_19",
    "Tag Line 5 NAME Tag Number  2": "LINE_20",

    "Tag Line 1 NAME Tag Number  3": "LINE_16",
    "Tag Line 2 NAME Tag Number  3": "LINE_17",
    "Tag Line 3 NAME Tag Number  3": "LINE_18",
    "Tag Line 4 NAME Tag Number  3": "LINE_19",
    "Tag Line 5 NAME Tag Number  3": "LINE_20",

    "Tag Line 1 NAME Tag Number  4": "LINE_16",
    "Tag Line 2 NAME Tag Number  4": "LINE_17",
    "Tag Line 3 NAME Tag Number  4": "LINE_18",
    "Tag Line 4 NAME Tag Number  4": "LINE_19",
    "Tag Line 5 NAME Tag Number  4": "LINE_20",

    "Tag Line 1 NAME Tag Number  5": "LINE_16",
    "Tag Line 2 NAME Tag Number  5": "LINE_17",
    "Tag Line 3 NAME Tag Number  5": "LINE_18",
    "Tag Line 4 NAME Tag Number  5": "LINE_19",
    "Tag Line 5 NAME Tag Number  5": "LINE_20",

    "Tag Line 1 NAME Tag Number  6": "LINE_16",
    "Tag Line 2 NAME Tag Number  6": "LINE_17",
    "Tag Line 3 NAME Tag Number  6": "LINE_18",
    "Tag Line 4 NAME Tag Number  6": "LINE_19",
    "Tag Line 5 NAME Tag Number  6": "LINE_20",
    // ====== LONG ======
    "Tag Line 1 LONG Tag Number 1": "LINE_21",
    "Tag Line 2 LONG Tag Number 1": "LINE_22",

    "Tag Line 1 LONG Tag Number  2": "LINE_21",
    "Tag Line 2 LONG Tag Number  2": "LINE_22",

    "Tag Line 1 LONG Tag Number  3": "LINE_21",
    "Tag Line 2 LONG Tag Number  3": "LINE_22",

    "Tag Line 1 LONG Tag Number  4": "LINE_21",
    "Tag Line 2 LONG Tag Number  4": "LINE_22",

    "Tag Line 1 LONG Tag Number  5": "LINE_21",
    "Tag Line 2 LONG Tag Number  5": "LINE_22",

    "Tag Line 1 LONG Tag Number  6": "LINE_21",
    "Tag Line 2 LONG Tag Number  6": "LINE_22",
    // ==== PERM ===
    "Tag Line 1 PERM Tag Number 1": "LINE_26",
    "Tag Line 2 PERM Tag Number 1": "LINE_27",
    "Tag Line 3 PERM Tag Number 1": "LINE_28",
    "Tag Line 4 PERM Tag Number 1": "LINE_29",
    "Tag Line 5 PERM Tag Number 1": "LINE_30",

    "Tag Line 1 PERM Tag Number  2": "LINE_26",
    "Tag Line 2 PERM Tag Number  2": "LINE_27",
    "Tag Line 3 PERM Tag Number  2": "LINE_28",
    "Tag Line 4 PERM Tag Number  2": "LINE_29",
    "Tag Line 5 PERM Tag Number  2": "LINE_30",

    "Tag Line 1 PERM Tag Number  3": "LINE_26",
    "Tag Line 2 PERM Tag Number  3": "LINE_27",
    "Tag Line 3 PERM Tag Number  3": "LINE_28",
    "Tag Line 4 PERM Tag Number  3": "LINE_29",
    "Tag Line 5 PERM Tag Number  3": "LINE_30",

    "Tag Line 1 PERM Tag Number  4": "LINE_26",
    "Tag Line 2 PERM Tag Number  4": "LINE_27",
    "Tag Line 3 PERM Tag Number  4": "LINE_28",
    "Tag Line 4 PERM Tag Number  4": "LINE_29",
    "Tag Line 5 PERM Tag Number  4": "LINE_30",

    "Tag Line 1 PERM Tag Number  5": "LINE_26",
    "Tag Line 2 PERM Tag Number  5": "LINE_27",
    "Tag Line 3 PERM Tag Number  5": "LINE_28",
    "Tag Line 4 PERM Tag Number  5": "LINE_29",
    "Tag Line 5 PERM Tag Number  5": "LINE_30",

    "Tag Line 1 PERM Tag Number  6": "LINE_26",
    "Tag Line 2 PERM Tag Number  6": "LINE_27",
    "Tag Line 3 PERM Tag Number  6": "LINE_28",
    "Tag Line 4 PERM Tag Number  6": "LINE_29",
    "Tag Line 5 PERM Tag Number  6": "LINE_30",

    // Closure function to generate data as above with rules
    // ...((rule, keyPrefix, valuePrefix, tagNumber) => Array(6).fill(0).reduce((bucket, x, tagId) => {
    //     return Object.entries(rule).reduce((o, [key, range]) => {
    //         for (let i = range[0], j = 1; i <= range[1]; i++ , j++) o[`${keyPrefix} ${j} ${key} ${tagNumber} ${tagId + 1}`] = `${valuePrefix}_${i}`;
    //         return o;
    //     }, bucket)
    // }, {}))({ 'WIRE': [1, 10], 'SHORT': [11, 15], 'NAME': [16, 20], 'LONG': [21, 25], 'PERM': [26, 30] }, 'Tag Line', 'LINE', 'Tag Number'), // rules, keyPrefix, valuePrefix

    "ASSOC": "ASSOC",
    "TBD": "TBD",
    "MFG_NOTES": "MFG_NOTES",
    "JKEY": "JOIN_KEY",
    "NTWRK": "NETWORK_ID",
    "Specify source of CDS information": "CDS",
    "Specify default configuration type": "CDS_DEFAULT_TYPE",
    "ADDITION_BUTTON": "ADDITION_BUTTON",
    "CAL-ITEMS1": "CAL-ITEMS1",
    "MSR_TYPE1": "MEASUREMENT_TYPE1",
    "LRV1": "LRV1",
    "URV1": "URV1",
    "UOM1": "UOM1",
    "TYPE1": "DESG1",
    "SQT1": "SQR1",
    "NP1": "NP1",
    "TBD1": "TBD1",
    "CAL-ITEMS2": "CAL-ITEMS2",
    "MSR_TYPE2": "MEASUREMENT_TYPE2",
    "LRV2": "LRV2",
    "URV2": "URV2",
    "UOM2": "UOM2",
    "TYPE2": "DESG2",
    "SQT2": "SQR2",
    "NP2": "NP2",
    "TBD2": "TBD2",
    "CAL-ITEMS3": "CAL-ITEMS3",
    "MSR_TYPE3": "MEASUREMENT_TYPE3",
    "LRV3": "LRV3",
    "URV3": "URV3",
    "UOM3": "UOM3",
    "TYPE3": "DESG3",
    "SQT3": "SQR3",
    "NP3": "NP3",
    "TBD3": "TBD3",
    "CAL-ITEMS4": "CAL-ITEMS4",
    "MSR_TYPE4": "MEASUREMENT_TYPE4",
    "LRV4": "LRV4",
    "URV4": "URV4",
    "UOM4": "UOM4",
    "TYPE4": "DESG4",
    "SQT4": "SQR4",
    "NP4": "NP4",
    "TBD4": "TBD4",
    "TAG-ITEMS": "TAG_TYPE1",
    "OPTION_TAG": "TAG_OPTN1",
    "ATTACH_TAG": "ATTACH1",
    "TAG-ITEMS 2": "TAG_TYPE2",
    "OPTION_TAG 2": "TAG_OPTN2",
    "ATTACH_TAG 2": "ATTACH2",
    "TAG-ITEMS 3": "TAG_TYPE3",
    "OPTION_TAG 3": "TAG_OPTN3",
    "ATTACH_TAG 3": "ATTACH3",
    "TAG-ITEMS 4": "TAG_TYPE4",
    "OPTION_TAG 4": "TAG_OPTN4",
    "ATTACH_TAG 4": "ATTACH4",
    "TAG-ITEMS 5": "TAG_TYPE5",
    "OPTION_TAG 5": "TAG_OPTN5",
    "ATTACH_TAG 5": "ATTACH5",
    "ASSOC1": "ASSOC1",
    "ASSOC2": "ASSOC2",
    "ASSOC3": "ASSOC3",
    "ASSOC4": "ASSOC4",
    "MSR_TYPE11": "MEASUREMENT_TYPE3",
    "LRV11": "LRV3",
    "URV11": "URV3",
    "UOM11": "UOM3",
    "NP11": "NP3",
    "MSR_TYPE22": "MEASUREMENT_TYPE3",
    "LRV22": "LRV3",
    "URV22": "URV3",
    "UOM22": "UOM3",
    "NP22": "NP3",
    "wuqing_special_paint": "WUQING_PAINT",
    "wuqing_paint_color": "WUQING_COLOR",
    "MANUFACTURING_NOTE1": "MFG_NOTES",
    //Added for RT_002480, RT_002898 START
    "ATTRIBUTE1": "ATTRIBUTE1",
    "ATTRIBUTE2": "ATTRIBUTE2",
    "ATTRIBUTE3": "ATTRIBUTE3",
    "ATTRIBUTE4": "ATTRIBUTE4",
    "ATTRIBUTE5": "ATTRIBUTE5",
    "ATTRIBUTE6": "ATTRIBUTE6",
    "ATTRIBUTE7": "ATTRIBUTE7",
    "ATTRIBUTE8": "ATTRIBUTE8",
    "ATTRIBUTE9": "ATTRIBUTE9",
    "ATTRIBUTE10": "ATTRIBUTE10",
    "ATTRIBUTE11": "ATTRIBUTE11",
    "ATTRIBUTE12": "ATTRIBUTE12",
    "ATTRIBUTE13": "ATTRIBUTE13",
    "ATTRIBUTE14": "ATTRIBUTE14",
    "ATTRIBUTE15": "ATTRIBUTE15",
    "ATTRIBUTE16": "ATTRIBUTE16",
    "ATTRIBUTE17": "ATTRIBUTE17", 
    "ATTRIBUTE18": "ATTRIBUTE18", 
    "ATTRIBUTE19": "ATTRIBUTE19", 
    "ATTRIBUTE20": "ATTRIBUTE20", 
    "ATTRIBUTE21": "ATTRIBUTE21", 
    "ATTRIBUTE22": "ATTRIBUTE22", 
    "ATTRIBUTE23": "ATTRIBUTE23", 
    "ATTRIBUTE24": "ATTRIBUTE24", 
    "ATTRIBUTE25": "ATTRIBUTE25", 
    "ATTRIBUTE26": "ATTRIBUTE26", 
    "ATTRIBUTE27": "ATTRIBUTE27", 
    "ATTRIBUTE28": "ATTRIBUTE28", 
    "ATTRIBUTE29": "ATTRIBUTE29", 
    "ATTRIBUTE30": "ATTRIBUTE30", 
    "ATTRIBUTE31": "ATTRIBUTE31",
    "ATTRIBUTE32": "ATTRIBUTE32",
    "ATTRIBUTE33": "ATTRIBUTE33", 
    "ATTRIBUTE34": "ATTRIBUTE34",
    "ATTRIBUTE35": "ATTRIBUTE35", 
    "ATTRIBUTE36": "ATTRIBUTE36", 
    "ATTRIBUTE37": "ATTRIBUTE37", 
    "ATTRIBUTE38": "ATTRIBUTE38", 
    "ATTRIBUTE39": "ATTRIBUTE39", 
    "ATTRIBUTE40": "ATTRIBUTE40", 
    "ATTRIBUTE41": "ATTRIBUTE41", 
    "ATTRIBUTE42": "ATTRIBUTE42", 
    "ATTRIBUTE43": "ATTRIBUTE43", 
    "ATTRIBUTE44": "ATTRIBUTE44", 
    "ATTRIBUTE45": "ATTRIBUTE45", 
    "ATTRIBUTE46": "ATTRIBUTE46", 
    "ATTRIBUTE47": "ATTRIBUTE47", 
    "ATTRIBUTE48": "ATTRIBUTE48", 
    "ATTRIBUTE49": "ATTRIBUTE49", 
    "ATTRIBUTE50": "ATTRIBUTE50", 
    "ATTRIBUTE51": "ATTRIBUTE51", 
    "ATTRIBUTE52": "ATTRIBUTE52", 
    "ATTRIBUTE53": "ATTRIBUTE53", 
    "ATTRIBUTE54": "ATTRIBUTE54", 
    "ATTRIBUTE55": "ATTRIBUTE55", 
    "ATTRIBUTE56": "ATTRIBUTE56", 
    "ATTRIBUTE57": "ATTRIBUTE57", 
    "ATTRIBUTE58": "ATTRIBUTE58", 
    "ATTRIBUTE59": "ATTRIBUTE59", 
    "ATTRIBUTE60": "ATTRIBUTE60", 
    "ATTRIBUTE61": "ATTRIBUTE61", 
    "ATTRIBUTE62": "ATTRIBUTE62", 
    "ATTRIBUTE63": "ATTRIBUTE63", 
    "ATTRIBUTE64": "ATTRIBUTE64", 
    "ATTRIBUTE65": "ATTRIBUTE65", 
    "ATTRIBUTE66": "ATTRIBUTE66", 
    "ATTRIBUTE67": "ATTRIBUTE67", 
    "ATTRIBUTE68": "ATTRIBUTE68", 
    "ATTRIBUTE69": "ATTRIBUTE69", 
    "ATTRIBUTE70": "ATTRIBUTE70", 
    "ATTRIBUTE71": "ATTRIBUTE71", 
    "ATTRIBUTE72": "ATTRIBUTE72", 
    "ATTRIBUTE73": "ATTRIBUTE73", 
    "ATTRIBUTE74": "ATTRIBUTE74", 
    "ATTRIBUTE75": "ATTRIBUTE75"
    //Added for RT_002480, RT_002898 END
};

export const InputPayload = {
    "HOST_HEADER_ID": null,
    "SEQUENCE_NUMBER": null,
    "LINE_NUMBER": null,
    "APPLICATION_CODE": null,
    "BASE_MODEL": null,
    "QUANTITY_REF": null,
    "UNIT_NUMBER": null,
    "DIVISION": null,
    "CALIBRATION_TYPE": null,
    "CALIBRATION_UNITS": null,
    "DENSITY_UNITS": null,
    "SPL_BASE_FLOW_UNITS": null,
    "SPL_BASE_TIME_UNITS": null,
    "CALIBRATION_INPUT_TYPE": null,
    "CONVERSION": null,
    "TOTALIZER_TEXT": null,
    "SPL_UNITS": null,
    "DENSITY_VALUE": null,
    "DESIRED_FLOW_RATE": null,
    "TAG_NUMBER": null,
    "TAG_TYPE": null,
    "LINE_1": null,
    "LINE_2": null,
    "LINE_3": null,
    "LINE_4": null,
    "LINE_5": null,
    "TAG_OPTN": null,
    "ATTACH": null,
    "MEASUREMENT_TYPE": null,
    "LRV": null,
    "URV": null,
    "UOM": null,
    "DESG": null,
    "ASSOC": null,
    "TBD": null,
    "SQR": null,
    "NP": null,
    "MFG_NOTES": null,
    "JOIN_KEY": null,
    "NETWORK_ID": null,
    "TAG_REQUIRED": null,
    "SS_TAG_TYPE": null,
    "FP1": null,
    "FP2": null,
    "FP3": null,
    "FP4": null,
    "FP5": null,
    "FP6": null,
    "FP7": null,
    "FP8": null,
    "FP9": null,
    "FP10": null,
    "FP11": null,
    "FP12": null,
    "FP13": null,
    "FP14": null,
    "FP15": null,
    "FP16": null,
    "FP17": null,
    "FP18": null,
    "FP19": null,
    "FP20": null,
    "PNTS1": null,
    "PNTS2": null,
    "PNTS3": null,
    "PNTS4": null,
    "PNTS5": null,
    "PNTS6": null,
    "PNTS7": null,
    "PNTS8": null,
    "PNTS9": null,
    "PNTS10": null,
    "PNTS11": null,
    "PNTS12": null,
    "PNTS13": null,
    "PNTS14": null,
    "PNTS15": null,
    "PNTS16": null,
    "PNTS17": null,
    "PNTS18": null,
    "PNTS19": null,
    "PNTS20": null,
    "TAG1": null,
    "TAG2": null,
    "TAG3": null,
    "TAG4": null,
    "TAG5": null,
    "TAG6": null,
    "TAG7": null,
    "TAG8": null,
    "TAG9": null,
    "TAG10": null,
    "TAG11": null,
    "TAG12": null,
    "TAG13": null,
    "TAG14": null,
    "TAG15": null,
    "TAG16": null,
    "TAG17": null,
    "TAG18": null,
    "TAG19": null,
    "TAG20": null,
    "TAG21": null,
    "TAG22": null,
    "TAG23": null,
    "TAG24": null,
    "TAG25": null,
    "TAG26": null,
    "TAG27": null,
    "TAG28": null,
    "TAG29": null,
    "TAG30": null,
    "TAG31": null,
    "TAG32": null,
    "TAG33": null,
    "TAG34": null,
    "TAG35": null,
    "TAG36": null,
    "TAG37": null,
    "TAG38": null,
    "TAG39": null,
    "TAG40": null,
    "TAG41": null,
    "TAG42": null,
    "TAG43": null,
    "TAG44": null,
    "TAG45": null,
    "TAG46": null,
    "TAG47": null,
    "TAG48": null,
    "TAG49": null,
    "TAG50": null,
    "CREATED_BY": null,
    "CREATION_DATE": null,
    "LAST_UPDATED_BY": null,
    "LAST_UPDATE_DATE": null,
    "ATTRIBUTE1": null,
    "ATTRIBUTE2": null,
    "ATTRIBUTE3": null,
    "ATTRIBUTE4": null,
    "ATTRIBUTE5": null,
    "ATTRIBUTE6": null,
    "ATTRIBUTE7": null,
    "ATTRIBUTE8": null,
    "ATTRIBUTE9": null,
    "ATTRIBUTE10": null,
    "ATTRIBUTE11": null,
    "ATTRIBUTE12": null,
    "ATTRIBUTE13": null,
    "ATTRIBUTE14": null,
    "ATTRIBUTE15": null,
    "ATTRIBUTE16": null, //Added for RT_002480, RT_002898 
    "ATTRIBUTE17": null, //Added for RT_002480, RT_002898 
    "ATTRIBUTE18": null, //Added for RT_002480, RT_002898 
    "ATTRIBUTE19": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE20": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE21": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE22": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE23": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE24": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE25": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE26": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE27": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE28": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE29": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE30": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE31": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE32": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE33": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE34": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE35": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE36": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE37": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE38": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE39": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE40": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE41": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE42": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE43": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE44": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE45": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE46": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE47": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE48": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE49": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE50": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE51": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE52": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE53": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE54": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE55": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE56": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE57": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE58": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE59": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE60": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE61": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE62": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE63": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE64": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE65": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE66": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE67": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE68": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE69": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE70": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE71": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE72": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE73": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE74": null, //Added for RT_002480, RT_002898
    "ATTRIBUTE75": null, //Added for RT_002480, RT_002898
    "DPTAG1": null,
    "DPTAG2": null,
    "DPTAG3": null,
    "DPTAG4": null,
    "DPTAG5": null,
    "DPTAG6": null,
    "DPTAG7": null,
    "DPTAG8": null,
    "DPTAG9": null,
    "DPTAG10": null,
    "DPTAG11": null,
    "DPTAG12": null,
    "DPTAG13": null,
    "DPTAG14": null,
    "DPTAG15": null,
    "DPTAG16": null,
    "DPTAG17": null,
    "DPTAG18": null,
    "DPTAG19": null,
    "DPTAG20": null,
    "DPTAG21": null,
    "DPTAG22": null,
    "DPTAG23": null,
    "DPTAG24": null,
    "DPTAG25": null,
    "DPTAG26": null,
    "DPTAG27": null,
    "DPTAG28": null,
    "DPTAG29": null,
    "DPTAG30": null,
    "DPTAG31": null,
    "DPTAG32": null,
    "DPTAG33": null,
    "DPTAG34": null,
    "DPTAG35": null,
    "DPTAG36": null,
    "DPTAG37": null,
    "DPTAG38": null,
    "DPTAG39": null,
    "DPTAG40": null,
    "DPTAG41": null,
    "DPTAG42": null,
    "DPTAG43": null,
    "DPTAG44": null,
    "DPTAG45": null,
    "DPTAG46": null,
    "DPTAG47": null,
    "DPTAG48": null,
    "DPTAG49": null,
    "DPTAG50": null,
    "CDS": null,
    "TAG_NBR": null,
    "VALVE_MOUNTING_KIT": null,
    "SPOOL_TYPE_BRACKET": null,
    "VALVE_MOUNTING_KIT_MATERIAL": null,
    "PAINT_NAME": null,
    "SPL_PAINT_OPTION": null,
    "PAINT_COLOR": null,
    "PAINT_SPECIFICATION": null,
    "SIZE_MANUAL_OVERIDE": null,
    "VENT_VALVE": null,
    "VALVE_Y_N": null,
    "VALVE_SUPPLY": null,
    "VALVE_SIZE": null,
    "TESTING": null,
    "DOCUMENTATION_LEVEL": null,
    "CDS_DEFAULT_TYPE": null,
    "WITNESS_TEST": null,
    "CONTROL_REQUIRED": null,
    "TEST_PRESSURE_NOTES": null,
    "SUBMODEL_NUMBER": null,
    "SELECT_HANDLE_KIT": null,
    "SELECT_HANDLE_KIT_DEC": null,
    "SELECT_GEAR_KIT": null,
    "SELECT_GEAR_KIT_DEC": null,
    "SELECT_ACTUATOR_PNEUMATIC": null,
    "SELECT_ACTUATOR_PNEUMATIC_DEC": null,
    "SELECT_MOUNTING_1": null,
    "SELECT_MOUNTING_1_DEC": null,
    "SELECT_MOUNTING_2": null,
    "SELECT_MOUNTING_2_DEC": null,
    "SELECT_MOUNTING_3": null,
    "SELECT_MOUNTING_3_DEC": null,
    "TUBE_NAME_TAG_01_LINE_1": null,
    "TUBE_NAME_TAG_02_LINE_1": null,
    "TUBE_NAME_TAG_03_LINE_1": null,
    "TUBE_NAME_TAG_04_LINE_1": null,
    "TUBE_NAME_TAG_05_LINE_1": null,
    "XMTR_NAME_TAG_01_LINE_1": null,
    "XMTR_NAME_TAG_02_LINE_1": null,
    "XMTR_NAME_TAG_03_LINE_1": null,
    "XMTR_NAME_TAG_04_LINE_1": null,
    "XMTR_NAME_TAG_05_LINE_1": null,
    "PAINT_TYPE_1_COAT": null,
    "PAINT_TYPE_2_COAT": null,
    "PAINT_TYPE_3_COAT": null,
    "PAINT_THICKNESS_1_COAT": null,
    "PAINT_THICKNESS_2_COAT": null,
    "PAINT_THICKNESS_3_COAT": null,
    "PAINT_COLOR_1_COAT": null,
    "PAINT_COLOR_2_COAT": null,
    "PAINT_COLOR_3_COAT": null,
    "MIN_AMB_TEMP": null,
    "MAX_AMB_TEMP": null,
    "AMB_UNITS": null,
    "PRSS_FLD_UNITS": null,
    "MIN_PRSS_TEMP": null,
    "MAX_PRSS_TEMP": null,
    "AMB_TEMP_VER": null,
    "PRSS_TEMP_VER": null,
    "FLOW_UNITS": null,
    "VAR_DAMPNG": null,
    "LINE_SIZE_UNIT": null,
    "FLUID_STATE": null,
    "MIN_FLOW_RATE": null,
    "MAX_FLOW_RATE": null,
    "OPRTING_DNSTY": null,
    "OPRTING_PRESS": null,
    "OPRTING_TEMP": null,
    "DEF_OPT": null,
    "PRCSS_TEMP_UNIT": null,
    "ELE_TEMP_UNIT": null,
    "NP_LINE_SIZE": null,
    "PIPE_UNIT": null,
    "SCHD_SELCT": null,
    "FLW_DAMPNG": null,
    "TEMP_DAMPNG": null,
    "BSE_DEN_UNT": null,
    "OPR_PRESS_UNT": null,
    "BSE_PRSS_UNT": null,
    "BSE_TMP_UNT": null,
    "SPECIFIC_GRAVITY": null,
    "FLOW_UNITS_PA": null,
    "OPERATING_PRESS_UNIT": null,
    "OPERATING_TEMP_UNIT": null,
    "OPR_VIS_UNT": null,
    "CUSTOM_FLUID": null,
    "DEN_RTO": null,
    "BSE_DEN": null,
    "BSE_PRSS": null,
    "BSE_TEMP": null,
    "OPR_COMR": null,
    "OPR_VIS": null,
    "MOL_WEGHT": null,
    "VAP_PRSS": null,
    "APPLICATION_TYPE": null,
    "CHANNEL_A_LRV": null,
    "CHANNEL_B_LRV": null,
    "CHANNEL_C_LRV": null,
    "CHANNEL_D_LRV": null,
    "CHANNEL_A_URV": null,
    "CHANNEL_B_URV": null,
    "CHANNEL_C_URV": null,
    "CHANNEL_D_URV": null,
    "FLUID": null,
    "WUQING_PAINT": null,
    "WUQING_COLOR": null,
    "PRIMARY_VAR": null,
    "SECON_VAR": null,
    "TERITARY_VAR": null,
    "QUATER_VAR": null,
    "FLOWVAL_BASIC": null,
    "MASS_LRV": null,
    "MASS_URV": null,
    "VOL_LRV": null,
    "VOL_URV": null,
    "FLOW_LRV": null,
    "FLOW_URV": null,
    "MASSUNITS": null,
    "VOLUNITS": null,
    "FLOWVALUNITS": null,
    "BASE_COMPRESSIBILITY": null,
    "VAPOR_PRESSURE_UNITS": null

}

export const RMTInputPayload = {
    "HOST_HEADER_ID": null,
    "SEQUENCE_NUMBER": null,
    "LINE_NUMBER": null,
    "APPLICATION_CODE": null,
    "BASE_MODEL": null,
    "UNIT_NUMBER": null,
    "TAG_TYPE1": null,
    "TAG_TYPE2": null,
    "TAG_TYPE3": null,
    "TAG_TYPE4": null,
    "TAG_TYPE5": null,
    "TAG_TYPE6": null,
    // Closure function to generate data as above with rules
    ...((size, keyPrefix) => {
        return new Array(size).fill(0).reduce((o, x, i) => {
            o[`${keyPrefix}${i + 1}`] = null;
            return o;
        }, {})
    })(30, 'LINE_'), // size
    "TAG_OPTN1": null,
    "TAG_OPTN2": null,
    "TAG_OPTN3": null,
    "TAG_OPTN4": null,
    "TAG_OPTN5": null,
    "TAG_OPTN6": null,
    "ATTACH1": null,
    "ATTACH2": null,
    "ATTACH3": null,
    "ATTACH4": null,
    "ATTACH5": null,
    "ATTACH6": null,
    "MEASUREMENT_TYPE1": null,
    "MEASUREMENT_TYPE2": null,
    "MEASUREMENT_TYPE3": null,
    "MEASUREMENT_TYPE4": null,
    "LRV1": null,
    "LRV2": null,
    "LRV3": null,
    "LRV4": null,
    "URV1": null,
    "URV2": null,
    "URV3": null,
    "URV4": null,
    "UOM1": null,
    "UOM2": null,
    "UOM3": null,
    "UOM4": null,
    "DESG1": null,
    "DESG2": null,
    "DESG3": null,
    "DESG4": null,
    "ASSOC1": null,
    "ASSOC2": null,
    "ASSOC3": null,
    "ASSOC4": null,
    "TBD1": null,
    "TBD2": null,
    "TBD3": null,
    "TBD4": null,
    "SQR1": null,
    "SQR2": null,
    "SQR3": null,
    "SQR4": null,
    "NP1": null,
    "NP2": null,
    "NP3": null,
    "NP4": null,
    "MFG_NOTES": null,
    "JOIN_KEY": null,
    "NETWORK_ID": null,
    "SUBMODEL_NUMBER": null
}

export const reverseFieldMapping = Object.entries(fieldMapping).reduce((o, [key, value]) => {
    o[value] = key;
    return o;
}, {})