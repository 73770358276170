import { connect } from 'react-redux';
import ModelRow from './ModelRow';
import {
    getModelName, getWareHouse, getModifier, getLocation, getInline,
    getUrd, clearCurrentModelName, clearUrdDataOnChange, additionalInfo, getRmtUrd
} from '../../../store/model-data';
import {
    prepareValidData, prepareUrlData, getCurrentModelName, prepareLocationStatus,
    prepareEnteredModelData, getDivisionCode, prepareModifier
} from '../../../utils/helper';

// ===== Map state received from reducer to props  =====
const mapStateToProps = (state, ownProps) => {
    let row = state.modalDataStore.modalData.rows.find(row => row.id === ownProps.id);
    return {
        modelStrings: prepareModelStrings(state.modalDataStore), // prepare model string
        modelName: getCurrentModelName(ownProps.row), // get the current model name 
        locationStatus: prepareLocationStatus(state.modalDataStore), // get the location status
        warehouses: prepareWarehouses(state.modalDataStore), // prepare the warehouse
        modifierData: prepareModifier(state.modalDataStore), // prepare the modifier
        validationData: prepareValidData(state.modalDataStore), // prepared data for validation
        userlocations: prepareUserLocation(state.modalDataStore), // prepare the user location
        onSaveError: state.modalDataStore.saveErrors, // prepare save errors 
        urlDetails: prepareUrlData(state.userStore), // prepare the URL data 
        URDDataOnExpand: state.modalDataStore.urdDetails, // get the URD Processed data 
        urdDetails: row ? row.urdDetails : [], // get the urd details
        quantityChanged: state.modalDataStore.quantity, // updates the props on quantity change
        enteredModelData: prepareEnteredModelData(state.orderStore), // prepared entered model data 
        divCode: getDivisionCode(state.modalDataStore) || "", // get the divcode
        data: state.modalDataStore.modalData,// get the model data
        status: state.modalDataStore.status, // get the status 
    };
};


// ===== Prepare the model string =====
const prepareModelStrings = data => {
    let result = [];
    if (Object.keys(data).length > 0) {
        try {
            result = data.modelString.OutputParameters.O_MODEL_STRING.O_MODEL_STRING_ITEM
                .map(i => i.MODEL_STRING)
        }
        catch { }
    }
    return result;
}

// ===== Prepare the warehouse details =====
const prepareWarehouses = data => {
    let result = [];
    let alphabeticData = [];
    if (Object.keys(data).length > 0) {
        try {
            let arrData = data.wareHouse.OutputParameters.O_WAREHOUSE.O_WAREHOUSE_ITEM;
            if (!Array.isArray(arrData)) {
                arrData = [arrData]
            }
            alphabeticData = arrData.sort(compare);
            result = alphabeticData.map(i => ({ label: i.WAREHOUSE, value: i.WAREHOUSE, operator: i.ARITHMETIC_OPERATOR }));
        }
        catch { }
    }
    return result;
}

// ===== Prepare the Alpabetic Order for Warehouse =====
function compare(a, b) {
    const wareHouseA = a.WAREHOUSE.toUpperCase();
    const wareHouseB = b.WAREHOUSE.toUpperCase();
    let comparison = 0;
    if (wareHouseA > wareHouseB) {
        comparison = 1;
    } else if (wareHouseA < wareHouseB) {
        comparison = -1;
    }
    return comparison;
}

// ===== Prepare the end user location =====
const prepareUserLocation = data => {
    let locationResult = [];
    if (Object.keys(data).length > 0) {
        try {
            locationResult = data.userLocation.OutputParameters.O_END_USER_LOCATION.O_END_USER_LOCATION_ITEM
                .map(item => ({ label: item, value: item, }));
        }
        catch { }
    }
    return locationResult;
}

// ===== Mapping dispatch functions to the props  =====
const mapDispatchToProps = {
    GetModelNameData: payload => getModelName(payload), // dispatching the model names 
    MyWareHouseData: payload => getWareHouse(payload), // dispatching the warehouse data
    MyGetModifierData: payload => getModifier(payload), // dispatching the modifier data
    UserLocationData: payload => getLocation(payload), // dispatching location
    GetInlineValidation: payload => getInline(payload), // dispatching the inline validation payload
    URDData: payload => getUrd(payload), // dispatching the URD data 
    clearModelName: payload => clearCurrentModelName(payload), // dispatching to clear the current model name
    clearURDData: payload => clearUrdDataOnChange(payload), // dispatching to clear the urd data on change
    getAdditionalInfoData: payload => additionalInfo(payload), // dispatching the additionla info
    getRmtUrd: payload => getRmtUrd(payload) // dispatching to get the RMT urd 
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelRow); //The connect() function connects a React component ModelRow to a Redux store(modalDataStore).

