import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { AppHome } from './container';
import Loader from './components/loader/loader';

const Routes = () => (
    <Router>
        <>
            <Loader />
            <Route exact path="/" component={AppHome} />
        </>
    </Router>
)

export default Routes;