// ...
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import sagas from './rootSagas';
import reducers from './rootReducer';

const middleWare = [];
const sagaMiddleware = createSagaMiddleware();
middleWare.push(sagaMiddleware)
const logger = createLogger({
  predicate: () => process.env.NODE_ENV === 'development',
});
middleWare.push(logger)
const store = createStore(
  reducers,
  compose( applyMiddleware(...middleWare))
);
sagaMiddleware.run(sagas);
export {
  store,
};