import React from 'react';
import ModelRow from '../model-row';
import PropTypes from 'prop-types';

let o_seqStart = null;
const ModelContent = ({ data, errors, onChange, onAdd, onRemove,
    warehouses, modelStrings, onModelStringSelect, seqStart, location, hasAction, 
    maxModelCount, toggleStatusPopup, baseModel, onFindModelNames, findWarehouses,exit,
     validationInProgress }) => { o_seqStart = validationInProgress?o_seqStart:seqStart;
    return (

        // Model row data passed.
        <tbody>
            {
                data.map((row, i) =>
                    <ModelRow seqStart={o_seqStart} lineNo={i+1} key={row.id} id={row.id} row={row} 
                        errors={errors[row.id] ? errors[row.id] : {}}
                        onRowChange={onChange} onRowAdd={onAdd} onRowRemove={onRemove}
                        warehouses={warehouses} modelStrings={modelStrings}
                        informModelStringSelect={onModelStringSelect}
                        location ={location}
                        hasAction ={hasAction} 
                        allowAdd={data.length-1 < maxModelCount}
                        toggleStatusPopup={toggleStatusPopup} 
                        baseModel={baseModel}
                        findWarehouses={findWarehouses}
                        onFindModelNames={onFindModelNames}
                        exit= {exit}/>
                    
                )
            }
        </tbody>
    );
}

// ===== To declare that a prop is a specific JS primitive. ===== 
ModelContent.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    errors: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    warehouses: PropTypes.arrayOf(PropTypes.object).isRequired,
    modelStrings: PropTypes.arrayOf(PropTypes.object).isRequired,
    onModelStringSelect: PropTypes.func.isRequired,
    maxModelCount: PropTypes.number.isRequired,
    toggleStatusPopup: PropTypes.func.isRequired,
    baseModel: PropTypes.string.isRequired,
    onFindModelNames: PropTypes.func.isRequired,
}

export default ModelContent;