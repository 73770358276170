import React from 'react';
import SelectInput from '../../shared/SelectInput';
import { getURLDetails } from "../../../utils/methods";
import TextInput from '../../shared/TextInput';

const PriceRollup = ({ data, assembleToData, priceRollDropdownData, onClose, onSave, onPriceDataChange, countMap }) => {

    return (
        <div class="modal mass-adj-modal" id="myModal">
            <div class="modal-dialog mass-adj-modal-dialog">
                <div class="modal-content">
                    {/* Modal Header */}
                    <div className="modal-header mass-adj-header">
                        <h4 className="modal-title">Price Rollup</h4>
                        <button
                            type="button"
                            onClick={onClose}
                            className="close mass-adj-close">&times;</button>
                    </div>

                    {/* Modal body */}
                    <div class="modal-body">
                        <div className="col-md-12">
                            <table className="w-100 modelData-tbl table-body-scroll">
                                <thead>
                                    <tr>
                                        <th>Line Number</th>
                                        <th>Model Name</th>
                                        <th>Assemble To</th>
                                        <th>Price Rollup</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data && data.map((row, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td className="text-center">{row.LINE_NUMBER}</td>
                                                    <td className="text-center">{row.MODEL_NAME}</td>
                                                    <td className="text-center">
                                                        {getURLDetails().I_APP_CODE === "ONT" ?
                                                            <TextInput name="ASSEMBLE_TO" value={row.ASSEMBLE_TO}
                                                                type="number" placeholder="Assemble To" min={1} max={100}
                                                                disabled={countMap[row.LINE_NUMBER] > 1 && row.MODEL_STRING !== null}
                                                                onChange={e => onPriceDataChange(e, row.LINE_NUMBER)}
                                                            /> :
                                                            <SelectInput
                                                                name="ASSEMBLE_TO"
                                                                disabled={countMap[row.LINE_NUMBER] > 1 && row.MODEL_STRING !== null}
                                                                value={row.ASSEMBLE_TO}
                                                                defaultOption={row.ASSEMBLE_TO !== null ? row.ASSEMBLE_TO : "--Select Assemble To--"}
                                                                options={assembleToData}
                                                                onChange={(e) => onPriceDataChange(e, row.LINE_NUMBER)}
                                                            />
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        <SelectInput
                                                            name="PRICE_ROLLUP"
                                                            value={row.PRICE_ROLLUP}
                                                            defaultOption="--Select Price Rollup--"
                                                            options={priceRollDropdownData}
                                                            disabled={countMap[row.LINE_NUMBER] > 1 && row.MODEL_STRING !== null}
                                                            onChange={(e) => onPriceDataChange(e, row.LINE_NUMBER)}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* Modal footer */}
                    <div className="modal-footer mass-adj-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={onSave}>Save</button>
                        <button
                            type="button"
                            className="btn btn-danger"
                            data-dismiss="modal"
                            onClick={onClose}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PriceRollup;