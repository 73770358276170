import React from 'react';

const ApplyHoldsHeader = () => {
    return (
        <thead>
            <tr>
                <th>Hold Name </th>
                <th>Hold Type</th>
                <th>Description</th>
                <th>Hold Until Date</th>
                <th>Hold Comments</th>
                <th>Action</th>
            </tr>
        </thead>
    );
}

export default ApplyHoldsHeader;