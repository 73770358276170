
const getURLDetails = () => {
    const localStorageUrlData = sessionStorage.getItem('urldata');
    let urlData = {};
    try {
        if (localStorageUrlData) {
            urlData = JSON.parse(JSON.parse(localStorageUrlData).urlData);
        }
    } catch {}
    
    return urlData;
}

const getUserDetails = () => {

    const localStoreUser = sessionStorage.getItem('persist:root');
    console.log('localStoreUser',  localStoreUser)
    let userDetails = {};
    try {
        if (localStoreUser) {
            userDetails = JSON.parse(JSON.parse(localStoreUser).user).OutputParameters.O_USER_DETAILS.O_USER_DETAILS_ITEM;
            let l_userDetails = JSON.stringify(userDetails);
            console.log('String data of user data', l_userDetails)
            if (l_userDetails.includes('[')) {
                userDetails = JSON.parse(JSON.parse(localStoreUser).user).OutputParameters.O_USER_DETAILS.O_USER_DETAILS_ITEM[0];
            } else {
                userDetails = JSON.parse(JSON.parse(localStoreUser).user).OutputParameters.O_USER_DETAILS.O_USER_DETAILS_ITEM;
            }
            // let l_userDetails = (localStoreUser.user).OutputParameters.O_USER_DETAILS.O_USER_DETAILS_ITEM;
            // console.log('user details begining', l_userDetails)
            // l_userDetails.replace("[", "");
            // l_userDetails.replace("]", "");
            // console.log('user details final', l_userDetails)
            // userDetails = JSON.parse(l_userDetails);
            console.log('Final User data ', userDetails)
        }
    } catch {
        console.log('Exception ', console.trace())

    }    return userDetails;
}

export const getCurrentAndPastDates = () => {
    var tempDate = new Date();
    var currentDate = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ';
    var pastDate = new Date();
    var numberOfDaysToAdd = 60;
    pastDate.setDate(pastDate.getDate() - numberOfDaysToAdd);
    var dd = pastDate.getDate();
    var mm = pastDate.getMonth() + 1;
    var yy = pastDate.getFullYear();
    var FormattedDate = yy + '-' + mm + '-' + dd;
    let dates = {
        currentDate,
        FormattedDate
    }
    return dates;
}

export {
    getURLDetails,
    getUserDetails,
}


