import { connect } from 'react-redux';
import QuoteTable from './QuoteTable';
import { getCopyLines, getQuoteDetails } from '../../store/order';

// ===== Map state received from reducer to props  =====
const mapStateToProps = (state) => {
    return {
        quoteTableData: prepareQuoteData(state.orderStore), // prepare quote data 
        quoteDetailsData: state.orderStore.quoteDetails, // prepare quote details
    }
};

// Funtion to prepare the Quote data 
const prepareQuoteData = data => {
    let result = [];
    if (Object.keys(data).length > 0) {
        try {
            let ResponseType = data.quoteData.OutputParameters.O_GS_QTE_HDR_REC.O_GS_QTE_HDR_REC_ITEM;
            if (Array.isArray(ResponseType) === false) {
                result = [data.quoteData.OutputParameters.O_GS_QTE_HDR_REC.O_GS_QTE_HDR_REC_ITEM]
            } else {
                result = data.quoteData.OutputParameters.O_GS_QTE_HDR_REC.O_GS_QTE_HDR_REC_ITEM
            }
        }
        catch { }
    }
    return result;
}

// ===== Mapping dispatch functions to the props  =====
const mapDispatchToProps = {
    getQuoteLines: payload => getQuoteDetails(payload), // dispatch the quote details
    CopyLinesData: payload => getCopyLines(payload) // dispatch copy lines  
};

export default connect(mapStateToProps, mapDispatchToProps)(QuoteTable); //The connect() function connects a React component QuoteTable to a Redux store(modalDataStore).

