import React, { Component } from 'react';
import Aux from '../../shared/AuxContainer';
import TextInput from '../../shared/TextInput';
import SelectInput from '../../shared/SelectInput';
import PropTypes from 'prop-types';
import IntegerInput from '../../shared/IntegerInput';

class LineNotesRow extends Component {

    render() {
        const { onRowAdd, onRowRemove, id, seedData, data } = this.props; // passing props to render
        return (
            <tbody>
                <Aux>
                    <tr>
                        <td className="position-relative">
                            <i className="fa fa-asterisk text-danger required-icon seqNo" aria-hidden="true" />
                            <IntegerInput name="lineNoSeq" value={data.lineNoSeq} type="number"
                                placeholder="Sequence No." onChange={e => this.onChangeData(e, id)}
                                onkeypressInteger={this.onSequenceNoInteger} min ={1} max={100}
                            />
                        </td>
                        <td>
                            <SelectInput name="lineCategory" value={data.lineCategory} options={seedData[0].lineNotesCategoryData}
                                defaultOption="--Select Category--" onChange={e => this.onChangeData(e, id)}
                            />
                        </td>
                        <td>
                            <TextInput name="lineNotesTitle" value={data.lineNotesTitle} type="text" placeholder="Title" onChange={e => this.onChangeData(e, id)} />
                        </td>
                        <td>
                            <TextInput name="lineNotesDescription" value={data.lineNotesDescription} type="text" placeholder="Description"
                                onChange={e => this.onChangeData(e, id)} />
                        </td>
                        <td>
                            <SelectInput name="lineNotesDataType" value={data.lineNotesDataType} options={seedData[0].lineNotesDataTypeData} defaultOption=" --Select Data Type--"
                                onChange={e => this.onChangeData(e, id)} />
                        </td>

                        <td className="text-center">
                            {
                                id === 0 && (<button onClick={onRowAdd}
                                    className="btn btn-primary minWidth-auto border-0 p-0 add-btn"
                                    title="Add Line Note">
                                    <i className="fa fa-plus-circle"></i>
                                </button>)
                            }
                            {
                                id !== 0 && (<button onClick={() => onRowRemove(id)} className="btn btn-primary minWidth-auto border-0 p-0 delete-btn" title="Delete Line Note">
                                    <i className="fa fa-trash-o" aria-hidden="true"></i>
                                </button>)
                            }
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={6}>
                            <textarea placeholder="Comments" rows="4" cols="70"
                                name="lineNotesComments" value={data.lineNotesComments} onChange={e => this.onChangeData(e, id)}>
                            </textarea>
                        </td>
                    </tr>
                </Aux>
            </tbody>
        )
    }

    // ===== On Change Data =====
    onChangeData = (e, id) => {
        const value = e.target.value;
        const name = e.target.name;
        this.updateRowsContent(id, name, value);
    };

    // ===== Function to update the row data =====
    updateRowsContent = (id, name, value) => {
        let initialRowLineNotes = Object.assign(
            {},
            JSON.parse(JSON.stringify(this.props.data))
        );
        initialRowLineNotes[name] = value;
        if (name === "lineCategory") {
            this.props.updateCategoryName({ id, initialRowLineNotes });
        } else {
            this.props.updateLineNotesRow({ id, initialRowLineNotes });
        }
    };

    // ===== Function called when OnKeypress event is triggered for interger input=====
    onSequenceNoInteger = (event) => {
        var charCode = (event.which) ? event.which : event.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault()
            return false;
        }
        return true;
    }
}

// ===== To declare that a prop is a specific JS primitive. ===== 
LineNotesRow.propTypes = {
    onRowAdd: PropTypes.func.isRequired,
    onRowRemove: PropTypes.func.isRequired,
}

export default LineNotesRow;