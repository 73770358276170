import { takeLatest, call, put,delay } from "redux-saga/effects"; //delay added for RT_002332 by sudhakar to show loader for the RFC AGL-ORA000231
import {
  GET_MODELSTRING,
  GET_MODELSTRING_SUCCESS,
  GET_MODELSTRING_ERROR,
  GET_MODELNAME,
  GET_MODELNAME_SUCCESS,
  GET_MODELNAME_ERROR,
  GET_WAREHOUSE,
  GET_WAREHOUSE_SUCCESS,
  GET_WAREHOUSE_ERROR,
  GET_MODIFIERS,
  GET_MODIFIERS_SUCCESS,
  GET_MODIFIERS_ERROR,
  GET_INLINE_VALIDATION,
  GET_INLINE_VALIDATION_SUCCESS,
  GET_INLINE_VALIDATION_ERROR,
  GET_INSERT_DATA,
  GET_INSERT_DATA_SUCCESS,
  GET_INSERT_DATA_ERROR,
  GET_URD_DATA,
  GET_URD_DATA_SUCCESS,
  GET_URD_DATA_ERROR,
  GET_USER_LOCATION,
  GET_USER_LOCATION_SUCCESS,
  GET_USER_LOCATION_ERROR,
  CLEAR_SAVE_ERROR,
  CLEAR_SAVE_ERROR_SUCCESS,
  GET_EDIT_DATA,
  GET_EDIT_DATA_SUCCESS,
  GET_EDIT_DATA_ERROR,
  ADD_NEW_ROW,
  ADD_NEW_ROW_SUCCESS,
  ADD_NEW_ROW_ERROR,
  TASK_NUMBER,
  TASK_NUMBER_SUCCESS,
  TASK_NUMBER_ERROR,
  ADDITIONAL_INFO,
  ADDITIONAL_INFO_SUCCESS,
  ADDITIONAL_INFO_ERROR,
  GET_APPLY_HOLDS,
  GET_APPLY_HOLDS_SUCCESS,
  GET_APPLY_HOLDS_ERROR,
  LINE_PRICE,
  LINE_PRICE_SUCCESS,
  LINE_PRICE_ERROR,
  GET_RMT_URD_DATA, GET_RMT_URD_DATA_SUCCESS, GET_RMT_URD_DATA_ERROR,
  UPDATE_INITIAL_DATA,
  IMPORT_EXCEL_DATA,
  IMPORT_EXCEL_DATA_SUCCESS,
  IMPORT_EXCEL_DATA_ERROR,
  IMPORT_PASTELIST_SUCCESS,
  IMPORT_PASTELIST_ERROR,
  IMPORT_PASTELIST,
  EMAIL_NOTIFICATION_SUCCESS,
  EMAIL_NOTIFICATION,
  EMAIL_NOTIFICATION_ERROR,
  GET_PRICE_ROLLUP_DATA_ERROR,
  GET_PRICE_ROLLUP_DATA,
  GET_PRICE_ROLLUP_DATA_SUCCESS,
  GET_ASSEMBLETO_SUCCESS,
  GET_ASSEMBLETO_ERROR,
  GET_ASSEMBLETO,
  //RT_002332 sudhakar(+)
  COPY_MFG_NOTES,
  COPY_MFG_NOTES_SUCCESS,
  COPY_TAG_DTLS,
  COPY_TAG_DTLS_SUCCESS,
  COPY_CALIB_DTLS,
  COPY_CALIB_DTLS_SUCCESS
   //RT_002332 sudhakar(-)
} from "./constant";
import { GET_MY_ENTERED_MODELDATA } from "../order/constant";
import { GET_ERRORMSG } from "../error-store/constant";
import {
  getModelStringAPI,
  getModelNameAPI,
  getWareHouse,
  getModiferAPI,
  getInlineValidationAPI,
  getInsertDataAPI,
  updateEnteredModelData,
  getUserLocationAPI,
  getUrdInfoAPI,
  EditDataAPI,
  EditUrdDataAPI,
  getTaskNumAPI,
  getAdditionalInfoAPI,
  getlinePriceAPI,
  getUrdRMTAPI,
  getPriceRollUpDataAPI,
  getAssembleToDataAPI
} from "./api";
import { prepareErrorObject } from "../error-store/helper";
// import modelDataApi from "../../api/mockModelData";

/**
 * action Type of GET_SUBMIT_DATA
 * @param {Object} action
 */

function* ModelStringSaga(action) {
  try {
    const data = yield call(getModelStringAPI, action.payload);
    // const data = yield call(modelDataApi.getModelString, action.payload);
    yield put({ type: GET_MODELSTRING_SUCCESS, data });
    yield put({ type: GET_ERRORMSG, data });
  } catch (error) {
    let data = prepareErrorObject(error.response.data.ISGServiceFault, GET_MODELSTRING_ERROR);
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: GET_MODELSTRING_ERROR, error });
  }
}

function* ModelNameSaga(action) {
  try {
    const { id, I_MODEL_DETAILS, I_PARENT_FLAG } = action.payload;
    const response = yield call(getModelNameAPI, { id, I_MODEL_DETAILS, I_PARENT_FLAG });
    let data = { ...response, id };
    //const data = yield call(getModelNameAPI, action.payload);

    // const data = yield call(modelDataApi.getModelName, action.payload);
    //data = {...data, id};
    yield put({ type: GET_MODELNAME_SUCCESS, data });
    yield put({ type: GET_ERRORMSG, data });

  } catch (error) {
    let data = prepareErrorObject(error, GET_MODELNAME_ERROR);
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: GET_MODELNAME_ERROR, error });
  }
}

function* WareHouseSaga(action) {
  try {
    const data = yield call(getWareHouse, action.payload);
    yield put({ type: GET_WAREHOUSE_SUCCESS, data });
    yield put({ type: GET_ERRORMSG, data });
  } catch (error) {
    let data = prepareErrorObject(error, GET_WAREHOUSE_ERROR );
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: GET_WAREHOUSE_ERROR, error });
  }
}

function* ModifierSaga(action) {
  try {
    const data = yield call(getModiferAPI, action.payload);
    yield put({ type: GET_MODIFIERS_SUCCESS, data });
    yield put({ type: GET_ERRORMSG, data });
  } catch (error) {
    let data = prepareErrorObject(error, GET_MODIFIERS_ERROR );
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: GET_MODIFIERS_ERROR, error });
  }
}

function* InlineValidationSaga(action) {
  try {
    const { validate, URDClick, ...input } = action.payload;
    let data = {};
    if (validate) {
      data = yield call(getInlineValidationAPI, input);
      yield put({ type: GET_INLINE_VALIDATION_SUCCESS, payload: { data, URDClick } });
      //yield put({ type: GET_MY_ENTERED_MODELDATA, data: {} });
      yield put({ type: GET_ERRORMSG, data });
      if (data && (input.divCode === "RMT" || input.divCode === "RNI") && URDClick === true) {
        let O_CFG_PARAMS_OUT_ITEM = data.OutputParameters.O_CFG_PARAMS_OUT.O_CFG_PARAMS_OUT_ITEM;
        if (!Array.isArray(O_CFG_PARAMS_OUT_ITEM)) {
          O_CFG_PARAMS_OUT_ITEM = [O_CFG_PARAMS_OUT_ITEM]
        }
        const getHeaderId = O_CFG_PARAMS_OUT_ITEM.map(item => item.CONFIG_HDR_ID);
        let parsedgetHeaderId = parseInt(getHeaderId[0]);
        const getRevNumber = O_CFG_PARAMS_OUT_ITEM.map(item => item.CONFIG_REV_NUM);
        let parsedRevNumber = parseInt(getRevNumber[0]);
        let modelNameString = input.modelData.filter((item) =>
          item[`MODEL_NAME_${input.RowIndex + 1}`]
        );
        let RMTModelname = modelNameString[0];
        RMTModelname = RMTModelname[`MODEL_NAME_${input.RowIndex + 1}`]
        let lineNumberRMT = input.lineNumber;
        const dataa = yield call(getUrdRMTAPI, { parsedgetHeaderId, parsedRevNumber, RMTModelname, lineNumberRMT });
        yield put({ type: GET_RMT_URD_DATA_SUCCESS, dataa, id: action.payload.RowId });
        yield put({ type: UPDATE_INITIAL_DATA, dataa, id: action.payload.RowId });
      }
    } else {
      data = {
        OutputParameters: {
          O_CFG_PARAMS_OUT: { O_CFG_PARAMS_OUT_ITEM: { CONFIG_STATUS: "TRUE" } }
        }
      };
      yield put({ type: GET_INLINE_VALIDATION_SUCCESS, payload: { data, URDClick: false } });
    }
  } catch (error) {
    let data = prepareErrorObject(error, GET_INLINE_VALIDATION_ERROR );
    // let data = prepareErrorObject({
    //   code: error.code,
    //   message: error.message,
    //   api: GET_URD_DATA_ERROR
    // });
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: GET_INLINE_VALIDATION_ERROR, error });
  }
}

function* InsertDataSaga(action) {
  try {
    const data = yield call(getInsertDataAPI, action.payload);
    
    const OutputParameters = data ? data.OutputParameters || {} : {};

    if(OutputParameters && OutputParameters.O_ERROR_FLAG === null) {
      yield(updateEnteredModelData, action.payload.lineNumber);
      yield put({ type: GET_INSERT_DATA_SUCCESS, data });
      yield put({ type: GET_MY_ENTERED_MODELDATA, data: {} });
      yield put({ type: GET_ERRORMSG, data });
    } else {
      let errorMsg = OutputParameters.O_ERROR_MSG || 'Insert API Error';
      throw new Error(errorMsg);
    }
  } catch (error) {
    let data = prepareErrorObject(error, GET_INSERT_DATA_ERROR);
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: GET_INSERT_DATA_ERROR, error });
  }
}

function* importExcelData(action) {
  try {
    const data = yield call(getInsertDataAPI, action.payload);

    const OutputParameters = data ? data.OutputParameters || {} : {};

    if(OutputParameters && OutputParameters.O_ERROR_FLAG === null) {
      yield put({ type: IMPORT_EXCEL_DATA_SUCCESS, data });
      yield put({ type: GET_MY_ENTERED_MODELDATA, data: {} });
      yield put({ type: GET_ERRORMSG, data });
    } else {
      let errorMsg = OutputParameters.O_ERROR_MSG || 'Insert API Error';
      throw new Error(errorMsg);
    }
    
  } catch (error) {
    let data = prepareErrorObject(error, IMPORT_EXCEL_DATA_ERROR);
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: IMPORT_EXCEL_DATA_ERROR, error });
  }
}

function* importPasteList(action) {
  try {
    const data = yield call(getInsertDataAPI, action.payload);
    const OutputParameters = data ? data.OutputParameters || {} : {};
    if(OutputParameters && OutputParameters.O_ERROR_FLAG === null) {
      yield put({ type: IMPORT_PASTELIST_SUCCESS, data });
      yield put({ type: GET_MY_ENTERED_MODELDATA, data: {} });
      yield put({ type: GET_ERRORMSG, data });
    } else {
      let errorMsg = OutputParameters.O_ERROR_MSG || 'Insert API Error';
      throw new Error(errorMsg);
    }
  } catch (error) {
    let data = prepareErrorObject(error, IMPORT_PASTELIST_ERROR);
    //let errorMsgCustom = OutputParameters.O_ERROR_MSG ?  'Entered Model name is incorrect , please enter proper data ' : OutputParameters.O_ERROR_MSG
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: IMPORT_PASTELIST_ERROR, error });
  }
}

function* importEmailNotification(action) {
  try {
    const data = yield call(getInsertDataAPI, action.payload);
    yield put({ type: EMAIL_NOTIFICATION_SUCCESS, data });
    yield put({ type: GET_ERRORMSG, data });
  } catch (error) {
    let data = prepareErrorObject(error, EMAIL_NOTIFICATION_ERROR);
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: EMAIL_NOTIFICATION_ERROR, error });
  }
}

function* UrdInfoSaga(action) {
  try {
    const data = yield call(getUrdInfoAPI, action.payload.modelNameString);
    // const data = yield call(modelDataApi.URD, action.payload);
    yield put({ type: GET_URD_DATA_SUCCESS, data, id: action.payload.id });
  } catch (error) {
    let data = prepareErrorObject({
      code: error.code,
      message: error.message,
      api: GET_URD_DATA_ERROR
    });
    yield put({ type: GET_ERRORMSG, data });
    // dispatch a failure action to the store with the error
    yield put({ type: GET_URD_DATA_ERROR, error });
  }
}

function* UserLocationSaga(action) {
  try {
    const data = yield call(getUserLocationAPI, action.payload);
    yield put({ type: GET_USER_LOCATION_SUCCESS, data });
    yield put({ type: GET_ERRORMSG, data });
  } catch (error) {
    let data = prepareErrorObject({
      code: error.code,
      message: error.message,
      api: GET_USER_LOCATION_ERROR
    });
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: GET_USER_LOCATION_ERROR, error });
  }
}

/**
 * action Type of GET_QUOTE_DATA
 * @param {Object} action
 */
function* clearSaveSaga(action) {
  try {
    const data = action.payload;
    yield put({ type: CLEAR_SAVE_ERROR_SUCCESS, data });
    //yield put({ type: GET_ERRORMSG, data });
  } catch (error) {
    // let data = prepareErrorObject({
    //   code: error.code,
    //   message: error.message,
    //   api: CLEAR_SAVE_ERROR_ERROR
    // });
  }
}

/**
 * action Type of EditData
 * @param {Object} action
 */
function* editDataSaga(action) {
  try {
    let { lineNumber, model, division, quantityCheck } = action.payload;
    const data = yield call(EditDataAPI, lineNumber);
    const urd = yield call(EditUrdDataAPI, lineNumber, model);
    yield put({ type: GET_EDIT_DATA_SUCCESS, data: { urd, model: data, divCode: division, quantityCheck: parseInt(quantityCheck) } });
    yield put({ type: GET_ERRORMSG, data: urd.response });
    yield put({ type: GET_ERRORMSG, data: data.response });
  } catch (error) {
    let data = prepareErrorObject({
      code: error.code,
      message: error.message,
      api: GET_EDIT_DATA_ERROR
    });
    yield put({ type: GET_ERRORMSG, data });
    // dispatch a failure action to the store with the error
    yield put({ type: GET_EDIT_DATA_ERROR, error });
  }
}

/**
 * action Type of AddNewRow
 * @param {Object} action
 */
function* onaddNewRow(action) {
  try {
    const data = action.payload;
    yield put({ type: ADD_NEW_ROW_SUCCESS, data });
  } catch (error) {
    let data = prepareErrorObject({
      code: error.code,
      message: error.message,
      api: ADD_NEW_ROW_ERROR
    });
    yield put({ type: GET_ERRORMSG, data });
    // dispatch a failure action to the store with the error
    yield put({ type: ADD_NEW_ROW_ERROR, error });
  }
}

/**
 * action Type of Task Number
 * @param {Object} action
 */
function* getTaskNumber(action) {
  try {
    const data = yield call(getTaskNumAPI, action.payload);
    yield put({ type: TASK_NUMBER_SUCCESS, data });
  } catch (error) {
    let data = prepareErrorObject({
      code: error.code,
      message: error.message,
      api: TASK_NUMBER_ERROR
    });
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: TASK_NUMBER_ERROR, error });
  }
}

/**
 * action Type of Additional Info
 * @param {Object} action
 */
function* getadditionalInfo(action) {
  try {
    // let { modelName, warehouse, divCode, id } = action.payload;
    let { id } = action.payload;
    const data = yield call(getAdditionalInfoAPI, action.payload);
    yield put({ type: ADDITIONAL_INFO_SUCCESS, payload: { data, id } });
  } catch (error) {
    let data = prepareErrorObject({
      code: error.code,
      message: error.message,
      api: ADDITIONAL_INFO_ERROR
    });
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: ADDITIONAL_INFO_ERROR, error });
  }
}

/**
 * action Type of Line Price
 * @param {Object} action
 */
function* getLinePrice(action) {
  try {
    const data = yield call(getlinePriceAPI, action.payload);
    yield put({ type: LINE_PRICE_SUCCESS, data });
  } catch (error) {
    let data = prepareErrorObject({
      code: error.code,
      message: error.message,
      api: LINE_PRICE_ERROR
    });
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: LINE_PRICE_ERROR, error });
  }
}


function* getApplyHolds(action) {
  try {
    // let { modelName, warehouse, divCode, id } = action.payload;
    const data = yield call(getAdditionalInfoAPI, action.payload);
    yield put({ type: GET_APPLY_HOLDS_SUCCESS, payload: data });
  } catch (error) {
    let data = prepareErrorObject({
      code: error.code,
      message: error.message,
      api: GET_APPLY_HOLDS_ERROR
    });
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: GET_APPLY_HOLDS_ERROR, error });
  }
}

function* getUrdRMTInfo(action) {
  try {
    // let { modelNameString, lineNumber } = action.payload;
    const data = yield call(getUrdRMTAPI, action.payload);
    yield put({ type: GET_RMT_URD_DATA_SUCCESS, data, id: action.payload.id });
  } catch (error) {
    let data = prepareErrorObject({
      code: error.code,
      message: error.message,
      api: GET_RMT_URD_DATA_ERROR
    });
    yield put({ type: GET_ERRORMSG, data });
    // dispatch a failure action to the store with the error
    yield put({ type: GET_RMT_URD_DATA_ERROR, error });
  }
}

function* getPriceRollUp(action) {
  try {
    const data = yield call(getPriceRollUpDataAPI, action.payload);
    yield put({ type: GET_PRICE_ROLLUP_DATA_SUCCESS, data });
  } catch (error) {
    let data = prepareErrorObject({
      code: error.code,
      message: error.message,
      api: GET_PRICE_ROLLUP_DATA_ERROR
    });
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: GET_PRICE_ROLLUP_DATA_ERROR, error });
  }
}

function* getAssembleTo(action) {
  try {
    const data = yield call(getAssembleToDataAPI, action.payload);
    yield put({ type: GET_ASSEMBLETO_SUCCESS, data });
  } catch (error) {
    let data = prepareErrorObject({
      code: error.code,
      message: error.message,
      api: GET_ASSEMBLETO_ERROR
    });
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: GET_ASSEMBLETO_ERROR, error });
  }
}

//RT_002332 sudhakar (+)
function* getMfgNotes(action){
  yield delay(1000);
  yield put({type: COPY_MFG_NOTES_SUCCESS, id: action.payload.id, unit: action.payload.unit});
}

function* getTagDtls(action){
  yield delay(1000);
  yield put({type: COPY_TAG_DTLS_SUCCESS, id: action.payload.id, unit: action.payload.unit});
}

function* getCalibDtls(action){
  yield delay(1000);
  yield put({type: COPY_CALIB_DTLS_SUCCESS, id: action.payload.id, unit: action.payload.unit});
}

//RT_002332 sudhakar (-)

// ================ EXPORT SAGA FUNCTIONS ================

export function* getModelStringSaga() {
  yield takeLatest(GET_MODELSTRING, ModelStringSaga);
}

export function* getModelNameSaga() {
  yield takeLatest(GET_MODELNAME, ModelNameSaga);
}

export function* getWareHouseSaga() {
  yield takeLatest(GET_WAREHOUSE, WareHouseSaga);
}

export function* getModifierSaga() {
  yield takeLatest(GET_MODIFIERS, ModifierSaga);
}

export function* getInlineValidationSaga() {
  yield takeLatest(GET_INLINE_VALIDATION, InlineValidationSaga);
}

export function* getInsertDataSaga() {
  yield takeLatest(GET_INSERT_DATA, InsertDataSaga);
}

export function* importExcelDataSaga() {
  yield takeLatest(IMPORT_EXCEL_DATA, importExcelData)
}

export function* importPasteListSaga() {
  yield takeLatest(IMPORT_PASTELIST, importPasteList)
}

export function* importEmailNotificationSaga() {
  yield takeLatest(EMAIL_NOTIFICATION, importEmailNotification)
}



export function* getUrdInfoSaga() {
  yield takeLatest(GET_URD_DATA, UrdInfoSaga);
}

export function* getUserLocationSaga() {
  yield takeLatest(GET_USER_LOCATION, UserLocationSaga);
}

/**
 * Get ClearStatus
 */
export function* clearStatusSaga() {
  yield takeLatest(CLEAR_SAVE_ERROR, clearSaveSaga);
}

/**
 * Get EditDataSaga
 */
export function* getEditDataSaga() {
  yield takeLatest(GET_EDIT_DATA, editDataSaga);
}

/**
 * Get ADDNewRow
 */
export function* addNewRowSaga() {
  yield takeLatest(ADD_NEW_ROW, onaddNewRow);
}

/**
 * Get Task Number
 */
export function* getTaskNumberSaga() {
  yield takeLatest(TASK_NUMBER, getTaskNumber);
}

/**
 * Get Additional Info 
 */
export function* additionalInfoSaga() {
  yield takeLatest(ADDITIONAL_INFO, getadditionalInfo);
}

export function* getApplyHoldsSaga() {
  yield takeLatest(GET_APPLY_HOLDS, getApplyHolds);
}
/**
 * Get Line Price 
 */
export function* linePriceSaga() {
  yield takeLatest(LINE_PRICE, getLinePrice);
}

export function* RMTUrdSaga() {
  yield takeLatest(GET_RMT_URD_DATA, getUrdRMTInfo);
}

export function* getPriceRollUpSaga() {
  yield takeLatest(GET_PRICE_ROLLUP_DATA, getPriceRollUp );
}

export function* getAssembleToSaga() {
  yield takeLatest(GET_ASSEMBLETO, getAssembleTo );
}

//RT_002332 RT_002332 sudhakar (+)
export function* copyMfgNotesSaga(){
  yield takeLatest(COPY_MFG_NOTES, getMfgNotes);
}

export function* copyTagDtlsSaga(){
  yield takeLatest(COPY_TAG_DTLS, getTagDtls);
}

export function* copyCalibDtlsSaga(){
  yield takeLatest(COPY_CALIB_DTLS, getCalibDtls);
}
//RT_002332 RT_002332 sudhakar (-)