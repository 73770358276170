import { connect } from 'react-redux';
import OrderTable from './OrderTable';
import { getCopyLines, getMyEnteredModel, getOrderDetails } from '../../store/order';
import { prepareEnteredModelData } from "../../utils/helper";

// ===== Map state received from reducer to props  =====
const mapStateToProps = (state) => {
    return {
        orderTableData: prepareOrderData(state.orderStore), // Fetch orderData from store
        orderDetails: state.orderStore.orderDetails, // Fetch Order Details
        copyLines: prepareCopyDetailsData(state.orderStore), // Fetch order store 
        enteredModelData: prepareEnteredModelData(state.orderStore)   // Prepare entered Model data 
    }
};

// Function to preape order data 
const prepareOrderData = data => {
    let result = [];
    if (Object.keys(data).length > 0) {
        try {
            let ResponseType = data.orders.OutputParameters.O_GS_ORD_HDR_REC.O_GS_ORD_HDR_REC_ITEM;
            if (Array.isArray(ResponseType) === false) { // Is Array check ?
                result = [data.orders.OutputParameters.O_GS_ORD_HDR_REC.O_GS_ORD_HDR_REC_ITEM]
            } else {
                result = data.orders.OutputParameters.O_GS_ORD_HDR_REC.O_GS_ORD_HDR_REC_ITEM
            }
        }
        catch {
        }
    }
    return result;
}

// Function to get the error flag 
const prepareCopyDetailsData = data => {
    let result = [];
    if (Object.keys(data).length > 0) {
        try {
            result = data.copyLines.OutputParameters.O_ERROR_FLAG
        }
        catch {
        }
    }
    return result;
}

// ===== Mapping dispatch functions to the props  =====
const mapDispatchToProps = {
    CopyLinesData: payload => getCopyLines(payload), // dispatching copy lines
    getOrderDetails: payload => getOrderDetails(payload), // Dispatching order details 
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderTable); //The connect() function connects a React component OrderTable to a Redux store(modalDataStore).

