const GET_USER = 'GET_USER';
const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
const GET_USER_ERROR = 'GET_USER_ERROR';
const LOGOUT_USER = 'LOGOUT_USER';
const UNAUTH_USER = 'UNAUTH_USER';
const GET_URL_DATA = "GET_URL_DATA";
const GET_URL_DATA_SUCCESS = "GET_URL_DATA_SUCCESS";
const GET_URL_DATA_ERROR = "GET_URL_DATA_ERROR";

export const GET_USER_DATA_INIT = 110;
export const GET_USER_DATA_SUCCESS = 120;
export const GET_USER_DATA_FAILED = 130;

export {
  GET_USER, GET_USER_SUCCESS, GET_USER_ERROR, LOGOUT_USER, UNAUTH_USER,
  GET_URL_DATA, GET_URL_DATA_SUCCESS, GET_URL_DATA_ERROR
};