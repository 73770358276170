import React from 'react';
import { LOADING } from '../../utils/constant';
import { connect } from 'react-redux';

function Loader(props) {
  if (props.loading) {
    // React spinner 
    return (
      <div data-test="loaderTest" className="spinner-block">
        <div className="loader-block">
          <div className="loader"></div>
        </div>
      </div>
    );
  }
  return null;
}

// const mapStateToProps = ({ orderStore, modalDataStore }) => ((orderStore, modalDataStore));

// ===== Map state received from reducer to props  =====
const mapStateToProps = ({ orderStore, modalDataStore, userStore }) => {
  return {
    loading: orderStore.phase === LOADING || modalDataStore.phase === LOADING || userStore.phase === LOADING
  }
}

export default connect(mapStateToProps)(Loader); //// The connect() function connects a React component Loader to a Redux store.