import { takeLatest, call, put } from "redux-saga/effects";
import {
  GET_SIZINGS, GET_SIZINGS_ERROR, GET_SIZINGS_SUCCESS,
  GET_COPY_SIZING, GET_COPY_SIZING_ERROR, GET_COPY_SIZING_SUCCESS,
  GET_ORDERS, GET_ORDERS_SUCCESS, GET_ORDERS_ERROR,
  GET_ORDER_DETAILS, GET_ORDER_DETAILS_SUCCESS, GET_ORDER_DETAILS_ERROR,
  GET_CANCEL_DATA, GET_CANCEL_DATA_SUCCESS, GET_CANCEL_DATA_ERROR,
  GET_ADDED_DATA, GET_ADDED_DATA_SUCCESS, GET_ADDED_DATA_ERROR,
  GET_MY_ENTERED_MODELDATA, GET_MY_ENTERED_MODELDATA_SUCCESS, GET_MY_ENTERED_MODELDATA_ERROR,
  GET_SUBMIT_DATA, GET_SUBMIT_DATA_SUCCESS, GET_SUBMIT_DATA_ERROR,
  GET_ORDER_COPY_LINES, GET_ORDER_COPY_LINES_SUCCESS, GET_ORDER_COPY_LINES_ERROR,
  GET_QUOTE, GET_QUOTE_SUCCESS, GET_QUOTE_ERROR,
  GET_QUOTE_DETAILS, GET_QUOTE_DETAILS_SUCCESS, GET_QUOTE_DETAILS_ERROR,
  GET_DELETE_DATA, GET_DELETE_DATA_SUCCESS, GET_DELETE_DATA_ERROR,
  INIT_SUBMIT_DATA_ERROR, INIT_SUBMIT_DATA, INIT_SUBMIT_DATA_SUCCESS
} from "./constant";
import { GET_ERRORMSG } from "../error-store/constant";
import {
  sizingAPI, copySizingAPI, OrderAPI, OrderDetailsAPI, QuoteDataAPI, QuoteLinesAPI,
  MyEnteredModelAPI, submitDataAPI, CancelDataAPI, copyLinesAPI
} from "./api";
import { DeleteDataAPI } from "../model-data/api";
import { prepareErrorObject } from "../error-store/helper";
import { UPDATE_MODEL, UPDATE_REQUIRED_MULTI_LEVEL_MODEL } from "../model-data/constant";
import { prepareEnteredModelData, getMaxLineNo } from "../../utils/helper";

function* sizingSaga(action) {
  try {
    const data = yield call(sizingAPI, action.payload);
    yield put({ type: GET_SIZINGS_SUCCESS, data });
    yield put({ type: GET_ERRORMSG, data });
  } catch (error) {
    let data = prepareErrorObject({
      code: error.code,
      message: error.message,
      api: GET_SIZINGS_ERROR
    });
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: GET_SIZINGS_ERROR, error });
  }
}

function* copySizingSaga(action) {
  try {
    const data = yield call(copySizingAPI, action.payload);
    yield put({ type: GET_COPY_SIZING_SUCCESS, data });
  } catch (error) {
    let data = prepareErrorObject({
      code: error.code,
      message: error.message,
      api: GET_COPY_SIZING_ERROR
    });
    yield put({ type: GET_ERRORMSG, data });
    // dispatch a failure action to the store with the error
    yield put({ type: GET_COPY_SIZING_ERROR, error });
  }
}

function* orderSaga(action) {
  try {
    const data = yield call(OrderAPI, action.payload);
    yield put({ type: GET_ORDERS_SUCCESS, data });
    yield put({ type: GET_ERRORMSG, data });
  } catch (error) {
    let data = prepareErrorObject({
      code: error.code,
      message: error.message,
      api: GET_ORDERS_ERROR
    });
    yield put({ type: GET_ERRORMSG, data });
    // dispatch a failure action to the store with the error
    yield put({ type: GET_ORDERS_ERROR, error });
  }
}

function* orderDetailsSaga(action) {
  try {
    const id = action.payload.id;
    const data = yield call(OrderDetailsAPI, id);
    const result = { id, data };
    yield put({ type: GET_ORDER_DETAILS_SUCCESS, data: result });
    yield put({ type: GET_ERRORMSG, data });
  } catch (error) {
    let data = prepareErrorObject({
      code: error.code,
      message: error.message,
      api: GET_ORDER_DETAILS_ERROR
    });
    yield put({ type: GET_ERRORMSG, data });
    // dispatch a failure action to the store with the error
    yield put({ type: GET_ORDER_DETAILS_ERROR, error });
  }
}

function* AddedDataSaga(action) {
  try {
    const data = action.payload;
    yield put({ type: GET_ADDED_DATA_SUCCESS, data });
    yield put({ type: GET_ERRORMSG, data });
  } catch (error) {
    let data = prepareErrorObject({
      code: error.code,
      message: error.message,
      api: GET_ADDED_DATA_ERROR
    });
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: GET_ADDED_DATA_ERROR, error });
  }
}

function* MyEnteredModelSaga(action) {
   
  try { 
    const data = yield call(MyEnteredModelAPI, action.payload);
    const inlineCheck = action.payload && action.payload.inlineStatus;
    yield put({ type: GET_MY_ENTERED_MODELDATA_SUCCESS, data });
    let enteredModelData = prepareEnteredModelData({ enteredModelData: data });
    if(inlineCheck && inlineCheck !== "failed") {
      yield put({
        type: UPDATE_MODEL,
        payload: { lineNo: getMaxLineNo(enteredModelData) + 1 }
      });

      yield put({ type: UPDATE_REQUIRED_MULTI_LEVEL_MODEL, payload: { requiredMultiLevelModel: false }});
    }
    yield put({ type: GET_ERRORMSG, data });
  } catch (error) {
    let data = prepareErrorObject(error, GET_MY_ENTERED_MODELDATA_ERROR);
    yield put({ type: GET_ERRORMSG, data });
    // dispatch a failure action to the store with the error
    yield put({ type: GET_MY_ENTERED_MODELDATA_ERROR, error });
  }

}

function* MySubmitDataSaga(action) {
  try {
    const data = yield call(submitDataAPI, action.payload);
    yield put({ type: GET_SUBMIT_DATA_SUCCESS, data });
    yield put({ type: GET_ERRORMSG, data });
  } catch (error) {
    let data = prepareErrorObject(error, GET_SUBMIT_DATA_ERROR);
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: GET_SUBMIT_DATA_ERROR, error });
  }
}


function* initSubmitData(action) {
  try {
    const data = yield call(submitDataAPI, action.payload);
    yield put({ type: INIT_SUBMIT_DATA_SUCCESS, data });
    yield put({ type: GET_ERRORMSG, data });
  } catch (error) {
    let data = prepareErrorObject(error, INIT_SUBMIT_DATA_ERROR);
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: INIT_SUBMIT_DATA_ERROR, error });
  }
}

function* cancelSaga(action) {
  try {
    const data = yield call(CancelDataAPI, action.payload);
    yield put({ type: GET_CANCEL_DATA_SUCCESS, data });
    yield put({ type: GET_ERRORMSG, data });
  } catch (error) {
    let data = prepareErrorObject({
      code: error.code,
      message: error.message,
      api: GET_CANCEL_DATA_ERROR
    });
    yield put({ type: GET_ERRORMSG, data });
    // dispatch a failure action to the store with the error
    yield put({ type: GET_CANCEL_DATA_ERROR, error });
  }
}

function* copyLinesSaga(action) {
  try {
    // const { id, selectedLines } = action.payload;
    const data = yield call(copyLinesAPI, action.payload);
    yield put({ type: GET_ORDER_COPY_LINES_SUCCESS, data });
    yield put({ type: GET_MY_ENTERED_MODELDATA, data: {} });
    yield put({ type: GET_ERRORMSG, data });
  } catch (error) {
    let data = prepareErrorObject(error, GET_ORDER_COPY_LINES_ERROR);
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: GET_ORDER_COPY_LINES_ERROR, error });
  }
}

function* quoteSaga(action) {
  try {
    const data = yield call(QuoteDataAPI, action.payload);
    yield put({ type: GET_QUOTE_SUCCESS, data });
    yield put({ type: GET_ERRORMSG, data });
  } catch (error) {
    let data = prepareErrorObject(error, GET_QUOTE_ERROR);
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: GET_QUOTE_ERROR, error });
  }
}

function* quoteDetailsSaga(action) {
  try {
    const id = action.payload.quoteId;
    const revNum = action.payload.revisionNum;
    const quoteNum = action.payload.quoteNum;
    const data = yield call(QuoteLinesAPI, { id, revNum, quoteNum });
    const result = { id, revNum, data };
    yield put({ type: GET_QUOTE_DETAILS_SUCCESS, data: result });
    yield put({ type: GET_ERRORMSG, data });
  } catch (error) {
    let data = prepareErrorObject(error, GET_QUOTE_DETAILS_ERROR);
    yield put({ type: GET_ERRORMSG, data });
    yield put({ type: GET_QUOTE_DETAILS_ERROR, error });
  }
}

function* deleteDataSaga(action) {
  try {
    const data = yield call(DeleteDataAPI, action.payload);
    yield put({ type: GET_DELETE_DATA_SUCCESS, data });
    const ModelData = yield call(MyEnteredModelAPI, action.payload);
    let enteredModelData = prepareEnteredModelData({ enteredModelData: ModelData });
      yield put({
        type: UPDATE_MODEL,
        payload: { lineNo: getMaxLineNo(enteredModelData) + 1 }
      });
//  yield put({ type: CLEAR_SAVE_ERROR_SUCCESS, data });
    yield put({ type: GET_MY_ENTERED_MODELDATA });
    yield put({ type: GET_ERRORMSG, data });
  } catch (error) {
    let data = prepareErrorObject({
      code: error.code,
      message: error.message,
      api: GET_DELETE_DATA_ERROR
    });
    yield put({ type: GET_ERRORMSG, data });
    // dispatch a failure action to the store with the error
    yield put({ type: GET_DELETE_DATA_ERROR, error });
  }
}

export function* getSizingSaga() {
  yield takeLatest(GET_SIZINGS, sizingSaga);
}

export function* getCopySizingSaga() {
  yield takeLatest(GET_COPY_SIZING, copySizingSaga);
}

export function* getOrderSaga() {
  yield takeLatest(GET_ORDERS, orderSaga);
}

export function* getOrderDetailsSaga() {
  yield takeLatest(GET_ORDER_DETAILS, orderDetailsSaga);
}

export function* getQuoteSaga() {
  yield takeLatest(GET_QUOTE, quoteSaga);
}

export function* getQuoteDetailsSaga() {
  yield takeLatest(GET_QUOTE_DETAILS, quoteDetailsSaga);
}

export function* getAddedDataSaga() {
  yield takeLatest(GET_ADDED_DATA, AddedDataSaga);
}

export function* getMyEnteredModelSaga() {
  yield takeLatest(GET_MY_ENTERED_MODELDATA, MyEnteredModelSaga);
}

export function* getSubmitDataSaga() {
  yield takeLatest(GET_SUBMIT_DATA, MySubmitDataSaga);
}

export function* initSubmitDataSaga() {
  yield takeLatest(INIT_SUBMIT_DATA, initSubmitData);
}

export function* getCancelSaga() {
  yield takeLatest(GET_CANCEL_DATA, cancelSaga);
}

export function* getcopyLinesSaga() {
  yield takeLatest(GET_ORDER_COPY_LINES, copyLinesSaga);
}

export function* getDeleteDataSaga() {
  yield takeLatest(GET_DELETE_DATA, deleteDataSaga);
}