import React from 'react';
import SelectInput from '../../shared/SelectInput';
import TextInput from '../../shared/TextInput';

const Pricing = ({ id, row, errors, modifiers, onSelect, getLineNum, onChange, onkeypress }) => {
    let operatorPlaceholder = 'Enter the Price Adjustment'; // default placeholder
    let methodText = ""
    let percentile = '';
    let priceFlag = "";
    let selectedModifier = modifiers.filter(m => m.value === row.modifier)
    if (selectedModifier.length > 0) {
        priceFlag = selectedModifier[0].flag;
        let operator = selectedModifier[0].operator;
        row.operator = operator;
        operatorPlaceholder += ` in ${operator}`;
        if (operator === "AMT") {
            methodText = "Amount"
        }
        else if (operator === "%") {
            percentile = "%"
        }
        else {
            methodText = "Lumpsum"
        }
    }

    return (
        <tr>
            <td colSpan="8">
                <div className="col-xs-12 pricing-container p-3">
                    <h4>Pricing</h4>
                    <div className="row">
                        <div className="col-md-6">
                            <span className="mr-2">{getLineNum}</span>
                            <label className="form-label mr-3">Manual Modifier </label>
                            <SelectInput wrapperClass="col-md-8 d-inline-block"
                                name="modifier" value={row.modifier}
                                defaultOption="--- Select Modifier ---" options={modifiers}
                                onChange={onSelect}
                            />
                        </div>

                        <div className="col-md-6">
                            <div className="row">
                               <label className="form-label mr-2">Price Adjustment:
                                    <span className="pl-1">{methodText}</span>
                                </label>
                                <div className="col-md-7 position-relative">
                                    <TextInput type="text"  name="priceValue"
                                        placeholder={operatorPlaceholder} value={row.priceValue}
                                        onChange={onChange}
                                        onkeypress={onkeypress} disabled ={priceFlag !=="Y" && row.modifier !=="" ? true : false}/>
                                        <span className="position-absolute pricing-perIcon">{percentile}</span>
                                </div>
                           </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    );
}

export default Pricing;