import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Moment from 'react-moment';
import React, { Component } from "react";
import GroupedTable from "../shared/grouped-table";

class QuoteTable extends Component {
    state = { // initial state
        selectedLines: []
    }

    render() {
        const { quoteTableData, quoteDetailsData } = this.props; // passing props
        const { selectedLines } = this.state; // passing state
        const { SearchBar } = Search;
        // Function to get the from and to deatils in pagination
        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                Showing {from} to {to} of {size} Results
            </span>
        );

        // BootstrapTable options
        const options = {
            paginationSize: 2,
            pageStartIndex: 1,
            firstPageText: "First",
            prePageText: "Previous",
            nextPageText: "Next",
            lastPageText: "Last",
            nextPageTitle: "First page",
            prePageTitle: "Pre page",
            firstPageTitle: "Next page",
            lastPageTitle: "Last page",
            showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [
                {
                    text: "5",
                    value: 5
                },
                {
                    text: "10",
                    value: 10
                },
                {
                    text: "All",
                    value: quoteTableData.length
                }
            ]
        };
        // Table Headings 
        const columns = [
            {
                dataField: "QUOTE_NUMBER",
                text: "Quote Number"
            },
            {
                dataField: "QUOTE_HEADER_ID",
                text: "Quote ID"
            },
            {
                dataField: "QUOTE_NAME",
                text: "Quote Name",
            },
            {
                dataField: "QUOTE_DATE",
                text: "Quote Date",
                formatter: this.formatDate
            },
            {
                dataField: "CREATED_BY",
                text: "Created By"
            },
            {
                dataField: "QUOTE_STATUS",
                text: "Quote Status"
            }
        ];
        const detailscolumns = [
            {
                dataField: "QUOTE_LINE_NUMBER",
                displayName: "Quote Line Number",
                group: true
            },
            {
                dataField: "PRODUCT_NAME",
                displayName: "Model Name",
                group: false
            },
            {
                dataField: "BASE_MODELNAME",
                displayName: "Base Model Name",
                group: false
            },
            {
                dataField: "MODEL_STRING",
                displayName: "Model String",
                group: false
            },
            {
                dataField: "LINE_TYPE",
                displayName: "Line Type",
                group: false
            },
            {
                dataField: "QUANTITY",
                displayName: "Quantity",
                group: false
            },
        ];
        const expandRow = {
            onExpand: (row, isExpand, rowIndex, e) => {
                // on expand rows
                const { quoteDetailsData, getQuoteLines } = this.props;
                let id = row.QUOTE_HEADER_ID;
                let quoteNum = row.QUOTE_NUMBER;
                let revNum = row.QUOTE_REVISION_NUM;
                if (isExpand && !quoteDetailsData[id]) {
                    getQuoteLines({ quoteId: id, revisionNum: revNum, quoteNum: quoteNum });
                }
            },
            renderer: row => {
                let id = row.QUOTE_HEADER_ID;
                let childData = quoteDetailsData[id];
                return (
                    <div>
                        {
                            childData && (
                                <GroupedTable hasRowCheck={true} onSelect={this.onSelectedOrderLine}
                                    data={childData} columns={detailscolumns} selectedIds={selectedLines} />
                            )
                        }
                        {

                            selectedLines.length > 0 && (
                                <button className="btn-primary m-auto d-block"
                                    onClick={() => this.copyOrderLines(selectedLines, id)} title="Copy Quotelines">
                                    <i className="fa fa-files-o pr-2" aria-hidden="true"></i>Copy Quotelines
                                </button>
                            )
                        }
                    </div>
                )
            },
            showExpandColumn: true,
            nonExpandable: [0]
        };

        return (
            <ToolkitProvider
                keyField="QUOTE_HEADER_ID"
                data={quoteTableData}
                columns={columns}
                search>
                {props => (
                    <div>
                        <div className="searchbar-row w-50">
                            <label className="label font-weight-bold float-left">My Quotes</label>
                            <SearchBar {...props.searchProps} className="searchbar w-100 border-right-0" placeholder="Search Quote" />
                            <button className="btn btn-default w-auto search-btn"><i className="fa fa-search"></i></button>
                        </div>

                        <BootstrapTable
                            bordered={false}
                            striped
                            hover
                            {...props.baseProps}
                            //selectRow={selectRow}
                            pagination={paginationFactory(options)}
                            expandRow={expandRow}
                            noDataIndication="No Quotes are Available"
                        />
                    </div>
                )}
            </ToolkitProvider>
        );
    }

    // Function to get the formatted date
    formatDate = (cell) => {
        return (
            <Moment format='YYYY/MM/DD' date={cell} />
        );
    };

    // On save function to cal copy lines data
    saveOrder = async () => {
        const { CopyLinesData } = this.props;
        await CopyLinesData();

    }

    // gets the selected order line details 
    onSelectedOrderLine = (id, checked) => {
        let selectedLines = Object.assign([], this.state.selectedLines);
        let refId = id;
        if (checked) {
            selectedLines.push(refId);
        } else {
            selectedLines = selectedLines.filter(id => id !== refId)
        }
        this.setState({ selectedLines });
    }

    // Function to get the copy order lines
    copyOrderLines = async (selectedLines, id) => {
        const { CopyLinesData, visibiltyCopySizing } = this.props;
        await CopyLinesData({ selectedLines, id });
        setTimeout(() => {
            this.setState({ selectedLines: [] });
            visibiltyCopySizing();
        }, 3000)
    }
}

export default QuoteTable;
