import { connect } from 'react-redux';
import AppHome from './AppHome';
import { loginUser, getUrlData } from '../../store/user';
import { removeSaveErrors, updateModel, clearBaseModels, insertEmail } from "../../store/model-data";
import { getSizing, getOrders, getOrderDetails, getMyEnteredModel, getQuote } from '../../store/order';
import { clearErrorStatus } from '../../store/error-store';
import { prepareUrlData, getDivisionCode, prepareEnteredModelData } from "../../utils/helper";


// ===== Map state received from reducer to props  =====
const mapStateToProps = (state) => {
  return {
    errorStatus: state.errorDataStore, // fetch error store data
    orderStore: state.orderStore, // fetch order store data
    urlDetails: prepareUrlData(state.userStore), // prepare the url data 
    onSaveError: state.modalDataStore.saveErrors, // fetch save error data  
    divCode: getDivisionCode(state.modalDataStore), // prepare the div code 
    myEnteredModelData: prepareEnteredModelData(state.orderStore), // preapre the entered model data
    data: state.modalDataStore.modalData, // fetch modal data from store
    status: state.modalDataStore.status, // fetch model data status
    submitStatus: state.orderStore.status,// fetch order data status
    submittedData: state.orderStore.submittedData, // fetch submitted data from order store
    userStatus: state.userStore.status, // fetch user store status
    sessionFlag: state.userStore.sessionFlag,// fetch session flag
    emailStatus: state.modalDataStore.emailStatus, // fetch Email Status Flag
    errorMessages : state.userStore.user
  }
};

const mapDispatchToProps = {
  login: payload => loginUser(payload), // distpatching login user data
  sizing: payload => getSizing(payload), // distpatching get sizing data 
  Orders: payload => getOrders(payload), // distpatching get orders data
  OrderDetailsData: payload => getOrderDetails(payload), // distpatching get order details
  Quotes: payload => getQuote(payload), // distpatching get quote data
  clearErrorStatus: payload => clearErrorStatus(payload), // distpatching clear error status data
  EnteredModelData: payload => getMyEnteredModel(payload), // distpatching my entered model data 
  UrlData: payload => getUrlData(payload), // distpatching url data 
  clearSaveErrors: payload => removeSaveErrors(payload), // distpatching to remove save errors
  updateRow: payload => updateModel(payload), // distpatching to update the model data
  clearBaseModels: payload => clearBaseModels(payload), // distpatching to clear base models
  insertEmailData: payload => insertEmail(payload), // dispatching  the payload of Email Insert 
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHome); //The connect() function connects a React component AppHome to a Redux store(modalDataStore).