import React, { Component } from 'react';
import ApplyHoldsHeader from '../apply-holds-header';
import ApplyHoldsRows from '../apply-holds-rows';
import uuid from "uuid";

class ApplyHolds extends Component {

    // ===== life cycle method to generate the base row =====
    componentDidMount() {
        this.generateFormWithBaseRow();
    }
    
    // =====Funtion to generate the base row on load =====
    generateFormWithBaseRow = () => {
        const initialRow = Object.assign({}, this.props.data.initialRowApplyHold);
        let ApplyHoldRows = [initialRow];
        this.props.updateRow({ ApplyHoldRows });
    };

    render() {
        const { ApplyHoldRows } = this.props.data // passing props
        return (
            <table className="w-100 modelData-tbl">
                <ApplyHoldsHeader />
                {
                    ApplyHoldRows.map((row, i) =>
                        <ApplyHoldsRows key={row.id} onRowAdd={this.onRowApplyHoldAdd} id={row.id}
                            onRowRemove={this.onRowItemRemove} />
                    )
                }

            </table>
        )
    }

    // ===== Add row method ===== 
    onRowApplyHoldAdd = () => {
        const { data } = this.props
        const initialRow = Object.assign({}, data.initialRowApplyHold);
        let id = uuid.v4();
        let item = {
            ...initialRow,
            id
        };
        this.props.addApplyholds([...data.ApplyHoldRows, item]);
    };

    // Delete row function
    onRowItemRemove = id => {
        let ApplyHoldRows = Object.assign([], this.props.data.ApplyHoldRows);
        ApplyHoldRows = ApplyHoldRows.filter(row => row.id !== id);
        this.props.deleteRow({
            ApplyHoldRows
        });
        this.props.updateRow({ ApplyHoldRows })
    };

}

export default ApplyHolds;