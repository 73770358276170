import React, { Component } from 'react';
import { connect } from 'react-redux';
import Drawer from '../../shared/Drawer';
import PasteTable from './PasteTable';
import CapturePaste from './CapturePaste';
import Button from "react-bootstrap/Button";
import { templateModelDataPayload, prepareHoldDataForExcelUpload, prepareLineDetailsDataForExcelUpload, generateNumberArrayBetweenRange } from '../../../utils/excel-helper';
import { InputPayload, RMTInputPayload } from '../../../utils/urd-template';
import { STATUS_IMPORT_PASTELIST_SUCCESS } from '../../../store/model-data/constant';
import { download } from '../../../utils/helper';

class PasteOptions extends Component {
    state = {
        content: [],
        selection: {},
    }

    componentDidUpdate(prev) {
        const { status } = this.props;
        if (prev.status !== status) {
            if (status === STATUS_IMPORT_PASTELIST_SUCCESS) {
                this.setState({
                    content: []
                })
            }
        }
    }

    render() {
        const { open, onClose } = { ...this.props };
        const { content, selection } = { ...this.state }

        return (
            <CapturePaste onPaste={(data) => this.processPaste(data)}>
                <Drawer anchor="right" open={open} onClose={onClose} expanded={content.length > 0}>
                    <div>
                        <span style={{ border: "none" }} className='btn-primary delete-btn i'>
                            <i style={{ fontSize: "24px" }} className="fa fa-close" onClick={onClose}></i>
                        </span>
                        <Button
                            variant="primary"
                            style={{ minWidth: "unset", margin: "8px" }}
                            className={"btn btn-primary mb-3 float-right"}
                            disabled={content.length === 0}
                            onClick={this.savePasteList}>
                            Save
                        </Button>

                        <Button
                            variant="primary"
                            style={{ minWidth: "unset", margin: "8px" }}
                            className={"btn btn-primary mb-3 float-right"}
                            disabled={content.length === 0}
                            onClick={this.clearPaseList}>
                            Clear All
                        </Button>

                        <Button
                            title="Download Template"
                            variant="primary"
                            style={{ minWidth: "unset", margin: "8px" }}
                            className={"btn btn-primary mb-3 float-right"}
                            onClick={this.downloadTemplate}>
                            Download
                        </Button>
                    </div>

                    <PasteTable
                        header={["Line Number", "Model Name", "Model String", "quantity",
                            "Warehouse", "Modifier Name", "Adjustment Value"]}
                        content={content}
                        selection={selection}
                        readOnly={false}
                        onSelect={(key, value) => {
                            this.setState({
                                selection: {
                                    ...this.state.selection,
                                    [key]: value
                                }
                            })
                        }}
                        onChange={([row, cell], value) => {
                            let _content = [...content];
                            _content[row][cell] = value;
                            if (cell === 3 || cell === 4)
                                for (let i = parseInt(row) + 1; Boolean(_content[i] && _content[i][0] && _content[i][0].indexOf && _content[i][0].indexOf('.') !== -1); i++) {
                                    _content[i][cell] = value === '' ? '-' : value;
                                }
                            this.setState({
                                content: _content
                            })
                        }}
                    />
                </Drawer>
            </CapturePaste>
        );
    }

    processPaste = (pasteData) => {
        let content = [];
        if (Boolean(pasteData)) {
            let copiedData = [...pasteData.split('\n')
                .map(x => x.split(/[\t]/))]
                .filter(x => Boolean(x[1]))
                .map(r => {
                    let rLen = r.length;
                    if (rLen < 7) {
                        generateNumberArrayBetweenRange(1, (7 - rLen))
                            .map(i => {
                                r.push("");
                                return i;
                            })
                    }
                    return r;
                });
            let lineNos = copiedData.reduce((acc, currVal) => {
                return acc.includes(currVal[0]) ? acc : acc.concat(currVal[0]);
            }, [])
            lineNos.map((lineNo, lineIndex) => {
                let lineData = copiedData.filter(r => r[0] === lineNo);
                let processedRows = lineData.map((r, i) => this.processLineRow(r, i, lineIndex));
                content = [...content, ...processedRows];
                return lineNo;
            })
        }
        this.setState({ content });
    }

    processLineRow = (row, i, lineIndex) => {
        let seqNo = `${this.props.lineNo + (lineIndex)}.${i + 1}`
        row[0] = seqNo;
        row[1] = row[1].toUpperCase();
        row[2] = row[2].toUpperCase();
        return [...row];
    }

    savePasteList = () => {
        const { onSave } = this.props;
        const { content } = this.state;
        let data = Object.assign([], JSON.parse(JSON.stringify(content)));
        data = data.map(row => {
            row[0] = parseInt(row[0]).toString();
            return row;
        })
        let parsedModelData = this.prepareParsedModelDataForSave(data);

        let payload = {
            modelData: parsedModelData,
            urdData: InputPayload,
            RMTurdData: [RMTInputPayload],
            lineNumber: null,
            flat: parsedModelData[0].MODEL_STRING_1 === parsedModelData[0].MODEL_NAME_1,
            holdData: prepareHoldDataForExcelUpload(),
            lineDetailsData: prepareLineDetailsDataForExcelUpload()
        };

        onSave(payload);
    }

    prepareParsedModelDataForSave = (data) => {
        let lineNos = data.map(r => r[0])
            .reduce((acc, n) => acc.includes(n) ? acc : acc.concat(n), []);

        let parsedData = lineNos.map(lineNo => {
            let rows = data.filter(r => r[0] === lineNo);
            const payload = Object.assign({}, templateModelDataPayload);
            payload.LINE_NUMBER = lineNo;
            payload.ITEM_QUANTITY = rows[0][3].trim();
            payload.WAREHOUSE = rows[0][4].trim();
            rows.map((rowItem, i) => {
                payload[`MODEL_NAME_${i + 1}`] = (rowItem[1] || '').trim();
                payload[`MODEL_STRING_${i + 1}`] = (rowItem[2] || '').trim();

                let manualModifier = (rowItem[5] || '').trim();
                let priceAdjustment = (rowItem[6] || '').trim();
                let priceAdjMethod = (manualModifier.split('|')[1] || '').trim();
                if (i === 0) {
                    payload['MANUAL_MODIFIER_BASE_MODEL'] = manualModifier;
                    payload['PRICE_ADJUSTMENT'] = priceAdjustment;
                    payload[`ADJUSTMENT_METHOD`] = priceAdjMethod;
                } else {
                    payload[`MANUAL_MODIFIER_SUB_MODEL_${i}`] = manualModifier;
                    payload[`PRICE_ADJUSTMENT_${i}`] = priceAdjustment;
                    payload[`ADJUSTMENT_METHOD_${i}`] = priceAdjMethod;
                }

                return rowItem;
            });

            return payload;
        });

        return parsedData;
    }

    clearPaseList = () => {
        this.setState({
            content: []
        })
    }

    downloadTemplate = () => {
        download('/template/Paste_List.xls');
    }
}
const mapStateToProps = state => {
    return {
        status: state.modalDataStore.status
    };
}

export default connect(mapStateToProps, null)(PasteOptions);
