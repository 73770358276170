import React from 'react';

const ModelHeader = ({location, hasAction}) => {
    return (
        <thead>
            <tr>
                <th></th>
                <th>Model String</th>
                <th>Model Name</th>
                <th className="quantity-col">Quantity</th>
                <th className="warehouse-col">WareHouse</th>
                {
                    location && (<th className="worldArea-col">World Area</th>)
                }
                {
                    hasAction && (<th>Action</th>)
                }
                <th>Line Details</th>
                <th>Status</th>
            </tr>
        </thead>
    );
}

export default ModelHeader;