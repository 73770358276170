import axios from "axios";
import { API_URL, API_SUB_KEY } from "../../utils/constant";
import { getURLDetails, getUserDetails } from "../../utils/methods";
import toastr from "toastr";
import qs from "qs";
import { MyEnteredModelAPI } from "../order/api";

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": API_SUB_KEY,
    Pragma: "no-cache"
  },
  // withCredentials: true,
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: "brackets" });
  },
  timeout: 240000
});

export const getModelStringAPI = () =>
  axiosInstance
    .post("/modelstring", {
      RESTHeader: {
        Responsibility: getUserDetails().RESP_KEY,
        RespApplication: getUserDetails().RESP_APPL_NAME,
        SecurityGroup: "STANDARD",
        NLSLanguage: "AMERICAN",
        Language: "EN",
        Org_Id: getURLDetails().I_ORG_ID
      },
      InputParameters: {
        I_DIV_CODE: getUserDetails().DIV || ""
      }
    })
    .then(response => response.data);

export const getModelNameAPI = ({ id, I_MODEL_DETAILS, I_PARENT_FLAG }) => {
  return axiosInstance
    .post("/findmodelname", {
      RESTHeader: {
        Responsibility: getUserDetails().RESP_KEY,
        RespApplication: getUserDetails().RESP_APPL_NAME,
        SecurityGroup: "STANDARD",
        NLSLanguage: "AMERICAN",
        Language: "EN",
        Org_Id: getURLDetails().I_ORG_ID
      },
      InputParameters: {
        I_MODEL_DETAILS,
        I_ORG_ID: getURLDetails().I_ORG_ID,
        I_PARENT_FLAG
      }
    })
    .then(response => {
      let modelNames = [];
      let baseModels = [];

      try {
        modelNames =
          response.data.OutputParameters.O_MODEL_DETAILS.I_MODEL_DETAILS_ITEM;
        if (!Array.isArray(modelNames)) {
          modelNames = [modelNames];
        }
        baseModels =
          response.data.OutputParameters.O_BASE_MODEL.O_BASE_MODEL_ITEM;
        if (!Array.isArray(baseModels)) {
          baseModels = [baseModels];
        }
      } catch { }

      baseModels = baseModels.map(m => {
        m.id = id;
        return m;
      });
      let OutputParameters = {
        O_ERROR_CODE: response.data.OutputParameters.O_ERROR_CODE,
        O_ERROR_FLAG: response.data.OutputParameters.O_ERROR_FLAG,
        O_ERROR_MSG: response.data.OutputParameters.O_ERROR_MSG
      };
      let data = Object.assign(
        {},
        {
          id,
          modelNames,
          baseModels,
          OutputParameters
        }
      );
      return data;
    });
};

export const getWareHouse = modelName =>
  axiosInstance
    .get("/warehouse", {
      params: {
        I_MODEL: modelName
      }
    })
    .then(response => response.data);

export const getModiferAPI = () =>
  axiosInstance
    .get("/modifiers", {
      params: {
        I_ORG_ID: getURLDetails().I_ORG_ID
      }
    })
    .then(response => response.data);

export const getInsertDataAPI = ({
  modelData,
  urdData,
  RMTurdData,
  lineNumber,
  holdData,
  lineDetailsData,
  emailData
}) => {
  let I_MODEL_REC_ITEM = modelData.map(item => {
    item.HOST_HEADER_ID = getURLDetails().I_HEADER_ID;
    item.SEQUENCE_NUMBER = getUserDetails().SEQUENCE_NUMBER;
    item.ORG_ID = getURLDetails().I_ORG_ID;
    item.APPLICATION_CODE = getURLDetails().I_APP_CODE;
    item.USER_ID = getURLDetails().I_USER_ID;

    if (lineNumber) {
      item.LINE_NUMBER = lineNumber;
    }

    return item;
  });
  emailData = emailData ? emailData : {};
  return axiosInstance
    .post("/insertmodeldata", {
      RESTHeader: {
        Responsibility: getUserDetails().RESP_KEY,
        RespApplication: getUserDetails().RESP_APPL_NAME,
        SecurityGroup: "STANDARD",
        NLSLanguage: "AMERICAN",
        Language: "EN",
        Org_Id: getURLDetails().I_ORG_ID
      },
      InputParameters: {
        I_MODEL_REC: {
          I_MODEL_REC_ITEM
        },
        I_URD_DETAILS_REC: {
          I_URD_DETAILS_REC_ITEM: urdData
        },
        I_RMT_URD_DETAILS_REC: {
          I_RMT_URD_DETAILS_REC_ITEM: RMTurdData
        },
        I_APPLY_HOLD: {
          I_APPLY_HOLD_ITEM: holdData
        },
        I_LINE_NOTES: {
          I_LINE_NOTES_ITEM: lineDetailsData
        },
        I_USER_ID: getURLDetails().I_USER_ID,
        I_USER_EMAIL: emailData.email || "",
        I_EMAIL_FLAG: (emailData.isChecked ? "Y" : "N") || "N"
      }
    })
    .then(response => response.data)
    .catch(e => { });
};

export const updateEnteredModelData = lineNumber => {
  MyEnteredModelAPI().then(data => {
    let intervalId = setInterval(() => {
      let result = false;

      if (
        `${lineNumber}` === `1` &&
        data.OutputParameters.O_MODEL_TYPE_LIST !== null
      ) {
        result = true;
      } else if (`${lineNumber}` !== `1`) {
        let rows =
          data.OutputParameters.O_MODEL_TYPE_LIST.O_MODEL_TYPE_LIST_ITEM;
        let lineNoLength = rows.reduce((acc, curr) => {
          return acc.includes(curr.LINE_NUMBER)
            ? acc
            : acc.concat(curr.LINE_NUMBER);
        }, []).length;
        if (parseInt(lineNumber) === lineNoLength) {
          result = true;
        }
      }

      if (result) {
        clearInterval(intervalId);
      }
    }, 3000);
  });
};

export const getInlineValidationAPI = ({ modelData, divCode, lineNumber }) => {
  let I_CFG_PARAMS_IN = {
    ROW_ID: "12345",
    HOST_HEADER_ID: getURLDetails().I_HEADER_ID,
    SEQUENCE_NUMBER: getUserDetails().SEQUENCE_NUMBER,
    LINE_NUMBER: lineNumber
  };
  modelData.map(items => {
    Object.keys(items).forEach(key => {
      I_CFG_PARAMS_IN[key] = items[key];
    });
    return items;
  });
  return axiosInstance
    .post("/inlinemdlvalidation", {
      RESTHeader: {
        Responsibility: getUserDetails().RESP_KEY,
        RespApplication: getUserDetails().RESP_APPL_NAME,
        SecurityGroup: "STANDARD",
        NLSLanguage: "AMERICAN",
        Language: "EN",
        Org_Id: getURLDetails().I_ORG_ID
      },
      InputParameters: {
        I_QTE_HEADER_REC: [
          {
            ORG_ID: getURLDetails().I_ORG_ID,
            DIV_CODE: getUserDetails().DIV || divCode,
            PRICING_REGION: "US",
            GS_REFERENCE_NUMBER: getUserDetails().SEQUENCE_NUMBER, //sequence no
            GS_SUB_ORDER_NUMBER: getURLDetails().I_HEADER_ID, //header id
            REFERENCE_NAME: "RQOM Shadow Quote TEST",
            CURRENCY_CODE: getUserDetails().CURRENCY_CODE,
            CONTACT_PARTY_ID: "",
            CUST_ACCOUNT_ID: getUserDetails().CUST_ACCOUNT_ID,
            INVOICE_TO_PARTY_SITE_ID: getUserDetails().INVOICE_TO_ORG_ID, //O_INVOICE_TO_ORG_ID
            SOLD_TO_PARTY_SITE_ID: getUserDetails().SOLD_TO_SITE_ID,
            SHIP_TO_PARTY_SITE_ID: getUserDetails().SHIP_TO_PARTY_SITE_ID,
            END_TO_PARTY_SITE_ID: "",
            BILL_TO_CONTACT_ID: "",
            SHIP_TO_CONTACT_ID: ""
          }
        ],
        I_CFG_PARAMS_IN
      }
    })
    .then(response => response.data);
};

export const getUrdInfoAPI = modelName => {
  return axiosInstance
    .get("/urdinfo", {
      params: {
        I_NAME: modelName
      }
    })
    .then(response => {
      let urdSimplifiedData = [];
      try {
        urdSimplifiedData =
          response.data.OutputParameters.O_COMP_LIST.O_COMP_LIST_ITEM;
        if (!Array.isArray(urdSimplifiedData)) {
          urdSimplifiedData = [urdSimplifiedData];
        }
      } catch { }
      let data = Object.assign(
        {},
        {
          urdSimplifiedData
        }
      );
      return data;
    });
};

export const getUserLocationAPI = modelName =>
  axiosInstance
    .get("/enduserloc", {
      params: {
        I_ORG: getURLDetails().I_ORG_ID,
        I_MODEL_NAME: modelName
      }
    })
    .then(response => response.data);

export const EditDataAPI = lineNumber =>
  axiosInstance
    .get("/displaymdlstatus", {
      params: {
        Responsibility: getUserDetails().RESP_KEY,
        RespApplication: getUserDetails().RESP_APPL_NAME,
        SecurityGroup: "STANDARD",
        NLSLanguage: "AMERICAN",
        Language: "EN",
        Org_Id: getURLDetails().I_ORG_ID,
        I_HOST_HEADER_ID: getURLDetails().I_HEADER_ID,
        I_SEQUENCE_NUMBER: getUserDetails().SEQUENCE_NUMBER
      }
    })
    .then(response => {
      return { lineNo: lineNumber, response: response.data };
    });

export const EditUrdDataAPI = (lineNumber, model) =>
  axiosInstance
    .get("/geturdlinesforedit", {
      params: {
        Responsibility: getUserDetails().RESP_KEY,
        RespApplication: getUserDetails().RESP_APPL_NAME,
        SecurityGroup: "STANDARD",
        NLSLanguage: "AMERICAN",
        Language: "EN",
        Org_Id: getURLDetails().I_ORG_ID,
        I_HEADER_ID: getURLDetails().I_HEADER_ID,
        I_SEQUENCE_NUMBER: getUserDetails().SEQUENCE_NUMBER,
        I_LINE_NUMBER: lineNumber,
        I_MODEL: model
      }
    })
    .then(response => {
      return { lineNo: lineNumber, model, response: response.data };
    });

export const DeleteDataAPI = lineNumber =>
  axiosInstance
    .post("/deletemdldata", {
      RESTHeader: {
        Responsibility: getUserDetails().RESP_KEY,
        RespApplication: getUserDetails().RESP_APPL_NAME,
        SecurityGroup: "STANDARD",
        NLSLanguage: "AMERICAN",
        Language: "EN",
        Org_Id: getURLDetails().I_ORG_ID
      },
      InputParameters: {
        I_HEADER_ID: getURLDetails().I_HEADER_ID,
        I_SEQUENCE_NUMBER: getUserDetails().SEQUENCE_NUMBER,
        I_LINE_NUMBER: lineNumber
      }
    })
    .then(response => {
      toastr.success("Model is deleted successfully", "Success");
      return response.data;
    });

/**ADDITIONAL INFO ****/

export const getAdditionalInfoAPI = ({ modelName, warehouse, divCode }) =>
  axiosInstance
    .get("/line3lpfields", {
      params: {
        I_HOST_HEADER_ID: getURLDetails().I_HEADER_ID,
        I_APPLICATION_CODE: getURLDetails().I_APP_CODE,
        I_RESP_ID: getURLDetails().I_RESP_ID,
        I_ORG_ID: getURLDetails().I_ORG_ID,
        I_ITEM: modelName || "",
        I_INV_ORG_CODE: warehouse || "",
        I_DIVISION: divCode || ""
      }
    })
    .then(response => response.data);

export const getTaskNumAPI = projectId =>
  axiosInstance
    .get("/tasknumber", {
      params: {
        I_PROJECT_ID: projectId ? projectId : 0
      }
    })
    .then(response => response.data);

export const getlinePriceAPI = data => {
  let I_LINE_NUMBER_ITEM = {
    I_LINE_NUMBER_ITEM: data.lineNumbers
  };

  axiosInstance
    .post("/lineprice", {
      RESTHeader: {
        Responsibility: getUserDetails().RESP_KEY,
        RespApplication: getUserDetails().RESP_APPL_NAME,
        SecurityGroup: "STANDARD",
        NLSLanguage: "AMERICAN",
        Language: "EN",
        Org_Id: getURLDetails().I_ORG_ID
      },
      InputParameters: {
        I_HEADER_ID: getURLDetails().I_HEADER_ID,
        I_SEQ_NUMBER: getUserDetails().SEQUENCE_NUMBER,
        I_APPLICATION_CODE: getURLDetails().I_APP_CODE,
        I_LINE_NUMBER: I_LINE_NUMBER_ITEM,
        I_MANUAL_MODIFIER: data.modifier,
        I_PRICE_ADJUSTMENT: data.priceValue,
        I_ADJUSTMENT_METHOD: data.operator
      }
    })
    .then(response => {
      toastr.success("Modifier is updated successfully", "Success");
      return response.data;
    });
};

export const getUrdRMTAPI = ({
  parsedgetHeaderId,
  parsedRevNumber,
  RMTModelname,
  lineNumberRMT,
  lineNumber
}) => {
  return axiosInstance
    .get("/rmturdinfo", {
      params: {
        I_MODEL_NAME: RMTModelname,
        I_HEADER_ID: getURLDetails().I_HEADER_ID,
        I_SEQUENCE_NUMBER: getUserDetails().SEQUENCE_NUMBER,
        // I_SEQUENCE_NUMBER: 4,
        I_LINE_NUMBER: lineNumberRMT,
        I_CONFIG_HEADER_ID: parsedgetHeaderId || "",
        I_CONFIG_REV_NUMBER: parsedRevNumber || ""
      }
    })
    .then(response => {
      let urdSimplifiedData = [];
      try {
        urdSimplifiedData =
          response.data.OutputParameters.O_COMP_LIST.O_COMP_LIST_ITEM;
        if (!Array.isArray(urdSimplifiedData)) {
          urdSimplifiedData = [urdSimplifiedData];
        }
      } catch { }
      let data = Object.assign(
        {},
        {
          urdSimplifiedData
        }
      );
      return data;
    });
};

export const getPriceRollUpDataAPI = () =>
  axiosInstance
    .get("/getassemblepricerollup", {
      params: {
        I_HOST_HEADER_ID: getURLDetails().I_HEADER_ID,
        I_SEQUENCE_NUMBER: getUserDetails().SEQUENCE_NUMBER
      }
    })
    .then(response => response.data);


export const getAssembleToDataAPI = (payload) => {
  let I_ASSEMBLE_P_ROLLUP_ITEM = payload && payload.map(item => {
    if (item.ASSEMBLE_TO) {
      item["ASSEMBLE_TO"] = item.ASSEMBLE_TO.split('-')[0];
    }
    return item;
  });
  return axiosInstance
    .post("/updateassemblepricerollup", {
      RESTHeader: {
        Responsibility: getUserDetails().RESP_KEY,
        RespApplication: getUserDetails().RESP_APPL_NAME,
        SecurityGroup: "STANDARD",
        NLSLanguage: "AMERICAN",
        Language: "EN",
        Org_Id: getURLDetails().I_ORG_ID
      },
      InputParameters: {
        I_HOST_HEADER_ID: getURLDetails().I_HEADER_ID,
        I_SEQUENCE_NUMBER: getUserDetails().SEQUENCE_NUMBER,
        I_APPLICATION_CODE: getURLDetails().I_APP_CODE,
        I_ASSEMBLE_P_ROLLUP: {
          I_ASSEMBLE_P_ROLLUP_ITEM
        },
      }
    })
    .then(response => {
      return response.data;
    });
};

// export const getUrdRMTAPI = ({ modelNameString, lineNumber }) =>
//   axios({
//     method: 'POST',
//     url: "http://usmtnasdebswi0e.dev.emrsn.org:34104/webservices/rest/rmt_urd_info/get_rmt_urd_info/",
//     headers: { 'Content-Type': 'application/json' },
//     data: {
//       "RESTHeader": {
//         "Responsibility": "XXASO_QUOTING_MMI",
//         "RespApplication": "ASO",
//         "SecurityGroup": "STANDARD",
//         "NLSLanguage": "AMERICAN",
//         "Language": "EN",
//         "Org_Id": "85"
//       },
//       "InputParameters": {
//         I_MODEL_NAME: modelNameString,
//         I_HEADER_ID: getURLDetails().I_HEADER_ID,
//         I_SEQUENCE_NUMBER: getUserDetails().SEQUENCE_NUMBER,
//         I_LINE_NUMBER: lineNumber
//       }
//     }
//   });
