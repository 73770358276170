const GET_MODELSTRING = 'GET_MODELSTRING';
const GET_MODELSTRING_SUCCESS = 'GET_MODELSTRING_SUCCESS';
const GET_MODELSTRING_ERROR = 'GET_MODELSTRING_ERROR';
const GET_MODELNAME = 'GET_MODELNAME';
const GET_MODELNAME_SUCCESS = 'GET_MODELNAME_SUCCESS';
const GET_MODELNAME_ERROR = 'GET_MODELNAME_ERROR';
const CLEAR_MODELNAME = 'CLEAR_MODELNAME';
const CLEAR_MODELNAME_SUCCESS = 'CLEAR_MODELNAME_SUCCESS';
const CLEAR_MODELNAME_ERROR = 'CLEAR_MODELNAME_ERROR';
export const CLEAR_BASE_MODELS = 'CLEAR_BASE_MODELS';
export const CLEAR_BASE_MODELS_SUCCESS = 'CLEAR_BASE_MODELS_SUCCESS';
export const CLEAR_BASE_MODELS_ERROR = 'CLEAR_BASE_MODELS_ERROR';
const GET_WAREHOUSE = 'GET_WAREHOUSE';
const GET_WAREHOUSE_SUCCESS = 'GET_WAREHOUSE_SUCCESS';
const GET_WAREHOUSE_ERROR = 'GET_WAREHOUSE_ERROR';
const GET_MODIFIERS = 'GET_MODIFIERS';
const GET_MODIFIERS_SUCCESS = 'GET_MODIFIERS_SUCCESS';
const GET_MODIFIERS_ERROR = 'GET_MODIFIERS_ERROR';
const GET_INLINE_VALIDATION = 'GET_INLINE_VALIDATION';
const GET_INLINE_VALIDATION_SUCCESS = 'GET_INLINE_VALIDATION_SUCCESS';
const GET_INLINE_VALIDATION_ERROR = 'GET_INLINE_VALIDATION_ERROR';
const GET_INSERT_DATA = 'GET_INSERT_DATA';
const GET_INSERT_DATA_SUCCESS = 'GET_INSERT_DATA_SUCCESS';
const GET_INSERT_DATA_ERROR = 'GET_INSERT_DATA_ERROR';
const GET_URD_DATA = 'GET_URD_DATA';
const GET_URD_DATA_SUCCESS = 'GET_URD_DATA_SUCCESS';
const GET_URD_DATA_ERROR = 'GET_URD_DATA_ERROR';
const GET_USER_LOCATION = 'GET_USER_LOCATION';
const GET_USER_LOCATION_SUCCESS = 'GET_USER_LOCATION_SUCCESS';
const GET_USER_LOCATION_ERROR = 'GET_USER_LOCATION_ERROR';
const UPDATE_URD_INSERT_DATA = 'UPDATE_URD_INSERT_DATA';
const UPDATE_URD_INSERT_DATA_SUCCESS = 'UPDATE_URD_INSERT_DATA_SUCCESS';
const UPDATE_URD_INSERT_DATA_ERROR = 'UPDATE_URD_INSERT_DATA_ERROR';
const CLEAR_SAVE_ERROR = 'CLEAR_SAVE_ERROR';
const CLEAR_SAVE_ERROR_SUCCESS = 'CLEAR_SAVE_ERROR_SUCCESS';
const CLEAR_SAVE_ERROR_ERROR = 'CLEAR_SAVE_ERROR_ERROR';
const QUANTITY_CHANGE = 'QUANTITY_CHANGE';
const QUANTITY_CHANGE_SUCCESS = 'QUANTITY_CHANGE_SUCCESS';
const QUANTITY_CHANGE_ERROR = 'QUANTITY_CHANGE_ERROR';
const UPDATE_MODEL = 'UPDATE_MODEL';
const UPDATE_URD = 'UPDATE_URD';
const ADD_ROWS = 'ADD_ROWS';
const DELETE_ROWS = 'DELETE_ROWS';
const GET_EDIT_DATA='GET_EDIT_DATA';
const GET_EDIT_DATA_SUCCESS='GET_EDIT_DATA_SUCCESS';
const GET_EDIT_DATA_ERROR='GET_EDIT_DATA_ERROR';
const CLEAR_URD_DATA = 'CLEAR_URD_DATA';
const CLEAR_URD_DATA_SUCCESS = 'CLEAR_URD_DATA_SUCCESS';
const CLEAR_URD_DATA_ERROR = 'CLEAR_URD_DATA_ERROR';
export const ADD_NEW_ROW ='ADD_NEW_ROW' ;
export const ADD_NEW_ROW_SUCCESS ='ADD_NEW_ROW_SUCCESS' ;
export const ADD_NEW_ROW_ERROR ='ADD_NEW_ROW_ERROR';
export const TASK_NUMBER ='TASK_NUMBER' ;
export const TASK_NUMBER_SUCCESS ='TASK_NUMBER_SUCCESS' ;
export const TASK_NUMBER_ERROR ='TASK_NUMBER_ERROR';
export const UPDATE_ROW ='UPDATE_ROW';
export const HANDLE_PICK_CHANGE = 'HANDLE_PICK_CHANGE';
export const ADDITIONAL_INFO = 'ADDITIONAL_INFO';
export const ADDITIONAL_INFO_SUCCESS = 'ADDITIONAL_INFO_SUCCESS';
export const ADDITIONAL_INFO_ERROR = 'ADDITIONAL_INFO_ERROR';
export const ADD_ROWS_APPLY_HOLDS = 'ADD_ROWS_APPLY_HOLDS';
export const GET_APPLY_HOLDS = 'GET_APPLY_HOLDS';
export const GET_APPLY_HOLDS_SUCCESS = 'GET_APPLY_HOLDS_SUCCESS';
export const GET_APPLY_HOLDS_ERROR = 'GET_APPLY_HOLDS_ERROR';
export const LINE_PRICE = 'LINE_PRICE';
export const LINE_PRICE_SUCCESS = 'LINE_PRICE_SUCCESS';
export const LINE_PRICE_ERROR = 'LINE_PRICE_ERROR';
export const ADD_ROWS_LINE_NOTES = 'ADD_ROWS_LINE_NOTES';
export const UPDATE_LINE_HOLD = "UPDATE_LINE_HOLD"
export const UPDATE_HOLD_ROW = "UPDATE_HOLD_ROW";
export const GET_RMT_URD_DATA = 'GET_RMT_URD_DATA';
export const GET_RMT_URD_DATA_SUCCESS = 'GET_RMT_URD_DATA_SUCCESS';
export const GET_RMT_URD_DATA_ERROR = 'GET_RMT_URD_DATA_ERROR';
export const UPDATE_LINE_CATEGORY ='UPDATE_LINE_CATEGORY';
export const UPDATE_LINE_NOTES_ROW = 'UPDATE_LINE_NOTES_ROW';
export const CLEAR_APPLY_HOLDS = 'CLEAR_APPLY_HOLDS';
export const ADDITION_BUTTON = 'ADDITION_BUTTON';
export const COPY_MFG_NOTES = 'COPY_MFG_NOTES' ; //RT_002332 sudhakar
export const COPY_MFG_NOTES_SUCCESS = 'COPY_MFG_NOTES_SUCCESS' ; //RT_002332 sudhakar
export const COPY_TAG_DTLS = 'COPY_TAG_DTLS' ; //RT_002332 sudhakar
export const COPY_TAG_DTLS_SUCCESS = 'COPY_TAG_DTLS_SUCCESS' ; //RT_002332 sudhakar
export const COPY_CALIB_DTLS = 'COPY_CALIB_DTLS' ; //RT_002332 sudhakar
export const COPY_CALIB_DTLS_SUCCESS = 'COPY_CALIB_DTLS_SUCCESS' ; //RT_002332 sudhakar
export const UPDATE_INITIAL_DATA = "UPDATE_INITIAL_DATA";
export const IMPORT_EXCEL_DATA = 'IMPORT_EXCEL_DATA';
export const IMPORT_EXCEL_DATA_SUCCESS = 'IMPORT_EXCEL_DATA_SUCCESS';
export const IMPORT_EXCEL_DATA_ERROR = 'IMPORT_EXCEL_DATA_ERROR';
export const IMPORT_PASTELIST = 'IMPORT_PASTELIST';
export const IMPORT_PASTELIST_SUCCESS = 'IMPORT_PASTELIST_SUCCESS';
export const IMPORT_PASTELIST_ERROR = 'IMPORT_PASTELIST_ERROR';
export const EMAIL_NOTIFICATION = 'EMAIL_NOTIFICATION';
export const EMAIL_NOTIFICATION_SUCCESS = 'EMAIL_NOTIFICATION_SUCCESS';
export const EMAIL_NOTIFICATION_ERROR = 'EMAIL_NOTIFICATION_ERROR';
export const STATUS_IMPORT_EXCEL_INIT = 0;
export const STATUS_IMPORT_EXCEL_SUCCESS = 1;
export const STATUS_IMPORT_EXCEL_FAILED = 2;
export const STATUS_INSERT_SUCCESS = 3;
export const STATUS_INSERT_FAILED = 4;
export const STATUS_IMPORT_PASTELIST_INIT = 5;
export const STATUS_IMPORT_PASTELIST_SUCCESS = 6;
export const STATUS_IMPORT_PASTELIST_FAILED = 7;
export const STATUS_GET_MODELNAME_INIT = 8;
export const STATUS_GET_MODELNAME_SUCCESS = 9;
export const STATUS_GET_MODELNAME_FAILED = 10;
export const STATUS_EMAIL_NOTIFICATION_INIT = 11;
export const STATUS_EMAIL_NOTIFICATION_SUCCESS = 12;
export const STATUS_EMAIL_NOTIFICATION_ERROR = 13;
export const UPDATE_URD_DISPLAY_DATA = "UPDATE_URD_DISPLAY_DATA";
export const GET_PRICE_ROLLUP_DATA = 'GET_PRICE_ROLLUP_DATA';
export const GET_PRICE_ROLLUP_DATA_SUCCESS = 'GET_PRICE_ROLLUP_DATA_SUCCESS';
export const GET_PRICE_ROLLUP_DATA_ERROR = 'GET_PRICE_ROLLUP_DATA_ERROR';
export const GET_ASSEMBLETO = 'GET_ASSEMBLETO';
export const GET_ASSEMBLETO_SUCCESS = 'GET_ASSEMBLETO_SUCCESS';
export const GET_ASSEMBLETO_ERROR = 'GET_ASSEMBLETO_ERROR';
export const SAVE_PRICEROLL = 'SAVE_PRICEROLL';
export const SAVE_PRICEROLL_SUCCESS = 'SAVE_PRICEROLL_SUCCESS';
export const SAVE_PRICEROLL_ERROR = 'SAVE_PRICEROLL_ERROR';
export const STATUS_URD_INIT = 14;
export const STATUS_URD_SUCCESS = 15;
export const STATUS_URD_FAILED = 16;

export const UPDATE_REQUIRED_MULTI_LEVEL_MODEL = 'UPDATE_REQUIRED_MULTI_LEVEL_MODEL';

export {
    GET_MODELSTRING, GET_MODELSTRING_SUCCESS, GET_MODELSTRING_ERROR,
    GET_MODELNAME, GET_MODELNAME_SUCCESS, GET_MODELNAME_ERROR,
    GET_WAREHOUSE, GET_WAREHOUSE_SUCCESS, GET_WAREHOUSE_ERROR,
    GET_MODIFIERS, GET_MODIFIERS_SUCCESS, GET_MODIFIERS_ERROR,
    GET_INLINE_VALIDATION, GET_INLINE_VALIDATION_SUCCESS, GET_INLINE_VALIDATION_ERROR,
    GET_INSERT_DATA, GET_INSERT_DATA_SUCCESS, GET_INSERT_DATA_ERROR,
    GET_URD_DATA, GET_URD_DATA_SUCCESS, GET_URD_DATA_ERROR,
    GET_USER_LOCATION, GET_USER_LOCATION_SUCCESS, GET_USER_LOCATION_ERROR,
    CLEAR_MODELNAME, CLEAR_MODELNAME_SUCCESS, CLEAR_MODELNAME_ERROR,
    UPDATE_URD_INSERT_DATA, UPDATE_URD_INSERT_DATA_SUCCESS, UPDATE_URD_INSERT_DATA_ERROR,
    CLEAR_SAVE_ERROR, CLEAR_SAVE_ERROR_SUCCESS, CLEAR_SAVE_ERROR_ERROR,
    QUANTITY_CHANGE, QUANTITY_CHANGE_SUCCESS, QUANTITY_CHANGE_ERROR,
    ADD_ROWS, DELETE_ROWS, UPDATE_MODEL, UPDATE_URD,
    GET_EDIT_DATA, GET_EDIT_DATA_SUCCESS, GET_EDIT_DATA_ERROR,
    CLEAR_URD_DATA, CLEAR_URD_DATA_SUCCESS, CLEAR_URD_DATA_ERROR
};