import React from 'react';
import PropTypes from 'prop-types';

const SearchableTextInput = ({
    name, value, type, placeholder, error, onChange, options,
    onSelect, showList, disabled, onBlur, onPaste, onKeyDown
}) => {
    const listClass = showList ? "d-block" : "d-none";
    disabled = disabled ? disabled : false;
    return (
        <>
            {/* Passing input type , name, value, onChange, onPaste, title, OnBlur, onKeyDown,
         placeholder, disabled, classname, autoComplete , onKeyPress */}
            <input type={type} name={name} value={value}
                onChange={onChange} placeholder={placeholder} onPaste={onPaste}
                className="form-input text-truncate"
                title={value} autoComplete="off" disabled={disabled} onBlur={onBlur}
                onKeyDown={onKeyDown} />
            <div className={"position-relative w-100 " + listClass} >
                <div className="model-string-lists position-absolute">
                    <ul className="p-0 m-0">
                        {/* Displaying the options in dropdown */}
                        {options &&
                            options.map((item, i) => (
                                <li
                                    onClick={onSelect}
                                    key={i}
                                >
                                    {item}
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
            {
                // Display Error message
                error && (
                    <div className="error-text alert alert-danger position-absolute w-75">{error}</div>
                )
            }
        </>

    );
};

SearchableTextInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    type: PropTypes.any.isRequired,
    placeholder: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.object),
    onSelect: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}

export default SearchableTextInput;