import React, { Component, Fragment } from 'react';
import { displayData, fieldMapping, reverseFieldMapping } from "../../../../utils/urd-template";
import { prepareInitialUrdDisplayData, getReferenceField, prepareChildUrdDisplayData, prepareSelectedTabData } from '../../../../utils/helper';
import SelectInput from '../../../shared/SelectInput';
import TextInput from '../../../shared/TextInput';
import IntegerInput from '../../../shared/IntegerInput';
import DecimalInput from '../../../shared/DecimalInput';
import toastr from "toastr";
import {COPY_MFG_NOTES,COPY_TAG_DTLS,COPY_CALIB_DTLS} from '../../../../store/model-data/constant' //RT_002332 sudhakar

class UrdTabDetails extends Component {
    constructor(props) {
        super(props);
        this.state = { // initial state
            isEnabled: true,
            checked: true,
            wireCheck: false
        }
    }
    selectedTabData = [];
    selectedTab = "";
    groupRelation = {};

    // init function to update URD on value change, restore state, and prepare initial display data 
    initUrd(props) {
        const { urdProcessedDetails, quantityTag, data, updateUrdDisplayData, id } = props;
        let restoreState = Object.keys(data).map(x => reverseFieldMapping[x]);
        const tabApiData = prepareSelectedTabData(this.selectedTab, urdProcessedDetails, fieldMapping);
        if (tabApiData.length > 0) {
            const initialTabControls = prepareInitialUrdDisplayData(this.selectedTab, tabApiData, displayData, parseInt(quantityTag), [], fieldMapping);
            const NewinitialTabControls = initialTabControls.filter(value => Object.keys(value).length !== 0);
            this.selectedTabData = tabApiData;
            let urdDisplayData = NewinitialTabControls.reduce((o, d) => (o[d.name] = d, o), {})
            restoreState = restoreState.filter(x => Boolean(
                displayData[this.selectedTab].find(item => {
                    return Boolean(item && item["display-features"][x])                
            })
            ));
            let fields = restoreState.map((key) => {
                let field = fieldMapping[key];
                let customEvent = {
                    target: {
                        name: field,
                        value: data[field]
                    }
                }
                return this.onUrdValueChange(customEvent, false, props)
            }).flat();
            let newUrdDisplayData = fields.reduce((o, d) => (o[d.name] = d, o), urdDisplayData)
            // call action to update store with row.id
            updateUrdDisplayData({ urdDisplayData: newUrdDisplayData, id })
        }
    }

    // Life cycle method calle when the props is changed or updated in store 
    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedKey !== "" && nextProps.data && (nextProps.selectedKey !== this.props.selectedKey || JSON.stringify(nextProps.urdProcessedDetails) !== JSON.stringify(this.props.urdProcessedDetails) || JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data) || parseInt(nextProps.quantityTag) !== parseInt(this.props.quantityTag))) {
            this.selectedTab = nextProps.selectedKey.replace(/(\|\|\|\d+\|\|\|)/, '');
            this.initUrd(nextProps);
        }
    }

    render() {
        const { urdDisplayData } = this.props; //Object.values(this.urdDisplayData);
        const { data, dispatchAction,copyMFGNotes,copyTagDtls,copyCalibDtls, id, unit,quantityTag } = this.props; //RT_002332 sudhakar
        //const { data, dispatchAction, id } = this.props;
        const { isEnabled } = this.state;
        let lastHeading = '';
        let organizedByGroupName
        organizedByGroupName = Object.values(urdDisplayData).reduce((o, x) => {
            if (o[x.groupName] !== undefined) o[x.groupName].push(x);
            else o[x.groupName] = [x];
            return o;
        }, {})

        return (
            <div className="col-12">
                <div className="row my-2">
                    <div className={"col-md-12 urd-labels" + ' ' + (this.selectedTab === "TAGS" ? "vortex-section col-md-12" : "")}>
                        {
                            Object.values(organizedByGroupName).flat().map((c, key) => {
                                let isGrouped = lastHeading === c.groupName;
                                lastHeading = c.groupName;
                                let label = (parseInt(c.min) > 0) ? '*' + c.label : c.label;
                                switch (c.type) {
                                    case 'dropdown': // when the input is a dropdown
                                        return (<Fragment>
                                            {c.groupName && !isGrouped ? <h4 className={c.groupType}>{c.groupName}</h4> : null}
                                            <SelectInput key={key} label={label} name={c.name}
                                                value={data[c.name] ? data[c.name] : ''}
                                                options={c.options} defaultOption={'--Select ' + c.label + '--'}
                                                onChange={this.onUrdValueChange} />
                                        </Fragment>)

                                    case 'decimal': // when the input is a text 
                                        return (<Fragment>
                                            {c.groupName && !isGrouped ? <h4 className={c.groupType}>{c.groupName}</h4> : null}
                                            <DecimalInput key={key} wrapperClass="" label={c.label}
                                                value={data[c.name] ? data[c.name] : ''} name={c.name} maxlength="10"
                                                onChange={this.onUrdValueChange} onKeyPress={this.onUrdkeypressDecimal} type="number" min="-9999" max="-1" />
                                        </Fragment>)

                                    case 'text': // when the input is a text //RT_002332 copy mfg notes sudhakar
                                        return (<Fragment>
                                            {c.groupName && !isGrouped ? <h4 className={c.groupType}>{c.groupName} 
                                            {quantityTag > 1 ? <button onClick={() => copyMFGNotes(COPY_MFG_NOTES, { id,unit })} className="btn btn-default float-right" name={c.name}>COPY {c.label} </button>:null }
                                            </h4> : null}
                                            <TextInput key={key} wrapperClass={c} label={c.label} value={data[c.name] ? data[c.name] : ''}
                                                type="text" name={c.name} maxlength={c.maxChar ? c.maxChar : "4000"} onChange={this.onUrdValueChange} />
                                        </Fragment>)


                                    case 'integer': // when the input is a integer 
                                        return (<Fragment>
                                            {c.groupName && !isGrouped ? <h4 className={c.groupType}>{c.groupName}</h4> : null}
                                            <IntegerInput key={key} type="number" label={c.label} name={c.name} value={data[c.name] ? data[c.name] : ''}
                                                onChange={this.onUrdValueChange} onkeypressInteger={this.onUrdkeypressInteger} />
                                        </Fragment>)

                                    case 'textForRMT': // when the input is a text for RMT 
                                        let textForRMTName = fieldMapping[c.name] ? fieldMapping[c.name] : c.name;
                                        return (<Fragment>
                                            {c.groupName && !isGrouped ? <h4 className={c.groupType}>{c.groupName}</h4> : null}
                                            <TextInput key={key} wrapperClass={c} label={c.label} value={data[textForRMTName] ? data[textForRMTName] : ''}
                                                type="text" name={fieldMapping[c.name] ? fieldMapping[c.name] : c.name} maxlength={c.maxChar} onChange={this.onUrdValueChange} />
                                        </Fragment>)

                                    case 'boolean':// when the input is a checkbox 
                                        return (<Fragment>
                                            {c.groupName && !isGrouped ? <h4 className={c.groupType}>{c.groupName}</h4> : null}
                                            <TextInput key={key} wrapperClass="booleanClass" label={c.label} value={data[c.name] ? data[c.name] : ''}
                                                type="checkbox" name={c.name} onChange={this.onUrdValueChange} checked={(data[c.name] ? data[c.name] : c.initialvalue) === "Y"} />
                                        </Fragment>)

                                    case 'static': // when the input is a text //RT_002332 to copy calib -- sudhakar
                                        return (<Fragment>
                                            {c.groupName && !isGrouped ? <h4 className={c.groupType}>{c.groupName}
                                            {quantityTag > 1 ? <button onClick={() => copyCalibDtls(COPY_CALIB_DTLS, { id,unit })} className="btn btn-default float-right" name={c.name}>COPY {c.label} </button> :null}                                          
                                            </h4> : null}
                                            <TextInput key={key} wrapperClass={c} label={c.label} value={data[c.name] ? data[c.name] : ''}
                                                type="text" name={c.name} maxlength={c.maxChar ? c.maxChar : "4000"} onChange={this.onUrdValueChange} disabled={isEnabled} />
                                        </Fragment>)

                                    case 'textJKey': // when the input is a text 
                                        return (<Fragment>
                                            {c.groupName && !isGrouped ? <h4 className={c.groupType}>{c.groupName}</h4> : null}
                                            <TextInput key={key} wrapperClass={c} label={c.label} value={data[c.name] ? data[c.name] : ''}
                                                type="text" name={c.name} maxlength={c.maxChar ? c.maxChar : "4000"} onChange={this.onUrdValueChange} onKeyPress={this.JKeyOnKeyPress} />
                                        </Fragment>)

                                    case 'buttonFeature': // when the input is a button //RT_002332, to copy tag details, sudhakar
                                        return (<Fragment>
                                            {c.groupName && !isGrouped ? <h4 className={c.groupType}>{c.groupName} 
                                            <div className="float-right">
                                            {quantityTag > 1 ?<button onClick={() => copyTagDtls(COPY_TAG_DTLS, { id,unit })} className="btn btn-default">Copy Tag Details</button> :null}
                                            &nbsp;
                                            <button onClick={(e) => dispatchAction(e.target.name, { id })} name={c.name} className="btn btn-default">{c.label}</button>
                                            </div>   
                                            </h4> : null}
                                            <div className="clearfix"></div>

                                        </Fragment>)
                                    default:
                                        return (
                                            <p></p>
                                        )
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }

    // ===== Function called when onChange event is triggered =====
    onUrdValueChange = (e, updateState = true, props = this.props) => {
        const { onChange, id, data, unit, urdDisplayData } = props;
        const urdDisplayValues = Object.values(urdDisplayData);
        let field = e.target.name;
        let value = e.target.value;
        let shouldUpdate = true;
        if (e.target.checked !== undefined && (field === "NP" || field === "NP1" || field === "NP2" || field === "NP3" || field === "NP4")) {
            value = e.target.checked ? "Y" : "N";
        }
        // To check if the maximum count has reached to select Tag Type 
        if (updateState && field.startsWith("TAG_TYPE")) {
            let count = Object.values(data).filter(key => key === value);
            let node = urdDisplayValues.find(c => c.name === field)
            if (node) {
                let optionSelected = node.options.find(x => x.value === value);
                let maxCount = parseInt(optionSelected ? optionSelected.maxSelect : 0);
                if (count.length >= maxCount) {
                    shouldUpdate = false;
                    toastr.warning(`Limits for tags of type ${value} is reached`, "Warning");
                }
            }
        }

        // Getting Wire Indexes
        let wireIndex = 1;
        let wireIndexes = {};
        let wireTags = Object.keys(data)
            .filter(key => key.startsWith("TAG_TYPE") && data[key] === "WIRE").sort()
            .map((key, i) => {
                wireIndexes[key] = i + 1;
                return key;
            });

        wireIndex = wireTags.length + 1;
        if (value === "WIRE") { // to check only when the value is WIRE
            if (wireTags.includes(field)) {
                wireIndex = wireIndexes[field]
            }
        }

        let fields = shouldUpdate ? this.reGenerateDynamicFields(field, value, updateState, wireIndex) : [];
        let urd = {
            ...data,
            ...(shouldUpdate ? fields.map(x => x.name).reduce((o, key) => (o[key] = field === key ? value : data[key], o), { [field]: value }) : {})
        }
        onChange({ id, urd, unit });
        return fields;
    }

    removeRelationFields = (field, value, force = false, history = []) => {
        const { id, updateUrdDisplayData } = this.props;
        let urdDisplayData = Object.assign({}, this.props.urdDisplayData);
        history.push(`${field}|${value}`);
        if (this.groupRelation[field] && (this.groupRelation[field].value !== value || force)) {
            this.groupRelation[field].result.map(x => x.name).forEach(x => {
                delete urdDisplayData[x];
                updateUrdDisplayData({ urdDisplayData, id })
                if (!history.includes(`${x}|${this.props.data[x]}`))
                    this.removeRelationFields(x, this.props.data[x], true, history)
            })
        }
    }

    // ===== Function called when any dropdown is selected and the corresponding fields are populated ======
    reGenerateDynamicFields = (field, value, setState = true, wireIndex = null) => {
        const { id, updateUrdDisplayData } = this.props;
        let urdDisplayData = Object.assign({}, this.props.urdDisplayData);
        let urdDisplayValues = Object.values(urdDisplayData);
        urdDisplayData = urdDisplayValues.filter(item => item.parentOf !== field);
        const { selectedTabData } = this;
        // Generate dynamic fields based on the selection
        let parent = getReferenceField(field, fieldMapping);
        const restoreState = {};
        // === to get the child elements on selecting any dropdown value
        const result = prepareChildUrdDisplayData(this.selectedTab, parent, field, value, displayData, selectedTabData, restoreState, wireIndex);
        var resetGroups = Array.from(new Set(result.map(x => x.groupName))).filter(x => x !== "");
        Object.entries(urdDisplayData).forEach(([key, value]) => {
            if (resetGroups.includes(value.groupName) && key !== field) delete urdDisplayData[key];
        })
        this.removeRelationFields(field, value);
        this.groupRelation[`${field}`] = { value, result };
        setState && (urdDisplayData = result.reduce((o, d) => (o[d.name] = d, o), urdDisplayData));
        updateUrdDisplayData({ urdDisplayData, id });
        return result;
    }

    // ===== Function called when OnKeypress event is triggered for interger input=====
    onUrdkeypressInteger = (event) => {
        var charCode = (event.which) ? event.which : event.keyCode
        if (charCode > 31 && charCode === 109 && (charCode < 48 || charCode > 57)) {
            event.preventDefault()
            return false;
        }
        return true;
    }

    // ===== Function called when OnKeypress event is triggered for Decimal input=====
    onUrdkeypressDecimal = (event) => {
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode !== 46 && charCode === 189 && charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
            return false;
        }
        return true;
    }

    JKeyOnKeyPress = (event) => {
        if ((event === 32) && (48 <= event && event <= 57) && (65 <= event && event <= 70) && (97 <= event && event <= 102)) {
            event.preventDefault();
            return false;
        }
        return true;
    }
}

export default UrdTabDetails;