import React from 'react';

export default ({ onPaste = () => { }, children }) => {
    return (
        <div 
            onPaste={e=>{ // Works for chrome
                onPaste(e.clipboardData.getData('text'));
            }}

            onKeyDown={(e) => { //Fallback for IE
                if ((e.keyCode === 86 || e.which === 86) && e.ctrlKey) {
                    let data = window.clipboardData ? window.clipboardData.getData('text') : '';
                    if (Boolean(data)) {
                        onPaste(data);
                    }
                }
            }}
        >
            {children}
        </ div>
    );
}