import React from 'react';
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";

const Popup = ({ title, message, type, onOK, onCancel }) => {
    // reusable component for error pop up messages 
    return (
        <div>
            <Modal show={true} onHide={onCancel}>
                {/* Modal Header */}
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                {/* Modal Body */}
                <Modal.Body>
                    <p>{message}</p>
                </Modal.Body>
                {/* Modal Footer */}
                <Modal.Footer>
                    <Button variant="primary" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={onOK}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Popup;