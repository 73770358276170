import { connect } from 'react-redux';
import ApplyHoldsRows from './ApplyHoldsRows';
import { updateLineHold, updateHoldRow, updateModel, clearHoldRow, removeSaveErrors } from "../../../store/model-data";
import { getDivisionCode } from '../../../utils/helper';

// ===== Map state received from reducer to props  =====
const mapStateToProps = (state, ownProps) => {
    return {
        seedData: state.modalDataStore.modalData.rows.map(i => i.additionalDataDetails), // fetching the entire master data
        data: state.modalDataStore.modalData.ApplyHoldRows.find(x => x.id === ownProps.id),// Fetching the apply hold data based on row id 
        holdData: state.modalDataStore.modalData.initialRowApplyHold, // Fetching the initial row data
        onSaveError: state.modalDataStore.saveErrors, // fetching the save error data
        divCode: getDivisionCode(state.modalDataStore) || "", // To get the divcode
    }
};

// ===== Mapping dispatch functions to the props  =====
const mapDispatchToProps = {
    updateHoldName: payload => updateLineHold(payload), // dispatching updated line holds
    updateHoldRow: payload => updateHoldRow(payload), // dispatching  updated hold rows
    updateRow: payload => updateModel(payload), // dispatching  models to update
    clearHoldRow: payload => clearHoldRow(payload), // dispatching  to clear the hold data
    clearSaveErrors: payload => removeSaveErrors(payload), // dispatching to remove the save errors 
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplyHoldsRows); //The connect() function connects a React component ApplyHoldsRows to a Redux store(modalDataStore).
