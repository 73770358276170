import React from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const TextInput = ({
    name, value, type, placeholder, error, onChange, wrapperClass, disabled,
    inputClass, min, max, onPaste, label, onBlur, maxlength,
    checked, onKeyDown }) => {
    wrapperClass = wrapperClass ? wrapperClass : '';
    inputClass = inputClass ? inputClass : 'form-input';

    return (
        <div className={wrapperClass}>
            {
                // Dynamic label
                label && (
                    <label className="form-label mr-2">{label}</label>
                )
            }
            {/* Passing input type , name, value, onChange, placeholder, disabled, classname, 
            min and max value, autoComplete , onKeyPress, maxLength, title, onPaste, checked */}
            <input type={type} name={name} value={value}
                onChange={onChange} placeholder={placeholder}
                title={value}
                onBlur={onBlur} disabled={disabled} className={inputClass} min={min} max={max}
                autoComplete="off" onKeyPress={(e) => rangeValidation(e, min, max)}
                onKeyDown={onKeyDown}
                onPaste={onPaste} maxlength={maxlength} checked={checked} />
            {
                // Error message
                error && (
                    <div className="error-text alert alert-danger position-absolute w-100">{error}</div>
                )
            }
        </div>

    );
};

// OnkeyPress Validation to limit the quantity to 50
const rangeValidation = (e, min, max) => {
    let controlValue = parseInt(e.target.value + e.key)
    if (controlValue > max) {
        e.target.value = max;
        e.preventDefault();
        toastr.warning('Quantity should be less than 50', 'Warning');
    }
    if (controlValue < min) {
        e.target.value = min;
        e.preventDefault();
    }
    return true;
}

// ===== To declare that a prop is a specific JS primitive. ===== 
TextInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    type: PropTypes.any.isRequired,
    placeholder: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default TextInput;