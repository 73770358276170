import { connect } from 'react-redux';
import EnteredModelData from './EnteredModelData';
import { getEditData, addNewRow, getModifier, updateModel, removeSaveErrors, additionalInfo, 
         getPriceRollUpData, getAssembleToData, priceDataSave, updateRequiredMultiLevelModel } from "../../store/model-data";
import { getSubmitedData, getMyEnteredModel, getCancelledData, getDeleteData, initSubmitData, clearConcurrentFlag } from '../../store/order';
import { prepareEnteredModelData, prepareModifier, getDivisionCode } from "../../utils/helper"

// ===== Map state received from reducer to props  =====
const mapStateToProps = (state) => {
    return {
        enteredModelData: prepareEnteredModelData(state.orderStore), // Simplified enterted model data
        submittedData: state.orderStore.submittedData, // retriving the submitted data from store
        cancelledData: state.orderStore.cancelledData, // retriving the cancelled data from store
        modifierData: prepareModifier(state.modalDataStore), // Modifier data 
        status: state.orderStore.status, // retriving the status data from store
        concurrentFlagStatus: state.orderStore.concurrentFlag, // con current flag
        concurrentStatus: state.orderStore.concurrentFlagStatus, // concurrent flag status
        divCode: getDivisionCode(state.modalDataStore) || "", // Divcode
        baseRow: state.modalDataStore.modalData.rows[0], // base row data
        priceRollData: state.modalDataStore.priceRollUpDataLines,
        assembleToData: state.modalDataStore.assembleToData,
        priceRollDropdownData: state.modalDataStore.priceRollUpValues,
        onSaveError: state.modalDataStore.saveErrors,
        //quantityChanged : state.modalDataStore.modalData.rows[0].quantity
    }
};

// ===== Mapping dispatch functions to the props  =====
const mapDispatchToProps = {
    getEnteredModelData: payload => getMyEnteredModel(payload), // dispatching my enterted model data 
    MyGetSubmitedData: payload => getSubmitedData(payload), // dispatching submitted data 
    initSubmitData: payload => initSubmitData(payload), // dispatching init submit data
    getCancelData: payload => getCancelledData(payload), // dispatching cancelled data 
    getEditData: payload => getEditData(payload), //  dispatching edit data
    getDeleteData: payload => getDeleteData(payload), // dispatching delete data
    onNewRow: payload => addNewRow(payload), // dispatching method to add new row
    updateRow: payload => updateModel(payload), // dispatching Update model
    getModifier: payload => getModifier(payload), // dispatching get modified
    clearConcurrentFlag: payload => clearConcurrentFlag(payload), // dispatching to clear the concurent flag
    clearSaveErrors: payload => removeSaveErrors(payload), // dispatching method to remove the save errors
    getAdditionalInfoData: payload => additionalInfo(payload), // dispatching additional info data 
    getpriceRollUpData : payload => getPriceRollUpData(payload), // dispatching Price Roll Up data
    getassembleData: payload => getAssembleToData(payload),  // dispatching Assemble To data
    onPriceSave: payload => priceDataSave(payload), // dispatching Pricing Data on Save To data
    updateRequiredMultiLevelModel: payload => updateRequiredMultiLevelModel(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(EnteredModelData); // The connect() function connects a React component EnteredModelData to a Redux store(OrderStore).

