import { connect } from 'react-redux';
import ModelManagement from './ModelManagement';
import {
    getInsert, getInline, getModelString, getUrd, getWareHouse, getModifier, getLocation,
    getModelName, clearBaseModels, removeSaveErrors, quantityOnChange, addRow, deleteRow,
    updateModel, clearUrdDataOnChange, additionalInfo, clearHoldRow, importExcel, importPasteList, updateRequiredMultiLevelModel
} from "../../../store/model-data";
import { getMyEnteredModel } from '../../../store/order';
import { loginUser } from "../../../store/user";
import { prepareUrlData, prepareModelNames, prepareValidData, prepareEnteredModelData, getDivisionCode, prepareLocationStatus } from '../../../utils/helper';

// ===== Map state received from reducer to props  =====
const mapStateToProps = (state) => {
    return {
        userdata: prepareUserData(state.userStore), // preapre user data from store
        locationStatus: prepareLocationStatus(state.modalDataStore), // prepare location status 
        modelNames: prepareModelNames(state.modalDataStore), // prepared modelNames
        divCode: getDivisionCode(state.modalDataStore) || "", // get divCode
        basemodeldata: prepareBaseModel(state.modalDataStore), // preapre the base model data 
        currentModelName: state.modalDataStore.modelName, // fetch the current model name
        validationData: prepareValidData(state.modalDataStore), // prepare validation data 
        insertedData: prepareInsertedData(state.modalDataStore), // inserted data to get the error flag
        onSaveError: state.modalDataStore.saveErrors, // on save errors 
        urlDetails: prepareUrlData(state.userStore), // fetch the url details from the hosted link
        enteredModelData: prepareEnteredModelData(state.orderStore), // prepare the entered model data
        quantityChanged: state.modalDataStore.quantity, // quantity change capture 
        data: state.modalDataStore.modalData, // store the entire modeldata 
        sequenceNo: state.modalDataStore.modalData.lineNo, //get the sequence number
        status: state.modalDataStore.status,// get the status
        urdFetchId: state.modalDataStore.urdFetchId,
        requiredMultiLevelModel: state.modalDataStore.requiredMultiLevelModel,
    }
};

// ===== Function to create the user Data =====
const prepareUserData = data => {
    let result = [];
    if (Object.keys(data).length > 0) {
        try {
            result = data.user.OutputParameters.O_USER_DETAILS.O_USER_DETAILS_ITEM.DIV
        }
        catch {
        }
    }
    return result;
}

// ===== Function to create the base model =====
const prepareBaseModel = data => {
    let result = [];
    if (Object.keys(data).length > 0) {
        try {
            result = data.baseModels
                .map(i => ({
                    label: i.MODEL_NAME,
                    value: i.MODEL_NAME,
                    maxModelCount: i.MAX_MODELS_COUNT
                }));
        }
        catch {
        }
    }
    return result;
}

// ===== Function to prepare the insert data 
const prepareInsertedData = data => {
    let result = "";
    if (Object.keys(data).length > 0) {
        try {
            result = data.insertData.OutputParameters.O_ERROR_FLAG
        }
        catch {
        }
    }
    return result;
}

// ===== Mapping dispatch functions to the props  =====

const mapDispatchToProps = {
    UserData: payload => loginUser(payload), // dispatching the login user 
    getModelString: payload => getModelString(payload), // dispatching the model string selected 
    URDData: payload => getUrd(payload), // dispatching the urd data 
    GetInsert: payload => getInsert(payload), // dispatching  the payload of Insert 
    importExcelData: payload => importExcel(payload), // dispatching  import excel
    importPasteList: payload => importPasteList(payload), // dispatching to import paste list
    GetInlineValidation: payload => getInline(payload), // dispatching the payload for inline validation
    clearBaseModels: payload => clearBaseModels(payload), // dispatching funtion to clear the base models
    clearSaveErrors: payload => removeSaveErrors(payload), // dispatching  to remove the save errors
    OnQuantityChange: payload => quantityOnChange(payload), // // dispatching  to update the store on quantity change
    findModelNames: payload => getModelName(payload), // dispatching to get the model name
    addRow: payload => addRow(payload), // dispatching  to add the new row
    MyWareHouseData: payload => getWareHouse(payload), // dispatching  to get the warehouses 
    MyGetModifierData: payload => getModifier(payload), // dispatching  to get the modifiers
    UserLocationData: payload => getLocation(payload), // dispatching  to get location
    updateRow: payload => updateModel(payload), // dispatching to update the model
    deleteRow: payload => deleteRow(payload), // dispatching to delete the row
    clearURDData: payload => clearUrdDataOnChange(payload), // dispatching  to clear the urd data 
    getAdditionalInfoData: payload => additionalInfo(payload), // dispatching  the additional info data 
    clearHoldRow: payload => clearHoldRow(payload), // dispatching  to clear the hold row
    updateRequiredMultiLevelModel: payload => updateRequiredMultiLevelModel(payload),
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelManagement); //The connect() function connects a React component ModelManagement to a Redux store(modalDataStore).

