import React from 'react';
import PropTypes from 'prop-types';


const IntegerInput = ({ type, name, value, placeholder, onkeypressInteger, onChange, error, min, max, wrapperClass, label }) => {
    wrapperClass = wrapperClass ? wrapperClass : '';

    return (
        <div className={wrapperClass}>
            {/* Dynamic Label */}
            {
                label && (
                    <label className="form-label mr-2">{label}</label>
                )
            }
            {/* Passing input type , value, onChange, placeholder, disabled, classname, min and max value, autoComplete , onKeyPress, maxLength */}
            <input type={type} name={name} value={value}
                onChange={onChange} placeholder={placeholder}
                className="form-input" min={min} max={max}
                autoComplete="off" onKeyPress={onkeypressInteger} />
            {
                // Displaying error message
                error && (
                    <div className="error-text alert alert-danger position-absolute w-100">{error}</div>
                )
            }
        </div>
    )
}

IntegerInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    placeholder: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}
export default IntegerInput;