import React from 'react';

const LineNotesHeader = () => {
    return (

        <thead>
            <tr>
                <th>Seq</th>
                <th>Category</th>
                <th>Title</th>
                <th>Description</th>
                <th>Data Type</th>
                <th>Action</th>
            </tr>
        </thead>

    );
}

export default LineNotesHeader;