import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import TextInput from '../../shared/TextInput';
// import { read } from 'fs';

class PasteTable extends Component {
    editableCells = {};

    componentDidMount() {
        this.editableCells = {}
    }

    componentWillUpdate(prev) {
        if (prev.content.length !== this.props.content.length) {
            this.editableCells = {}
        }
    }

    render() {
        const { header, readOnly = false, onChange = () => { } } = { ...this.props };
        const { content } = this.props;
        return (
            <table className="w-100 modelData-tbl pasteOption">
                <thead>
                    <tr>
                        {
                            header && header.map((h, i) => {
                                return <th key={i}>{h}</th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        content && content.map((c, j) => {
                            return <tr key={j}>
                                {
                                    c && c.map((_c, i) => {
                                        let view = (i === 0 || (_c === '\x0D' || (_c === '' && (!this.editableCells[`${j}.${i}`]))) ? _c : (readOnly ? _c : <TextInput value={_c} type="text" onChange={(e) => onChange([j, i], e.target.value)} />));
                                        if (this.editableCells[`${j}.${i}`] === undefined) this.editableCells[`${j}.${i}`] = (i !== 0 && (_c !== '\x0D' && (_c !== '')) && !readOnly);
                                        if (c[0] !== undefined && c[0].indexOf('.') >= 0 && (i === 3 || i === 4)) {
                                            if (typeof view !== "string") {
                                                view = _c;
                                            }
                                        }
                                        return <td key={i} className="center">
                                            <div>
                                                <span>{view}</span>
                                            </div>
                                        </td>
                                    })
                                }
                            </tr>
                        })
                    }
                    <tr><td /><td /></tr>
                    <tr><td /><td /></tr>
                </tbody>
            </table>
        );


    }


}

export default PasteTable;