import { connect } from 'react-redux';
import ModelContent from './ModelContent';

// ===== Map state received from reducer to props  =====
const mapStateToProps = state => ({
    validationInProgress: state.modalDataStore.saveErrors.status === "progress" // validation check
});

// ===== Mapping dispatch functions to the props  =====
const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ModelContent); //The connect() function connects a React component ModelContent to a Redux store(modalDataStore).

