import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import PropTypes from 'prop-types';
import React, { Component } from "react";
import Moment from 'react-moment';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { STATUS_COPY_SIZING_SUCCESS } from "../../store/order/constant";


class SizingTable extends Component {
    state = {
        selectedIds: []
    }

    componentDidUpdate(prevProps) {
        const { visibiltyCopySizing, EnteredModelData, status } = this.props;
        if (prevProps.status !== status) {
            if (status === STATUS_COPY_SIZING_SUCCESS) {
                toastr.success('Copy Sizings Added Successfully', 'Success');
                visibiltyCopySizing();
                EnteredModelData();
            }
        }
    }

    handleOnSelect = (row, isSelect, e) => {
        let selectedIds = Object.assign([], this.state.selectedIds);
        let refId = row.REFERENCE_ID;
        if (isSelect) {
            selectedIds.push(refId);
        } else {
            selectedIds = selectedIds.filter(id => id !== refId)
        }
        this.setState({ selectedIds });
    }

    copySizing = async (data) => {
        const { CopySelectedSizings, referenceId, divCode, enteredModelData } = this.props;
        let lineNumber = (enteredModelData.length || 0) + 1;
        await CopySelectedSizings({ data: data, divCode: divCode, lineNumber });
        if (referenceId) {
            this.setState({ selectedIds: [] })
        }
    }

    render() {
        const { sizingTable, CopySizings } = this.props;
        const { selectedIds } = this.state;
        const { SearchBar } = Search;
        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                Showing {from} to {to} of {size} Results
            </span>
        );

        const options = {
            paginationSize: 2,
            pageStartIndex: 1,
            firstPageText: "First",
            prePageText: "Previous",
            nextPageText: "Next",
            lastPageText: "Last",
            nextPageTitle: "First page",
            prePageTitle: "Pre page",
            firstPageTitle: "Next page",
            lastPageTitle: "Last page",
            showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [
                {
                    text: "5",
                    value: 5
                },
                {
                    text: "10",
                    value: 10
                },
                {
                    text: "All",
                    value: sizingTable.length
                }
            ]
        };

        const columns = [
            {
                dataField: "REFERENCE_ID",
                text: "Reference Id"
            },
            {
                dataField: "MODEL_NAME",
                text: "Model Name"
            },
            {
                dataField: "CREATION_DATE",
                text: "Creation Date",
                formatter: this.formatDate
            },
            {
                dataField: "PREPARED_BY",
                text: "Prepared By"
            },
        ];
        const selectRow = {
            mode: "checkbox",
            clickToSelect: true,
            selected: this.state.selectedIds,
            onSelect: this.handleOnSelect
        };

        return (

            <ToolkitProvider
                keyField="REFERENCE_ID"
                data={sizingTable}
                columns={columns}
                search
            >
                {props => (
                    <div>
                        <div className="searchbar-row w-50">
                            <label className="label font-weight-bold float-left">My Sizings</label>
                            <SearchBar {...props.searchProps} className="searchbar w-100 border-right-0" placeholder="Search Sizing" />
                            <button className="btn btn-default w-auto search-btn"><i className="fa fa-search"></i></button>
                        </div>
                        <BootstrapTable
                            bordered={false}
                            striped
                            hover
                            {...props.baseProps}
                            selectRow={selectRow}
                            pagination={paginationFactory(options)}
                            className="search-table"
                            noDataIndication="No Sizings Available"
                        />
                        {
                            selectedIds.length > 0 && (
                                <button className="btn-primary m-auto d-block save-table-btn"
                                    onClick={() => this.copySizing(selectedIds)} title="Copy Sizings">
                                    <i className="fa fa-files-o pr-2" aria-hidden="true"></i>Copy Sizings
                                </button>
                            )
                        }
                        {CopySizings}
                    </div>
                )}
            </ToolkitProvider>
        );
    }

    formatDate = (cell) => {
        return (
            <Moment format='YYYY-MM-DD' date={cell} />
        );
    };
}

// ===== To declare that a prop is a specific JS primitive. ===== 

SizingTable.propTypes = {
    visibiltyCopySizing: PropTypes.func.isRequired,
    CopySelectedSizings: PropTypes.func.isRequired,
    EnteredModelData: PropTypes.func.isRequired,
    divCode: PropTypes.object.isRequired
}

export default SizingTable;
