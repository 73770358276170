import React, { Component } from 'react';
import { Tabs, TabPanel, TabList } from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import PropTypes from 'prop-types';
import UrdTabs from './urd-tabs';
import UrdTabDetails from './urd-tab-details';
import { InputPayload } from '../../../utils/urd-template'
import { getURLDetails, getUserDetails } from "../../../utils/methods";
class Urd extends Component {
  state = {
    selected: 0,
    selectedKey: '',
    unit: 0
  };
  // Life cycle method to set the payload values 
  componentDidMount() {
    let inputPayload = { ...InputPayload };
    let lineNumber = (this.props.enteredModelData.length || 0) + 1;
    InputPayload['HOST_HEADER_ID'] = getURLDetails().I_HEADER_ID;
    InputPayload['SEQUENCE_NUMBER'] = getUserDetails().SEQUENCE_NUMBER;
    InputPayload['LINE_NUMBER'] = lineNumber;
    InputPayload['APPLICATION_CODE'] = getURLDetails().I_APP_CODE;
    InputPayload['BASE_MODEL'] = this.props.BaseModelname;
  }

  render() {
    const { selected, selectedKey, unit } = this.state;
    const { list, urdDetails, quantityChanged, data, dispatchAction, divCode, rowWiseModelName, rowWiseModelString,copyMFGNotes,copyTagDtls,copyCalibDtls } = this.props; //rowWiseModelString added by sudhakar RT_002474 ,RT_002332
    let urdDetailsLength = urdDetails.length;

    return (
      data[unit] !== undefined ? <div className="urd-section">
        <Tabs defaultTab="vertical-tab-one" vertical className="vertical-tabs">
          {/* Verticle tab list */} {/* <UrdTabs list={list} divCode ={divCode}  data ={data} modified by sudhakar RT_002474 */}
          {
            urdDetailsLength === 0 ? "" : <TabList selected={selected}>
              <UrdTabs list={list} divCode ={divCode}  data ={data}
                onClick={(e, name, unit) => this.onSelect(e, name, unit)}
              />
            </TabList>
          }

          {/* Tab data  */}
          {
            urdDetailsLength === 0 && (divCode !== "RMT" && divCode !== "RNI") ? <h5 className = "urdNoData" >{` No URD data found for model name - ${rowWiseModelName}`} </h5> : <TabPanel tabId={selectedKey}>
              {
                <UrdTabDetails
                  selectedKey={selectedKey}
                  urdProcessedDetails={urdDetails}
                  quantityTag={quantityChanged}
                  onkeypress={this.onUrdkeypress}
                  data={data[unit]}
                  dispatchAction={dispatchAction}
                  copyMFGNotes={copyMFGNotes} //RT_002332 sudhakar
                  copyTagDtls={copyTagDtls} //RT_002332 sudhakar
                  copyCalibDtls={copyCalibDtls} //RT_002332 sudhakar             
                  onChange={this.props.updateRow}
                  id={this.props.id}
                  unit={unit}
                  urdDisplayData={this.props.urdDisplayData}
                  updateUrdDisplayData={this.props.updateUrdDisplayData}
                />
              }
            </TabPanel>
          }

        </Tabs>
      </div> : null
    );
  }

  // ===== Function called when the left side vertical tab is selected =====
  onSelect = async (e, name, unit) => {
    const { divCode } = this.props;
    const keyValue = name;
    if (divCode === "RMT" || divCode === "RNI") {
      await this.setState({
        selectedKey: keyValue,
        unit
      })
    }
    if (divCode !== "RMT" && divCode !== "RNI") {
      await this.setState({
        selectedKey: keyValue,
      })
    }
  }

  // Functions called when the onkeyPress event is triggered
  onUrdkeypress = (event) => {
    var key = event.keyCode || event.which;
    key = String.fromCharCode(key);
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      event.returnValue = false;
      if (event.preventDefault) event.preventDefault();
    }
  }

  // Functions called when the onkeyPress event is triggered
  onUrdkeypressInteger = (event) => {
    var charCode = (event.which) ? event.which : event.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault()
      return false;
    }
    return true;
  }

  prepareControlData = (data, name) => {
    let result = [];
    try {
      let master = data.filter(item => item.NAME === name)[0] || [];
      let nodeType = '';
      let nodeDescription = '';
      let orderId = 0;
      master.O_PSNODE_LIST.O_PSNODE_LIST_ITEM.map((item, i) => {
        nodeType = item['NODE_TYPE'];
        nodeDescription = item['DESCRIPTION'];
        result[nodeDescription] = {};
        result[nodeDescription]['orderId'] = i;
        result[nodeDescription]['name'] = item['NAME'];
        result[nodeDescription]['min'] = item['MINIMUM'];
        result[nodeDescription]['max'] = item['MAXIMUM'];
        if (nodeType === 'OPTION FEATURE') {
          result[nodeDescription]['type'] = 'dropdown';
          result[nodeDescription]['options'] = item['CHILD']
            .map(o => ({ label: o.DESCRIPTION, value: o.DESCRIPTION }));
        } else if (nodeType === 'TEXT FEATURE') {
          result[nodeDescription]['type'] = 'text';
        } else if (nodeType === 'INTEGER FEATURE') {
          result[nodeDescription]['type'] = 'integer';
        } else if (nodeType === 'BOOLEAN FEATURE') {
          result[nodeDescription]['type'] = 'boolean';
        } else if (nodeType === 'DECIMAL FEATURE') {
          result[nodeDescription]['type'] = 'decimal';
        }
      })
    }
    catch { }
    return result;
  }
}


// ===== To declare that a prop is a specific JS primitive. ===== 
Urd.propTypes = {
  urdInsert: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  list: PropTypes.any.isRequired,
  onUrdkeypressInteger: PropTypes.func.isRequired,
  onUrdkeypress: PropTypes.func.isRequired,
}

export default Urd;