import React from 'react';
import { Provider } from 'react-redux';
import { store } from './store/main';
import Routes from './routes';
import NetworkService from './utils/network-service';
import { createBrowserHistory } from "history";

// App-Insights
import { ai } from './utils/TelemetryService';

const history = createBrowserHistory({ basename: '' });
ai.initialize({ history: history });

NetworkService.setupInterceptors(store);
function App() {
  return (
    <Provider data-test='appRenderedTest' store={store}>
        <Routes />
    </Provider>
  );
}

export default App;