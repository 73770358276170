import { connect } from 'react-redux';
import LineNotes from './LineNotes';
import { addLineNotes, updateModel, deleteRow } from "../../../store/model-data";

// ===== Map state received from reducer to props  =====
const mapStateToProps = (state) => {
    return {
        data: state.modalDataStore.modalData // get the modelData data from store
    }
};

// ===== Mapping dispatch functions to the props  =====
const mapDispatchToProps = {
    addLineNotes: payload => addLineNotes(payload),// dispatching  function to add linenotes
    updateRow: payload => updateModel(payload), // dispatching  to update the model
    deleteRow: payload => deleteRow(payload), // dispatching  to delete the rpw
};

export default connect(mapStateToProps, mapDispatchToProps)(LineNotes);
