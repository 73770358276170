import React from 'react';
import PropTypes from 'prop-types';

const SelectInput = ({ disabled, label, name, value, error, onChange, options,
    defaultOption, wrapperClass, required, inputClass }) => {
    wrapperClass = wrapperClass ? wrapperClass : '';

    inputClass = inputClass ? inputClass : 'form-input';
    return (
        <div className={wrapperClass}>
            {
                // Dynamic label
                label && (
                    <label className="form-label mr-2">{label}</label>
                )
            }
            {/* Select dropdown  */}
            <select name={name} value={value} onChange={onChange} required={required}
                className={inputClass} disabled={disabled}>
                {/* Dropdown options */}
                <option value=''>{defaultOption}</option>
                {
                    options 
                    && options.length > 0
                    && options.map(o => <option key={o.value} value={o.value}>{o.label}</option>)
                }
            </select>
            {
                // Error message
                error && (
                    <div className="error-text alert alert-danger position-absolute w-75">{error}</div>
                )
            }
        </div>

    );
};

SelectInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    error: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    defaultOption: PropTypes.string.isRequired
}

export default SelectInput;