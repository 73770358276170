import React, { Component } from 'react';
// import PropTypes from 'prop-types';

export default class Drawer extends Component {

    render() {
        let { anchor, open, onClose, children, expanded = false } = this.props;

        return (<div className={`drawer ${open ? 'open' : ''}`} onClick={e => { e.stopPropagation(); onClose(e); }}>
            <div className="backdrop" />
            <div className={`${anchor} ${expanded ? 'expanded' : ''}`} onClick={e => e.stopPropagation()}>
                <div className={`children`}>
                    {children}
                </div>
            </div>
        </div>)
    }
}