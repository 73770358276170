import { connect } from 'react-redux';
import Urd from '../urd/Urd';
import { updateURD, updateInitialURD, updateUrdDisplayData } from "../../../store/model-data";
import { getDivisionCode } from '../../../utils/helper';


// ===== Map state received from reducer to props  =====
const mapStateToProps = (state, ownProps) => {
    let row = state.modalDataStore.modalData.rows.find(row => row.id === ownProps.id);
    return {
        list: prepareUrd(state.modalDataStore, ownProps, row.quantity), //tab list
        urdDetails: row ? preProcessUrdDetails(row.urdDetails) : [], //urd data
        quantityChanged: row.quantity? row.quantity : "1", // urd quantity
        divCode: getDivisionCode(state.modalDataStore) || "", // divCode
        rowWiseModelName : row.modelName,
        rowWiseModelString : row.modelString, //sudhakar RT_002474
        urdDisplayData: state.modalDataStore.modalData.rows
            .filter(row => row.id === ownProps.id)
            .map(item => item.urdDisplayData)[0] // URDDisplay Data
    };
};

// ===== Mapping dispatch functions to the props  =====
const mapDispatchToProps = dispatch => ({
    updateRow: payload => dispatch(updateURD(payload)), // Dispatching updateUrd inside store
    copyTagDtls: (action,payload) => dispatch({type: action, payload}), //RT_002332 sudhakar
    copyMFGNotes: (action,payload) => dispatch({ type: action,payload }), //RT_002332 sudhakar
    copyCalibDtls: (action,payload) => dispatch({type: action, payload}), //RT_002332 sudhakar    
    dispatchAction: (action, payload) => dispatch({ type: action, payload }),// Dispatching action for addition button
    updateInitialURD: payload => dispatch(updateInitialURD(payload)), // Dispatching  initial URD data
    updateUrdDisplayData: payload => dispatch(updateUrdDisplayData(payload)) // Dispatching  URD display data
});

// ====== Function to get the Vertical tab list for URD ======
//BaseModelString added by sudhakar RT_002474
const prepareUrd = (data, { id, divCode, BaseModelString }, quantity) => {
    quantity = quantity ? quantity : "1";
    let result = [];
    if (Object.keys(data).length > 0) {
        try {
            let Rowresult = data.modalData.rows.find(row => row.id === id);
            let RowtabDetails = []
            if (divCode === "RMT" || divCode === "RNI") { // to generate the tabs based on quantity for only RMT BU
                RowtabDetails = new Array(parseInt(quantity))
                    .fill(0)
                    .map((q, unit) =>
                        Rowresult.urdDetails.map(i => ({ name: `${i.NAME}|||${unit}|||`, taglabel: `${i.DESCRIPTION} (Unit ${unit + 1})`, unit }))
                    ).flat();
            } else { // generate the tabs based on API response for NON RMT BU
                //commented and added by sudhakar RT_002474
                //RowtabDetails = Rowresult.urdDetails.map(i => ({ name: i.NAME, taglabel: i.DESCRIPTION }))
                RowtabDetails = Rowresult.urdDetails
                .filter(
                    function(val){
                       if(
                            (divCode ==='ISV'
                            &&(
                                BaseModelString.endsWith('NP1') ||
                                BaseModelString.endsWith('NP2') ||
                                BaseModelString.endsWith('NP3')  
                            )
                            )                                                        
                        )
                        return val.NAME
                        else if(
                            (divCode ==='ISV'
                            &&(
                                !BaseModelString.endsWith('NP1') ||
                                !BaseModelString.endsWith('NP2') ||
                                !BaseModelString.endsWith('NP3')  
                            )
                            )                                                        
                        )
                        return val.NAME !== "Tag_Model"
                        else
                        return val.NAME
                    })
                    .map(i =>  ({ name: i.NAME, taglabel: i.DESCRIPTION }))
            }
            return RowtabDetails;
        }
        catch (err) { }
    }
    return result;
}

// ====== Function to group the Option_Feature and Options in a single object ======
const preProcessUrdDetails = (data, id) => {
    let result = [];
    if (data && Object.keys(data).length > 0) { // length check for data
        data.forEach(d => {
            result.push(findAndcombineOptionChilds(d));
        })
    }
    return result;
}

const findAndcombineOptionChilds = data => {
    let O_PSNODE_LIST = data.O_PSNODE_LIST.O_PSNODE_LIST_ITEM;
    if (!Array.isArray(O_PSNODE_LIST)) { // checks if the data is Array or not 
        O_PSNODE_LIST = [O_PSNODE_LIST];
    }
    O_PSNODE_LIST = O_PSNODE_LIST.filter(i => i.NODE_TYPE !== "COMPONENT")
    let combinedResult = {};
    O_PSNODE_LIST.forEach(item => {
        combinedResult[item.NAME] = item;
        if (item.NODE_TYPE === 'OPTION FEATURE') {
            if (combinedResult[item.NAME]['CHILD'] && combinedResult[item.NAME]['CHILD'].length > 0) {
                combinedResult[item.NAME]['CHILD'].loaded = true
            }
            else combinedResult[item.NAME]['CHILD'] = [];
        }
        if (item.NODE_TYPE === 'OPTION') {
            if (combinedResult[item.PARENT] && combinedResult[item.PARENT]['CHILD'] && !combinedResult[item.PARENT]['CHILD'].loaded) {
                combinedResult[item.PARENT]['CHILD'].push({ ...item })
            }
        }
    });
    O_PSNODE_LIST.O_PSNODE_LIST_ITEM = Object.values(combinedResult);
    return { ...data, O_PSNODE_LIST };
}

// ====== Function to group the Features as TEXT, INTEGER, OPTION_FEATURE, BUTTON and DECIMAL FEATURE ======
const prepareControlData = (data, name) => {
    let result = {};
    let childItemTypes = ["OPTION"]
    if (Object.keys(data).length > 0) {
        try {
            let master = data.urdDetails.O_COMP_LIST.O_COMP_LIST_ITEM.find(item => item.NAME === name);
            let nodeType = '';
            let nodeDescription = '';
            let orderId = 0;
            master.O_PSNODE_LIST_ITEM.map(item => {
                if (item['NODE TYPE'] !== nodeType && !childItemTypes.includes(item['NODE TYPE'])) {
                    nodeType = item['NODE TYPE'];
                    nodeDescription = item['DESCRIPTION']; // fetch description
                    result[nodeDescription] = {};
                    result[nodeDescription]['orderId'] = orderId;
                    orderId++; orderId++;
                    if (nodeType === 'OPTION FEATURE') { // when node type is option feature checks its type and add new param
                        result[nodeDescription]['type'] = 'dropdown'; // when the nodetype is Option feature 
                        result[nodeDescription]['options'] = [];
                    } else if (nodeType === 'TEXT FEATURE') { // when the nodetype is text feature 
                        result[nodeDescription]['type'] = 'text';
                    } else if (nodeType === 'INTEGER FEATURE') { // when the nodetype is Integer feature 
                        result[nodeDescription]['type'] = 'integer';
                    } else if (nodeType === 'BOOLEAN FEATURE') { // when the nodetype is Boolean feature 
                        result[nodeDescription]['type'] = 'boolean';
                    }
                    else if (nodeType === 'DECIMAL FEATURE') { // when the nodetype is Decimal feature 
                        result[nodeDescription]['type'] = 'decimal';
                        result[nodeDescription]['min'] = item['MINIMUM'];
                        result[nodeDescription]['max'] = item['MAXIMUM'];
                    }
                    else if (nodeType === 'STATIC FEATURE') { // when the nodetype is static feature 
                        result[nodeDescription]['type'] = 'static';
                    }
                    else if (nodeType === 'TEXT RMT FEATURE') { // when the nodetype is Txt rmt feature feature 
                        result[nodeDescription]['type'] = 'textJKey';
                    }
                    else if (nodeType === 'BUTTON FEATURE') { // when the nodetype is button feature 
                        result[nodeDescription]['type'] = 'buttonFeature';
                    }

                } else {
                    if (nodeType === 'OPTION FEATURE') { // when its option feature , add label and value to options
                        let option = {
                            label: item['DESCRIPTION'],
                            value: item['DESCRIPTION']
                        }
                        result[nodeDescription]['options'].push(option)
                    }
                }
                return item;
            })
        }
        catch {
        }
    }
    return result;
}

export default connect(mapStateToProps, mapDispatchToProps)(Urd); //The connect() function connects a React component Urd to a Redux store(modalDataStore).
