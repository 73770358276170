import { all } from 'redux-saga/effects';
import { getURLSaga, getUserSaga } from './user/saga';
import { 
    getModelStringSaga, getModelNameSaga, getWareHouseSaga, getModifierSaga, 
    getInlineValidationSaga, getUserLocationSaga, getInsertDataSaga, getUrdInfoSaga,
    clearStatusSaga, getEditDataSaga, addNewRowSaga, getTaskNumberSaga,additionalInfoSaga,
    getApplyHoldsSaga, linePriceSaga, RMTUrdSaga, importExcelDataSaga, importPasteListSaga,
    importEmailNotificationSaga, getPriceRollUpSaga, getAssembleToSaga,
    copyMfgNotesSaga,copyTagDtlsSaga,copyCalibDtlsSaga //RT_002332 RT_002332 sudhakar
} from './model-data/saga';
import { 
    getCopySizingSaga, getSizingSaga, getOrderSaga, getOrderDetailsSaga, getQuoteSaga,
    getAddedDataSaga, getMyEnteredModelSaga, getSubmitDataSaga, getcopyLinesSaga, 
    getCancelSaga, getDeleteDataSaga, getQuoteDetailsSaga, initSubmitDataSaga } from './order/saga';

export default function* (){
    yield all([
        getURLSaga(),
        getUserSaga(),
        getModelStringSaga(),
        getModelNameSaga(),
        getCopySizingSaga(),
        getSizingSaga(),
        getOrderSaga(),
        getOrderDetailsSaga(),
        getQuoteSaga(),
        getAddedDataSaga(),
        getMyEnteredModelSaga(),
        getSubmitDataSaga(),
        initSubmitDataSaga(),
        getCancelSaga(),
        getWareHouseSaga(),
        getModifierSaga(),
        getInlineValidationSaga(),
        getUrdInfoSaga(),
        getUserLocationSaga(),
        getInsertDataSaga(),
        importExcelDataSaga(),
        importPasteListSaga(),
        getcopyLinesSaga(),
        clearStatusSaga(),
        getEditDataSaga(),
        getDeleteDataSaga(),
        addNewRowSaga(),
        getTaskNumberSaga(),
        additionalInfoSaga(),
        getApplyHoldsSaga(),
        linePriceSaga(),
        RMTUrdSaga(),
        getQuoteDetailsSaga(),
        importEmailNotificationSaga(),
        getPriceRollUpSaga(),
        getAssembleToSaga(),
        //RT_002332 RT_002332 sudhakar (+)
        copyMfgNotesSaga(),
        copyTagDtlsSaga(),
        copyCalibDtlsSaga()
        //RT_002332 RT_002332 sudhakar (-)
    ])
}