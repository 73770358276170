import React from 'react';
import PropTypes from 'prop-types';

const DecimalInput = ({
    name, value, onChange, placeholder, disabled, error, wrapperClass,
    inputClass, min, max, onKeyPress, label, type, maxLength
}) => {
    wrapperClass = wrapperClass ? wrapperClass : '';
    return (
        <div className={wrapperClass}>
            {// Dynamic label
                label && (
                    <label className="form-label mr-2">{label}</label>
                )
            }
            {/* Passing input type , value, onChange, placeholder, disabled, classname, min and max value, autoComplete , onKeyPress, maxLength */}
            <input name={name} value={value}
                onChange={onChange} placeholder={placeholder}
                disabled={disabled} className="form-input" min={min} max={max}
                autoComplete="off" onKeyPress={onKeyPress} type={type} maxLength={maxLength} />

            {/* Error message  */}
            {
                error && (
                    <div className="error-text alert alert-danger position-absolute w-100">{error}</div>
                )
            }
        </div>
    );
};

DecimalInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    placeholder: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default DecimalInput;