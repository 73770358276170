import { connect } from 'react-redux';
import ApplyHolds from './ApplyHolds';
import { addApplyholds, updateModel, deleteRow } from "../../../store/model-data";

// ===== Map state received from reducer to props  =====
const mapStateToProps = (state) => {
    return {
        data: state.modalDataStore.modalData // fetch the modelData store data
    }
};

// ===== Mapping dispatch functions to the props  =====
const mapDispatchToProps = {
    addApplyholds: payload => addApplyholds(payload), // dispatching Add apply holds
    updateRow: payload => updateModel(payload), // dispatching  update model
    deleteRow: payload => deleteRow(payload), // dispatching  delete row action
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplyHolds); // The connect() function connects a React component ApplyHolds to a Redux store(modalDataStore).
