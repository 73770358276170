const GET_SIZINGS = 'GET_SIZINGS';
const GET_SIZINGS_SUCCESS = 'GET_SIZINGS_SUCCESS';
const GET_SIZINGS_ERROR = 'GET_SIZINGS_ERROR';
const GET_COPY_SIZING = 'GET_COPY_SIZING';
const GET_COPY_SIZING_ERROR = 'GET_COPY_SIZING_ERROR';
const GET_COPY_SIZING_SUCCESS = 'GET_COPY_SIZING_SUCCESS';
const GET_SAVE_SIZING = 'GET_SAVE_SIZING';
const GET_SAVE_SIZING_SUCCESS = 'GET_SAVE_SIZING_SUCCESS';
const GET_SAVE_SIZING_ERROR = 'GET_SAVE_SIZING_ERROR';
const GET_ORDERS = 'GET_ORDERS';
const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
const GET_ORDERS_ERROR = 'GET_ORDERS_ERROR';
const GET_ORDER_DETAILS = 'GET_ORDER_DETAILS';
const GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS';
const GET_ORDER_DETAILS_ERROR = 'GET_ORDER_DETAILS_ERROR';
const GET_QUOTE = 'GET_QUOTE';
const GET_QUOTE_SUCCESS = 'GET_QUOTE_SUCCESS';
const GET_QUOTE_ERROR = 'GET_QUOTE_ERROR';
export const GET_QUOTE_DETAILS = 'GET_QUOTE_DETAILS';
export const GET_QUOTE_DETAILS_SUCCESS = 'GET_QUOTE_DETAILS_SUCCESS';
export const GET_QUOTE_DETAILS_ERROR = 'GET_QUOTE_DETAILS_ERROR';
const GET_ADDED_DATA = 'GET_ADDED_DATA';
const GET_ADDED_DATA_SUCCESS = 'GET_ADDED_DATA_SUCCESS';
const GET_ADDED_DATA_ERROR = 'GET_ADDED_DATA_ERROR';
const GET_MY_ENTERED_MODELDATA = 'GET_MY_ENTERED_MODELDATA';
const GET_MY_ENTERED_MODELDATA_SUCCESS = 'GET_MY_ENTERED_MODELDATA_SUCCESS';
const GET_MY_ENTERED_MODELDATA_ERROR = 'GET_MY_ENTERED_MODELDATA_ERROR';
const GET_SUBMIT_DATA = 'GET_SUBMIT_DATA';
const GET_SUBMIT_DATA_SUCCESS = 'GET_SUBMIT_DATA_SUCCESS';
const GET_SUBMIT_DATA_ERROR = 'GET_SUBMIT_DATA_ERROR';
export const INIT_SUBMIT_DATA = 'INIT_SUBMIT_DATA';
export const INIT_SUBMIT_DATA_SUCCESS = 'INIT_SUBMIT_DATA_SUCCESS';
export const INIT_SUBMIT_DATA_ERROR = 'INIT_SUBMIT_DATA_ERROR';
export const STATUS_INIT_SUBMIT_INIT = 'STATUS_INIT_SUBMIT_INIT';
export const STATUS_INIT_SUBMIT_SUCCESS = 'STATUS_INIT_SUBMIT_SUCCESS';
export const STATUS_INIT_SUBMIT_FAILED = 'STATUS_INIT_SUBMIT_FAILED';
export const CLEAR_CONCURRENT_FLAG = 'CLEAR_CONCURRENT_FLAG';
const GET_ORDER_COPY_LINES = 'GET_ORDER_COPY_LINES';
const GET_ORDER_COPY_LINES_ERROR = 'GET_ORDER_COPY_LINES_ERROR';
const GET_ORDER_COPY_LINES_SUCCESS = 'GET_ORDER_COPY_LINES_SUCCESS';
const GET_CANCEL_DATA = 'GET_CANCEL_DATA';
const GET_CANCEL_DATA_SUCCESS = 'GET_CANCEL_DATA_SUCCESS';
const GET_CANCEL_DATA_ERROR = 'GET_CANCEL_DATA_ERROR';
const GET_DELETE_DATA = 'GET_DELETE_DATA';
const GET_DELETE_DATA_SUCCESS = 'GET_DELETE_DATA_SUCCESS';
const GET_DELETE_DATA_ERROR = 'GET_DELETE_DATA_ERROR';
export const STATUS_COPY_SIZING_INIT = 17;
export const STATUS_COPY_SIZING_SUCCESS = 18;
export const STATUS_COPY_SIZING_FAILED = 19;

export {
    GET_SIZINGS, GET_SIZINGS_ERROR, GET_SIZINGS_SUCCESS,
    GET_SAVE_SIZING, GET_SAVE_SIZING_SUCCESS, GET_SAVE_SIZING_ERROR,
    GET_COPY_SIZING, GET_COPY_SIZING_ERROR, GET_COPY_SIZING_SUCCESS,
    GET_ORDERS, GET_ORDERS_SUCCESS, GET_ORDERS_ERROR,
    GET_QUOTE, GET_QUOTE_SUCCESS, GET_QUOTE_ERROR,
    GET_ORDER_DETAILS, GET_ORDER_DETAILS_SUCCESS, GET_ORDER_DETAILS_ERROR,
    GET_ADDED_DATA, GET_ADDED_DATA_SUCCESS, GET_ADDED_DATA_ERROR,
    GET_MY_ENTERED_MODELDATA, GET_MY_ENTERED_MODELDATA_SUCCESS, GET_MY_ENTERED_MODELDATA_ERROR,
    GET_SUBMIT_DATA, GET_SUBMIT_DATA_SUCCESS, GET_SUBMIT_DATA_ERROR,
    GET_ORDER_COPY_LINES, GET_ORDER_COPY_LINES_SUCCESS, GET_ORDER_COPY_LINES_ERROR,
    GET_CANCEL_DATA, GET_CANCEL_DATA_SUCCESS, GET_CANCEL_DATA_ERROR,
    GET_DELETE_DATA, GET_DELETE_DATA_SUCCESS, GET_DELETE_DATA_ERROR
};