import { connect } from 'react-redux';
import LineNotesRow from './LineNotesRow';
import { updateLineCategory, updateLineNotesRow } from '../../../store/model-data';

// ===== Map state received from reducer to props  =====
const mapStateToProps = (state, ownProps) => {
    return {
        seedData: state.modalDataStore.modalData.rows.map(i => i.additionalDataDetails), // fetching the additional data from store
        data: state.modalDataStore.modalData.LineNotesRows.find(x => x.id === ownProps.id),// Fetching the linenotes  based on row ID
    }
};

// ===== Mapping dispatch functions to the props  =====
const mapDispatchToProps = {
    updateCategoryName: payload => updateLineCategory(payload), // dispatching function to update the line category 
    updateLineNotesRow: payload => updateLineNotesRow(payload) // dispatching function to update linenotes rows
};

export default connect(mapStateToProps, mapDispatchToProps)(LineNotesRow); //The connect() function connects a React component LineNotesRow to a Redux store(modalDataStore).
