import React, { Component } from 'react';
import toastr from "toastr";
import TextInput from '../../shared/TextInput';
import SelectInput from '../../shared/SelectInput';
import PropTypes from 'prop-types';
import SearchableTextInput from '../../shared/SearchableTextInput';
import Pricing from '../pricing';
import Aux from '../../shared/AuxContainer';
import AdditionalInfo from '../additional-info';
import Status from '../../shared/Status';
import Collapsible from 'react-collapsible';
import Urd from '../urd';
import { LOADING } from '../../../utils/constant';
import ApplyHolds from '../apply-holds';
import LineNotes from '../line-notes';
import { getUserDetails, getURLDetails } from "../../../utils/methods";
import { parseModelDataForInlineValidation } from "../../../utils/helper";
import { STATUS_GET_MODELNAME_SUCCESS } from '../../../store/model-data/constant';
//changes are made for the RFC AGL-ORA000231
class ModelRow extends Component {
    state = { // set inital state
        showModelStringList: false,
        showPricing: false,
        update: true,
        filteredModelStrings: [],
        open: false,
        infoData: false,
        OpenApplyHolds: false,
        OpenLineNotes: false,
        CallRMTUrdOnExpand: true,
        isModelStringChanged: false,
        isModelNameChanged: false,
    }
    // Life cycle method to call on load of applictaion 
    componentDidMount() {
        this.setState({
            filteredModelStrings: this.props.modelStrings
        });
        document.addEventListener('mousedown', this.handleClick, false);
        const { row, URDData } = this.props;
        if (row.id > 0 && row.modelName !== '') {
            URDData({ modelNameString: row.modelName, id: row.id });
        }
    }

    // Life cycle method called when component is being removed from the DOM
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false)
    }

    componentDidUpdate(prevProps) {
        let prevModelName = '';
        let modelNameString = '';
        let divCode = getUserDetails().DIV; // get divcode 
        const { modelName, status, row } = this.props; // passing props
        try {
            prevModelName = prevProps.modelName.NAME || '';
            modelNameString = this.props.modelName.NAME || '';
        } catch { }
        // checks  of current and previous model name is equal
        if (prevModelName !== modelNameString) {
            const { id, onRowChange, divCode } = this.props;
            let customEvent = { target: {} };
            customEvent.target.name = "modelName";
            customEvent.target.value = modelNameString;
            onRowChange(customEvent, id);
            if (modelNameString && modelNameString !== '') {
                this.props.MyWareHouseData(modelNameString);
                if (divCode === "RMT" || divCode === "RNI") { }
                else {
                    this.props.URDData({ modelNameString, id });
                }
                if (this.props.location) {
                    this.props.UserLocationData(modelNameString);
                }
            }
        }
        // checks  of current and previous warehouse is equal
        if (prevProps.row.warehouse !== this.props.row.warehouse) {
            const { row } = this.props;
            this.props.getAdditionalInfoData({ modelName: modelNameString, warehouse: row.warehouse, divCode, id: row.id });
        }

        if (this.props.exit && (this.state.open || this.state.infoData)) {
            this.setState({
                open: false,
                infoData: false,
                OpenApplyHolds: false,
                OpenLineNotes: false
            })
        }

        if (this.props.onSaveError.status === "success" && !this.props.onSaveError.isRMT) {
            if (this.state.showPricing) {
                this.setState({ showPricing: false })
            }
        }

        // checks  of current and previous status is equal
        if (prevProps.status !== status) {
            // Got model-name response and it is blank
            if (status === STATUS_GET_MODELNAME_SUCCESS
                && Object.keys(modelName).length === 0
                && row.modelString !== ''
                && row.modelName === '') {
                toastr.warning(`Model Name not found. Please Enter Model Name`, "Warning");
            }
        }
    }

    render() {
        const { id, row, errors, onRowChange, onRowAdd, onRowRemove,
            warehouses, modifierData, seqStart, lineNo, userlocations,
            location, hasAction, allowAdd, onSaveError, baseModel,
            onFindModelNames, locationStatus, enteredModelData, divCode, quantityChanged } = this.props; // passing props
        const { showModelStringList, showPricing, filteredModelStrings, open, infoData,
            OpenApplyHolds, OpenLineNotes, isModelNameChanged, isModelStringChanged } = this.state; // passing state
        let sessionFlagData =  getUserDetails().SESSION_FLAG;
        return (
            <Aux>
                <tr>
                    <td className="align-middle rowNum">{this.prepareLineNumber(seqStart, lineNo)}</td>
                    <td className="position-relative"
                        ref={modStrNode => this.modStrNode = modStrNode}>
                        <SearchableTextInput name="modelString" value={row.modelString}
                            type="text" placeholder="Model String"
                            error={row.modelString === "" ? errors.modelString : null}
                            onChange={e => {
                                // if(sessionFlagData === 'Y'){
                                let value = e.target.value;
                                let prevValue = row.modelString;
                                if (value !== prevValue) {
                                    this.setState({ isModelStringChanged: true });
                                } else {
                                    this.setState({ isModelStringChanged: false });
                                }
                                this.onSearchModelString(e, id)
                            // }
                        }}
                            options={filteredModelStrings} onSelect={e => this.onModelStringSelect(e, id)}
                            showList={showModelStringList} disabled={!row.modelStringEnable}
                            onBlur={e => {
                                // if(sessionFlagData === 'Y'){
                                if (!showModelStringList && isModelStringChanged) {
                                    onFindModelNames(e.target.value, id, row.modelName);
                                    this.setState({ isModelStringChanged: false });
                                }
                            // }
                            }}
                            //onPaste={e => onFindModelNames(e.clipboardData.getData('Text'), id, row.modelName)}
                            onPaste={e => {
                                // if(sessionFlagData === 'Y'){
                                    let value = e.clipboardData.getData('Text');
                                    let prevValue = row.modelString;
                                    if (value !== prevValue) {
                                        this.setState({ isModelStringChanged: true });
                                    } else {
                                        this.setState({ isModelStringChanged: false });
                                    }
                                // }
                           
                            }}
                            onKeyDown={e => {
                                // if(sessionFlagData === 'Y'){
                                if (e.key === "Tab"
                                    && isModelStringChanged) {
                                    onFindModelNames(e.target.value, id, row.modelName);
                                    this.setState({
                                        isModelStringChanged: false,
                                        showModelStringList: false
                                    });
                                }
                            // }
                        }}
                        />
                    </td>
                    <td className="position-relative"
                        ref={modNameNode => this.modNameNode = modNameNode}>
                        <TextInput name="modelName" value={row.modelName}
                            type="text" placeholder="Model Name" error={errors.modelName}
                            onChange={e => {
                                let value = e.target.value;
                                let prevValue = row.modelName;
                                if (value !== prevValue) {
                                    this.setState({ isModelNameChanged: true });
                                } else {
                                    this.setState({ isModelNameChanged: false });
                                }
                                onRowChange(e, id);
                            }}
                            onBlur={e => {
                                if (isModelNameChanged) {
                                    onFindModelNames(row.modelString, id, (e.target.value).toUpperCase());
                                    this.props.findWarehouses(e.target.value, id);
                                    this.setState({ isModelNameChanged: false });
                                }
                            }}
                            //onPaste={e => onFindModelNames(row.modelString, id, e.clipboardData.getData('Text'))}
                            onPaste={e => {
                                let value = e.clipboardData.getData('Text');
                                let prevValue = row.modelString;
                                if (value !== prevValue) {
                                    this.setState({ isModelNameChanged: true });
                                } else {
                                    this.setState({ isModelNameChanged: false });
                                }
                            }}
                            onKeyDown={e => {
                               
                                    if (e.key === "Tab" && e.isModelNameChanged) {
                                        onFindModelNames(row.modelString, id, (e.target.value).toUpperCase());
                                        this.props.findWarehouses(e.target.value, id);
                                        this.setState({ isModelNameChanged: false });
                                    }
                                
                               
                            }}
                        />

                        {/* onPaste={e => this.props.findWarehouses(e.clipboardData.getData('Text'), id)} */}
                    </td>
                    <td className="quantity-col">
                        <TextInput name="quantity" value={row.quantity}
                            type="number" error={errors.quantity}
                            onChange={e => onRowChange(e, id)}
                            min={1}
                            max={50}
                            disabled={(id !== 0) || (id === 0 && row.modelName === '')} placeholder="" />
                    </td>
                    <td className="warehouse-col">
                        <SelectInput name="warehouse" value={row.warehouse}
                            error={errors.warehouse}
                            defaultOption="--- Select Warehouse ---" options={warehouses}
                            onChange={e => onRowChange(e, id)}
                            disabled={(id !== 0) || (id === 0 && row.modelName === '')} />
                    </td>
                    {location && (
                        <td className="position-relative">
                            {
                                userlocations && (
                                    <>{locationStatus === "1" ? <i className="fa fa-asterisk text-danger required-icon" aria-hidden="true" /> : ""}
                                        <SelectInput name="userlocation" value={row.userlocation}
                                            defaultOption="--Select Location--" options={userlocations}
                                            error={errors.location}
                                            onChange={e => onRowChange(e, id)} required={true} />
                                    </>)
                            }
                        </td>
                    )
                    }

                    {
                        hasAction && (
                            <td className="text-center">
                                {
                                    id === 0 && (
                                        <button onClick={onRowAdd}
                                            className="btn btn-primary minWidth-auto border-0 p-0 add-btn"
                                            disabled={!allowAdd || baseModel === ""}
                                            title={baseModel === "" ? 'Please Select the Base Model' : 'Add Model String'}>
                                            <i className="fa fa-plus-circle"></i>
                                        </button>
                                    )
                                }

                                {
                                    id !== 0 && (
                                        <button onClick={() => onRowRemove(id)} className="btn btn-primary minWidth-auto border-0 p-0 delete-btn" title="Delete Model String">
                                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                                        </button>
                                    )
                                }
                            </td>
                        )
                    }

                    <td className="text-center">
                        <i className={(showPricing ? "fa fa-chevron-up disabled crsr-ptr" : (row.modelString && row.modelName !== "" || baseModel !== "" ? "fa fa-chevron-down crsr-ptr" : "fa fa-chevron-down disabled"))}
                            onClick={this.togglePricing}
                            aria-hidden="true" disabled="disabled"></i>
                    </td>
                    <td className="text-center status-col">
                        {
                            id === 0 && onSaveError && (
                                this.props.phase === LOADING ? "loading" :
                                    <Status
                                        id={row.id}
                                        name={row.id}
                                        wrapperClass="btn-primary w-100 position-relative"
                                        status={onSaveError.status}
                                        onClick={(e) => this.showStatusPopup(e, onSaveError.status)} />
                            )
                        }
                    </td>
                </tr>
                {
                    ((id === 0 && baseModel === '') || id !== 0 || ((divCode === "RMT" || divCode === "RNI") && id === 0 && !baseModel.startsWith('LM')) || (id === 0 && baseModel === 'flat')) && showPricing && (
                        <Aux>

                            {/* Mounted Pricing component  */}
                            {id === 0 && (this.props.data.rows.length === 1) &&
                                (<Pricing row={row} modifiers={modifierData}
                                    getLineNum={this.prepareLineNumber(seqStart, lineNo)}
                                    onSelect={(e) => this.onModifierSelect(e, id)}
                                    onChange={(e) => this.onPricingChange(e, id)}
                                    onkeypress={e => this.validateInput(e, id)} />)}

                            {/* Mounted Pricing component  */}
                            {id !== 0 &&
                                (<Pricing row={row} modifiers={modifierData}
                                    getLineNum={this.prepareLineNumber(seqStart, lineNo)}
                                    onSelect={(e) => this.onModifierSelect(e, id)}
                                    onChange={(e) => this.onPricingChange(e, id)}
                                    onkeypress={e => this.validateInput(e, id)} />)}


                            {/* Mounted URD component  */}{/* sudhakar  RT_002474 added BaseModelString */}
                            {divCode !== "MHM" && (row.modelString !== row.modelName || (row.modelString === row.modelName && row.modelString === '')) && (
                                <tr>
                                    <td colSpan="8">
                                        <Collapsible
                                            trigger="Unit Related Data"
                                            open={open}
                                            handleTriggerClick={() => this.handleClickURD()}>
                                            <Urd id={row.id} data={row.urdData} enteredModelData={enteredModelData}
                                                BaseModelname={row.modelName} divCode={divCode} quantity={quantityChanged}
                                                BaseModelString = {row.modelString}
                                            />
                                        </Collapsible>
                                    </td>
                                </tr>
                            )
                            }

                        </Aux>)}
                {/* Mounted Additional Information component  */}
                {/* {id === 0 && showPricing && ( commented and added by sudhakar RT_002477 RT_002336*/} 
                    {showPricing && (
                    <Aux>
                        <tr>
                            <td colSpan="8">
                                <Collapsible
                                    trigger="Additional Information"
                                    open={infoData}
                                    handleTriggerClick={() => this.handleClickAdditionalInfo()}>
                                    <AdditionalInfo row={row} id={id} />
                                </Collapsible>
                            </td>
                        </tr>
                        {/* Mounted Apply Holds component  */}
                        {getURLDetails().I_APP_CODE === "ONT" && (
                            <tr>
                                <td colSpan="8">
                                    <Collapsible
                                        trigger="Apply Holds"
                                        open={OpenApplyHolds}
                                        handleTriggerClick={() => this.handleClickApplyHolds()}>
                                        <ApplyHolds />
                                    </Collapsible>
                                </td>
                            </tr>
                        )
                        }
                        {/* Mounted Line Notes component  */}
                        <tr>
                            <td colSpan="8">
                                <Collapsible
                                    trigger="Line Notes"
                                    open={OpenLineNotes}
                                    handleTriggerClick={() => this.handleClickLineNotes()}>
                                    <LineNotes />
                                </Collapsible>

                            </td>
                        </tr>
                    </Aux>
                )
                }
            </Aux>
        );
    }

    // Function called when the Line details is expanded 
    handleClick = e => {
        if (this.modStrNode.contains(e.target)) {
            return;
        } else {
            if (this.state.showModelStringList) {
                this.toggleModelStringList(false);
            }
        }
    }

    // Function called when there is a change in text box for Searchable input
    onSearchModelString = async (e, id) => {
        this.props.onRowChange(e, id); // updates props on changing row
        const str = e.target.value;
        if (str.length >= 3) {
            let filteredModelStrings = Object.assign([], this.props.modelStrings);
            filteredModelStrings = filteredModelStrings.filter(item =>
                item.toLowerCase().startsWith(str.toLowerCase()));
            this.setState({ filteredModelStrings })
            this.toggleModelStringList(true); // to get the dropdown list
        } else {
            this.toggleModelStringList(false); // to hide the model string list 
        }
        this.props.clearModelName(id);
        this.props.clearURDData();
    }

    onSearchModelName = (e, id) => {
        this.props.onRowChange(e, id);
        this.toggleModelNameList(true);
    }

    // on select event called when a model string is selecetd 
    onModelStringSelect = async (e, id) => {
        const { MyGetModifierData, UserLocationData, location, onFindModelNames, } = this.props;
        //this.props.informModelStringSelect(e, id);
        let modelString = e.target.textContent;
        let modelName = this.props.row.modelName;
        let customEvent = { target: {} };
        customEvent.target.name = "modelString";
        customEvent.target.value = modelString;
        this.props.onRowChange(customEvent, id);
        onFindModelNames(modelString, id, modelName);
        await MyGetModifierData();
        if (location) {
            await UserLocationData(this.props.modelName);
        }
        this.toggleModelStringList(false);
    }

    // Function to get the modelName
    getModelName = async (modelString, id) => {
        const { GetModelNameData } = this.props;
        await GetModelNameData({ modelString, id });
    }

    // Function to show the model string list 
    toggleModelStringList = (status) => {
        this.setState({
            showModelStringList: status
        });
    }

    // Function to toggle the pricing accordion 
    togglePricing = () => {
        const { showPricing } = this.state
        this.setState({
            showPricing: !showPricing
        })
        const { divCode, getAdditionalInfoData, modelName, row } = this.props;
        if (showPricing === false) {
            if (row.id === 0) {
                getAdditionalInfoData({ modelName: modelName.NAME, warehouse: row.warehouse, divCode, id: row.id });
            }
        }
    }

    // Function called when the modifier is selected 
    onModifierSelect = (e, id) => {
        this.props.onRowChange(e, id);
        this.setState({
            update: true
        })
    }

    // Function called when pricing dropdown is changed 
    onPricingChange = (e, id) => {
        this.props.onRowChange(e, id);
        this.setState({
            update: true
        })

        return true;
    }

    // Function to validate the inputs 
    validateInput = (e, id) => {
        var charCode = (e.which) ? e.which : e.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault()
            return false;
        }
    }

    // Function to generate the Line number
    prepareLineNumber = (seqStart, lineNo) => {
        let result = "";
        if (seqStart == null) return "#.#";
        if (lineNo > 9) {
            seqStart += parseInt(lineNo / 10);
            lineNo = parseInt(lineNo % 10);
        }
        result = `${seqStart}.${lineNo}`;
        return result;
    }

    showStatusPopup = (e, status) => {
        if (status === "failed") {
            this.props.toggleStatusPopup(true);
        }
    }

    // Function called when the URD accordion is clicked 
    handleClickURD = async e => {
        const { id, enteredModelData, GetInlineValidation, divCode } = this.props;
        const { rows } = this.props.data;
        const { open, CallRMTUrdOnExpand } = this.state;
        this.setState(state => ({ open: !state.open }));
        let lineNumber = (enteredModelData.length || 0) + 1;
        let clickedIndex = 0;
        rows.map((row, i) => {
            if (row.id === id) {
                clickedIndex = i;
            }
            return row;
        });
        // Call inline validation when its RMT BU
        if ((divCode === "RMT" || divCode === "RNI") && open === false) {
            let parsedDataForInline = await parseModelDataForInlineValidation({ rows, divCode });
            await GetInlineValidation({
                modelData: parsedDataForInline,
                divCode: divCode,
                lineNumber,
                validate: rows[0].modelString !== rows[0].modelName,
                RowId: id,
                URDClick: CallRMTUrdOnExpand,
                RowIndex: clickedIndex
            });
        }
    }

    // Function called when the AdditionalInfo accordion is clicked 
    handleClickAdditionalInfo = async () => {
        this.setState(state => ({ infoData: !state.infoData }));
    }

    // Function called to get the project data from additional info API
    getProjectData = () => {
        const { divCode, getAdditionalInfoData, modelName, row } = this.props;
        getAdditionalInfoData({ modelName: modelName.NAME, warehouse: row.warehouse, divCode, id: row.id });
    }

    // Function called when the ApplyHolds accordion is clicked 
    handleClickApplyHolds = () => {
        const { OpenApplyHolds } = this.state;
        this.setState(state => ({ OpenApplyHolds: !OpenApplyHolds }));
    }

    // Function called when the LineNotes accordion is clicked 
    handleClickLineNotes = () => {
        const { OpenLineNotes } = this.state;
        this.setState(state => ({ OpenLineNotes: !OpenLineNotes }));
    }
}

// ===== To declare that a prop is a specific JS primitive. ===== 
ModelRow.propTypes = {
    row: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    errors: PropTypes.object.isRequired,
    onRowChange: PropTypes.func.isRequired,
    onRowAdd: PropTypes.func.isRequired,
    onRowRemove: PropTypes.func.isRequired,
    warehouses: PropTypes.arrayOf(PropTypes.object).isRequired,
    UserLocationData: PropTypes.func.isRequired,
    informModelStringSelect: PropTypes.func.isRequired,
    lineNo: PropTypes.number.isRequired,
    seqStart: PropTypes.number.isRequired,
    MyWareHouseData: PropTypes.any.isRequired,
    GetModelNameData: PropTypes.any.isRequired,
    MyGetModifierData: PropTypes.any.isRequired,
    location: PropTypes.bool.isRequired,
    hasAction: PropTypes.bool.isRequired,
    allowAdd: PropTypes.bool.isRequired,
    toggleStatusPopup: PropTypes.func.isRequired,
    showStatusPopup: PropTypes.func.isRequired,
    baseModel: PropTypes.string.isRequired,
    onFindModelNames: PropTypes.func.isRequired,
}

export default ModelRow;