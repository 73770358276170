import { INIT, SUCCESS, LOADING, ERROR } from "../../utils/constant";
import {
  GET_SIZINGS, GET_SIZINGS_ERROR, GET_SIZINGS_SUCCESS,
  GET_COPY_SIZING, GET_COPY_SIZING_ERROR, GET_COPY_SIZING_SUCCESS,
  GET_ORDERS, GET_ORDERS_SUCCESS, GET_ORDERS_ERROR,
  GET_ORDER_DETAILS, GET_ORDER_DETAILS_SUCCESS, GET_ORDER_DETAILS_ERROR,
  GET_QUOTE, GET_QUOTE_SUCCESS, GET_QUOTE_ERROR,
  GET_QUOTE_DETAILS, GET_QUOTE_DETAILS_SUCCESS, GET_QUOTE_DETAILS_ERROR,
  GET_ADDED_DATA, GET_ADDED_DATA_SUCCESS, GET_ADDED_DATA_ERROR,
  GET_MY_ENTERED_MODELDATA, GET_MY_ENTERED_MODELDATA_SUCCESS, GET_MY_ENTERED_MODELDATA_ERROR,
  GET_SUBMIT_DATA, GET_SUBMIT_DATA_SUCCESS, GET_SUBMIT_DATA_ERROR,
  INIT_SUBMIT_DATA, INIT_SUBMIT_DATA_SUCCESS, INIT_SUBMIT_DATA_ERROR,
  GET_ORDER_COPY_LINES, GET_ORDER_COPY_LINES_SUCCESS, GET_ORDER_COPY_LINES_ERROR,
  GET_CANCEL_DATA, GET_CANCEL_DATA_SUCCESS, GET_CANCEL_DATA_ERROR,
  GET_DELETE_DATA, GET_DELETE_DATA_SUCCESS, GET_DELETE_DATA_ERROR,
  STATUS_INIT_SUBMIT_INIT, STATUS_INIT_SUBMIT_SUCCESS, STATUS_INIT_SUBMIT_FAILED,
  STATUS_COPY_SIZING_INIT, STATUS_COPY_SIZING_SUCCESS, STATUS_COPY_SIZING_FAILED,
  CLEAR_CONCURRENT_FLAG

} from "./constant";

import {
  prepareOrderDetailsData, prepareQuoteDetailsData, prepareSubmitLinesData,
  prepareConcurrentFlag
} from "../../utils/helper";

const initialState = {
  phase: INIT,
  sizing: null,
  error: null,
  orders: null,
  orderDetails: {},
  quoteData: null,
  quoteDetails: {},
  addedData: null,
  enteredModelData: null,
  submittedData: null,
  cancelledData: null,
  copyLines: [],
  copySizingsData: null,
  editData: [],
  status: '',
  concurrentFlag: "",
  concurrentStatus: ""
};

export function orderStore(state = initialState, action) {
  switch (action.type) {
    case GET_SIZINGS:
    case GET_ORDERS:
    case GET_ORDER_DETAILS:
    case GET_QUOTE:
    case GET_QUOTE_DETAILS:
    case GET_ADDED_DATA:
    case GET_SUBMIT_DATA:
    case GET_CANCEL_DATA:
    case GET_ORDER_COPY_LINES:
    case GET_DELETE_DATA:
      return {
        ...state,
        phase: LOADING
      };
    case GET_SIZINGS_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        sizing: action.data,
        error: null
      };
    case GET_COPY_SIZING:
      return {
        ...state,
        phase: LOADING,
        status: STATUS_COPY_SIZING_INIT
      }
    case GET_SIZINGS_ERROR:
    case GET_ORDERS_ERROR:
    case GET_ORDER_DETAILS_ERROR:
    case GET_QUOTE_ERROR:
    case GET_QUOTE_DETAILS_ERROR:
    case GET_ADDED_DATA_ERROR:
    case GET_MY_ENTERED_MODELDATA_ERROR:
    case GET_SUBMIT_DATA_ERROR:
    case GET_CANCEL_DATA_ERROR:
    case GET_ORDER_COPY_LINES_ERROR:
    case GET_DELETE_DATA_ERROR:
      return {
        ...state,
        phase: ERROR,
        error: action.error
      };
    case GET_COPY_SIZING_ERROR:
      return {
        ...state,
        phase: ERROR,
        error: action.error,
        status: STATUS_COPY_SIZING_FAILED
      }

    case GET_COPY_SIZING_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        copySizingsData: action.data,
        error: null,
        status: STATUS_COPY_SIZING_SUCCESS,
      };

    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        orders: action.data,
        error: null
      };
    case GET_ORDER_DETAILS_SUCCESS:
      let orderDetails = Object.assign({}, state.orderDetails);
      let id = action.data.id;
      let result = prepareOrderDetailsData(action.data.data);
      orderDetails[id] = result;
      return {
        ...state,
        phase: SUCCESS,
        orderDetails,
        error: null
      };
    case GET_QUOTE_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        quoteData: action.data,
        error: null
      };

    case GET_QUOTE_DETAILS_SUCCESS: {
      let quoteDetails = Object.assign({}, state.quoteDetails);
      let id = action.data.id;
      let result = prepareQuoteDetailsData(action.data.data);
      quoteDetails[id] = result;
      return {
        ...state,
        phase: SUCCESS,
        quoteDetails,
        error: null
      };
    }

    case GET_ADDED_DATA_SUCCESS:
      // update entered data
      //let enteredModelData = Object.assign([], state.enteredModelData);
      return {
        // ...state,
        // phase: SUCCESS,
        // addedData: action.data,
        // error: null,
      };

    case GET_MY_ENTERED_MODELDATA:
      return {
        ...state,
        phase: LOADING,
        concurrentFlag: "",
      };
    case GET_MY_ENTERED_MODELDATA_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        enteredModelData: action.data,
        concurrentFlag: prepareConcurrentFlag(action.data),
        error: null
      };

    case GET_SUBMIT_DATA_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        submittedData: prepareSubmitLinesData(action.data),
        error: null
      };

    case INIT_SUBMIT_DATA:
      return {
        ...state,
        phase: LOADING,
        status: STATUS_INIT_SUBMIT_INIT
      };
    case INIT_SUBMIT_DATA_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        submittedData: prepareSubmitLinesData(action.data),
        error: null,
        status: STATUS_INIT_SUBMIT_SUCCESS
      };
    case INIT_SUBMIT_DATA_ERROR:
      return {
        ...state,
        phase: ERROR,
        error: action.error,
        status: STATUS_INIT_SUBMIT_FAILED
      };

    case CLEAR_CONCURRENT_FLAG:
      return {
        ...state,
        concurrentFlag: '',
      };

    case GET_CANCEL_DATA_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        cancelledData: action.data,
        error: null
      };
    case GET_ORDER_COPY_LINES_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        copyLines: action.data,
        error: null
      };

    case GET_DELETE_DATA_SUCCESS:
      return {
        ...state,
        error: null
      };

    default:
      return state;
  }
}

export const getSizing = payload => ({
  type: GET_SIZINGS,
  payload
});

export const getCopySizing = payload => ({
  type: GET_COPY_SIZING,
  payload
});

export const getOrders = payload => ({
  type: GET_ORDERS,
  payload
});

export const getOrderDetails = payload => ({
  type: GET_ORDER_DETAILS,
  payload
});

export const getQuote = payload => ({
  type: GET_QUOTE,
  payload
});

export const getQuoteDetails = payload => ({
  type: GET_QUOTE_DETAILS,
  payload
});

export const getAddedData = payload => ({
  type: GET_ADDED_DATA,
  payload
});

export const getMyEnteredModel = payload => ({
  type: GET_MY_ENTERED_MODELDATA,
  payload
});

export const getSubmitedData = payload => ({
  type: GET_SUBMIT_DATA,
  payload
});

export const initSubmitData = payload => ({
  type: INIT_SUBMIT_DATA,
  payload
});

export const clearConcurrentFlag = payload => ({
  type: CLEAR_CONCURRENT_FLAG,
  payload
});

export const getCancelledData = payload => ({
  type: GET_CANCEL_DATA,
  payload
});

export const getCopyLines = payload => ({
  type: GET_ORDER_COPY_LINES,
  payload
});

export const getDeleteData = payload => ({
  type: GET_DELETE_DATA,
  payload
});
